import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AppService } from '../services/app.service';
import { CustomersService } from '../services/customers.service';
import { ICaseSearchOptions, IContactPerson, ICustomerStatistics, ICustomerLetterSearchOptions, ICustomerSummary, IDashboardNotifications, WebUserRight, IWebNewsModel } from '../shared/models';
import { LocalizationService, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { DashboardService } from './dashboard.service';
import { TwofactorUserSetupComponent } from '../shared/twofactor/twofactor-user-setup/twofactor-user-setup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private dashboardService: DashboardService,
    private customerService: CustomersService,
    private application: AppService,
    private configuration: AppConfigService,
    private localization: LocalizationService,
    private translateService: TranslateService) { }

  subscriptions: Subscription[] = [];
  userCustomerSummary: ICustomerStatistics;
  customerNumber$ = this.application.customerNumber$;
  customer: ICustomerSummary;
  currencyCode: string;
  numberFormat: LocalizedNumberFormat;
  integerPipeFormat: LocalizedNumberFormat;
  currencySymbol: string;
  currencyPipeFormat: LocalizedNumberFormat;
  untreatedDocumentsQueryParams = { state: "UNREAD" } as ICustomerLetterSearchOptions;
  userHasAccessToDebtCollectionList: boolean;
  toDebtCollectionCasesRestCapitalSubLabel: string;

  statistics$: Observable<ICustomerStatistics>;
  contactPerson$: Observable<IContactPerson>;
  dashboardNotifications$: Observable<IDashboardNotifications>;
  newsAndAlerts$ = this.dashboardService.getDashboardNews().pipe(shareReplay(1));
  dashboardAlert$: Observable<IWebNewsModel>;
  news$: Observable<IWebNewsModel[]>;

  ngOnInit(): void {
    this.loadData();

    this.currencyCode = this.configuration.getConfig().currencyCode;
    
    this.subscriptions.push(...[
      this.application.customer$.subscribe(x => this.customer = x),
      this.localization.settings$.subscribe(x => {
        this.numberFormat = x.numberPipeFormat;
        this.integerPipeFormat = x.integerPipeFormat;
        this.currencySymbol = x.currency;
        this.currencyPipeFormat = x.currencyPipeFormat;
      })
    ]);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(x => x.unsubscribe());
  }

  getActiveCasesQueryParams() {
    return { term: null, state: 'Active' } as ICaseSearchOptions;
  }

  getClosedCasesQueryParams() {
    return { term: null, state: 'Closed' } as ICaseSearchOptions;
  }

  private loadData(): void {
    this.statistics$ = this.customerNumber$
      .pipe(
        mergeMap(customerNumber =>
          this.customerService.getStatistics(customerNumber)),
        shareReplay(1)
      );

    this.contactPerson$ = this.customerNumber$
      .pipe(
        mergeMap(customerNumber => this.dashboardService.getContactPerson(customerNumber)),
        shareReplay(1));

    this.dashboardNotifications$ = this.customerNumber$.pipe(
      mergeMap(customerNumber =>
        this.dashboardService.getDashboardNotifications(customerNumber).pipe(
          catchError(() => of({ tasksCount: 0, casesWithNewInfoCount: 0, untreatedDocumentsCount: 0, flaggedCasesCount: 0 } as IDashboardNotifications))
        )),
      shareReplay(1));

     this.subscriptions.push(this.application.getPermissions().subscribe(_userPermissions => {
        this.userHasAccessToDebtCollectionList = (_userPermissions?.rights ?? []).indexOf(WebUserRight.ToDebtCollectionList) >= 0;
        const settingNumberOfDaysDebtCollectionList = _userPermissions?.settingNumberOfDaysDebtCollectionList;
        this.toDebtCollectionCasesRestCapitalSubLabel = this.translateService.instant('Dashboard.WithinDays', { settingNumberOfDaysDebtCollectionList });
      }));

      this.dashboardAlert$ = this.newsAndAlerts$.pipe(map(_ =>  _.find(x => x.newsSection == 'dashboardAlert')));

      this.news$ = this.newsAndAlerts$.pipe(map(_ =>  _.filter(x => x.newsSection == 'news')));
  }
}
