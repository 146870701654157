import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LocalizationService, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { IRecentlyCreatedCase } from 'src/app/shared/models';
import { NewCaseService } from '../new-case.service';

@Component({
  selector: 'app-recently-created-cases',
  templateUrl: './recently-created-cases.component.html',
  styleUrls: ['./recently-created-cases.component.scss']
})
export class RecentlyCreatedCasesComponent implements OnInit, OnDestroy {

  recentlyCreatedCases$: Observable<IRecentlyCreatedCase[]>;
  numberFormat: LocalizedNumberFormat;
  subscription: Subscription;

  constructor(private newCaseService: NewCaseService,
    private localizationService: LocalizationService) {}

  ngOnInit(): void {
    this.recentlyCreatedCases$ = this.newCaseService.getRecentlyCreatedCases();
    this.subscription = this.localizationService.settings$.subscribe((_ => this.numberFormat = _.numberPipeFormat));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
