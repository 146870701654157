import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ICanDeactivateComponent } from './models';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  {
  canDeactivate(
    component: ICanDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (component.canDeactivate === undefined || component.canDeactivate === null || component.canDeactivate()) {
        return true;
      } else {
        return confirm('You have unsaved changes. Press Cancel to go back, or OK to lose these changes.');
      }
  }
  
}
