import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { CustomersService } from 'src/app/services/customers.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { ICustomerLetter, ICustomerLetterSearchOptions } from 'src/app/shared/models';
import { RxHook, RxState } from 'src/app/shared/RxHook';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-case-remittance',
  templateUrl: './case-remittance.component.html',
  styleUrls: ['./case-remittance.component.scss']
})
export class CaseRemittanceComponent implements OnChanges {


  constructor (private service: CustomersService, private rxHook: RxHook
    , private utils: UtilsService, private notifiation: MessageToUiService) { }

  @Input() caseNumber: number;

  data: RxState<ICustomerLetter[]>;

  ngOnChanges(changes: SimpleChanges) {
    var caseNrChange = changes['caseNumber'];

    if (caseNrChange.currentValue != caseNrChange.previousValue) {
      this.load();
    }
  }

  private load() {
    this.data = this.rxHook.use(this.service.searchLetters({ caseNumber: this.caseNumber, type: 'REM', pageSize: 100 } as ICustomerLetterSearchOptions)
      .pipe(map(_ => _.pageItems.map(l => Object.assign(l, this.getFileProperties(l))))));
  }

  openLetter(letter: ICustomerLetter & { fileName: string, mimeType: string }) {

    this.service.getLetter(letter.customerNumber, letter.letterGuid)
      .pipe(map(_ => this.utils.b64toBlob(_.content, letter.mimeType)))
      .subscribe(blob => {

        var fileURL = window.URL.createObjectURL(blob);
        let tab = window.open();
        tab.location.href = fileURL;

      },
        error => { this.notifiation.genericError(); }
      );
  }

  private getFileProperties(letter: ICustomerLetter): { fileName: string, mimeType: string } {

    return letter.code?.includes('CSV')
      ? { fileName: `${letter.customerNumber}_${letter.type}_${letter.remittanceNumber ?? ''}.csv`, mimeType: 'text/csv' }
      : { fileName: `${letter.customerNumber}_${letter.type}_${letter.remittanceNumber ?? ''}.pdf`, mimeType: 'application/pdf' };
  }
}
