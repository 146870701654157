<div mat-dialog-content>
  <ng-container *ngIf="agreementTerms$ | async as agreementTerms; else loading">
    <p id="{{termsId}}">
      {{ 'WithdrawCaseDialog.WithdowCaseInfo' | translate:{currency: agreementTerms.currencyCode, amount:
      agreementTerms.totalCosts | number : numberFormat.style: numberFormat.culture} }}
    </p>
    <p class="required">{{'Case.WithdrawCaseReason' | translate}}:</p>
    <textarea name="note" [(ngModel)]="reason" class="required text-input" rows="4" [required]="true"
      maxlength="1000"></textarea>
  </ng-container>
</div>
<mat-dialog-actions class="footer">
  <button type="button" class="button-basic" (click)="acceptAggreementTerms()" [disabled]="!canAccept">
    {{'WithdrawCaseDialog.Accept' | translate}}
  </button>

  <button type="button" class="button-red" (click)="cancel()">
    {{'WithdrawCaseDialog.Cancel' | translate}}
  </button>
</mat-dialog-actions>

<ng-template #loading>
  <mat-progress-bar style="margin-top: 1rem;" mode="indeterminate"></mat-progress-bar>
</ng-template>