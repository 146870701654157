<p-table [value]="list" [scrollable]="true" selectionMode="single" [scrollable]="true"
    scrollHeight="flex" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th>{{'Case.Securities.Document' | translate}}</th>
      <th>{{'Case.Securities.Object' | translate}}</th>
      <th>{{'Case.Securities.DebtorName' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-security>
    <tr>
      <td>{{ security.document }}</td>
      <td>{{ security.object }}</td>
      <td>{{ security.debtorName }}</td>
    </tr>
  </ng-template>
</p-table>
