import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppConfigService } from '../../app-config.service';
import { KreditorService } from '../../shared/kreditor.service';
import { ICaseRegistered as INewCaseRegistered, ICountry, INewCase, INewInvoice, IRecentlyCreatedCase, ServiceResult, IUploadFileResult } from '../../shared/models';
import { UtilsService } from '../../shared/utils.service';
import { CasesService } from 'src/app/services/cases.service';

@Injectable({
  providedIn: 'root'
})
export class NewCaseService {
  constructor (
    private kreditorWs: KreditorService,
    private config: AppConfigService,
    private utils: UtilsService,
    private casesService: CasesService) { }

  createCase(newCase: INewCase, reminderAttachments: File[], invoiceAttachments: File[]): Observable<INewCaseRegistered> {
    return this.kreditorWs.post<ServiceResult<INewCaseRegistered>, INewCase>('cases', newCase)
      .pipe(mergeMap(createResponse => {
        const registered = createResponse.result;

        const uploadReminders$ = reminderAttachments?.length > 0 ? this.uploadCaseDocuments(registered.caseNumber, reminderAttachments) : of([]);
        const uploadInvoicesRequestArray$ = this.buildUploadInvoicesRequestArray(registered.caseNumber, newCase, invoiceAttachments);

        return uploadReminders$
          .pipe(
            mergeMap(uploadRemindersResult => {
              const failedUploads = uploadRemindersResult.filter(x => !x.isSuccess);
              registered.failedUploadDocuments = failedUploads.map(x => x.fileName);
              return forkJoin(uploadInvoicesRequestArray$).pipe(
                map(invoicesUploadResult => {
                  const failedUploads = invoicesUploadResult.filter(x => !x.isSuccess);
                  registered.failedUploadDocuments = registered.failedUploadDocuments.concat(failedUploads.map(x => x.fileName));
                  registered.attachmentsUploadSuccess = registered.failedUploadDocuments.length == 0;
                  return registered;
                })
              )
            }),
            catchError(uploadError => {
              registered.attachmentsUploadSuccess = false;
              return of(registered)
          }));
      }));
  }

  uploadCaseDocuments(caseNumber: number, documents: File[]): Observable<IUploadFileResult[]> {
    return this.kreditorWs.postFormData<ServiceResult<IUploadFileResult[]>>(`cases/${caseNumber}/documents/multiple`, this.utils.toFileFormData(documents, 'attachments'))
      .pipe(map(_ => _.result));
  }

  getDefaults(): Observable<INewCase> {
    return this.getInvoiceDefaults().pipe(map(inv => {
      return {
        invoices: [inv],
        countryCode: this.config.getConfig().countryCode,
        shouldSendReminder: true,
        debtorHasObjections: false,
        isRoadFreight: false
      } as INewCase;
    }));
  }

  getInvoiceDefaults(): Observable<INewInvoice> {
     return of({ amount: 0, currencyCode: this.config.getConfig().currencyCode } as INewInvoice);
  }

  getCountries(): Observable<ICountry[]> {
    return this.kreditorWs.getServiceResult<ICountry[]>('countries')
      .pipe(map(_ => _.result.sort((x,y) => x.name.localeCompare(y.name))));
  }

  getCurrencies(): Observable<string[]> {
    return this.kreditorWs.getServiceResult<string[]>('firm/currencies')
      .pipe(map(_ => _.result));
  }

  getRecentlyCreatedCases(): Observable<IRecentlyCreatedCase[]> {
    return this.kreditorWs.getServiceResult<IRecentlyCreatedCase[]>('cases/recentlyCreated')
      .pipe(map(_ => _.result));
  }

  private buildUploadInvoicesRequestArray(caseNumber: number, newCase: INewCase, invoiceAttachments: File[]): Observable<IUploadFileResult>[] {
    return newCase.invoices.map(x => {
      const invoiceCopy = invoiceAttachments.find(y => y.name == x.invoiceCopyName);
      return this.casesService.uploadInvoiceCopy(caseNumber, x.number, invoiceCopy)
      .pipe(
        map(_ => { return { fileName: invoiceCopy.name, isSuccess: true } as IUploadFileResult }),
        catchError(_ => { return of({ fileName: invoiceCopy.name, isSuccess: false } as IUploadFileResult) }));;
    });
  }
}
