import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';
import { MessagesService } from 'src/app/messages/messages.service';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';
import { ICaseMessage, ICaseNoteAttachment, ICaseNoteTreatRequest } from 'src/app/shared/models';
import { ValidatorService } from 'src/app/shared/validator.service';

@Component({
  selector: 'app-note-list',
  standalone: true,
  imports: [CommonModule, TranslateModule, TableModule, MatTooltipModule],
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss']
})
export class NoteListComponent {
  @Input() list: ICaseMessage[];
  @Input() caseNumber: number;

  @Output() downloadAttachments = new EventEmitter<ICaseNoteAttachment[]>();
  @Output() downloadAttachment = new EventEmitter<string>();
  @ViewChild('form') form: NgForm;


  constructor(private messagesService: MessagesService,
    private spinner: GlobalSpinnerService,
    private validator: ValidatorService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  onDownloadAttachments(attachments: ICaseNoteAttachment[]) {
    this.downloadAttachments.emit(attachments);
  }

  onDownloadAttachment(documentIdentifier: string) {
    this.downloadAttachment.emit(documentIdentifier);
  }

  public treat(note: ICaseMessage) {
    this.spinner.show();
    let treatRequest: ICaseNoteTreatRequest = { id: note.id, caseNumber: this.caseNumber, isTreated: true };

    this.messagesService.treatNote(this.caseNumber, treatRequest)
      .pipe(finalize(() => {
        this.spinner.hide();
      }))
      .subscribe({
        next: () => {
          note.isTreated = true;
          this.changeDetectorRef.detectChanges();
        }
        , error: (e) => {
          this.spinner.hide();
          this.validator.handleError(this.form, e);
        }
      });
  }
}
