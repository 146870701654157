<div class="reset-container">
  <div class="form-header">
    <div class="logo-container">
      <img src="./assets/sveaLogo.png">
    </div>
  </div>
  <form #resetPasswordForm="ngForm">
    <fieldset>
      <custom-password-input [(ngModel)]="newPassword" [(password)]="newPassword" #password="ngModel"
        placeholder="{{'Login.NewPassword' | translate}}" [required]="true" [minlength]="8">
      </custom-password-input>
      <div [hidden]="password.valid || (!password.touched && !password.dirty)" class="input-error">
        <div *ngIf="password.errors?.required">{{'Login.Errors.PasswordRequired' | translate}}</div>
        <div *ngIf="password.errors?.minlength">{{'Login.Errors.PasswordLength' | translate}}</div>
      </div>
    </fieldset>
    <fieldset>
      <custom-password-input [(ngModel)]="confirmPassword" [(password)]="confirmPassword" name="confirmPassword"
        placeholder="{{'Login.ConfirmPassword' | translate}}" #confirmation="ngModel">
      </custom-password-input>
      <div [hidden]="confirmation.valid || (!confirmation.touched && !confirmation.dirty)" class="input-error">
        <div *ngIf="confirmation.hasError('notEqual')">{{'Login.Errors.PasswordMustMatch' | translate}}</div>
      </div>
    </fieldset>
    <button class="button-basic" type="submit" (click)="onResetPassword()">{{'Login.ResetPassword' | translate}}
    </button>
  </form>
</div>
