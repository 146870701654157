<ng-container *ngIf="data$ | async as data">
  <div mat-dialog-content>
    <app-payment-link #paymentLink [displaySend]="false" [debtor]="data.debtor" [cases]="data.cases" [contractReference]="contractReference"
      (sent)="onSuccess()"></app-payment-link>
  </div>
  <mat-dialog-actions class="footer">
    <button #cancelButton type=" button" class="button-basic" (click)="cancel()">
      {{'Cancel' | translate}}
    </button>

    <button #sendButton type="button" class="button-red" [disabled]="!paymentLink.canSend"
      (click)="paymentLink.submit()">
      {{'PaymentLink.Send' | translate}}
    </button>
  </mat-dialog-actions>
</ng-container>
<ng-template #loading>
  <mat-progress-bar style="margin-top: 1rem;" mode="indeterminate"></mat-progress-bar>
</ng-template>
