import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { ServiceResult, ICaseMessageApproval, ICaseMessage, ICaseRecallCost, INewMessage, ICaseNoteSummary, ICaseNoteTreatRequest } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private kreditorService: KreditorService) { }

  sendMessage(caseNumber: number, formData: FormData): Observable<ServiceResult<object>> {
    return this.kreditorService.postFormData<ServiceResult<object>>(`cases/${caseNumber}/messages`, formData);
  }

  getCaseMessages(caseNumber: number): Observable<ICaseMessage[]> {
    return this.kreditorService.getServiceResult<ICaseMessage[]>(`cases/${caseNumber}/messages`)
      .pipe(map(_ => _.result));
  }

  acceptMessageApproval(caseNumber: number, messageId: number): Observable<ICaseMessageApproval> {
    return this.kreditorService.post<ICaseMessageApproval, ICaseMessageApproval>(`cases/${caseNumber}/approvals`,
      { messageId: messageId, type: 'Accepted' } as ICaseMessageApproval);
  }

  declineMessageApproval(caseNumber: number, messageId: number): Observable<ICaseMessageApproval> {
    return this.kreditorService.post<ICaseMessageApproval, ICaseMessageApproval>(`cases/${caseNumber}/approvals`,
      { messageId: messageId, type: 'Declined' } as ICaseMessageApproval);
  }

  getWithdrawCost(caseNumber: number): Observable<ICaseRecallCost> {
    return this.kreditorService.getServiceResult<ICaseRecallCost>(`cases/${caseNumber}/recallcosts`)
      .pipe(map(_ => _.result));
  }

  sendWithdrawCaseRequest(caseNr: number, acceptedTerms: string): Observable<INewMessage> {
    return this.kreditorService.post<ServiceResult<INewMessage>, INewMessage>(`cases/${caseNr}/recall`,
      { caseNumber: caseNr, text: acceptedTerms } as INewMessage)
      .pipe(map(_ => _.result));
  }

  treatNote(caseNumber: number, note: ICaseNoteTreatRequest): Observable<ICaseNoteSummary> {
    return this.kreditorService.postServiceResult<ICaseNoteSummary, ICaseNoteTreatRequest>(`cases/${caseNumber}/messages/${note.id}/treat`, note)
    .pipe(map(_ => _.result));
  }
}
