import { KeyValue } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, signal, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'custom-multi-select',
  templateUrl: './custom-multi-select.component.html',
  styleUrls: ['./custom-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomMultiSelectComponent),
      multi: true
    }]
})
export class CustomMultiSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  /*
  * The key uniquely identifies an option - it can have any type
  * The value can be either a translation tag or directly the option value
  */
  @Input()
  options: KeyValue<any, string>[];

  @Input()
  selectedKeys: any[] = [];
  @Output()
  selectedKeysChange = new EventEmitter<any[]>();

  @Input()
  placeholderTranslationTag?: string = 'General.Select';

  @Input()
  searchEnabled: boolean;

  @Input()
  clearEnabled: boolean;

  @Input()
  displayDetailedSelection: boolean = false;

  @Input()
  disabled : boolean = false;

  selectedValues: string[] = [];
  selectedDetailedValues: string[] = [];
  filteredOptions: SelectedKeyValue<any, string>[];

  disableSelect = signal<boolean>(false);
  displayDropDown = signal<boolean>(false);

  @ViewChild('searchBox') set searchBox(searchBox: ElementRef) {
    if (searchBox != null) searchBox.nativeElement.focus();
  }
  @ViewChild('trigger') trigger: ElementRef;

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.relatedTarget == null || event.relatedTarget != this.trigger.nativeElement)
      this.displayDropDown.set(false);
  }

  @HostListener('click', ['$event']) onTriggerClick($event: MouseEvent) {
    if(this.disabled) return;

    this.displayDropDown.set(!this.displayDropDown());

    $event.stopPropagation();
    document.dispatchEvent(new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
      relatedTarget: this.trigger.nativeElement
    }));
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.options) {
      if (this.selectedKeys != null) 
        this.selectedValues = this.options.filter(x => this.selectedKeys.includes(x.key)).map(x => x.value);
      
      this.filteredOptions = this.mapToSelectedKeyValue(this.options);

      this.searchEnabled = this.searchEnabled && this.filteredOptions.length > 5;
    }

    if (changes['selectedKeys']) {
      if (changes['selectedKeys'].currentValue == null) this.clear();
      else {
        if (this.displayDetailedSelection == true) {
          this.selectedValues = this.options.filter(x => this.selectedKeys.includes(x.key)).map(x => x.value);
          this.selectedDetailedValues =
            this.options.filter(x => this.selectedKeys.includes(x.key))
              .map(x => ' ' + x.value.slice(0, x.value.indexOf(" ")));
        }
        this.selectedValues = this.options?.filter(x => this.selectedKeys?.includes(x.key)).map(x => x.value) ?? [];
      };
    }

    this.disableSelect.set(this.disabled || !this.filteredOptions?.length);
  }

  onSelectedChange(option: SelectedKeyValue<any, string>) {
    option.isSelected = !option.isSelected;

    const selectedOptions = this.filteredOptions.filter(x => x.isSelected);
    this.selectedKeys = selectedOptions.map(x => x.key);
    this.selectedValues = selectedOptions.map(x => x.value);

    this.selectedKeysChange.emit(this.selectedKeys);
  }

  onSearchInputChanged(searchTerm: string) {
    const filteredOptions = this.options.filter(x => x.value.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1);
    this.filteredOptions = this.mapToSelectedKeyValue(filteredOptions);
  }

  clear() {
    if(this.disabled) return;

    this.selectedValues = null;
    this.selectedKeys = [];
    this.filteredOptions = this.filteredOptions.map(x => { x.isSelected = false; return x; });
    this.selectedKeysChange.emit(this.selectedKeys);
  }

  private mapToSelectedKeyValue(keyValuePairs: KeyValue<any, string>[]): SelectedKeyValue<any, string>[] {
    return keyValuePairs.map(x => { return { key: x.key, value: x.value, isSelected: this.selectedKeys?.includes(x.key) } });
  }

  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}

export interface SelectedKeyValue<T, S> extends KeyValue<T, S> {
  isSelected: boolean;
}

