<div class="invoice-list-container">
  <p-table [value]="list" selectionMode="single" [scrollable]="true" scrollHeight="flex"
    [tableStyle]="{'min-height': '100px'}" styleClass="p-datatable-sm" [class.expanded]="isTableExpanded">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'Case.Invoice.Number' | translate}}</th>
        <th>{{'Case.Invoice.Date' | translate}}</th>
        <th>{{'Case.Invoice.DueDate' | translate}}</th>
        <th>{{'Case.Invoice.Amount' | translate}} {{firmCurrencyCode}}</th>
        <th *ngIf="hasInternationalCurrency">{{'Case.Invoice.Amount' | translate}} {{caseCurrencyCode}}</th>
        <th>{{'Case.Invoice.Type' | translate}}</th>
        <th>
          <i class="fas fa-arrow-circle-down" matTooltip="{{'General.Download' | translate}}"
          (click)="onDownloadInvoices()"></i>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invoice>
      <tr>
        <td>
          <div class="invoice-number-container">
            <i matRipple [matRippleColor]="'#00800066'" [matRippleUnbounded]="true" [matRippleCentered]="true"
              class="fas fa-copy clickable" [cdkCopyToClipboard]="invoice.number"></i>
            <span [class.clickable]="invoice.isInvoiceCopyAvailable" (click)="requestCopy(invoice)">{{invoice.number}}
            </span>
          </div>
        </td>
        <td>{{invoice.date | date}}</td>
        <td>{{invoice.dueDate | date}}</td>
        <td>{{invoice.amount | number : numberFormat.style: numberFormat.culture}}</td>
        <td *ngIf="hasInternationalCurrency">{{invoice.currencyAmount | number : numberFormat.style:
          numberFormat.culture}}</td>
        <td colspan="2">{{invoice.amount < 0 ? 'Kreditnota' : 'Faktura' }}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-container *ngIf="list?.length > 5">
    <span class="arrow" [ngClass]="isTableExpanded ? 'up' :'down'" (click)="onToggleExpandTable()"></span>
  </ng-container>
</div>