import { AbstractControl, NgForm } from "@angular/forms";


export class UserDataValidator
{
    constructor(isNewUser : boolean , userData : any, form : NgForm)
    {
        this.userData = userData;
        this.form = form;
        this.isNewUser = isNewUser;
    }

    userData : any = {};
    form : NgForm
    isNewUser : boolean;
    addedPasswordError : boolean = false;

    private validatePasswordsExist()
    {
        let passwordControl = this.form.form.controls.password as AbstractControl;
        let passwordConfirmControl = this.form.form.controls.passwordConfirm;
        
        if(!passwordControl || !passwordConfirmControl)
            return
        
        if(!this.userData.password.value)
        {
            passwordControl.setErrors({errorCode:"IsRequired"})
            this.addedPasswordError = true;
        }

        if(!this.userData.passwordConfirm.value)
        {
            passwordConfirmControl.setErrors({errorCode:"IsRequired"})
            this.addedPasswordError = true;
        }
        
    }

    private validatePasswordsMatch()
    {
        let passwordControl = this.form.form.controls.password;
        let passwordConfirmControl = this.form.form.controls.passwordConfirm;

        if(!passwordControl || !passwordConfirmControl)
            return

        if(this.userData.password.value !== this.userData.passwordConfirm.value)
        {
            passwordControl.setErrors({errorCode:"Passwords_Dont_Match"})
            passwordConfirmControl.setErrors({errorCode:"Passwords_Dont_Match"})
            this.addedPasswordError = true;
        }

    }

    private validatePasswordsHaveLength()
    {
        let passwordControl = this.form.form.controls.password;
        let passwordConfirmControl = this.form.form.controls.passwordConfirm;

        if(!passwordControl || !passwordConfirmControl)
            return

        if(this.userData.password?.value?.length < 8)
        {
            passwordControl.setErrors({errorCode:"PasswordMinimumLength"})
            this.addedPasswordError = true;
        }

        if(this.userData.passwordConfirm?.value?.length < 8)
        {
            passwordConfirmControl.setErrors({errorCode:"PasswordMinimumLength"})
            this.addedPasswordError = true;
        }
    }


    private validatePassword()
    {
        let passwordControl = this.form.form.controls.password;
        let passwordConfirmControl = this.form.form.controls.passwordConfirm;

        passwordControl?.setErrors(null)
        passwordConfirmControl?.setErrors(null)

        if(this.isNewUser || this.userData?.password?.value || this.userData?.passwordConfirm?.value)
        {
            this.validatePasswordsExist();
            if(!this.addedPasswordError) this.validatePasswordsMatch();
            if(!this.addedPasswordError) this.validatePasswordsHaveLength();
        }
    }

    private validateName()
    {
        let nameControl = this.form.form.controls.name;
        
        if(!nameControl)
            return;

        nameControl.setErrors(null)

        if(!this.userData.name)
            return

        if(!this.userData.name.value)
            nameControl.setErrors({errorCode:"IsRequired"})
    }

    private validateEmail()
    {
        let emailControl = this.form.form.controls.webEmail;
        if(!emailControl)
            return;

            emailControl.setErrors(null)

        if(!this.userData.webEmail)
            return

        if(!this.userData.webEmail.value)
            emailControl.setErrors({errorCode:"IsRequired"})
    }


    public validate()
    {
        this.addedPasswordError = false;
        this.validateEmail();
        this.validateName();
        this.validatePassword();
    }

}