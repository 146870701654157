import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { CustomerLetterState, ICaseNotesFilter, ICaseNotesSearchResults, ICustomerLetter, ICustomerLetterSearchOptions, ICustomerLetterSearchResult, ICustomerStatistics, ICustomerSummary, ILastAccessedCaseRow, IPaginatedResponse, IPaginatedSearch, ISubCustomerRequest, ISubCustomerResponse, ServiceResult } from '../shared/models';
import { UtilsService } from '../shared/utils.service';
import { SimpleCache } from '../shared/simple-cache';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  DEFAULT_PAGE_SIZE = 20;

  private _countStatisticsUrl = 'customers/countStatistics';
  private _countStatisticsCacheLifetime = 5 * 60;
  private _customerCountStatisticsCache = {};
  private _userCountStatisticsCache = new SimpleCache<ICustomerStatistics>({
    lifetime: this._countStatisticsCacheLifetime,
    loadFunction: () => this.kreditorService.getServiceResult<ICustomerStatistics>(this._countStatisticsUrl).pipe(
      shareReplay(1),
      map(_ => _.result)
    )
  });

  constructor(private kreditorService: KreditorService, private utils: UtilsService) { }

  getCustomers(): Observable<ICustomerSummary[]> {
    return this.kreditorService.getServiceResult<ICustomerSummary[]>('customers')
      .pipe(map(_ => _.result));
  }

  getCustomer(cusNumber: number): Observable<ICustomerSummary> {
    return this.kreditorService.getServiceResult<ICustomerSummary>(`customers/${cusNumber}`)
      .pipe(map(_ => _.result));
  }

  getStatistics(customerNumber?: number): Observable<ICustomerStatistics> {
    if (customerNumber) { 
      if (!this._customerCountStatisticsCache[customerNumber]) 
        this.cacheCustomerCountStatistics(customerNumber);
      return this._customerCountStatisticsCache[customerNumber].data$;
    }
    return this._userCountStatisticsCache.data$;
  }

  getTasks(paginatedSearch: IPaginatedSearch<ICaseNotesFilter>): Observable<ICaseNotesSearchResults> {
    const url = this.utils.getUrlFromObject('customers/tasks', paginatedSearch);
    return this.kreditorService.getServiceResult<ICaseNotesSearchResults>(url)
      .pipe(map(_ => _.result));
  }

  getNewCaseInformation(paginatedSearch: IPaginatedSearch<ICaseNotesFilter>): Observable<ICaseNotesSearchResults> {
    const url = this.utils.getUrlFromObject('customers/newCaseInformation', paginatedSearch);
    return this.kreditorService.getServiceResult<ICaseNotesSearchResults>(url)
      .pipe(map(_ => _.result));
  }

  searchLetters(options: ICustomerLetterSearchOptions, catchException: boolean = false): Observable<ICustomerLetterSearchResult> {
    let query = `?type=${(options.type ?? 'NA')}`;
    query += `&state=${options.state ?? 'NA'}`;
    query += `&startDate=${options.startDate?.toISOString() ?? ''}`;
    query += `&endDate=${options.endDate?.toISOString() ?? ''}`;
    query += `&pageNumber=${((options.pageNumber ?? 0) === 0 ? '1' : options.pageNumber)}&pageSize=${options.pageSize ?? this.DEFAULT_PAGE_SIZE}`
    query += `&customerNumbers=${options.customerNumber ?? ''}`;
    query += `&caseNumber=${options.caseNumber ?? ''}`;


    return this.kreditorService.getServiceResult<ICustomerLetterSearchResult>(`customers/letters/search${query}`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => {
        if (catchException) {
          return of({ pageItems: [] } as ICustomerLetterSearchResult);
        } throw e;
      }));
  }

  getLetter(customerNumber: number, letterGuid: string): Observable<ICustomerLetter> {
    return this.kreditorService.getServiceResult<ICustomerLetter>(`customers/${customerNumber}/letters/${letterGuid}`)
      .pipe(map(_ => _.result));
  }

  setLettersState(letterState: CustomerLetterState, letterIdentifiers: string[]) {
    return this.kreditorService.post<ServiceResult<number>, string[]>(`customers/letters/states/${letterState}`, letterIdentifiers)
      .pipe(map(_ => _.result));
  }

  addSubCustomer(customerNumber, addSubCustomerRequest: ISubCustomerRequest): Observable<ISubCustomerResponse> {
    return this.kreditorService.postServiceResult<ISubCustomerResponse, ISubCustomerRequest>(`customers/${customerNumber}/subCustomers`, addSubCustomerRequest)
      .pipe(map(_ => _.result));
  }

  getLastAccessedCases(customerNumber?: number, noRows?: number): Observable<ILastAccessedCaseRow[]> {
    let params: string[] = [];

    if (customerNumber != null)
      params.push(`customerNumber=${customerNumber}`)
    if (noRows != null)
      params.push(`noRows=${noRows}`)

    let url = 'customers/lastAccessedCases';

    if (params.length > 0)
      url += "?" + params.join("&");

    return this.kreditorService.getServiceResult<ILastAccessedCaseRow[]>(url)
      .pipe(map(_ => _.result));
  }

  private cacheCustomerCountStatistics(customerNumber: number) {
    this._customerCountStatisticsCache[customerNumber] = new SimpleCache<ICustomerStatistics>({ 
      lifetime: this._countStatisticsCacheLifetime, 
      loadFunction: () => this.kreditorService.getServiceResult<ICustomerStatistics>(`${this._countStatisticsUrl}?number=${customerNumber}`).pipe(
        shareReplay(1),
        map(_ => _.result)
      )
    });
  }
}
