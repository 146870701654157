import localeNb from '@angular/common/locales/nb';
import localeDanish from '@angular/common/locales/da';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { LocalizationService } from './shared/localization/localization.service';
import { LocalizationComponent } from './shared/localization/localization.component';
import { ToolbarComponent } from './navigation/toolbar/toolbar.component';
import { MaterialModule } from './shared/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchBarComponent } from './navigation/search-bar/search-bar.component';
import { MyMissingTranslationHandler } from './shared/localization/missing-translation.handler';
import { CasesModule } from './cases/cases.module';
import { UrlService } from './services/url.service';
import { MessageService } from 'primeng/api';
import { InformationModule } from './information/information.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { UnauthorizedHttpInterceptor } from './auth/unauthorized-http-interceptor';
import { CustomerSelectComponent } from './navigation/customer-select/customer-select.component';
import { ToastModule } from 'primeng/toast';
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { AuthHttpInterceptor as AuthHttpInterceptor } from './auth/auth-http-interceptor';
import { AdminModule } from './admin/admin.module';
import { UserGuard } from './admin/user-guard';
import { UserModule } from './user/user.module';
import { KreditoruiVersionInterceptor } from './shared/interceptors/kreditorui-version.interceptor';

const appInitializerFn = (appConfig: AppConfigService, localization: LocalizationService, injector: Injector) => {
    return () => new Promise<any>(async (resolve: any) => {
        await appConfig.getConfig();

        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

        locationInitialized.then(() => {
            localization.setupLanguage().subscribe((_) => { },
                _ => {
                    console.error(`Problem on localization setup. ${_}`);
                }, () => {
                    resolve(null);
                });
        });

    });
};


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ToolbarComponent,
        SearchBarComponent,
        ResetPasswordComponent,
        CustomerSelectComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        ReactiveFormsModule,
        CasesModule,
        MaterialModule,
        InformationModule,
        ToastModule,
        AdminModule,
        UserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LocalizationComponent,
                deps: [LocalizationService, HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler
            }
        })
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedHttpInterceptor,
            multi: true
        },
        AuthGuard, AppConfigService, LocalizationService, UrlService, UserGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService, LocalizationService, Injector]
        },
        MessageService,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
                ...new MatDialogConfig(),
                panelClass: 'popup'
            } as MatDialogConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KreditoruiVersionInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

registerLocaleData(localeNb, 'nb-NO');
registerLocaleData(localeDanish, 'da-DK');
