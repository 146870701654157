import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { IPaginatedResponse, IPaginatedSearch, KreditorReportType } from '../shared/models';
import { UtilsService } from '../shared/utils.service';
import {
  ICustomCaseSearchOptions, ICustomerCaseBalanceRequest,
  ICustomerMonthlyCaseStatistics, IPortfolioAnalysis, ISavedCustomSearch, IActiveCasesRequest, IClosedCasesRequest, ICustomerCaseBalanceResponse, IClosedCaseReportResult, IActiveCaseReportResult, IReportParameters, ISuccessRateSummary, ICasesStatusRequest, ICustomerCasesStatusSummary, ICustomerCaseToDebtCollectionModel, ICustomerCasesToDebtCollectionRequest, ICaseStatusDetail, ICasesStatusDetailsRequest, ICasesScoreDetailsRequest, ICasesScoreRequest, ICustomerCasesScoreSummary, ICustomerCasesScoreDetail
} from './reports-models';
import { SimpleCache } from '../shared/simple-cache';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private _cacheLifetime = 5 * 60;

  private _monthlyCasesDataUrl = 'reports/monthlycases/data';
  private _customerMonthlyCasesDataCache = { };
  private _userMonthlyCasesDataCache = new SimpleCache<ICustomerMonthlyCaseStatistics[]>({ 
    lifetime: this._cacheLifetime, 
    loadFunction: () => this.kreditorService.getServiceResult<ICustomerMonthlyCaseStatistics[]>(this._monthlyCasesDataUrl).pipe(
      shareReplay(1),
      map(_ => _.result)
    )
  });

  private _successRateSummaryUrl = 'reports/successRate';
  private _customerSuccessRateSummaryCache = { };
  private _userSuccessRateSummaryCache = new SimpleCache<ISuccessRateSummary>({ 
    lifetime: this._cacheLifetime, 
    loadFunction: () => this.kreditorService.getServiceResult<ISuccessRateSummary>(this._successRateSummaryUrl).pipe(
      shareReplay(1),
      map(_ => _.result)
    )
  });
 
  constructor(
    private kreditorService: KreditorService,
    private utilsService: UtilsService
  ) { }

  getAvailableReports(): Observable<KreditorReportType[]> {
    return this.kreditorService.getServiceResult<KreditorReportType[]>('reports').pipe(map(_ => _.result));
  }

  getMonthlyCasesData(customerNumber: number = null): Observable<ICustomerMonthlyCaseStatistics[]> {
    if (customerNumber) { 
      if (!this._customerMonthlyCasesDataCache[customerNumber]) 
        this.cacheCustomerMonthlyCasesData(customerNumber);
      return this._customerMonthlyCasesDataCache[customerNumber].data$;
    }
    return this._userMonthlyCasesDataCache.data$;
  }

  exportExcel(searchOptions: ICustomCaseSearchOptions, languageCode: string): Observable<Blob> {
    return this.kreditorService.postForFile<ICustomCaseSearchOptions>(`reports/search/exportExcel/${languageCode}`, searchOptions);
  }

  getSavedSearches(): Observable<ISavedCustomSearch[]> {
    return this.kreditorService.getServiceResult<ISavedCustomSearch[]>('reports/search/saved').pipe(map(_ => _.result));
  }

  saveSearch(savedSearch: ISavedCustomSearch): Observable<ISavedCustomSearch> {
    return this.kreditorService.postServiceResult<ISavedCustomSearch, ISavedCustomSearch>('reports/search/saved', savedSearch)
      .pipe(map(_ => _.result));
  }

  updateSavedSearch(userPreferenceId: number, savedSearch: ISavedCustomSearch) {
    return this.kreditorService.postServiceResult<ISavedCustomSearch, ISavedCustomSearch>(`reports/search/saved/${userPreferenceId}`, savedSearch)
      .pipe(map(_ => _.result));
  }

  deleteSavedSearch(userPreferenceId: number) {
    return this.kreditorService.delete<number>(`reports/search/saved/${userPreferenceId}`);
  }

  getPortfolioAnalysisData(languageCode: string, customerNumber: number = null): Observable<IPortfolioAnalysis> {
    let url = `reports/portfolioAnalysis?languageCode=${languageCode}`;
    url += customerNumber > 0 ? `&customerNumber=${customerNumber}` : '';
    return this.kreditorService.getServiceResult<IPortfolioAnalysis>(url)
      .pipe(map(_ => _.result));
  }

  getOutstandingAmountsData(paginatedSearch: IPaginatedSearch<ICustomerCaseBalanceRequest>): Observable<ICustomerCaseBalanceResponse> {
    const url = this.utilsService.getUrlFromObject('reports/outstandingAmount', paginatedSearch);
    return this.kreditorService.getServiceResult<ICustomerCaseBalanceResponse>(url)
      .pipe(map(_ => _.result));
  }

  getOutstandingAmountsExcel(request: ICustomerCaseBalanceRequest, languageCode: string): Observable<Blob> {
    const url = this.utilsService.getUrlFromObject(`reports/outstandingAmount/exportExcel/${languageCode}`, request);
    return this.kreditorService.getFile(url);
  }

  getActiveCasesReport(paginatedParameters: IPaginatedSearch<IActiveCasesRequest>): Observable<IPaginatedResponse<IActiveCaseReportResult>> {
    return this.kreditorService.postServiceResult<IPaginatedResponse<IActiveCaseReportResult>, IPaginatedSearch<IActiveCasesRequest>>(
      'reports/activeCases',
      paginatedParameters)
      .pipe(map(_ => _.result));
  }

  exportActiveCasesForExcel(searchOptions: IActiveCasesRequest, languageCode: string): Observable<Blob> {
    return this.kreditorService.postForFile<IActiveCasesRequest>(`reports/activeCases/${languageCode}/export`, searchOptions);
  }

  getClosedCasesReport(paginatedParameters: IPaginatedSearch<IClosedCasesRequest>): Observable<IPaginatedResponse<IClosedCaseReportResult>> {
    return this.kreditorService.postServiceResult<IPaginatedResponse<IClosedCaseReportResult>, IPaginatedSearch<IClosedCasesRequest>>(
      'reports/closedCases', paginatedParameters)
      .pipe(map(_ => _.result));
  }

  exportClosedCasesForExcel(searchOptions: IClosedCasesRequest, languageCode: string): Observable<Blob> {
    return this.kreditorService.postForFile<IClosedCasesRequest>(`reports/closedCases/${languageCode}/export`, searchOptions);
  }

  renderReport(parameters: IReportParameters) : Observable<any>{
    return this.kreditorService.postServiceResult<any, IReportParameters>(`reports/render`, parameters)
    .pipe(map(_ => _.result))
  }

  getSuccessRateSummary(customerNumber?: number) : Observable<ISuccessRateSummary>{
    if (customerNumber) { 
      if (!this._customerSuccessRateSummaryCache[customerNumber]) 
        this.cacheCustomerSuccessRateSummary(customerNumber);
      return this._customerSuccessRateSummaryCache[customerNumber].data$;
    }
    return this._userSuccessRateSummaryCache.data$;
  }

  getStatusReport(paginatedParameters: IPaginatedSearch<ICasesStatusRequest>) : Observable<IPaginatedResponse<ICustomerCasesStatusSummary>>{
    return this.kreditorService.postServiceResult<IPaginatedResponse<ICustomerCasesStatusSummary>, IPaginatedSearch<ICasesStatusRequest>>(
      'reports/status', paginatedParameters)
    .pipe(map(_ => _.result))
  }

  getToDebtCollectionList(paginatedParameters: IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>): Observable<IPaginatedResponse<ICustomerCaseToDebtCollectionModel>> {
    return this.kreditorService.postServiceResult<IPaginatedResponse<ICustomerCaseToDebtCollectionModel>, IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>>(
      'reports/toDebtCollection', paginatedParameters)
      .pipe(map(_ => _.result));
  }

  getStatusDetails(request: ICasesStatusDetailsRequest ): Observable<ICaseStatusDetail[]> {
    return this.kreditorService.postServiceResult<ICaseStatusDetail[], ICasesStatusDetailsRequest>(`reports/status/details`, request)
      .pipe(map(_ => _.result));
  }

  exportStatusReportForExcel(searchOptions: ICasesStatusRequest, languageCode: string): Observable<Blob> {
    return this.kreditorService.postForFile<ICasesStatusRequest>(`reports/status/${languageCode}/export`, searchOptions);
  }

  getScoreReport(parameteres: ICasesScoreRequest) : Observable<ICustomerCasesScoreSummary[]>{
    return this.kreditorService.postServiceResult<ICustomerCasesScoreSummary[], ICasesScoreRequest>(
      'reports/score', parameteres)
    .pipe(map(_ => _.result))
  }

  getScoreDetails(request: ICasesScoreDetailsRequest ): Observable<ICustomerCasesScoreDetail[]> {
    return this.kreditorService.postServiceResult<ICustomerCasesScoreDetail[], ICasesScoreDetailsRequest>(`reports/score/details`, request)
      .pipe(map(_ => _.result));
  }

  exportScoreReportForExcel(searchOptions: ICasesScoreRequest, languageCode: string): Observable<Blob> {
    return this.kreditorService.postForFile<ICasesScoreRequest>(`reports/score/${languageCode}/export`, searchOptions);
  }

  private cacheCustomerMonthlyCasesData(customerNumber: number) {
    this._customerMonthlyCasesDataCache[customerNumber] = new SimpleCache<ICustomerMonthlyCaseStatistics[]>({ 
      lifetime: this._cacheLifetime, 
      loadFunction: () => this.kreditorService.getServiceResult<ICustomerMonthlyCaseStatistics[]>(`${this._monthlyCasesDataUrl}?customerNumber=${customerNumber}`).pipe(
        shareReplay(1),
        map(_ => _.result)
      )
    });
  }

  private cacheCustomerSuccessRateSummary(customerNumber: number) {
    this._customerSuccessRateSummaryCache[customerNumber] = new SimpleCache<ISuccessRateSummary>({ 
      lifetime: this._cacheLifetime, 
      loadFunction: () => this.kreditorService.getServiceResult<ISuccessRateSummary>(`${this._successRateSummaryUrl}?customerNumber=${customerNumber}`).pipe(
        shareReplay(1),
        map(_ => _.result)
      )
    });
  }
}
