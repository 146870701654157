import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/shared/localization/localization.service';

@Component({
  selector: 'app-success-rate-donut',
  templateUrl: './success-rate-donut.component.html',
  styleUrls: ['./success-rate-donut.component.scss']
})
export class SuccessRateDonutComponent implements OnInit, OnDestroy {
  radius = 12;

  @Input()
  color: any;
  @Input()
  percentage: any;
  @Input()
  title: string;

  x = 25;
  y = 20;

  constructor(
    private localization: LocalizationService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getStrokeDasharray(percentage: number): string {
    const circumference = this.radius * 2 * Math.PI;
    return `${percentage * circumference / 100} ${circumference}`;
  }

  getCirclePath() {
    return `M ${this.x}, ${this.y - this.radius}
    a ${this.radius},${this.radius} 0 1,1 0,${this.radius * 2}
    a ${this.radius},${this.radius} 0 1,1 0,-${this.radius * 2}`;
  }

}
