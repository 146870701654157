import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, signal } from '@angular/core';
import { ISelectableTableColumn } from '../models';

@Component({
  selector: 'app-table-select-columns',
  templateUrl: './table-select-columns.component.html',
  styleUrls: ['./table-select-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSelectColumnsComponent implements OnChanges {
  @Input() 
  columnsList: ISelectableTableColumn[] = [];
  @Input() 
  position: 'left' | 'right';
  @Output()
  columnsListChange = new EventEmitter<ISelectableTableColumn[]>();

  selectedColumns = signal<ISelectableTableColumn[]>([]);
  availableColumns = signal<ISelectableTableColumn[]>([]);
  displayList = signal(false);

  constructor(private currentElement: ElementRef) {}

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if(!this.currentElement.nativeElement.contains(event.target))
      this.displayList.set(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['columnsList']) {
      this.selectedColumns.set(this.columnsList.filter(x => x.isSelected));
      this.availableColumns.set(this.columnsList.filter(x => !x.isSelected));
    }
  }

  onDragStart(event: DragEvent, draggedObjectName: string, sourceListType: ListType) {
    let index = null;
    if (sourceListType == 'Available') {
      index = this.availableColumns().findIndex((l) => l.name === draggedObjectName);
    }
    else {
      index = this.selectedColumns().findIndex((l) => l.name === draggedObjectName);
    }
    event?.dataTransfer.setData('sourceListType', sourceListType);
    event?.dataTransfer?.setData('index', index.toString());
  }

  onDragOver(event: Event) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, destinationList: ListType) {
    event.preventDefault();

    const draggedItemIndex = Number(event?.dataTransfer?.getData('index'));
    const sourceListType = (event?.dataTransfer?.getData('sourceListType'));
    if (typeof draggedItemIndex !== 'number') {
      return;
    }

    if (destinationList == 'Selected') {
      if (sourceListType == 'Available') this.selectColumn(draggedItemIndex);
      else this.orderSelected(event, draggedItemIndex);
    }
    else if (destinationList == 'Available' && sourceListType == 'Selected') {
      this.unselectColumn(draggedItemIndex);
    }
   
    this.emitChanges();
    event?.dataTransfer?.clearData?.();
  }

  private orderSelected(event: DragEvent, draggedItemIndex: number) {
    const targetItemId = (event.target as Element).id;
    const targetItemIndex = this.selectedColumns().findIndex(x => x.name == targetItemId);
    this.selectedColumns.set(this.selectedColumns().move(draggedItemIndex, targetItemIndex));
  }

  private selectColumn(columnIndex: number) {
    const draggedObject = this.availableColumns()[columnIndex];
    this.availableColumns.set(this.availableColumns().filter((data, idx) => idx !== columnIndex));
    this.selectedColumns.set(this.selectedColumns().concat(draggedObject));
  }

  private unselectColumn(columnIndex: number) {
    const draggedObject = this.selectedColumns()[columnIndex];
    this.selectedColumns.set(this.selectedColumns().filter((data, idx) => idx !== columnIndex));
    this.availableColumns.set(this.availableColumns().concat(draggedObject));
  }

  private emitChanges() {
    this.columnsList = this.columnsList.map(x => {
      x.isSelected = this.selectedColumns().includes(x);
      return x;
    }).mapOrder(this.selectedColumns());
    this.columnsListChange.emit(this.columnsList);
  }
}

export type ListType = 'Selected' | 'Available';
