import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalSpinnerService } from './global-spinner.service';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.css']
})
export class GlobalSpinnerComponent {

  isLoading: Subject<boolean> = this.globalSpinnerService.isLoading;

  constructor(private globalSpinnerService: GlobalSpinnerService) {
  }

}
