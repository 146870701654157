<p-table 
  class="cases-table" 
  styleClass="p-datatable-sm" 
  [columns]="selectedColumns()" 
  [value]="list"
  [autoLayout]="true" 
  [paginator]="true" 
  [lazy]="true" 
  [first]="first" 
  [(rows)]="numberOfItemsPerPage"
  [rowsPerPageOptions]="[5,10,15,20]" 
  [totalRecords]="totalNumberOfItems" 
  (onLazyLoad)="onPageChange($event)"
  selectionMode="single" 
  [loading]="isLoading" 
  [scrollable]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>
        <app-table-select-columns [columnsList]="cols()" (columnsListChange)="onColumnsChange($event)"
         position="left">
        </app-table-select-columns>
      </th>
      <th *ngFor="let col of selectedColumns()">
        {{col.translationTag | translate}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-case let-columns="columns">
    <tr (click)="onSelected(case)" *ngIf="localizationSettings$ | async">
      <td></td>
      <td *ngFor="let col of columns" [ngClass]="{'text-center': col.type == 'boolean'}">
        <ng-container [ngSwitch]="col.name">
          <ng-container *ngSwitchCase="'socialNumber'">
            <ng-container *ngIf="case.debtorBirtdate;else orgNr">
              {{ case.debtorBirthdate | date: datePipe }}
            </ng-container>
            <ng-template #orgNr>
              {{ case.debtorOrganizationNumber }}
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="'invoiceNumbers'">
            <p *ngFor="let invoice of case.invoices">
              {{invoice.number}}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'invoiceDates'">
            <p *ngFor="let invoice of case.invoices">
              {{invoice.date | date: datePipe}}
            </p>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'date'">
                {{ case[col.name] | date: datePipe }}
              </ng-container>
              <ng-container *ngSwitchCase="'decimal'">
                {{ case[col.name] | number : numberPipe.style: numberPipe.culture }}
              </ng-container>
              <ng-container *ngSwitchDefault>{{case[col.name]}}</ng-container>
            </ng-container>
          </ng-container>
          </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <label class="label" (click)="onExportExcelClick.emit('exportExcel')">
      {{ 'General.Download' | translate }}
    </label>
  </ng-template>
</p-table>