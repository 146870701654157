import { KeyValue } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, interval, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { debounce, finalize, map, switchMap } from "rxjs/operators";
import { ContractsService } from "src/app/contracts/contracts.service";
import { AppService } from "src/app/services/app.service";
import { CustomersService } from "src/app/services/customers.service";
import { MessageToUiService } from "src/app/services/message-to-ui.service";
import { GlobalSpinnerService } from "src/app/shared/global-spinner/global-spinner.service";
import { ICustomerSummary, IUserSetting, MailFrequencyEnum, UserViewModel, WebUserRight } from "src/app/shared/models";
import { ValidatorService } from "src/app/shared/validator.service";
import { AdminService } from "../admin.service";
import { CustomersTreeElement, InputType, UIContractClaimTypeSummary, UIReportsAvailable } from "./add-edit-users-models";
import { AddEditUserUtils } from "./add-edit-users-utils";
import { BaseUserInfoComponent } from "./base-user-info/base-user-info.component";
import { UserDataValidator } from "./user-data-validator";
import { ReportsService } from "src/app/reports/reports.service";

@Component({
  selector: 'app-add-edit-user',
  templateUrl: "./add-edit-user.component.html",
  styleUrls: ["./add-edit-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditUserComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private customersService: CustomersService,
    private reportsService: ReportsService,
    private contractsService: ContractsService,
    private spinnerService: GlobalSpinnerService,
    private router: Router,
    public validator: ValidatorService,
    private translate: TranslateService,
    private application: AppService,
    private messageToUiService: MessageToUiService) { }

  user: UserViewModel = new UserViewModel();
  customers: CustomersTreeElement[] = [];
  claimTypes: UIContractClaimTypeSummary[] = []
  reportsAvailable: UIReportsAvailable[] = [];
  passwordConfirm: string;
  saveButtonLabel: string = 'Admin.AddUser';
  pageTitleLabel: string = 'Admin.NewUser';
  blockUserLabel: string = 'Admin.BlockUser';
  openUserLabel: string = 'Admin.OpenUser';
  isNewUser: boolean = true;
  requestInProgress: boolean = false;
  canBlockUser$ = new BehaviorSubject<boolean>(false);
  canAddOrEdit$ = new BehaviorSubject<boolean>(false);
  utils: AddEditUserUtils = new AddEditUserUtils();

  isEditingOwnProfile: boolean;

  areCustomersValid: boolean = true;
  customersInvalidMessage: string = 'Admin.Error.AtLeastOneCustomer';
  dataValidator: UserDataValidator;
  $validatingSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  $user: Observable<UserViewModel>;
  allowIperAccess$ = this.application.userHasRight(WebUserRight.Iper);
  userPermissions$ = this.application.getPermissions();

  passwordPlaceholder: string = '********'

  @ViewChildren('form') public forms !: QueryList<NgForm>;
  @ViewChild('baseInfo') public baseInfoComponent !: BaseUserInfoComponent;

  mailFrequency: KeyValue<string, string>[] = [
    { key: MailFrequencyEnum[MailFrequencyEnum.NoEmail], value: this.translate.instant('Admin.MailFrequency.NoEmail') }
    , { key: MailFrequencyEnum[MailFrequencyEnum.Daily], value: this.translate.instant('Admin.MailFrequency.Daily') }
    , { key: MailFrequencyEnum[MailFrequencyEnum.Weekly], value: this.translate.instant('Admin.MailFrequency.Weekly') }
  ];

  translateSubscription: Subscription = null;
  $userCode: ReplaySubject<string | null> = new ReplaySubject<string | null>(1);
  pageDataSubscription$: Subscription;
  formsSubscription$: Subscription;

  baseUserInputs: any;

  ngOnInit(): void {
    this.$validatingSubject.pipe(debounce(() => interval(300)))
      .subscribe(() => {
        this.dataValidator?.validate();
        this.baseInfoComponent.checkChanges();
      })
    this.$user = this.$userCode.pipe(switchMap(userCode => {
      this.isNewUser = userCode == null;
      return userCode ? this.adminService.getUser(userCode) : of(new UserViewModel());
    }));
    let $reports: Observable<UIReportsAvailable[]> = this.reportsService.getAvailableReports().pipe(map(reports => {
      return reports.map(x => { return { name: x.name, id: x.id, isSelected: false, label: x.label } as UIReportsAvailable })
    }));
    let $contracts: Observable<UIContractClaimTypeSummary[]> = this.contractsService.getClaimTypesWithStatuses().pipe(map(claimTypes => {
      return claimTypes.map(x => { let y = x as UIContractClaimTypeSummary; y.isSelected = false; return y })
    }));
    let $customers: Observable<ICustomerSummary[]> = this.customersService.getCustomers();

    this.translateSubscription = this.translate.onLangChange.subscribe(_ => this.updateSelectLabels())

    this.pageDataSubscription$ = combineLatest([this.$user, $reports, $contracts, $customers, this.application.user$, this.userPermissions$])
      .subscribe(([user, reports, claimTypes, customers, applicationUser, permissions]) => {
        this.user = user;
        this.user.webMailFrequency = this.user.webMailFrequency ? this.user.webMailFrequency : MailFrequencyEnum[MailFrequencyEnum.NoEmail];
        this.isEditingOwnProfile = applicationUser.code == this.user.code;

        this.createUserBaseData(this.isEditingOwnProfile, permissions.settings);
        this.setAddEditLabels();
        this.setAccesibleReports(reports,user);
        this.setClaimTypes(claimTypes, user);

        this.customers = this.utils.buildCustomersTree([...customers], user.userCustomers);
        this.baseInfoComponent.inputs = this.baseUserInputs;
        this.baseInfoComponent.checkChanges();
        this.dataValidator = new UserDataValidator(this.isNewUser, this.baseUserInputs, this.forms.first);
        this.$validatingSubject.next(null);

        this.canBlockUser$.next(!this.isEditingOwnProfile && !this.user.locked && !this.isNewUser);
        this.canAddOrEdit$.next(this.areCustomersValid && this.forms?.first?.valid && !this.requestInProgress);

        this.spinnerService.hide();
      });

    this.activatedRoute.params.subscribe(params => {
      this.$userCode.next(params['userCode']);
      this.spinnerService.show();
    });
  }

  private setAddEditLabels()
  {
    if (this.isNewUser) {
      this.saveButtonLabel = 'Admin.AddUser'
      this.pageTitleLabel = 'Admin.NewUser'
    }
    else {
      this.saveButtonLabel = 'Admin.EditUser'
      this.pageTitleLabel = 'Admin.EditUser'
    }
  }

  private setAccesibleReports(reports: UIReportsAvailable[], user: UserViewModel){
    this.reportsAvailable = reports;
    let accessibleReports = user.reportsAccessible.split(",").map(x => parseInt(x));
    for (let i = 0; i < accessibleReports.length; i++) {
      let report = this.reportsAvailable.find(x => x.id == accessibleReports[i]);
      if (report)
        report.isSelected = true
    }
  }

  private setClaimTypes(claimTypes: UIContractClaimTypeSummary[], user: UserViewModel){
    this.claimTypes = claimTypes;

    for (let i = 0; i < user.userClaimTypes.length; i++) {
      let claim = this.claimTypes.find(x => x.claimTypeCode == user.userClaimTypes[i].claimTypeCode);
      if (claim)
        claim.isSelected = true
    }
  }

  ngOnDestroy(): void {
    this.$userCode?.complete();
    this.translateSubscription?.unsubscribe();
    this.$validatingSubject?.complete();
    this.pageDataSubscription$?.unsubscribe();
    this.formsSubscription$?.unsubscribe()
  }

  ngAfterViewInit(): void {
    this.formsSubscription$ = this.forms.first.valueChanges.subscribe(() => {
      this.$validatingSubject.next(null)
    });
  }

  getUserSetting(settingCategory: string, settingName: string) {
    return this.user.userSettings?.find(s => s.category.toUpperCase() == settingCategory.toUpperCase() && s.name.toUpperCase() == settingName.toUpperCase())?.value ?? '';
  }

  updateUserSetting(event, propertyCategory: string, propertyName: string,) {
    if (event != '' && propertyName != '') {
      let existingSettingIndex = this.user.userSettings.findIndex(s => s.category.toUpperCase() == propertyCategory.toUpperCase() && s.name.toUpperCase() == propertyName.toUpperCase());
      if (existingSettingIndex == -1) {
        let newSetting = {
          userId: this.user.id,
          category: propertyCategory,
          name: propertyName,
          value: event,
          fieldType: 'string'
        } as IUserSetting;
        this.user.userSettings.push(newSetting);
      }
      else {
        this.user.userSettings[existingSettingIndex].value = event;
      }
    }
  }

  createUserBaseData(isEditingOwnProfile: boolean, applicationUserSettings: IUserSetting[]) {
    this.initialiseBaseData(isEditingOwnProfile);
    if (isEditingOwnProfile) {
      this.baseUserInputs.password.value = this.passwordPlaceholder;
      this.baseUserInputs.passwordConfirm.value = this.passwordPlaceholder;
    }

    if (this.isNewUser) {
      this.baseUserInputs.password.labelClass = "required";
      this.baseUserInputs.passwordConfirm.labelClass = "required";
      this.user.userSettings = applicationUserSettings;
    }

    this.baseUserInputs.name.value = this.user.name;
    this.baseUserInputs.webEmail.value = this.user.webEmail;
    this.baseUserInputs.passwordLocked.value = this.user.passwordLocked;
    this.baseUserInputs.isSuperuser.value = this.user.webSuperUser;
    this.baseUserInputs.phoneNumber.value = this.user.phone;
    this.baseUserInputs.mailFrequency.value = this.user.webMailFrequency;
    this.baseUserInputs.mailFrequency.options = this.mailFrequency;
  }

  initialiseBaseData(isEditingOwnProfile: boolean) {
    if (this.isNewUser || isEditingOwnProfile) {
      this.baseUserInputs = {
        webEmail: { name: "webEmail", type: "text", value: this.user.webEmail, label: "Admin.UsernameEmail", formOrder: 0, labelClass: "required" } as InputType,
        name: { name: "name", type: "text", value: this.user.name, label: "General.Name", formOrder: 1, labelClass: "required" } as InputType,
        password: { name: "password", type: "text", subtype: "password", label: "Admin.Password", formOrder: 2 } as InputType,
        passwordConfirm: { name: "passwordConfirm", type: "text", subtype: "password", label: "Admin.ConfirmPassword", formOrder: 3 } as InputType,
        passwordLocked: { name: "passwordLocked", type: "bool", value: this.user.passwordLocked, label: "Admin.PasswordLocked", formOrder: 4 } as InputType,
        phoneNumber: { name: "phoneNumber", type: "text", value: this.user.phone, label: "Admin.PhoneNumber", formOrder: 5 } as InputType,
        mailFrequency: { name: "mailFrequency", type: "select", options: this.mailFrequency, value: this.user.webMailFrequency, label: "Admin.MailFrequency", formOrder: 6, labelClass: "required" } as InputType,
        isSuperuser: { name: "isSuperuser", type: "bool", value: this.user.webSuperUser, label: "Admin.UserList.SuperUser", formOrder: 7 } as InputType
      };
    }
    else {
      this.baseUserInputs = {
        webEmail: { name: "webEmail", type: "text", value: this.user.webEmail, label: "Admin.UsernameEmail", formOrder: 0, labelClass: "required" } as InputType,
        name: { name: "name", type: "text", value: this.user.name, label: "General.Name", formOrder: 1, labelClass: "required" } as InputType,
        phoneNumber: { name: "phoneNumber", type: "text", value: this.user.phone, label: "Admin.PhoneNumber", formOrder: 2 } as InputType,
        passwordLocked: { name: "passwordLocked", type: "bool", value: this.user.passwordLocked, label: "Admin.PasswordLocked", formOrder: 3 } as InputType,
        mailFrequency: { name: "mailFrequency", type: "select", options: this.mailFrequency, value: this.user.webMailFrequency, label: "Admin.MailFrequency", formOrder: 4, labelClass: "required" } as InputType,
        isSuperuser: { name: "isSuperuser", type: "bool", value: this.user.webSuperUser, label: "Admin.UserList.SuperUser", formOrder: 5 } as InputType
      };
    }
  }

  updateSelectLabels() {
    this.mailFrequency = [
      { key: MailFrequencyEnum[MailFrequencyEnum.NoEmail], value: this.translate.instant('Admin.MailFrequency.NoEmail') }
      , { key: MailFrequencyEnum[MailFrequencyEnum.Daily], value: this.translate.instant('Admin.MailFrequency.Daily') }
      , { key: MailFrequencyEnum[MailFrequencyEnum.Weekly], value: this.translate.instant('Admin.MailFrequency.Weekly') }
    ]

    this.baseUserInputs.mailFrequency.options = this.mailFrequency;
  }

  manageUserCustomers(event: boolean, customer: CustomersTreeElement) {
    this.updateSelfAndChildrenSelection(event, customer)
    if (!event)
      this.updateParentsSelection(customer)

    if (this.user.userCustomers == null || this.user.userCustomers.length == 0) this.areCustomersValid = false;
    else this.areCustomersValid = true;
  }

  updateParentsSelection(customer: CustomersTreeElement) {
    let parent = this.getParentCustomer(this.customers, customer.number);
    while (parent != null) {
      parent.isSelected = false;
      let parentIndex = this.user.userCustomers.findIndex(x => x == parent.number)
      if (parentIndex != -1)
        this.user.userCustomers.splice(parentIndex, 1);
      parent = this.getParentCustomer(this.customers, parent.number)
    }
  }

  updateSelfAndChildrenSelection(event: boolean, customer: CustomersTreeElement) {
    customer.isSelected = event
    let index = this.user.userCustomers.findIndex(x => x == customer.number);

    if (event) {
      if (index == -1)
        this.user.userCustomers.push(customer.number)
    }
    else {
      if (index != -1)
        this.user.userCustomers.splice(index, 1)
    }

    for (let i = 0; i < customer.children.length; i++) {
      this.updateSelfAndChildrenSelection(event, customer.children[i])
    }

  }

  getParentCustomer(customers: CustomersTreeElement[], childNumber): CustomersTreeElement | null {
    if (customers.some(x => x.number == childNumber)) return null;

    let allChildren = [];

    for (let i = 0; i < customers.length; i++) {
      if (customers[i].children.findIndex(x => x.number == childNumber) != -1)
        return customers[i];
      allChildren = allChildren.concat(customers[i].children);
    }

    return this.getParentCustomer(allChildren, childNumber);
  }

  async blockUser() {
    this.user.locked = true;
    this.saveUser();
    this.canBlockUser$.next(false);
  }

  async addOrEdit() {
    if (this.user.locked) {
      this.unlockUser();
    }
    this.transferProprieties();
    this.user.userClaimTypes = this.claimTypes.filter(x => x.isSelected).map(x => {
      return {
        claimTypeCode: x.claimTypeCode,
        isDefault: false//no way for now to see or make it default in UI
      }
    });
    this.user.reportsAccessible = this.reportsAvailable.filter(x => x.isSelected).map(x => x.id).join(", ");
    if (this.isNewUser)
      this.user.code = await this.adminService.getNextUserCode().toPromise();

    this.saveUser();
  }

  private transferProprieties() {
    this.user.webEmail = this.baseUserInputs.webEmail.value;
    this.user.name = this.baseUserInputs.name.value;
    if(this.baseUserInputs.password != undefined
        && this.baseUserInputs.password.value != this.passwordPlaceholder)
    {
      this.user.password = this.baseUserInputs.password.value;
    }
    this.user.passwordLocked = this.baseUserInputs.passwordLocked.value;
    this.user.webMailFrequency = this.baseUserInputs.mailFrequency.value;
    this.user.phone = this.baseUserInputs.phoneNumber.value;
    this.user.webSuperUser = this.baseUserInputs.isSuperuser.value;
  }

  private unlockUser() {
    this.user.locked = false;
    this.canBlockUser$.next(true);
  }

  private saveUser() {
    this.spinnerService.show();
    this.requestInProgress = true;

    this.adminService.addEditUser(this.user)
      .pipe(finalize(() => {
        this.spinnerService.hide();
        this.requestInProgress = false;
        this.baseInfoComponent.checkChanges();
      }))
      .subscribe({
        next: (user: UserViewModel) => {
          this.router.navigate([`admin/user/${user.code}`])
        },
        error: (e) => {
          this.formatErrors(e?.error?.result?.errors)
          this.validator.handleError(this.forms.first, e, true);
        },
        complete: () => {
          this.messageToUiService.success(this.translate.instant('Generic.Success'));
        }
      })
  }

  private formatErrors(errors = []) {
    for (let i = 0; i < errors.length; i++) {
      let split = errors[i].propertyName.split(".")
      errors[i].propertyName = split.pop();
    }
  }

  validate() {
    this.$validatingSubject.next(null);
  }
}
