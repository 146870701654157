import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-reminder-collection-policy-info',
  templateUrl: './reminder-collection-policy-info.component.html',
  styleUrls: ['./reminder-collection-policy-info.component.css']
})
export class ReminderCollectionPolicyInfoComponent implements OnInit {

  constructor(
    private config: AppConfigService
  ) { }

  currentCountryCode: string;
  reminderDaysLimit: number = 14;

  ngOnInit(): void {
    this.setCurrentCountryCode();
    this.setReminderDaysLimit();
  }

  setCurrentCountryCode() {
    this.currentCountryCode = this.config.getConfig().countryCode;
  }

  usingDanishRules(): boolean{
    if(this.currentCountryCode == null) return false;
    return this.currentCountryCode?.toLocaleLowerCase() == 'dk';
  }

  setReminderDaysLimit() {
    let numberOfDaysNo: number = 14;
    let numberOfDaysDk: number = 10;

    if(this.usingDanishRules()) {
      this.reminderDaysLimit = numberOfDaysDk;
    }
    else {
      this.reminderDaysLimit = numberOfDaysNo;
    }
  }
}
