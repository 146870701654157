<div class="cases-container">
  <section class="left-container">
    <app-categorized-notes-list listCategory="myTasks" [numberOfItemsPerPage]="15" [largeHeading]="true"
      [resume]="resume?.tasks"></app-categorized-notes-list>
  </section>
  <section class="right-container">
    <app-categorized-notes-list listCategory="newCaseInformation" [numberOfItemsPerPage]="5"
      [resume]="resume?.newCaseInfo"></app-categorized-notes-list>
    <app-flagged-cases [resume]="resume?.flagged"></app-flagged-cases>
  </section>
</div>
