import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageToUiService {

  constructor (private tool: MessageService, private translate: TranslateService) {
  }

  genericError() {
    this.tool.add(
      {
        severity: 'error',
        summary: this.translate.instant(KEYS.Error_GenericTitle),
        detail: this.translate.instant(KEYS.Error_GenericMessage)
      });
  }

  errorKey(key: string) {
    this.tool.add(
      {
        severity: 'error',
        summary: this.translate.instant(key),
        detail: null
      });
  }

  error(title: string, message: string = null, sticky: boolean = false) {
    const translatedTitle = this.translate.instant(title);
    this.tool.add(
      {
        severity: 'error',
        summary: translatedTitle.length ? translatedTitle : title,
        detail: message,
        sticky: sticky
      });
  }

  success(title: string, message: string = null, sticky: boolean = false) {
    this.tool.add(
      {
        severity: 'success',
        summary: title,
        detail: message,
        sticky: sticky
      });
  }

  successKey(key: string = 'Generic.Success') {
    this.tool.add(
      {
        severity: 'success',
        summary: this.translate.instant(key),
        detail: null,
      });
  }

  warning(title: string, message: string = null, sticky: boolean = false) {
    this.tool.add(
      {
        severity: 'warn',
        summary: title,
        detail: message,
        sticky: sticky
      });
  }
}

export const KEYS = {
  Error_GenericTitle: 'Errors.Generic.Title',
  Error_GenericMessage: 'Errors.Generic.Message',
  Error_NoDataFound: 'Errors.Generic.NoData',
  WrongUsernameOrPassword: 'Errors.WrongUsernameOrPassword',
  PasswordLocked: 'Errors.PasswordLocked',
  UserLocked: 'Errors.UserLocked',
  ValidationError_Required: 'Errors.IsRequired',
  ValidationError_Email: 'Errors.Email',
  NewCase_Errors_FailedToUploadAttachment: 'NewCase.Errors.FailedToUploadAttachment',
  NewCase_Inserted_CaseNumber: 'NewCase.Inserted.CaseNumber',
  NewCase_Errors_AttachmentRequiredIfNoReminder: 'NewCase.Errors.AttachmentRequiredIfNoReminder',
  NewCase_Errors_NotAllInvoicesMatched: 'NewCase.Errors.NotAllInvoicesMatched',
  NewCase_Errors_NotDistinctInvoiceCopies: 'NewCase.Errors.NotDistinctInvoiceCopies',
  NewCase_Errors_NotSameNumberInvoicesAndCopies: 'NewCase.Errors.NotSameNumberInvoicesAndCopies',
  TwoFactorRequired: 'TwoFactorAuth.IsRequired',
  TwoFactorInvalidCode: 'TwoFactorAuth.InvalidCode'
};
