import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { CaseActionSentBy, IHistoryData } from 'src/app/shared/models';

@Component({
  selector: 'app-case-timeline',
  templateUrl: './case-timeline.component.html',
  styleUrls: ['./case-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseTimelineComponent {
  constructor(public dialog: MatDialog, private notificationService: MessageToUiService) {
  }

  @Input() list: IHistoryData[];
  @Output() documentRequested = new EventEmitter<string>();
  @Output() smsRequested = new EventEmitter<IHistoryData>();

  @ViewChild('itemView') template: TemplateRef<any>;


  get itemsOfIterest(): IHistoryData[] {
    let now = new Date();
    let intervalStart = now.setMonth(now.getMonth() - 3);
    now = new Date();
    let intervalEnd = now.setMonth(now.getMonth() + 3);

    return this.list?.map(item => {
      item.date = new Date(item.date);
      item.sentBy = this.filterSentBy(item);
      item.tooltip = this.formatTooltip(item);
      item.isFutureLetterOrSms = !item.isExecuted && item.type != 'NA';
      return item;
    })
      .filter(item => {
        return item.date
          && intervalStart <= item.date.getTime()
          && item.date.getTime() <= intervalEnd
          && (item.isFutureLetterOrSms || item.documentIdentifier?.length > 1)
      })
      .sort((a, b) => new Date(a.date).getTime() < new Date(b.date).getTime() ? -1 : 1);
  }

  formatTooltip(item: IHistoryData): string {
    let tooltip = Intl.DateTimeFormat('no-NO').format(item.date).toString() + ' ' + item.text;
    if (item.sentBy != undefined) {
      tooltip += ` ` + `-> ` + item.sentBy;
    }
    return tooltip;
  }

  filterSentBy(item: IHistoryData): string {
    return item.sentById == CaseActionSentBy.JustExecuted ? '' : CaseActionSentBy[item.sentById];
  }

  view(item: IHistoryData): void {
    if (item.isFutureLetterOrSms) {
      this.notificationService.errorKey('Error.LetterNotProduced');
      return;
    }
    if (item.type == "SMS" || item.code =='T-BR-S') {
      this.smsRequested.emit(item);
    }
    else {
      this.documentRequested.emit(item.documentIdentifier);
    }
  }

  viewAll() {
    this.dialog.open(this.template, {
      height: '50%',
      data: {
        executed: this.list?.filter(x => x.isExecuted),
        notExecuted: this.list?.filter(x => !x.isExecuted).reverse()
      }
    });
  }
}
