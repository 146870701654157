import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalizationService, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { ICustomerCaseToDebtCollectionModel, ICustomerCasesToDebtCollectionRequest } from '../../reports/reports-models';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, debounceTime, finalize, map, of, shareReplay, switchMap, takeUntil } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { IPaginatedSearch } from 'src/app/shared/models';
import { LazyLoadEvent, SortEvent } from 'primeng/api';
import { CasesService } from 'src/app/services/cases.service';
import { CaseOpenLocationService } from '../case-open-location.service';

@Component({
  selector: 'app-to-debt-collection',
  templateUrl: './to-debt-collection.component.html',
  styleUrls: ['./to-debt-collection.component.scss']
})
export class ToDebtCollectionComponent implements OnInit, OnDestroy {
  customerNumber$ = this.appService.customerNumber$;
  list$: Observable<ICustomerCaseToDebtCollectionModel[]>;
  numberOfRowsPerPage$ = new BehaviorSubject<number>(25);
  currentPageNumber$ = new BehaviorSubject<number>(1);
  searchTerm$ = new BehaviorSubject<string>('');
  orderByColumn$ = new BehaviorSubject<string>('');
  orderAscending$ = new BehaviorSubject<boolean>(true);
  paginatedSearch$: Observable<IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>>;

  totalNumberOfItems: number;
  numberFormat: LocalizedNumberFormat;
  dateFormat: string;
  isLoading: boolean;

  language: string;

  columns = [
    { name: 'customerNumber', translationTag: 'Reports.ToDebtCollectionList.CustomerNumber'}
  , { name: 'customerName', translationTag: 'Reports.ToDebtCollectionList.CustomerName' }
  , { name: 'debtorName', translationTag: 'Reports.ToDebtCollectionList.DebtorName'}
  , { name: 'invoiceNumber', translationTag: 'Reports.ToDebtCollectionList.InvoiceNumber'}
  , { name: 'caseNumber', translationTag: 'Reports.ToDebtCollectionList.CaseNumber' }
  , { name: 'customerReference1', translationTag: 'Reports.ToDebtCollectionList.CustomerReference'}
  , { name: 'localizedStatus', translationTag: 'Reports.ToDebtCollectionList.Status' }
  , { name: 'originalTotal', type: 'decimal', translationTag: 'Reports.ToDebtCollectionList.OriginalTotal'}
  , { name: 'paymentsAmount', type: 'decimal', translationTag: 'Reports.ToDebtCollectionList.PaymentsAmount'}
  , { name: 'restTotal', type: 'decimal', translationTag: 'Reports.ToDebtCollectionList.RestTotal'}
  , { name: 'numberOfDaysToDebtCollection', translationTag: 'Reports.ToDebtCollectionList.NumberOfDaysToDebtCollection' }
];

  get first(): number {
    return ((this.currentPageNumber$.getValue() ?? 1) - 1) * this.numberOfRowsPerPage$.getValue();
  }

  private readonly unsubscribe$ = new Subject();

  constructor(
    private appService: AppService,
    private casesService: CasesService,
    private notifications: MessageToUiService,
    private localisationService: LocalizationService,
    private openFromTracker: CaseOpenLocationService,
    private localization: LocalizationService,
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.initializeLocalization();
    this.loadData();

    const olderSearch = history.state.resume as IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>;
    if (olderSearch) this.resume(olderSearch);
  }

  onPageChange(event: LazyLoadEvent) {
    this.numberOfRowsPerPage$.next(event.rows);
    this.currentPageNumber$.next(event.first == 0 ? 1 : event.first / event.rows + 1);
  }

  onTableSort(event: SortEvent) {
    this.orderByColumn$.next(event.field);
    this.orderAscending$.next(event.order == 1);
  }

  onSearchTermChanged(event) {
    this.searchTerm$.next(event.target.value)
  }

  onNavigateToCase(cas: ICustomerCaseToDebtCollectionModel, pageItems: ICustomerCaseToDebtCollectionModel[]) {
    this.paginatedSearch$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(paginatedSearch => {
      this.openFromTracker.openCase({ type: 'toDebtCollection', caseNumberIndex: pageItems.indexOf(cas), filter: paginatedSearch, totalCount: this.totalNumberOfItems, pageItems: pageItems})
    })
  }

  private initializeLocalization(): void {
    this.localisationService.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.numberFormat = _.numberPipeFormat;
        this.dateFormat = _.datePipeFormat;
      });
  }

  private loadData(): void {
    this.localization.currentLanguage$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => this.language = result.toLowerCase());

    this.paginatedSearch$ = combineLatest([
      this.customerNumber$
      , this.currentPageNumber$
      , this.numberOfRowsPerPage$
      , this.orderByColumn$
      , this.orderAscending$
      , this.searchTerm$.pipe(debounceTime(200))])
      .pipe(map(([customerNumber, currentPageNumber, numberOfRowsPerPage, orderByColumn, orderAscending, searchTerm]) => {
        this.isLoading = true;
        return {
          pageSize: numberOfRowsPerPage,
          currentPage: currentPageNumber,
          sortField: orderByColumn,
          sortAscending: orderAscending,
          filter: { customerNumber: customerNumber, searchTerm: searchTerm }
        } as IPaginatedSearch<ICustomerCasesToDebtCollectionRequest> }));

      this.list$ = this.paginatedSearch$.pipe(switchMap((paginatedSearch) => {
          return this.casesService.getToDebtCollectionList(paginatedSearch)
          .pipe(
             map(result => {
              this.totalNumberOfItems = result.totalCount;
              result.pageItems.forEach( element =>
                {
                  element.localizedStatus = this.language == 'en' ? element.statusEn : element.status;
                }
              );
              return result.pageItems;
            })
            ,catchError(() => {
              this.notifications.genericError();
              return of([]);
            })
            ,finalize(() => {
              this.isLoading = false;
              window.scrollTo(0, 0);
            })
            ,shareReplay(1)
          );
      }));
  }

  private resume(olderSearch: IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>) {
    this.currentPageNumber$.next(olderSearch.currentPage);
    this.numberOfRowsPerPage$.next(olderSearch.pageSize);
    this.searchTerm$.next(olderSearch.filter.searchTerm);
    this.orderByColumn$.next(olderSearch.sortField);
    this.orderAscending$.next(olderSearch.sortAscending);
  }
}
