import { Component, OnDestroy, OnInit } from '@angular/core';
import { IContractClaimStatusSummary, IContractClaimTypeSummary, IUpdatedContractsMultipleStatuses} from '../models';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, finalize } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import { ContractsService } from '../contracts.service';
import { KEYS, MessageToUiService } from 'src/app/services/message-to-ui.service';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent implements OnInit, OnDestroy {

  private _selectedIds: number[];
  public get selectedIds() {
    return this._selectedIds;
  }
  public set selectedIds(value: number[]) {
    this._selectedIds = value;
    this.getWarningMessage();
  }
  selectedClaimTypeCodes: string[];

  selectedClaimTypes: IContractClaimTypeSummary[];
  messages: Message[];
  isEnglish: boolean;

  subscriptions: Subscription[] = [];
  contractsStatusInfo: IUpdatedContractsMultipleStatuses[];

  constructor(private dialogRef: MatDialogRef<any>,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private service: ContractsService,
    private messageToUi: MessageToUiService,
    private spinner: GlobalSpinnerService) {
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
  }

  getWarningMessage() {
    this.subscriptions.push(this.settingsService.getSettingValueByName('MaxNumberOfChangedContracts').subscribe(_ => {
      let max = +_;
      if (this.selectedIds.length > max) {
        this.messages = [{ severity: 'error', summary: '', detail: this.translate.instant('Contracts.ChangeContract_WarningMessage', { noOfContracts: this.selectedIds.length }) }];
      }
      else this.messages = [];
    }));
  }

  getClassForWorkListItem(item: IContractClaimStatusSummary): string {
    if (item.workList === true) return 'work-list';
    return '';
  }

  cancel() {
    this.dialogRef.close(false);
  }

  update() {
    this.spinner.show();

    this.contractsStatusInfo.forEach(x => {
      this.setNewStatus(x);
    });

    this.service.changeStatus(this.contractsStatusInfo)
      .pipe(finalize(() => { this.spinner.hide(); }))
      .subscribe({
        next: (_) => { this.dialogRef.close(true); },
        error: () => { this.messageToUi.errorKey(KEYS.Error_GenericMessage); }
      });
  }

  setNewStatus(statusInfo: IUpdatedContractsMultipleStatuses) {
    var newStatus = this.selectedClaimTypes.find((c => c.claimTypeCode == statusInfo.claimTypeCode));
    if (newStatus) {
      statusInfo.newStatusId = newStatus?.selectedStatus.id;
    }
  }


  onChangeStatus(event, claimType: IContractClaimTypeSummary) {

    if (event.value.initiateClosingFee == true) {
      this.subscriptions.push(this.service.getClosingFee(event.value.id, this.selectedIds).subscribe(_ => {
        claimType.closingFeeText = _;
      }));
    }
  }
}
