import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CaseProfileEnum, ICaseViewModel } from 'src/app/shared/models';

@Component({
  selector: 'app-case-collection-process',
  templateUrl: './case-collection-process.component.html',
  styleUrls: ['./case-collection-process.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseCollectionProcessComponent implements OnInit, OnChanges {


  @Input() case: ICaseViewModel;

  step: string;

  items: Array<string> = [];


  ngOnInit(): void {
    this.items = ['P', 'H', 'O', 'Legal', 'Closed'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.step = null;
    if (this.case) {
      this.step = this.determineCurrentStep(this.case);
    }
  }

  determineCurrentStep(caseModel: ICaseViewModel): string {
    const caseRole = caseModel.mainCase != null ? caseModel.mainCase.role.char : caseModel.role.char;

    if (caseModel.terminationDate) {
      return CaseProfileEnum.Closed;
    }

    if (caseRole == 'O') {
      return caseRole;
    }

    if (caseModel.requirements.originalPublicFee > 0) {
      return 'Legal';
    }

    return caseRole;
  }
}
