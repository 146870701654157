<div class="page-container" [hidden]="!user">
    <section class="title-container">
        <h2 class="title">{{pageTitleLabel | translate}}</h2>
        <hr class="separation-line" />
    </section>

    <form #form="ngForm" class="form-container">
        <section class="options-container">
            <app-base-user-info #baseInfo (changeEvent)="validate()"></app-base-user-info>
            <app-twofactor-user-setup></app-twofactor-user-setup>
        </section>
        <button class="button-basic" id="add-user-button" (click)="save()" [disabled]="!canSave()">
            {{ saveButtonLabel | translate}}
        </button>
    </form>
</div>