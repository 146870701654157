import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'custom-password-input',
  templateUrl: './custom-password-input.component.html',
  styleUrls: ['./custom-password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomPasswordInputComponent),
      multi: true
    }]
})
export class CustomPasswordInputComponent implements ControlValueAccessor {
  @Input()
  password: string;
  @Input()
  placeholder: string;
  @Input()
  required: boolean;
  @Input()
  minLength: number;
  @Output()
  passwordChange = new EventEmitter<string>();

  showPassword: boolean;

  registerOnChange(value: any): void {
    this.password = value;
    this.passwordChange.emit(value);
  }

  writeValue(obj: any): void {
  }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

}
