<form #paymentLinkForm="ngForm" *ngIf="vm" (ngSubmit)="send(paymentLinkForm)">
  <p-table *ngIf="locale$ | async as locale" [value]="vm.cases ?? []" class="cases-table">
    <ng-template pTemplate="header">
      <th></th>
      <th>{{'CaseRow.CustomerNumber' | translate}}</th>
      <th>{{'CaseRow.CaseNumber' | translate}}</th>
      <th>{{'CaseRow.CustomerReference1' | translate}}</th>
      <th>{{'CaseRow.CustomerReference2' | translate}}</th>
      <th class="text-right">{{'Case.Debtor.RestCapital' | translate}}</th>
      <th class="text-right">{{'Case.Debtor.RestAmount' | translate}}</th>
    </ng-template>
    <ng-template pTemplate="body" let-cas>
      <tr [class.disabled]="cas.isDisabled">
        <td class="checkbox-cell">
          <label class="custom-checkbox-container">
            <input type="checkbox" [disabled]="cas.isDisabled" [(ngModel)]="cas.isSelected"
              [ngModelOptions]="{standalone: true}">
            <span class="checkmark" [class.disabled]="cas.isDisabled"></span>
          </label>
        </td>
        <td>{{cas.customerNumber}}</td>
        <td>{{cas.caseNumber}}</td>
        <td>{{cas.customerReference}}</td>
        <td>{{cas.customerReference2}}</td>
        <td class="text-right">{{cas.restCapital | number : locale.numberPipeFormat.style: locale.numberPipeFormat.culture}}</td>
        <td class="text-right">{{cas.restAmount | number : locale.numberPipeFormat.style: locale.numberPipeFormat.culture}}</td>
    </ng-template>
  </p-table>

  <section class="inputs-container">
    <div>
      <fieldset>
        <label class="custom-radio-container">
          {{'Case.PaymentLink.Email' | translate}}
          <input type="radio" checked="checked" name="radio" name="sendType" value="Email" [(ngModel)]="vm.sendType">
          <span class="checkmark"></span>
        </label>
      </fieldset>
      <fieldset>
        <input type="email" class="text-input" name="email" [(ngModel)]="vm.email" [disabled]="vm.sendType != 'Email'"
          email #email="ngModel" [required]="vm.sendType == 'Email'">
        <app-control-errors [control]="email" [hide]="!paymentLinkForm.submitted"></app-control-errors>
      </fieldset>
    </div>
    <div>
      <fieldset>
        <label class="custom-radio-container">
          {{'Case.PaymentLink.Sms' | translate}}
          <input type="radio" checked="checked" name="radio" name="sendType" value="Sms" [(ngModel)]="vm.sendType">
          <span class="checkmark"></span>
        </label>
      </fieldset>
      <fieldset>
        <input type="text" class="text-input" name="phone" [(ngModel)]="vm.phone" [disabled]="vm.sendType != 'Sms'"
          [required]="vm.sendType == 'Sms'" #phone="ngModel">
        <app-control-errors [control]="phone"></app-control-errors>
      </fieldset>
    </div>
  </section>
  <fieldset>
    <textarea class="text-input" cols="5" rows="3" name="text" [(ngModel)]="vm.text" #text="ngModel" required>
    </textarea>
    <app-control-errors [control]="text"></app-control-errors>
  </fieldset>

  <legend>{{'Case.PaymentLink.Note' | translate}}</legend>

  <input type="hidden" name="recipient" ngModel #recipient="ngModel">
  <app-control-errors [control]="recipient"></app-control-errors>
</form>