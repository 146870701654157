import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AppConfigService } from "../app-config.service";

@Injectable()
export class UnauthorizedHttpInterceptor implements HttpInterceptor {
  private _serviceUrl: string;
  constructor (private router: Router, private appConfig: AppConfigService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if ( err instanceof HttpErrorResponse
          && err.status === 401
          && this.isRequestToOurBackend(request)
          && !this.isAuthRequest(request)) {

          // clearing token so we don't falsely believe we have a user authenticated when using token decode
          localStorage.removeItem('T0K3N');

          if (this.isNotLoginPage()) { this.router.navigate(['login']); }
        }
      }));
  }

  private isNotLoginPage() {
    return this.router.url?.length > 0 && this.router.url !== '/login';
  }

  private isRequestToOurBackend(request: HttpRequest<any>): boolean {
    this.populateServiceUrl();

    if (this._serviceUrl == null || !request.url.startsWith(this._serviceUrl)) { return false; }

    return true;
  }

  private isAuthRequest(request: HttpRequest<any>) {
    return request.url.startsWith(`${this._serviceUrl}auth`);
  }

  private populateServiceUrl() {
    if (this._serviceUrl == null) { this._serviceUrl = this.appConfig?.getConfig().kreditorServiceUrl; }
  }
}
