export class ServiceResult<T> {
  constructor(data?: T) {
    this.result = data;
  }

  result: T;
}

export interface IValidationError {
  attemptedValue?: any;
  errorCode: string;
  errorMessage: string;
  propertyName: string;
}

export interface IValidationResult {
  message: string;
  errors: IValidationError[];
}

export interface IGenericServerError {
  errorReference: string;
  errorCode: string;
  errorMessage: string;
}

export interface IToolbarItemLink {
  translationTag: string;
  link: string;
  icon?: string;
  name: string;
  guard: IRouteData;
  isSelected: boolean;
  subMenu: IToolbarItemLink[];
}

export class ISearchEngine {
  name: string;
  isSelected: boolean;
  label: string;
  guard: IRouteData;
  alwaysDisplay: boolean;
  isDisabled: boolean;
}

export enum WebUserRight {
  CreateCase = 'CreateCase',
  RegisterPayment = 'RegisterPayment',
  IsSuperUser = 'IsSuperUser',
  AddSubCustomer = 'AddSubCustomer',
  AddUser = 'AddUser',
  ViewScore = 'ViewScore',
  WithdrawCase = 'WithdrawCase',
  PaymentLink = 'PaymentLink',
  MatchIt = 'MatchIt',
  ToDebtCollectionList = 'ToDebtCollectionList',
  Iper = 'Iper',
  AddMessageOnSameReference = 'AddMessageOnSameReference',
  AddCaseNote = 'AddCaseNote',
  ApproveLegalRequest = 'ApproveLegalRequest'
}

export interface IRouteData {
  userRight: WebUserRight;
  userRole: UserRole;
  comparison: string;
}

export type UserRole = 'NA' | 'Regular' | 'SuperUser';

export interface IAppUser {
  isAuthenticated: boolean;
  pending2FA: boolean;
  code: string;
  name: string;
  email: string;
}

export interface IUserCredentials {
  userName: string;
  password: string;
  twoFactorCode: string;
}

export interface IAuthResult {
  value: string;
  expiration: string;
  twoFactorRequired: boolean;
}

export interface IUserPermissions {
  rights: WebUserRight[];
  role: UserRole;
  settingNumberOfDaysDebtCollectionList: number;
  settings: IUserSetting[];
}

export type CaseStateFilter = 'NA' | 'Active' | 'Closed';
export type CaseRoleName = 'NA' | 'ReminderCase' | 'MainCase' | 'ShadowCase' | 'Surveillance';

export interface ICaseSearchOptions {
  customerNumber?: number;
  type?: 'generic' | 'socialNumber';
  role?: CaseRoleName;
  state?: CaseStateFilter;

  term: string;

  pageNumber?: number;
  pageSize?: number;

  customerReference1?: string;
  customerReference2?: string;

  searchPartialMatches: boolean;
}

export interface ICustomerStatistics {
  unsolvedCapital: number;
  solvedCapitalLast12Months: number;
  numberOfUnsolvedCases: number;
  numberOfSolvedCasesLast12Months: number;
  numberOfAgreementInstallments: number;
  expectedCashFlowFromAgreementsNextMonth: number;
  toDebtCollectionCasesRestCapital: number;
}

export interface ICaseModel {
  caseNumber: number;

  registeredDate: Date;
  originalRegisteredDate?: Date;
  terminationDate?: Date;

  customerName: string;
  originalCustomerName: string;
  customerNumber: number;
  customerMaximumInstallments: number;
  customerMinimumInstallmentAmount: number;
  customerReference: string;
  customerReference2: string;
  terminationCodeDescription: string;
  terminationCodeDescriptionEn: string;

  debtorName: string;
  debtorAddress: string;
  debtorOrganizationNumber: string;
  debtorBirthdate?: Date;
  
  hasDebtorNotification: boolean;
  postCode: string;

  expenses: number;
  interestRate: number;
  isClosed: boolean;
  originalAmount: number;
  originalCapital: number;
  restAmount: number;
  restCapital: number;
  totalPaid: number;
  restCosts: number;

  statusCode: number;
  statusText: string;
  statusTextEn: string;
  caseStatusGroupId?: number;
  statusGroupName: string;
  statusGroupTranslationTag: string;

  profileType: number;
  profile: CaseProfileEnum;

  statusOrTerminationCode: string;
  invoices: ICaseInvoiceModel[];
  invoiceNumbers: string;
  invoiceDates: string;
}


export interface ICaseInvoiceModel {
  number: string;
  date: Date;
  caseNumber: number;
}

export enum CaseProfileEnum {
  Reminder = 'ReminderCase',
  MainCase = 'MainCase',
  Legal = 'Legal',
  Surveillance = 'Surveillance',
  Closed = 'Closed'
}

export interface ICaseSummary {
  caseNumber: number;
  debtorName: string;
  isSelected: boolean;
  flaggedDate?: Date;
}

export interface IRecentlyCreatedCase extends ICaseSummary {
  amount: number;
  customerNumber: number;
  customerReference2: string;
}

export interface ICaseSearchResult {
  pageNumber: number;
  pageSize: number;
  totalNumberOfItems: number;
  pageItems: ICaseModel[];
}

export interface IForgotPasswordRequest {
  userCodeOrEmail: string;
  userLanguageCode: string;
  applicationSource: string;
}

export interface IResetPasswordRequest {
  token: string;
  newPassword: string;
}

export interface INewMessage {
  text: string;
  caseNumber: number;
}

export type CaseNotesSourceOrTargetEnum = 'NA' | 'Sidra' | 'Creditor' | 'Debtor';

export interface ICaseNoteSummary {
  id: number;
  caseNumber: number;
  customerNumber: number;
  shadowCaseNumber?: number;
  source: CaseNotesSourceOrTargetEnum;
  target: CaseNotesSourceOrTargetEnum;
  text: string;
  date: Date;
  hasObjections: boolean;
  isImportant: boolean;
  isTreated: boolean;
  displayedOnWeb: boolean;
  userName: string;
  debtorName: string;
  customerReference: string
  customerReference1: string;
  customerReference2: string;
  category: string;
  categoryEN: string;
  canBeTreated: boolean;  
}

export interface ICaseNoteTreatRequest {
  id: number;
  caseNumber: number;
  isTreated: boolean;
}

export interface ICaseMessage {
  id: number;
  text: string;
  date: string;
  source: CaseNotesSourceOrTargetEnum;
  target: CaseNotesSourceOrTargetEnum;
  requiresPermission: boolean;
  isTreated: boolean;
  categoryCode: string;
  canBeTreated: boolean;
  attachments: ICaseNoteAttachment[];
  userName: string;
  caseNoteCategoryTypeName: string;
}

export interface ICaseNoteAttachment {
  caseNoteId: number;
  fileName: string;
  documentIdentifier: string;
}

export interface ICaseNotesFilter {
  customerNumber: number | null;
  anyCustomerReference: string;
  categoryCodes: string[];
}


export interface ICaseNotesSearchResults {
  totalNumberOfItems: number;
  pageNumber: number;
  pageSize: number;
  categories: ICaseNoteCategory[];
  pageItems: ICaseNoteSummary[];
}

export interface ICaseNoteCategory {
  categoryCode: string;
  categoryName: string;
  categoryNameEn: string;
}

export interface ICustomerSummary {
  name: string;
  number: number;
  id: number;
  parentCustomerNumber?: number;
  accountManagerName: string;
  accountManagerCode: string;
  officerName: string;
  officerCode: string;
  salesmanName: string;
  salesmanNumber: string;
  parentCustomerName: string;
  organizationNumber: string;
  postPlace: string;
  postCode: string;
  originalName: string;
  group: string;
  setForeignAgreement: boolean;
  isParent: boolean;
  isBarred:boolean;
  isBlockedNewCase: boolean;
}

export type CountryCode = 'AE' | 'AL' | 'AN' | 'AO' | 'AR' | 'AT' | 'AU' | 'BA' | 'BD' | 'BE' | 'BG' | 'BH' | 'BO' | 'BR' | 'BY' | 'CA' | 'CH' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CY' | 'CZ' | 'DE' | 'DK' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'ES' | 'FI' | 'FL' | 'FO' | 'FR' | 'GA' | 'GB' | 'GE' | 'GH' | 'GI' | 'GL' | 'GN' | 'GR' | 'GT' | 'HK' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IN' | 'IR' | 'IS' | 'IT' | 'JE' | 'JO' | 'JP' | 'KE' | 'KH' | 'KM' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LI' | 'LK' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MK' | 'MQ' | 'MT' | 'MU' | 'MW' | 'MX' | 'MY' | 'NA' | 'NG' | 'NI' | 'NL' | 'NN' | 'NO' | 'NP' | 'NZ' | 'PA' | 'PE' | 'PH' | 'PK' | 'PL' | 'PT' | 'PY' | 'QA' | 'RO' | 'RS' | 'RU' | 'SA' | 'SC' | 'SD' | 'SE' | 'SG' | 'SI' | 'SK' | 'SN' | 'SO' | 'SV' | 'SY' | 'TG' | 'TH' | 'TN' | 'TR' | 'TT' | 'TW' | 'TZ' | 'UA' | 'UG' | 'US' | 'UY' | 'VE' | 'VG' | 'VN' | 'XK' | 'XX' | 'YU' | 'ZA' | 'ZM' | 'PR' | 'ET' | 'FJ' | 'BI' | 'BM' | 'LB' | 'PS' | 'LA' | 'AZ' | 'MH' | 'RW' | 'IQ' | 'MG' | 'BW' | 'AF' | 'AX' | 'AS' | 'AD' | 'AI' | 'AQ' | 'AG' | 'AM' | 'AW' | 'BS' | 'BB' | 'BZ' | 'BJ' | 'BT' | 'BQ' | 'BV' | 'IO' | 'BN' | 'BF' | 'CV' | 'CF' | 'TD' | 'CX' | 'CD' | 'CG' | 'CK' | 'CI' | 'CU' | 'CW' | 'DJ' | 'DM' | 'GQ' | 'ER' | 'SZ' | 'FK' | 'GF' | 'PF' | 'TF' | 'GD' | 'GP' | 'GU' | 'GG' | 'GW' | 'GY' | 'HM' | 'VA' | 'HN' | 'IM' | 'JM' | 'KG' | 'LS' | 'LR' | 'MO' | 'MV' | 'ML' | 'MR' | 'YT' | 'FM' | 'MN' | 'MS' | 'MZ' | 'MM' | 'NR' | 'NC' | 'NE' | 'NU' | 'NF' | 'MP' | 'OM' | 'PW' | 'PG' | 'PN' | 'RE' | 'BL' | 'SH' | 'KN' | 'LC' | 'MF' | 'PM' | 'VC' | 'WS' | 'SM' | 'ST' | 'SL' | 'SX' | 'SB' | 'GS' | 'SS' | 'SR' | 'SJ' | 'TJ' | 'TL' | 'TK' | 'TO' | 'TM' | 'TC' | 'TV' | 'UM' | 'UZ' | 'VU' | 'VI' | 'WF' | 'EH' | 'YE' | 'ZW';

export interface IDebtorModel {
  activeCases: number;
  addressCity: string;
  addressCountry: string;
  addressCountryCode: CountryCode;
  addressId: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressPoBoxNumber: string;
  addressPostCode: string;
  addressPostId: number;
  addressSourceCode: string;
  addressStatusCode?: number;
  addressStatusDate?: Date;
  announcementRegisterId?: number;
  birthDate?: Date;
  companyCode: string;
  courtPostCode: string;
  deadlineDate?: Date;
  // debtorEmails: IDebtorEmailModel[];
  debtorId: number;
  // debtorPhones: IDebtorPhoneModel[];
  enrolledInAutoUpdates: boolean;
  externalId: string;
  goPeriodEnd?: Date;
  goPeriodStart?: Date;
  hasDigitalMailbox: boolean;
  iban: string;
  industryText: string;
  isDebtorRegistry: boolean;
  isPerson: boolean;
  legalReference: string;
  name: string;
  socialNumber: string;
  sourceCode: string;
  statusCode?: number;
  statusDate?: Date;
  statusText: string;
  swift: string;
  totalCases: number;
  riskClass: number;
  riskUpdated: Date;
}

export interface IInvoice {
  id: number;
  number: string;
  date: Date;
  dueDate: Date;
  amount: number;
  currencyAmount: number;
  residual: number;
  interest: number;
  interestAmount: number;
  restAmount: number;
  interestDate?: Date;
  kid: string;
  isInvoiceCopyAvailable: boolean;
}


export interface ICaseFlagStatus {
  caseNumber: number;
  flagDate?: Date;
}

export interface ICasePaymentSummary {
  date: Date;
  amount: number;
  currencyAmount: number;
  actionDescription: string;
  actionDescriptionEn: string;
}

export interface ICaseSecuritySummary {
  document: string;
  object: string;
  debtorName: string;
}

export interface ICaseDocument {
  fileName: string;
  dateCreated: Date;
  documentIdentifier: string;
}

export interface IRequirementsModel {
  chargeInterestRate: number;
  interestRate: number;
  isInterestFixed: boolean;

  originalAdditionalFee: number;
  originalCapital: number;
  originalChargeInterest: number;
  originalCustomerReminderFee: number;
  originalEuFee: number;
  originalFee: number;
  originalInterest: number;
  originalNewCaseFee: number;
  originalPublicFee: number;

  paidAdditionalFee: number;
  paidCapital: number;
  paidChargeInterest: number;
  paidCustomerReminderFee: number;
  paidEuFee: number;
  paidFee: number;
  paidInterest: number;
  paidNewCaseFee: number;
  paidPublicFee: number;

  tooMuchPaid: number;

  restAdditionalFee: number;
  restValutaAdditionalFee: number;
  restCapital: number;
  restValutaCapital: number;
  restChargeInterest: number;
  restValutaChargeInterest: number;
  restCustomerReminderFee: number;
  restValutaCustomerReminderFee: number;
  restEuFee: number;
  restValutaEuFee: number;
  restFee: number;
  restValutaFee: number;
  restInterest: number;
  restValutaInterest: number;
  restNewCaseFee: number;
  restValutaNewCaseFee: number;
  restPublicFee: number;
  restValutaPublicFee: number;
  restTotal: number;
  restValuteTotal: number;
  remittedCapital: number;
  remittedInterest: number;
  remittedAdditionalFee: number;
  remittedChargeInterest: number;
  remittedFee: number;
  remittedPublicFee: number;
  remittedCustomerReminderFee: number;
  remittedNewCaseFee: number;
  remittedEuFee: number;

  customersPublicFee: number;
  customersAdditionalFee: number;
}

export interface IClaimSettings {
  isCapitalEnabled: boolean;
  isPublicFeeEnabled: boolean;
  isInterestEnabled: boolean;
  isChargeInterestEnabled: boolean;
  isFeeEnabled: boolean;
  isAdditionalFeeEnabled: boolean;
  isCustomerReminderFeeEnabled: boolean;
  isEuFeeEnabled: boolean;
  isNewCaseFeeEnabled: boolean;
}

export interface IAddressViewModel {
  countryCode: string;
  countryName: string;
  line1: string;
  line2: string;
  postCode: string;
  postName: string;
}

export interface ICaseViewCaseHandlerModel {
  email: string;
  name: string;
  userCode: string;
}
export interface ICaseViewCustomerModel {
  name: string;
  number: number;
}
export interface ICaseViewDebtorModel {
  address: IAddressViewModel;
  id: number;
  name: string;
  birthDate?: Date;
  socialNumber: string;
  email: string;
  phone: string;
  canEditRisk: boolean;
  riskClass: number;
  riskUpdated: Date;
}

export interface ICaseViewRoleModel {
  char: string;
  name: string;
}
export interface ICaseViewStatusModel {
  code: number;
  description: string;
  descriptionEnglish: string;
  statusGroupName: string;
}
export interface IMainCaseViewModel {
  number: string;
  role: ICaseViewRoleModel;
  status: ICaseViewStatusModel;
}

export interface ICaseViewModel {
  address: IAddressViewModel;
  caseHandler: ICaseViewCaseHandlerModel;
  customer: ICaseViewCustomerModel;
  debtor: ICaseViewDebtorModel;
  mainCase: IMainCaseViewModel;
  number: number;
  reference1: string;
  reference2: string;
  reference3: string;
  requirements: IRequirementsModel;
  role: ICaseViewRoleModel;
  status: ICaseViewStatusModel;
  terminationDate?: Date;
  terminationDescription: string;
  terminationDescriptionEn: string;
  registrationDate: Date;
  currencyCode: string;
  bankAccount: string;
  kid: string;
  wasPostponedByCreditor: boolean
}

export interface ICaseViewModelExtended extends ICaseViewModel {
  paymentPlan?: IPaymentPlanSummary;
}

export interface IPaymentPlanSummary {
  planId?: number;
  isSalarayDeducation?: boolean;
  isDebtSettlement?: boolean;
  caseNumbers?: number[];
  registrationDate?: Date;
  amount?: number;
  agreementAmount?: number;
  lastPaidDate?: Date;
  lastPaidAmount?: Date;
  nextDueDate?: Date;
  nextInstallmentAmount?: number;
}

export class RequirementsTotalCalculator {
  constructor(private model: IRequirementsModel) { }

  get originalTotal(): number {

    if (!this.model) return undefined;

    return this.model.originalCapital
      + this.model.originalInterest
      + this.model.originalChargeInterest
      + this.model.originalPublicFee
      + this.model.originalFee
      + this.model.originalAdditionalFee
      + this.model.originalEuFee
      + this.model.originalNewCaseFee
      + this.model.originalCustomerReminderFee;
  }

  get paidTotal(): number {

    if (!this.model) return undefined;

    return this.model.paidCapital
      + this.model.paidInterest
      + this.model.paidChargeInterest
      + this.model.paidPublicFee
      + this.model.paidFee
      + this.model.paidAdditionalFee
      + this.model.paidEuFee
      + this.model.paidNewCaseFee
      + this.model.paidCustomerReminderFee;
  }
}

export type HistoryDataType = 'NA' | 'Letter' | 'SMS';

export interface IHistoryData {
  amount?: number;
  code: string;
  type: HistoryDataType;
  date?: Date;
  dueDate?: Date;
  isExecuted: boolean;
  text: string;
  textEN: string;
  userCode: string;
  documentIdentifier?: string;
  sentById: CaseActionSentBy;
  sentBy: string;
  receiver: string;
  tooltip: string;
  isFutureLetterOrSms: boolean;
}

export enum CaseActionSentBy {
  JustExecuted = 0,
  Letter = 1,
  Email = 2,
  SMS = 3,
  Digital = 4,
  ElectronicInvoice = 5,
  Eboks = 6,
  Vipps = 7,
  EHF = 8
}

export interface IFaqsModel {
  id: number;
  question: string;
  questionEN: string;
  answer: string;
  answerEN: string;
  sidraSystemId: number;
}


export interface ICreditNoteOrPayment {
  date: Date;
  amount: number;
  currencyCode: string;
  reference?: string;
}

export interface ICreditNoteOrPaymentRegistered extends ICreditNoteOrPayment {
  id: number;
}
export interface IUserGroupSummary {
  id: number;
  code: number;
  name: string;
  description: string;
}

export interface ICustomerLetter {
  caseNumber?: number;
  code: string;
  comments: string;
  content: string;
  customerNumber: number;
  dateCreated: Date;
  dateLastAccessed?: Date;
  dateReceived?: Date;
  dateSent?: Date;
  letterGuid: string;
  receiver: string;
  remittanceNumber?: number;
  state: CustomerLetterState;
  text: string;
  type: CustomerLetterType;
  typeDescription: string;
}

export type CustomerLetterState = 'NA' | 'UNREAD' | 'READ' | 'ARCHIVED';

export type CustomerLetterType = 'NA' | 'REM' | 'NYE' | 'CLO' | 'IFA';

export interface ICustomerLetterSearchOptions {
  customerNumber: number;
  endDate?: Date;
  pageNumber: number;
  pageSize: number;
  startDate?: Date;
  state: CustomerLetterState;
  type: CustomerLetterType;
  caseNumber?: number;
}
export interface ICustomerLetterSearchResult {
  totalNumberOfItems: number;
  pageItems: ICustomerLetter[];
  pageNumber: number;
  pageSize: number;
}
export interface INewInvoice {
  number: string;
  date: Date;
  dueDate: Date;
  amount: number;
  currencyCode: string;
  invoiceCopyName: string;
}

export interface INewCase {
  customerNumber: number;
  customerReference1: string;
  customerReference2: string;
  socialNumber: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  countryCode: string;
  postCode: string;
  phoneNumber: string;
  emailAddress: string;
  invoices: INewInvoice[];
  shouldSendReminder: boolean;
  debtorHasObjections: boolean;
  additionalDetails: string;
  isFirm?: boolean;
  euFee?: number;
  invoicesDescription: string;
  isRoadFreight: boolean;
}

export interface ICountry {
  code: string;
  name: string;
}

export interface ICaseRegistered {
  caseNumber: number;
  attachmentsUploadSuccess?: boolean;
  failedUploadDocuments: string[];
}

export interface IRiskClass {
  id: number;
  name: string;
  translationTag: string;
}

export type MessageApprovalType = 'NA' | 'Accepted' | 'Declined';

export interface ICaseMessageApproval {
  messageId: number;
  type: MessageApprovalType;
}

export interface IRelatedCase {
  caseNumber: number;

  customerReference: string;

  customerReference2: string;

  customerNumber: number;

  customerName: string;

  statusCode: number;

  statusText: string;

  restCapital: number;

  restAmount: number;

  caseHandlerCode: string;

  caseHandlerName: string;

  registrationDate: Date;

  hasDebtSettlement: boolean;

  hasPendingLegalRequest: boolean;

  hasRequiredBetoppf: boolean;

  hasRequiredVarsel: boolean;

  caseRoleCode: string
}

export interface ICaseRecallCost {
  fee: number;
  courtFee: number;
  additionalFee: number;
  euFee: number;
  publicFee: number;
  publicFeeToExpense: number;
  expensedNotPaidPublicFeeToRevert: number;
  totalCosts: number;
}

export interface IContactPerson {
  name: string;
  email: string;
  phone: string;
  title: string;
  photo: Uint8Array;
}

export interface IDashboardNotifications {
  tasksCount: number;
  untreatedDocumentsCount: number;
  flaggedCasesCount: number;
  casesWithNewInfoCount: number;
  casesToDebtCollectionCount: number;
}

export interface IWebNewsModel {
  id?: number;
  newsTitle?: string;
  newsText?: string;
  newsSection?: string;
  newsFor?: string;
  publishDate?: Date;
  expireTime?: Date;
}

export interface IDebtorStats {
  numberOfActiveCases: number;
  restCapital: number;
  restTotal: number;
}

export interface IUploadDocumentRestrictions {
  maxSizeMb: number;
  allowedFileExtensions: string[];
}

export interface IStatusGroup {
  id: number;
  translationTag: string;
}

export interface IPaginatedSearch<T> {
  pageSize: number;
  currentPage: number;
  sortField: string;
  sortAscending: boolean;
  filter: T;
}

export interface IPaginatedResponse<T> {
  pageItems: T[];
  totalCount: number;
}


export interface IKreditorUser {
  username: string
  name: string
  customerGroup: string
  lastLoginDateTime: Date
  accountLocked: boolean
  email: string
  id: number
  isSuperUser: boolean

}

export interface KreditorUserSearchResponse {
  totalNumberOfItems: number
  pageNumber: number
  pageSize: number
  pageItems: IKreditorUser[]

}



export class UserViewModel {
  id: number
  code: string
  name: string
  windowsId: string
  email: string
  phone: string
  type: string
  typeCode: string
  departmentNumber?: number
  departmentName: string
  functionId?: number
  locked: boolean = false
  lastLoginDate?: Date
  anonymizationDate?: Date
  webSuperUser?: boolean
  webNews: boolean
  webEmail: string
  nameForEmail: string
  password: string
  passwordLocked: boolean
  passwordHashed: boolean
  passwordTries: number
  webActiveCases: any //IActiveCaseTypeEnum
  webTypeCases: any //ICaseTypeEnum
  webTypeMessages: number
  webElectricCompany?: boolean
  webRegisterPaymentsLocked?: boolean
  webNewCaseLocked?: boolean
  webNotesLocked?: boolean
  webCloseCaseLocked?: boolean
  webShowCredits?: boolean
  webCanNotAddMessagesOnSameReference?: boolean
  webCanChangeMessageType?: boolean
  webCanLockCase?: boolean
  webCanAddUsers?: boolean
  webCanAddSubCustomers?: boolean
  webSendUntreatedMessagesEmail: boolean
  webCanCreatePaymentLink: boolean
  webCanViewScore: boolean
  webUseLegacySite: boolean
  webDefaultCustomerGroupId?: number
  webAllowCustomDashboard: boolean
  webDashboardTemplateName: string
  webDocShowRemittance?: boolean
  webDocShowInvoice?: boolean
  webDocShowNewCase?: boolean
  webDocShowClosedCase?: boolean
  webCreditCheckLocked?: boolean
  webCreditCheckId: string
  webCreditCheckPassword: string
  webMailFrequency: string // MailFrequencyEnum as string
  matchItAllowed?: boolean
  matchItUsername: string
  matchItPassword: string
  reportsAccessible: string = ""
  webCanSearchDebtor: boolean
  webHasAccessToIper: boolean
  hasApiKey: boolean
  userGroups: string[] = [];
  userRights: string[] = [];
  userCustomers: number[] = [];
  userClaimTypes: any[] = [];
  userSettings: any[] = [];

}

export interface IUserSetting {
  category: string;
  name: string;
  value: string;
  fieldType: string;
}

export enum MailFrequencyEnum {
  Daily = 1,
  Weekly,
  NoEmail
}

export interface KreditorReportType {
  name: string;
  id: number;
  label: string;
}

export type LogicalOperator = 'And' | 'Or';


export interface IAddress {
  id: number
  line1: string
  line2: string
  line3: string
  postId: number
  poBoxNumber: string
  postCode: string
  countryCode: string //CountryCode enum

}

export interface ICustomerAddress {
  addressType: string // CustomerAddressType enum
  address: IAddress
}

export interface ISubCustomerRequest {
  organizationNumber: string
  nameStandard: string
  nameBilling: string
  addresses: ICustomerAddress[]
  contactPersonName: string
  email: string
  isVatExcepted: boolean
  bankAccounts: ICustomerBankAccount[]
  parentsIdentifier: string
  subCustomerNumber?: number
  agreementDocument?: any[]

}

export interface ICustomerBankAccount {
  type: string //CustomerBankAccountType enum
  bankAccount: IBankAccount
}

export interface IBankAccount {
  bankNumber: string
  accountNumber: string
  currency: string //CurrencyCode enum
  iban: string
  swift: string
  kid: string
}

export interface IPostCodeSummary {
  id: number
  postNumber: string
  name: string
  municipality: string
  country: string

}

export interface ISubCustomerResponse {
  subCustomerNumber: number
  isSuccess: boolean
  message: string
}

export interface IEntitySearchRequest {
  socialNumber: string;
  customerNumber: number | null;
  customerReference: string;
  usePostadresseIfAvailable: boolean;
}

export interface IEntitySearchResult {
  name: string;
  type: EntityType;
  address: string;
  addressLine2: string;
  countryCode: string;
  postCode: string;
  emailAddress: string;
  phoneNumber: string;
  birthDate: string | null;
  socialNumber: string;
}

export enum EntityType {
  Invalid = 0,
  Person = 1,
  Company = 2,
}

export interface IUploadFileResult {
  fileName: string;
  isSuccess: boolean;
}

export interface ICanDeactivateComponent {
  canDeactivate(): boolean;
}


export interface ILastAccessedCaseRow {
  debtorReference1: string
  caseNumber: number
  debtorName: string
  customerNumber?: number
  customerName: string
  status: string
  lastAccessedDate: Date
}

export enum CasePauseReasonEnum {
  OtherReason = 0,
  DisputedInvoices = 1,
  DeferralOfPayment = 2
}

export interface ICreditorCasePauseRequest {
  pauseReason: CasePauseReasonEnum;
  noteText: string;
  caseNumber: number;
  userConfirmation?: boolean;
}

export interface ISelectableTableColumn extends ITableColumn{
  isSelected: boolean;
}

export interface ITableColumn {
  name: string;
  translationTag: string;
  type: string;
}
