import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CaseListComponent } from './case-list/case-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MaterialModule } from './material/material.module';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessagesModule } from 'primeng/messages';
import { GlobalSpinnerComponent } from './global-spinner/global-spinner.component';
import { LanguageMenuComponent } from './language-menu/language-menu.component';
import { SubMenuTriggerDirective } from './directives/sub-menu-trigger.directive';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { TableModule } from 'primeng/table';
import { CustomPasswordInputComponent } from './custom-password-input/custom-password-input.component';
import { CustomMultiSelectComponent } from './custom-multi-select/custom-multi-select.component';
import { BaseUserInfoComponent } from '../admin/add-edit-user/base-user-info/base-user-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InputWithSpinnerComponent } from './input-with-spinner/input-with-spinner.component';
import { ControlErrorsComponent } from './control-errors/control-errors.component';
import { TwofactorUserSetupComponent } from './twofactor/twofactor-user-setup/twofactor-user-setup.component';
import { ButtonModule } from 'primeng/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TableSelectColumnsComponent } from './table-select-columns/table-select-columns.component';


@NgModule({
  declarations: [
    CaseListComponent,
    FileUploadComponent,
    GlobalSpinnerComponent,
    LanguageMenuComponent,
    SubMenuTriggerDirective,
    CustomSelectComponent,
    CustomPasswordInputComponent,
    CustomMultiSelectComponent,
    BaseUserInfoComponent,
    InputWithSpinnerComponent,
    TwofactorUserSetupComponent,
    TableSelectColumnsComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    TableModule,
    KeyValuePipe,
    MatProgressSpinnerModule,
    ControlErrorsComponent,
    ButtonModule,
    MatSlideToggleModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CaseListComponent,
    FileUploadComponent,
    CalendarModule,
    InputNumberModule,
    MessagesModule,
    GlobalSpinnerComponent,
    LanguageMenuComponent,
    SubMenuTriggerDirective,
    CustomSelectComponent,
    CustomPasswordInputComponent,
    CustomMultiSelectComponent,
    TableModule,
    KeyValuePipe,
    BaseUserInfoComponent,
    MatProgressSpinnerModule,
    InputWithSpinnerComponent,
    ControlErrorsComponent,
    TwofactorUserSetupComponent,
    ButtonModule,
    MatSlideToggleModule,
    TableSelectColumnsComponent
  ]
})
export class SharedModule { }
