<div class="select-container">
  <label class="selected-option" [ngClass]="{'disabled' : disableSelect()}" #dropdownTrigger>
    <ng-container *ngIf="selectedValue; else unselected">
      {{ (selectedValue | translate) != '' ? (selectedValue | translate) : selectedValue }}
    </ng-container>
    <ng-template #unselected>
      <span class="placeholder-select">{{ (placeholderTranslationTag ?? 'General.Select') | translate}}</span>
    </ng-template>
    <span class="actions">
      <i *ngIf="clearEnabled && selectedValue" class="fas fa-times fa-xs " (click)="clear($event)"></i>
      <i class="fas fa-caret-down"></i>
    </span>
  </label>
  <ul class="options" *ngIf="displayDropDown()">
    <li *ngIf="searchEnabled">
      <input type="text" class="text-input" (click)="$event.stopPropagation()" [value]="searchTerm"
        (input)="onSearchInputChanged($event.target.value)" #searchBox>
    </li>
    <li class="options-item" *ngFor="let option of filteredOptions" (click)="onSelectedChange(option)">
      {{ (option.value | translate) != '' ? (option.value | translate) : option.value }}
    </li>
  </ul>
</div>
