<table>
  <tbody>
    <tr *ngFor="let note of list" [ngClass]="{'untreated' : note?.canBeTreated === true && note?.isTreated === false}">
      <td class="date-column">{{note?.date | date}}</td>
      <td>{{note?.userName}}</td>
      <td>
        <div>
          <span>{{note?.text}}</span>
          <div *ngIf="note.canBeTreatedDirectly && !note.isTreated" class="treat-link" (click)="treat(note)">{{'Notes.Treat' | translate}}</div>
        </div>
        <div class="attachments-container">
          <span *ngFor="let attachment of note?.attachments"
            (click)="onDownloadAttachment(attachment)">{{attachment.fileName}}</span>
        </div>
      </td>
      <td class="attachment-icon">
        <i *ngIf="note?.attachments?.length" class="fas fa-paperclip"
          (click)="onDownloadAttachments(note?.attachments)"></i>
      </td>
    </tr>
  </tbody>
</table>