import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { ContractsService } from '../contracts.service';
import { mergeMap, tap } from 'rxjs/operators';
import { LocalizationService } from 'src/app/shared/localization/localization.service';

@Component({
  selector: 'app-contract-type-status-statistics',
  templateUrl: './contract-type-status-statistics.component.html',
  styleUrls: ['./contract-type-status-statistics.component.scss']
})
export class ContractTypeStatusStatisticsComponent implements OnInit, OnChanges {

  _contractType: string;
  get contractType(): string {
    return this._contractType;
  }
  @Input() set contractType(value: string) {
    this._contractType = value;
    this.getStatistics();
  }

  @Input() isEnglish: boolean;

  @Input() refresh: number;

  customerNumber$: Observable<number> = this.application.customerNumber$;
  statistics$: Observable<any>;
  format$ = this.localizationService.settings$;

  totalNumberOfContracts: number;
  totalCapital: number;

  constructor(private application: AppService,
    private service: ContractsService,
    private localizationService: LocalizationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const refreshChange = changes['refresh'];
    if (refreshChange && refreshChange.currentValue != refreshChange.previousValue) {
      this.getStatistics();
    }
  }

  ngOnInit(): void {
  }

  getStatistics() {
    this.statistics$ = this.application.customerNumber$
      .pipe(mergeMap(newCustomerNr => {
        return this.service.getStatistics(this.contractType, newCustomerNr).pipe(tap(_ => {
          if (_ && _.length > 0) {
            this.totalNumberOfContracts = _.map(p => p.numberOfContracts).reduce((a, b) => a + b);
            this.totalCapital = _.map(p => p.restCapital).reduce((a, b) => a + b);
          }
          else{
            this.totalNumberOfContracts = 0;
            this.totalCapital = 0;
          }          
        }));
      }));
  }

}
