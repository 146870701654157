import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../app-config.service";
import { LoginService } from "../login/login.service";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor (private configuration: AppConfigService, private loginService: LoginService) {

  }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.shouldIntercept && this.loginService.isLoggedIn()) {

      const token = localStorage.getItem("token");

      const cloned = req.clone({
        headers: req.headers.set("Authorization",
          "Bearer " + token)
      });

      this.loginService.extendSession();

      return next.handle(cloned);
    }
    else {
      return next.handle(req);
    }
  }

  private shouldIntercept(req: HttpRequest<any>): boolean {
    return req.url.startsWith(this.configuration.getConfig().kreditorServiceUrl);
  }
}
