import { RouterModule, Routes } from "@angular/router";
import { UserProfileComponent } from "./profile/user-profile.component";
import { AuthGuard } from "../auth/auth.guard";
import { NgModule } from "@angular/core";

const routes: Routes = [
  { path: 'user/profile', component: UserProfileComponent, canActivate: [AuthGuard] }, 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }