import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CasesRoutingModule } from './cases-routing.module';
import { MaterialModule } from '../shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NewCaseComponent } from './new-case/new-case.component';
import { ReminderCollectionPolicyInfoComponent } from './new-case/reminder-collection-policy-info/reminder-collection-policy-info.component';
import { CasesComponent } from './cases.component';
import { CasesIteratorComponent } from './case/cases-iterator.component';
import { CaseDebtorComponent } from './case/case-debtor.component';
import { CaseApprovalComponent } from './case/case-approval.component';
import { CaseCollectionProcessComponent } from './case/case-collection-process.component';
import { CasePageComponent } from './case/case-page.component';
import { CaseTimelineComponent } from './case/case-timeline.component';
import { CaseNotesComponent } from './case/case-notes.component';
import { CaseRequirementsComponent } from './case/case-requirements.component';
import { CaseInvoicesComponent } from './case/case-invoices.component';
import { RecentlyCreatedCasesComponent } from './new-case/recently-created-cases/recently-created-cases.component';
import { ControlErrorsComponent } from '../shared/control-errors/control-errors.component';
import { CaseRemittanceComponent } from './case/case-remittance.component';
import { InvoiceListComponent } from './invoices/invoice-list.component';
import { NoteListComponent } from './notes/note-list.component';
import { CaseComponent } from './case/case.component';
import { CaseAddPaymentComponent } from './case/case-add-payment.component';
import { CreditScoreComponent } from './case/credit-score/credit-score.component';
import { FlaggedCasesComponent } from './flagged-cases/flagged-cases.component';
import { CategorizedNotesListComponent } from './categorized-notes-list/categorized-notes-list.component';
import { InvoiceCopiesComponent } from './new-case/invoice-copies/invoice-copies.component';
import { JsonDateParsePipe } from '../shared/pipes/json-date-parse.pipe';
import { CasesRootComponent } from './cases-root/cases-root.component';
import { ToDebtCollectionComponent } from './to-debt-collection/to-debt-collection.component';
import { CasePaymentsComponent } from './case/case-payments.component';
import { CaseSecuritiesComponent } from './case/case-securities.component';
import { LastAccessedCasesComponent } from './last-accessed-cases/last-accessed-cases.component';
import { PauseCaseComponent } from './case/pause-case/pause-case.component';
@NgModule({
  declarations: [
    NewCaseComponent,
    ReminderCollectionPolicyInfoComponent,
    CasesComponent,
    CaseComponent,
    CasesIteratorComponent,
    CaseDebtorComponent,
    CaseApprovalComponent,
    CaseCollectionProcessComponent,
    CasePageComponent,
    CaseTimelineComponent,
    CaseNotesComponent,
    CaseRequirementsComponent,
    CaseInvoicesComponent,
    RecentlyCreatedCasesComponent,
    CaseRemittanceComponent,
    CaseAddPaymentComponent,
    FlaggedCasesComponent,
    CategorizedNotesListComponent,
    InvoiceCopiesComponent,
    CasesRootComponent,
    ToDebtCollectionComponent,
    CasePaymentsComponent,
    CaseSecuritiesComponent,
    LastAccessedCasesComponent,
    PauseCaseComponent
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    CasesRoutingModule,
    TranslateModule,
    SharedModule,
    MaterialModule,
    ControlErrorsComponent,
    InvoiceListComponent,
    NoteListComponent,
    CreditScoreComponent,
    JsonDateParsePipe,
  ]
})
export class CasesModule { }
