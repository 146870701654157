<section class="header">
  <div>
    <h5 class="title">
      {{ 'Cases.FlaggedCases' | translate }}
    </h5>
    <hr class="separation-line" />
    <span class="total-items">{{'Cases.TotalNumberItems' | translate}}: {{totalNumberOfItems}}</span>
  </div>

  <div class="tasks-filters-container">
    <fieldset class="filter">
      <label class="label">{{ 'CaseRow.CustomerReference' | translate }}</label>
      <input type="text" class="text-input" [(ngModel)]="filter.anyCustomerReference"
        (input)="anyCustomerReferenceBS.next($event.target.value)" />
    </fieldset>
  </div>
</section>

<p-table class="cases-table" [autoLayout]="true" [paginator]="true" [lazy]="true" [loading]="isLoading"
  [value]="flaggedCases$ | async" (onLazyLoad)="onPageChange($event)" [rows]="numberOfItemsPerPage$ | async" [first]="first"
  [rowsPerPageOptions]="[5,10,15,20]" [totalRecords]="totalNumberOfItems" selectionMode="single" #tbl>
  <ng-template pTemplate="header">
    <tr>
      <th scope="col">{{ 'CaseRow.CaseNumber' | translate }}</th>
      <th scope="col">{{ 'CaseRow.CustomerNumber' | translate }}</th>
      <th scope="col">{{ 'CaseRow.Date' | translate }}</th>
      <th scope="col">{{ 'CaseRow.DebtorName' | translate }}</th>
      <th scope="col" class="reference-cell">{{ 'CaseRow.CustomerReference' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-case>
    <tr (click)="openCase(case, tbl.value)">
      <td>
        <i [ngClass]="{'fas fa-flag': case.caseNumber}"></i>
        <span class="case-number">{{case?.caseNumber}}</span>
      </td>
      <td>{{case?.customer}}</td>
      <td>{{case?.flaggedDate | date: dateFormat}}</td>
      <td>{{case?.debtorName}}</td>
      <td class="reference-cell">{{case?.customerReference}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <ng-container *ngIf="!initialLoadingDone">
      <tr *ngFor="let emptyRow of emptyRows">
        <td>
          <i></i>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

