<p-table *ngIf="format$ | async as format" [value]="statistics$ | async" styleClass="p-datatable-sm"
    [scrollable]="false">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ "Contracts.Status" | translate }}</th>
            <th class="text-right">{{ "Contracts.NumberOfContracts" | translate }}</th>
            <th class="text-right">{{ "Contracts.RestCapital" | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{isEnglish ? item.statusNameEn : item.statusName}} </td>
            <td class="text-right">{{item.numberOfContracts}} </td>
            <td class="text-right">{{item.restCapital | number :
                format.numberPipeFormat.style:format.numberPipeFormat.culture}} </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr>
            <td>{{'General.Total' | translate}}</td>
            <td class="text-right">{{totalNumberOfContracts}}</td>
            <td class="text-right">{{totalCapital | number :
                format.numberPipeFormat.style:format.numberPipeFormat.culture}}</td>
        </tr>
    </ng-template>
</p-table>