<ng-container *ngIf="componentMode == 'normal'">
  <label>
    {{ 'TwoFactorAuth.Title' | translate }}
  </label>

  <ng-container *ngIf="activeTwoFactor?.isActive == false; else active2fa">
    <div class="clickable options-title" (click)="openTwoFactorPopup()">
      <div>
        <i class="fas fa-times-circle red-icon"></i>
      </div>

      <div>
        {{ 'TwoFactorAuth.ClickToSetup' | translate }}
      </div>

      <div class="col-1">
        <i class="fas fa-edit"></i>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #active2fa>
  <div class="options-title">
    <div>
      <i class="far fa-check-circle green-icon"></i>
    </div>
    <div>
      {{'TwoFactorAuth.ThisProfileUses' | translate }} <b>{{ activeTwoFactor?.type }} {{ 'General.Verification' |
        translate }}</b>
    </div>
    <div (click)="openTwoFactorPopup()" class="clickable">
      <i class="fas fa-edit"></i>
    </div>
  </div>
</ng-template>

<ng-template #twoFactorPopup>
  <div class="container-twofactor">
    <div class="left-container">
      <div class="title-container">
        <h2 class="title">{{'TwoFactorAuth.SecurityUpdate' | translate}}</h2>
        <hr class="separation-line" />
      </div>

      <div class="setup-container">
        <div>
          {{ 'TwoFactorAuth.SecurityUpdateParagraphOne' | translate }}
        </div>

        <div>
          {{ 'TwoFactorAuth.SecurityUpdateParagraphTwo' | translate }}
        </div>

        <ng-container *ngIf="setupMode == 'none'">
          <div class="options-container">
            <b>
              {{ 'TwoFactorAuth.HowToSetup' | translate }}
            </b>

            <button (click)="changeSetupType('app')" class="button-basic">
              {{ 'TwoFactorAuth.AuthAppSetup' | translate }}
            </button>

            <button (click)="changeSetupType('email')" class="button-basic">
              {{ 'TwoFactorAuth.EmailSetup' | translate }}
            </button>
          </div>

          <div class="contact-container">
            <img [src]="'data:image/png;base64,'+ (contactPerson$ | async)?.image"
              onerror="this.src='./assets/accountManagers/Firstname Lastname.png'" alt="User icon"
              class="img-container-contact">
            <div>
              <p class="title">{{ (contactPerson$ | async)?.name }}</p>
              <p>{{ (contactPerson$ | async)?.title }}</p>
              <p>
                {{(contactPerson$ | async)?.phone }}
              </p>
              <p> {{ (contactPerson$ | async)?.email }} </p>
            </div>
          </div>
        </ng-container>

        <div *ngIf="setupMode == 'app'">
          <div class="options-title">
            <b>
              {{ 'TwoFactorAuth.AuthAppSetup' | translate }}
            </b>

            <div class="clickable" (click)="changeSetupType('none')">
              {{ 'General.Back' | translate }}
            </div>
          </div>

          <div class="flex-column paragraphs">
            <div class="flex-inline">
              <div class="app-steps">
                <ul>
                  <li>
                    {{ 'TwoFactorAuth.AppStep1' | translate }}
                  </li>
                  <li>
                    {{ 'TwoFactorAuth.AppStep2' | translate }}
                  </li>
                  <li>
                    {{ 'TwoFactorAuth.AppStep3' | translate }}
                  </li>
                  <li>
                    {{ 'TwoFactorAuth.AppStep4' | translate }} {{ currentAppSetup?.appManualEntryKey }}
                  </li>
                </ul>
                <label> {{ 'TwoFactorAuth.ExtraInformation' | translate }} </label>
              </div>
              
              <div class="img-container"><img [src]="currentAppSetup?.appImageUrl"></div>
            </div>
          </div>
        </div>

        <div *ngIf="setupMode == 'email'">
          <div class="options-title">
            <b>
              {{ 'TwoFactorAuth.EmailSetup' | translate }}
            </b>

            <div class="clickable" (click)="changeSetupType('none')">
              {{ 'General.Back' | translate }}
            </div>
          </div>

          <div>
            {{ 'TwoFactorAuth.EmailSetupSentMessage' | translate }}
          </div>
        </div>

        <div *ngIf="setupMode != 'none'" class="code-input-container">
          <label>  {{ 'TwoFactorAuth.WriteCode' | translate }} </label>
          <div class="code-input">
            <input [(ngModel)]="verificationCode" class="text-input">
            <button (click)="finishSetup()" class="button-basic">
              {{ 'General.Verify' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="twofactor-image-container">
      <img class="img-fluid h-100 twofactor-radius" src="/assets/2fa/2fa-1.jpg">
    </div>

  </div>
</ng-template>
