import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICustomerSummary } from 'src/app/shared/models';

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSelectComponent),
      multi: true
    }]
})
export class CustomerSelectComponent implements OnChanges {
  @ViewChild('searchInput') set searchInput(searchInput: ElementRef) {
    if (searchInput != null) searchInput.nativeElement.focus();
  }

  @Input() customers: ICustomerSummary[];
  @Output() customerNumberChange: EventEmitter<number> = new EventEmitter<number>();
  showDropdown: boolean;
  customerName: string;
  customerNumber: number;
  filteredCustomers: ICustomerSummary[];
  canFilter: boolean;

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.showDropdown = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['customers']) {
      if (this.customers?.length == 1) {
        const customer = this.customers[0];
        this.customerNumber = customer.number;
        this.customerName = customer.name;
      }
      else {
        this.filteredCustomers = this.customers;
      }

      this.canFilter = this.customers?.length > 5;
    }
  }

  onSelectedChange(customer: ICustomerSummary = null) {
    this.customerNumber = customer?.number ?? null;
    this.customerName = customer?.name;
    this.customerNumberChange.emit(this.customerNumber);
  }

  onSearchInputChanged(searchTerm: string) {
    this.filteredCustomers = this.customers.filter(x =>
      x.number.toString().indexOf(searchTerm) > -1 ||
      x.name.toLocaleLowerCase().indexOf(searchTerm) > -1);
  }

  writeValue(value: number): void {
  }
  registerOnChange(fn: (_: number) => void): void {
  }
  registerOnTouched(fn: () => void): void {
  }
  setDisabledState(isDisabled: boolean): void {
  }
}
