import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfigurationModel } from './app/app-configuration-model';
import { APP_CONFIG } from './app/app-config.service';

if (environment.production) {
  enableProdMode();
}




fetch('environments/appConfig.json')
  .then(response => response.json())
  .then((config: AppConfigurationModel) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config },
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });

