<div class="select-container">
  <label class="selected-option" [ngClass]="{'disabled' : disableSelect()}" #trigger>
    <ng-container *ngIf="selectedValues?.length > 0; else unselected">
      <span class="selected-value">
        <ng-container *ngIf="selectedValues?.length == 1; else multipleSelected">
          {{ (selectedValues[0] | translate) != '' ? (selectedValues[0] | translate) : selectedValues[0] }}
        </ng-container>
        <ng-template #multipleSelected>
          {{ (displayDetailedSelection == true) ? (selectedDetailedValues) : (selectedValues.length + ' ' +
          ('General.OptionsSelected' | translate))}}
        </ng-template>
      </span>
    </ng-container>
    <ng-template #unselected>
      <span class="placeholder-select">{{ placeholderTranslationTag | translate}}</span>
    </ng-template>
    <span class="actions">
      <i *ngIf="clearEnabled && selectedValues?.length > 0" class="fas fa-times fa-xs"
        (click)="$event.stopPropagation();clear()"></i>
      <i class="fas fa-caret-down"></i>
    </span>
  </label>

  <ul class="options" *ngIf="displayDropDown()">
    <li *ngIf="searchEnabled">
      <input type="text" class="text-input" (click)="$event.stopPropagation()"
        (input)="onSearchInputChanged($event.target.value)" #searchBox>
    </li>
    <li class="options-item" *ngFor="let option of filteredOptions"
      (click)="$event.stopPropagation();onSelectedChange(option)">
      <input type="checkbox" class="checkbox" [checked]="option.isSelected">
      {{ (option.value | translate) != '' ? (option.value | translate) : option.value }}
    </li>
  </ul>
</div>