import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, computed, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { LocalizationService, LocalizedNumberFormat } from '../localization/localization.service';
import { ICaseModel, ISelectableTableColumn } from '../models';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseListComponent implements OnInit, OnChanges, OnDestroy {
  constructor (
    private translateService: TranslateService,
    private localizationService: LocalizationService) { }
 
  @Input() isLoading: boolean;

  @Input() list: ICaseModel[];

  @Input() pageNumber: number;

  @Input() totalNumberOfItems: number = 0;

  @Input() numberOfItemsPerPage: number = 20;

  @Input() includeClosedCases: boolean;

  @Input() userSearchColumns: string[];
  @Output() 
  userSearchColumnsChange = new EventEmitter<string[]>();

  @Output()
  selectedCase = new EventEmitter<ICaseModel>();

  @Output()
  selectedPageNumber = new EventEmitter<number>();

  @Output()
  numberOfItemsPerPageChange = new EventEmitter<number>();

  @Output()
  onExportExcelClick = new EventEmitter();

  selectedColumns = computed(() => this.cols().filter(x => x.isSelected));
  numberPipe: LocalizedNumberFormat;
  datePipe: string;
  localizationSettings$ = this.localizationService.settings$
    .pipe(tap(_ => {
      this.numberPipe = _.numberPipeFormat;
      this.datePipe = _.datePipeFormat;
    }));

  get first(): number {
    return ((this.pageNumber ?? 1)  - 1) * this.numberOfItemsPerPage;
  }

  cols = signal<ISelectableTableColumn[]>([
    { name: 'customerNumber', translationTag: 'CaseRow.CustomerNumber', isSelected: true } as ISelectableTableColumn,
    { name: 'originalCustomerName', translationTag: 'CaseRow.OriginalCustomer'} as ISelectableTableColumn,
    { name: 'originalRegisteredDate', translationTag: 'CaseRow.OriginalRegisteredDate', type: "date", isSelected: true } as ISelectableTableColumn,
    { name: 'caseNumber', translationTag: 'CaseRow.CaseNumber', isSelected: true } as ISelectableTableColumn,
    { name: 'customerReference', translationTag: 'CaseRow.CustomerReference1', isSelected: true } as ISelectableTableColumn,
    { name: 'customerReference2', translationTag: 'CaseRow.CustomerReference2', isSelected: true } as ISelectableTableColumn,
    { name: 'statusCode', translationTag: 'CaseRow.StatusCode' } as ISelectableTableColumn,
    { name: 'statusOrTerminationCode', translationTag: 'CaseRow.Status', isSelected: true } as ISelectableTableColumn,
    { name: 'debtorName', translationTag: 'CaseRow.DebtorName', isSelected: true } as ISelectableTableColumn,
    { name: 'debtorAddress', translationTag: 'CaseRow.DebtorAddress' } as ISelectableTableColumn,
    { name: 'socialNumber', translationTag: 'CaseRow.SocialNumber'} as ISelectableTableColumn,
    { name: 'originalCapital', translationTag: 'CaseRow.OriginalCapital', type: 'decimal', isSelected: true } as ISelectableTableColumn,
    { name: 'restCapital', translationTag: 'CaseRow.RestCapital', type: 'decimal', isSelected: true } as ISelectableTableColumn,
    { name: 'totalPaid', translationTag: 'CaseRow.TotalPaid', type: 'decimal', isSelected: true } as ISelectableTableColumn,
    { name: 'restAmount', translationTag: 'CaseRow.RestTotal', type: 'decimal', isSelected: true } as ISelectableTableColumn,
    { name: 'restCosts', translationTag: 'CaseRow.Costs', type: 'decimal' } as ISelectableTableColumn,
    { name: 'invoiceNumbers', translationTag: 'CaseRow.InvoiceNumbers'} as ISelectableTableColumn,
    { name: 'invoiceDates', translationTag: 'CaseRow.InvoiceDates'} as ISelectableTableColumn,
  ]);

  private readonly unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => this.determineDisplayStatusOrTermination())
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list'] && this.list != null) {
      this.determineDisplayStatusOrTermination();
    }

    else if (changes['includeClosedCases']) {
      if (this.includeClosedCases && !this.cols().filter(x => x.name == 'terminationDate').length) {
        const terminationDateColumn = { name: 'terminationDate', translationTag: 'CaseRow.TerminationDate', type: 'date', isSelected: true } as ISelectableTableColumn;
        this.cols.set(this.cols().concat(terminationDateColumn));
      }
      else {
        this.cols.set(this.cols().filter(x => x.name != 'terminationDate'));
      }
    }

    else if (changes['userSearchColumns']) {
      if (this.userSearchColumns?.length > 0) {
        this.setColumnsFromUserPreferences();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onPageChange(event: LazyLoadEvent) {
    this.numberOfItemsPerPageChange.emit(event.rows);
    this.selectedPageNumber.emit(event.first == 0 ? 1 : event.first / event.rows + 1);
  }

  onSelected(caseModel: ICaseModel) {
    this.selectedCase.emit(caseModel);
  }

  onColumnsChange(columns: ISelectableTableColumn[]) {
    this.cols.set(columns);
    this.userSearchColumns = this.selectedColumns().map(x => x.name);
    this.userSearchColumnsChange.emit(this.userSearchColumns);
  }

  private determineDisplayStatusOrTermination() {
    this.list.map(x => {
      if (!x.terminationDate) {
        x.statusOrTerminationCode = this.translateService.instant(x.statusGroupTranslationTag);
      }
      else {
        x.statusOrTerminationCode = this.translateService.getDefaultLang() == 'en' ? x.terminationCodeDescriptionEn : x.terminationCodeDescription;
      }
      return x;
    })
  }

  private setColumnsFromUserPreferences() {
    this.cols.set(this.cols().map(x => {
      x.isSelected = this.userSearchColumns.includes(x.name);
      return x;
    }).sort((a, b) => { 
      if (this.userSearchColumns.indexOf(a.name) > this.userSearchColumns.indexOf(b.name)) 
        return 1;
      else return -1; }));
  }
}
