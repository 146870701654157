<div class="component-container">
  <section class="details">
    <div class="title-container">
      <h4 class="title">{{'Case.Debtor.Details' | translate}}</h4>
      <hr class="separation-line" />
    </div>
    <div class="property-container">
      <label>{{'General.Customer' | translate}}:</label>
      <span>{{case?.customer?.number}}</span>
      <span>{{case?.customer?.name}}</span>
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.Reference1' | translate}}:</label>
      <span>{{case?.reference1}}</span>
    </div>
    <div *ngIf="case?.reference2?.length > 0" class="property-container">
      <label>{{'Case.Debtor.Reference2' | translate}}:</label>
      <span>{{case?.reference2}}</span>
    </div>
    <div *ngIf="case?.reference3?.length > 0" class="property-container">
      <label>{{'Case.Debtor.Reference3' | translate}}:</label>
      <span>{{case?.reference3}}</span>
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.CaseHandler' | translate}}:</label>
      <span>{{case?.caseHandler.name}}</span>
    </div>
    <div class="property-container">
      <label>{{'Status' | translate}}:</label>
      <span>{{case?.status.description}}</span>
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.RegistrationDate' | translate}}:</label>
      <span>{{case?.registrationDate | date: dateFormat}}</span>
    </div>
  </section>
  <section class="customer">
    <div class="title-container">
      <h4 class="title">{{'Case.Debtor.Customer' | translate}}</h4>
      <hr class="separation-line" />
    </div>
    <div class="property-container">
      <span>{{case?.debtor.name}}</span>
    </div>
    <div class="property-container" *ngIf="case?.debtor.birthDate == null">
      <span>{{case?.debtor.socialNumber}}</span>
    </div>
    <div class="property-container" *ngIf="case?.debtor.birthDate != null">
      <span>{{case?.debtor.birthDate | date}}</span>
    </div>
    <div class="property-container">
      <span>{{case?.debtor.address.line1}}</span>
    </div>
    <div class="property-container">
      <span>{{case?.debtor.address.postCode}}, {{case?.debtor.address.postName}}</span>
    </div>
    <div class="property-container">
      <span>{{case?.debtor.phone}}</span>
    </div>
    <div class="property-container">
      <span>{{case?.debtor.email}}</span>
    </div>
  </section>
  <section class="status">
    <div class="title-container">
      <h4 class="title">{{'Case.Debtor.Status' | translate }}</h4>
      <hr class="separation-line" />
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.NumberActiveCases' | translate}}:</label>
      <span>{{debtorStats?.numberOfActiveCases}}</span>
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.RestCapital' | translate}}</label>
      <span> {{debtorStats?.restCapital | number : numberFormat.style: numberFormat.culture}} </span>
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.RestCosts' | translate}}</label>
      <span> {{(debtorStats ? (debtorStats.restTotal - debtorStats.restCapital): undefined) | number :
        numberFormat.style: numberFormat.culture}} </span>
    </div>
    <div class="property-container">
      <label>{{'Case.Debtor.RestAmount' | translate}}</label>
      <span> {{debtorStats?.restTotal | number : numberFormat.style: numberFormat.culture}} </span>
    </div>
    <span class="open-popup" (click)="onOpenRelatedCasesPopup()">
      {{'Case.Debtor.SeeMore' | translate}} </span>
  </section>
  <section class="grid-container">
    <label>{{'Case.Debtor.BankAccount' | translate}}</label>
    <span>{{case.bankAccount}}</span>
    <label>{{'Case.Debtor.KID' | translate}}</label>
    <span>{{case.kid}} </span>
  </section>

  <ng-template #relatedCasesPopup let-data>
    <div class="data-container">
      <div mat-dialog-title></div>
      <div class="title-container">
        <div>
          <h5 class="title">{{'RegisteredCases.DebtorRelatedCases' | translate}}</h5>
          <hr class="separation-line">
        </div>
        <div class="toggle-container">
          <mat-slide-toggle [(ngModel)]="debtorRelatedCasesOnlyActive" (change)="onRelatedCasesToggleChange('Debtor')"
            [disabled]="data.sameDebtorData?.state == 'busy'">
            <ng-container *ngIf="debtorRelatedCasesOnlyActive;else allDebtorCases"> {{ 'General.ActiveCases' | translate }} </ng-container>
            <ng-template #allDebtorCases> {{ 'General.AllCases' | translate }} </ng-template>
          </mat-slide-toggle>
        </div>
      </div>
      <div [ngSwitch]="data.sameDebtorData?.state">
        <p-table *ngIf="data.sameDebtorData.value?.length" class="related-table" [value]="data.sameDebtorData.value"
          [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <th>{{'CaseRow.CaseNumber' | translate}}</th>
            <th>{{'Case.Debtor.References' | translate}}</th>
            <th>{{'Case.Debtor.RestCapital' | translate}}</th>
            <th>{{'Case.Debtor.RestCosts' | translate}}</th>
            <th>{{'Case.Debtor.RestAmount' | translate}}</th>
            <th>{{'Case.Debtor.Status' | translate}}</th>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr (click)="openCase(item.caseNumber)" *ngSwitchCase="'success'">
              <td>{{item.caseNumber}}</td>
              <td>{{item.customerReference}}</td>
              <td>{{item.restCapital | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{(item.restTotal - item.restCapital) | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{item.restTotal | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{ isEnglish ? item.statusTextEn : item.statusText }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngIf="getTotals(data.sameDebtorData.value) as totals" class="totals-row">
              <td>{{'General.Total' | translate}}</td>
              <td></td>
              <td>{{totals.restCapitals | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{totals.restCosts | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{totals.restTotals | number : numberFormat.style: numberFormat.culture}}</td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
        <mat-progress-spinner mode="indeterminate" [diameter]="40" *ngSwitchCase="'busy'"></mat-progress-spinner>
        <span *ngSwitchCase="'error'" class="alert alert-danger"> {{'Errors.Generic.Message' | translate}} </span>
        <span *ngSwitchDefault class="no-data"> {{'Case.NoData' | translate}} </span>
      </div>
    </div>

    <div class="data-container">
      <div class="title-container">
        <div>
          <h5>{{('RegisteredCases.ReferenceRelatedCases' | translate) + ' ' + (case?.reference1 ?? '') + ' ' +
            (case?.reference2 ?? '')}}</h5>
          <hr class="separation-line">
        </div>
        <div class="toggle-container">
          <mat-slide-toggle [(ngModel)]="referencesRelatedCasesOnlyActive"
            (change)="onRelatedCasesToggleChange('Reference')" [disabled]="data.sameReferenceData?.state == 'busy'">
            <ng-container *ngIf="referencesRelatedCasesOnlyActive; else allReferenceCases"> {{ 'General.ActiveCases' | translate }}
            </ng-container>
            <ng-template #allReferenceCases> {{ 'General.AllCases' | translate }} </ng-template>
          </mat-slide-toggle>
        </div>
      </div>
      <div [ngSwitch]="data.sameReferenceData?.state">
        <p-table *ngIf="data.sameReferenceData.value?.length" class="related-table"
          [value]="data.sameReferenceData.value" [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <th>{{'CaseRow.CaseNumber' | translate}}</th>
            <th>{{'Case.Debtor.Reference1' | translate}}</th>
            <th>{{'Case.Debtor.Reference2' | translate}}</th>
            <th>{{'Case.Debtor.RestCapital' | translate}}</th>
            <th>{{'Case.Debtor.RestCosts' | translate}}</th>
            <th>{{'Case.Debtor.RestAmount' | translate}}</th>
            <th>{{'Case.Debtor.Status' | translate}}</th>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr (click)="openCase(item.caseNumber)" *ngSwitchCase="'success'">
              <td>{{item.caseNumber}}</td>
              <td>{{item.customerReference1}}</td>
              <td>{{item.customerReference2}}</td>
              <td>{{item.restCapital | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{(item.restTotal - item.restCapital) | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{item.restTotal | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{ isEnglish ? item.statusTextEn : item.statusText }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr *ngIf="getTotals(data.sameReferenceData.value) as totals" class="totals-row">
              <td>{{'General.Total' | translate}}</td>
              <td></td>
              <td></td>
              <td>{{totals.restCapitals | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{totals.restCosts | number : numberFormat.style: numberFormat.culture}}</td>
              <td>{{totals.restTotals | number : numberFormat.style: numberFormat.culture}}</td>
              <td></td>
            </tr>
          </ng-template>
        </p-table>
        <mat-progress-spinner mode="indeterminate" [diameter]="40" *ngSwitchCase="'busy'"></mat-progress-spinner>
        <span *ngSwitchCase="'error'" class="alert alert-danger"> {{'Errors.Generic.Message' | translate}} </span>
        <span *ngSwitchDefault class="no-data"> {{'Case.NoData' | translate}} </span>
      </div>
    </div>
  </ng-template>
</div>