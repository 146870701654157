import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ICaseSearchOptions, ISearchEngine } from 'src/app/shared/models';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnChanges {
  @Input()
  searchEngines: ISearchEngine[];
  placeholder$: BehaviorSubject<string>;

  constructor (private router: Router) { }

  searchTerm = '';
  ngOnChanges() {
    this.placeholder$ = new BehaviorSubject<string>(this.searchEngines.find(x => x.isSelected)?.label);
  }

  search() {
    const engine = this.searchEngines.find(x => x.isSelected);

    switch (engine.name) {
      case 'Svea': this.router.navigate(['/search'], { queryParams: this.searchOptions() }); break;
      default: this.router.navigate([`/search/${engine.name}`], { queryParams: this.searchOptions() }); break;
    }

    this.searchTerm = null
  }

  selectEngine(engine: ISearchEngine): void {
    if (engine.isDisabled) return;
    this.placeholder$.next(engine.label);
    this.searchEngines.map(x => x.isSelected = x.name == engine.name);
  }

  private searchOptions() {
    return { term: this.searchTerm } as ICaseSearchOptions;
  }
}
