import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { ICasePaymentSummary } from 'src/app/shared/models';

@Component({
  selector: 'app-case-payments',
  templateUrl: './case-payments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasePaymentsComponent implements OnChanges {

  @Input() list: ICasePaymentSummary[];
  @Input() language: string;
  @Input() hasInternationalCurrency: boolean;
  @Input() caseCurrencyCode: string;
  @Input() firmCurrencyCode: string;
  @Input() numberFormat: LocalizedNumberFormat;
  isEnglish: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['language']) this.isEnglish = this.language.toLocaleLowerCase() == 'en';
  }
}
