import { Injectable } from "@angular/core";
import { KreditorService } from "../shared/kreditor.service";
import { CustomerLetterType, KreditorUserSearchResponse, UserViewModel } from "../shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private kreditorService: KreditorService
  ) { }


  public getAllUsersHavingCommonCustomers(pageNumber: number, pageSize: number, selectedCustomerNumber?: number): Observable<KreditorUserSearchResponse> {
    let url = `Users/kreditorUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&selectedCustomerNumber=${selectedCustomerNumber}`
    return this.kreditorService.getServiceResult<KreditorUserSearchResponse>(url)
      .pipe(map(_ => _.result))
  }

  public isAllowedToViewOrEditUser(userCodeToManage: string): Observable<boolean> {
    return this.kreditorService.getServiceResult<boolean>(`Users/isAllowedToViewOrEditUser?userCodeToManage=${userCodeToManage}`)
      .pipe(map(_ => _.result));
  }

  public getUser(userCode: string): Observable<UserViewModel> {
    return this.kreditorService.getServiceResult<UserViewModel>(`Users/${userCode}`)
      .pipe(map(_ => _.result));
  }

  public addEditUser(user: UserViewModel): Observable<any> {
    return this.kreditorService.postServiceResult<UserViewModel, any>('Users/addEdit', user)
      .pipe(map(_ => _.result))
  }

  public getNextUserCode(): Observable<string> {
    return this.kreditorService.getServiceResult<string>("Users/GetNextUserCode")
      .pipe(map(_ => _.result));
  }
}
