import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { MaterialModule } from '../shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { InformationComponent } from './information.component';
import { InformationRoutingModule } from './information-routing.module';

@NgModule({
  declarations: [FaqComponent, InformationComponent],
  imports: [
    FormsModule,
    CommonModule,
    InformationRoutingModule,
    MaterialModule,
    TranslateModule,
    SharedModule
    ],
   exports:[FaqComponent]
})
export class InformationModule { }
