<div mat-dialog-content> 
    <div class="titleContainer">
        <h2 class="title">{{'CasePause.Pause' | translate}}</h2>
        <hr class="separation-line">
    </div>  
    <form #form="ngForm">
        <h5 class="required">{{'CasePause.ChooseReason' | translate}}</h5>
        
        <fieldset id="postponeReason">
            <label class="custom-checkbox-container" title="{{canUseDeferralOfPayment ? ('CasePause.AlreadyPostponed' | translate) : null}}" 
              [ngClass]="{'disabled' : canUseDeferralOfPayment}">
                <input id="paymentDeferralReason" type="radio" class="column-checkbox" [attr.disabled]="canUseDeferralOfPayment ? 'true' : null"
                    value="{{CasePauseReasonEnum.DeferralOfPayment}}" name="pauseReason" [(ngModel)]="pauseReason" #PauseReasonInput="ngModel" >
                <span class="checkmark"></span>
                <label>{{'CasePause.DeferralOfPayment' | translate}}</label>
            </label>
            
            <label class="custom-checkbox-container">
                <input id="disputedInvoiceReason" type="radio" class="column-checkbox" value="{{CasePauseReasonEnum.DisputedInvoices}}" 
                    name="pauseReason" [(ngModel)]="pauseReason"  #PauseReasonInput="ngModel" (ngModelChange)="resetUserApproval()">
                <span class="checkmark"></span>
                <label>{{'CasePause.DisputedInvoices' | translate}}</label>
            </label>
            
            <label class="custom-checkbox-container">
                <input id="otherReason" type="radio" class="column-checkbox" value="{{CasePauseReasonEnum.OtherReason}}"  
                    name="pauseReason" [(ngModel)]="pauseReason" #PauseReasonInput="ngModel" (ngModelChange)="resetUserApproval()">
                <span class="checkmark"></span>
                <label>{{'CasePause.OtherReasons' | translate}}</label>
            </label>

            <app-control-errors [control]="PauseReasonInput"></app-control-errors>
        </fieldset>
      
    
    
        <div class="required-textArea">
            <textarea id="noteTextCasePause" class="text-input" [(ngModel)]="noteTextValue" 
                #noteText="ngModel" placeholder="{{'CasePause.TextareaPlaceholder' | translate}}" 
                name="noteTextCasePause"></textarea>
            <app-control-errors [control]="noteText"></app-control-errors>
        </div>
        
        <div [ngClass]="{'hidden' : !userConfirmationNeeded}">
            <label class="custom-checkbox-container required" >
                <input id="userConfirmationCheckbox" type="checkbox" class="column-checkbox" [(ngModel)]="userConfirmationModel" #confirmation="ngModel" name="userConfirmation" >
                <span class="checkmark"></span>
                <label>{{'CasePause.UserConfirmation' | translate}}</label>
            </label>
            <app-control-errors [control]="confirmation"></app-control-errors>
        </div>
    </form>


</div>
    
<mat-dialog-actions class="footer">
    <button type="button" class="button-basic" [disabled]="!isValid" (click)="save()">
        {{'General.Save' | translate}}
    </button>
</mat-dialog-actions>