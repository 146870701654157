import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Observable, Subject, defer, map, mergeMap, takeUntil, tap } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { CustomersService } from "src/app/services/customers.service";
import { LocalizationService, LocalizedNumberFormat } from "src/app/shared/localization/localization.service";
import { ILastAccessedCaseRow } from "src/app/shared/models";
import { UtilsService } from "src/app/shared/utils.service";

@Component({
    selector : "app-last-accessed-cases",
    templateUrl : "last-accessed-cases.component.html",
    styleUrls : ["last-accessed-cases.component.scss"],
    changeDetection : ChangeDetectionStrategy.OnPush
})
export class LastAccessedCasesComponent implements OnInit
{
    constructor(private customersService : CustomersService
        , private localizationService : LocalizationService
        , public utils : UtilsService
        , private changeDetectorRef : ChangeDetectorRef
        , private appService : AppService)
    {
    }

    caseList$ : Observable<ILastAccessedCaseRow[]>
    numberFormat : LocalizedNumberFormat;
    dateTimeFormat : string;
    isLoading : boolean = true;
    totalNumberOfItems : number = 0;
    first : number = 0;
    numberOfItemsPerPage: number = 10;    

    private readonly unsubscribe$ : Subject<any> = new Subject();

    TABLE_COLUMNS = [
        { name: 'debtorReference1',  type:'any', translationTag: 'CaseRow.CustomerReference' }
       ,{ name: 'caseNumber',   type:'any',translationTag: 'General.CaseNumber' }
       ,{ name: 'debtorName',   type:'any',translationTag: 'General.DebtorName' }
       ,{ name: 'customerNumber',   type:'any',translationTag: 'General.CustomerNumber' }
       ,{ name: 'customerName',   type:'any',translationTag: 'General.CustomerName' }
       ,{ name: 'status',   type:'any',translationTag: 'CaseRow.Status' }
       ,{ name: 'lastAccessedDate', type:'datetime',  translationTag: 'LastAccessedCases.LastAccessedDate' }
    ]
    
    ngOnInit(): void {
        this.isLoading = true;

        this.localizationService.settings$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(_ => {
          this.numberFormat = _.numberPipeFormat;
          this.dateTimeFormat = _.dateTimePipeFormat;
          this.changeDetectorRef.markForCheck();
        });

        this.caseList$ = this.appService.customerNumber$.pipe(
              takeUntil(this.unsubscribe$)
            , tap(() => {
                this.isLoading = true; 
                this.changeDetectorRef.markForCheck();
            }) 
            , mergeMap((customerNumber) => this.customersService.getLastAccessedCases(customerNumber))
            , tap((cases) => {
                this.totalNumberOfItems = cases.length;
                this.first = 0;
                this.isLoading = false;
                this.changeDetectorRef.markForCheck();
            })
        )
    }

    fillToPageSize(rows : ILastAccessedCaseRow[] = []){
        let arrayCopy : ILastAccessedCaseRow[] = [...rows];
        for(let i = arrayCopy.length; i< this.numberOfItemsPerPage; i++)
            arrayCopy.push({} as ILastAccessedCaseRow);
        return arrayCopy;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }
}