<ng-container *ngIf="(user$ | async)?.isAuthenticated; else unauthenticated">
  <header>
    <app-toolbar [menuItems]="menu" [searchEngines]="searchEngines" [customers]="customers$ | async"
      (customerNumberChange)="onCustomerChange($event)"></app-toolbar>
  </header>

  <main class="app-body">
    <app-global-spinner></app-global-spinner>
    <div class="app-content">
      <router-outlet></router-outlet>
    </div>
  </main>

  <app-twofactor-user-setup [componentMode]="'notice'"></app-twofactor-user-setup>

</ng-container>

<p-toast position="top-center"></p-toast>

<ng-template #unauthenticated>
  <div>
    <app-global-spinner></app-global-spinner>
    <router-outlet></router-outlet>
  </div>
</ng-template>


<footer>
  <ng-container *ngIf="sveaAddress$ | async as sveaAddress">
    {{sveaAddress}}
  </ng-container>
</footer>
