import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TwofactorService } from 'src/app/services/twofactor.service';
import { IActive2FAModel, ITwoFactorAuthAppSetupModel, TwoFactorAuthType } from '../twofactor.models';
import { MatDialog } from '@angular/material/dialog';
import { GlobalSpinnerService } from '../../global-spinner/global-spinner.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { Observable, Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { IContactPerson } from '../../models';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'app-twofactor-user-setup',
  templateUrl: './twofactor-user-setup.component.html',
  styleUrls: ['./twofactor-user-setup.component.scss']
})
export class TwofactorUserSetupComponent implements OnInit, OnDestroy {
  @ViewChild('twoFactorPopup') twoFactorPopupTemplate;

  @Input()
  componentMode: 'normal' | 'notice' = 'normal';
  setupMode: 'none' | 'app' | 'email' = 'none';

  currentAppSetup: ITwoFactorAuthAppSetupModel;
  verificationCode: any;
  activeTwoFactor: IActive2FAModel;
  subs: Subscription[] = [];

  customerNumber$ = this.application.customerNumber$;
  contactPerson$: Observable<IContactPerson>;
  currentDialog: any;

  constructor(
    private service: TwofactorService,
    private spinner: GlobalSpinnerService,
    private messageToUi: MessageToUiService,
    private dialog: MatDialog,
    private application: AppService,
    private dashboardService: DashboardService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
    this.loadActiveTwoFactor();

      this.contactPerson$ = this.customerNumber$.pipe(
        mergeMap(customerNumber => this.dashboardService.getContactPerson(customerNumber)
        ), shareReplay(1)
      );
  }


  changeSetupType(newSetupType: 'none' | 'app' | 'email') {
    this.setupMode = newSetupType;

    switch (this.setupMode) {
      case "app":
        this.loadAppSetup();
        break;

      case "email":
        this.loadEmailSetup();
        break;
    }
  }

  loadActiveStatus() {
    this.subs.push(
      this.service.getTwoFactorStatus().subscribe({
        next: (dat) => {
          this.activeTwoFactor = dat;
          this.ref.markForCheck();
        }
      })
    );
  }

  loadAppSetup() {
    this.spinner.show();
    this.service.getTwoFactorSetupApp().subscribe({
      next: (dat) => {
        this.currentAppSetup = dat;
      },
      complete: () => this.spinner.hide()
    })
  }

  loadEmailSetup() {
    this.spinner.show();
    this.subs.push(
      this.service.getTwoFactorSetupEmail().subscribe({
        next: () => {
          this.messageToUi.successKey('TwoFactorAuth.EmailSent');
        },
        complete: () => this.spinner.hide()
      })
    );
  }

  finishSetup() {
    this.spinner.show();
    var type = this.currentAppSetup != null ? TwoFactorAuthType.App : TwoFactorAuthType.Email;
    this.subs.push(
      this.service.finishTwoFactorAuth(this.verificationCode, type).subscribe({
        next: (success) => {
          if (!success) {
            this.verificationCode = null;
            this.messageToUi.errorKey('TwoFactorAuth.IncorrectCode');
          }
          else {
            this.currentDialog.disableClose = false;
            this.messageToUi.successKey('TwoFactorAuth.Activated')
            this.dialog.closeAll();
          }
        },
        complete: () => this.spinner.hide()
      })
    );
  }

  loadActiveTwoFactor() {
    this.subs.push(
      this.service.getTwoFactorStatus().subscribe({
        next: (dat) => {
          this.activeTwoFactor = dat;
          if (this.componentMode == 'notice' && dat.isActive == false) this.openTwoFactorPopup();
        }
      })
    );
  }

  openTwoFactorPopup() {
    this.currentDialog = this.dialog.open(this.twoFactorPopupTemplate, { disableClose: this.componentMode == 'notice',  panelClass: 'twofa-dialog-container' });

    this.subs.push(
      this.currentDialog.afterClosed().subscribe({
        next: () => {
          this.changeSetupType('none');
          this.loadActiveStatus();
        }
      })
    );
  }
}
