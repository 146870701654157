import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICaseNotesFilter, ICaseSearchOptions, ICaseSearchResult, IPaginatedSearch } from '../shared/models';
import { ICustomCaseSearchOptions, ICustomerCasesToDebtCollectionRequest } from '../reports/reports-models';
import { map, tap } from 'rxjs/operators';
import { CaseIteratorInput } from './case/cases-iterator.component';
import { CaseSearchService } from '../services/case-search.service';
import { CustomersService } from '../services/customers.service';
import { KeyValue } from '@angular/common';
import { CasesService } from '../services/cases.service';

@Injectable({
  providedIn: 'root'
})
export class CaseOpenLocationService {

  private caseOpenLocationBS = new BehaviorSubject<SupportedTracking>(null);

  readonly caseOpenLocation$ = this.caseOpenLocationBS.asObservable();

  readonly caseIteration$ = this.caseOpenLocation$.pipe(map(_ => this.mapToInterateCasesInstructions(_)));

  constructor (
    private router: Router,
    private caseSearchService: CaseSearchService,
    private customerService: CustomersService,
    private casesService: CasesService) {
  }

  openCase(opening: SupportedTracking) {
    this.caseOpenLocationBS.next(opening);
    this.router.navigate([`cases/${this.getCaseNumberFrom(opening)}`], { state: this.mapToInterateCasesInstructions(opening) });
  }

  getCaseNumberFrom(opening: SupportedTracking): number {
    if (opening.type == 'search') {
      return opening.data.pageItems[opening.caseNumberIndex].caseNumber
    }

    return opening.pageItems[opening.caseNumberIndex].caseNumber;
  }

  return(fromCaseNumber: number) {
    var sourceSearch = this.caseOpenLocationBS.getValue();

    if (sourceSearch && this.mapToInterateCasesInstructions(sourceSearch).caseNumbers?.indexOf(fromCaseNumber) >= 0)
      switch (sourceSearch.type) {
        case 'search':
          this.router.navigate(['/search'], { state: { resume: sourceSearch.filter } })
          break;

        case 'reports':
          this.router.navigate(['reports/customReports'], { state: { resume: sourceSearch.filter } })
          break;

        case 'flagged':
          this.router.navigate(['cases/lists'], { state: { resume: { flagged: sourceSearch.filter } } })
          break;

        case 'myTasks':
          this.router.navigate(['cases/lists'], { state: { resume: { tasks: { options: sourceSearch.filter, caseNoteCategories: sourceSearch.caseNoteCategories } } } });
          break;

        case 'newCaseInformation':
          this.router.navigate(['cases/lists'], { state: { resume: { newCaseInfo: { options: sourceSearch.filter, caseNoteCategories: sourceSearch.caseNoteCategories } } } });
          break;

        case 'toDebtCollection':
          this.router.navigate(['cases/todebtcollection'], { state: { resume: sourceSearch.filter } })
        default: break;
      }
  }

  next() {
    let currentLocation = this.caseOpenLocationBS.getValue();
    this.openCase({ ...currentLocation, caseNumberIndex: (currentLocation.caseNumberIndex + 1) });
  }

  previous() {
    let currentLocation = this.caseOpenLocationBS.getValue();
    this.openCase({ ...currentLocation, caseNumberIndex: (currentLocation.caseNumberIndex - 1) });
  }

  movePage(direction: 'back' | 'forward'): Observable<any> | null {
    let currentLocation = this.caseOpenLocationBS.getValue();

    if (currentLocation == null) return null;

    switch (currentLocation.type) {
      case 'search': {

        const newPageNumber = currentLocation.filter.pageNumber + (direction == 'forward' ? 1 : -1);
        const newFilter: ICaseSearchOptions = { ...currentLocation.filter, pageNumber: newPageNumber };

        const search$ = this.caseSearchService.search(newFilter)
          .pipe(map(_searchResult => {
            return {
              ...currentLocation,
              filter: newFilter,
              data: _searchResult,
              caseNumberIndex: direction == 'forward' ? 0 : (_searchResult.pageItems.length - 1),
            } as SearchTrackingRecord
          }))
          .pipe(tap(_newOpening => { this.openCase(_newOpening) }))

        return search$;
      }

      case 'reports':
      case 'flagged': {
        const newPageNumber = currentLocation.filter.currentPage + (direction == 'forward' ? 1 : -1);
        const newFilter = { ...currentLocation.filter, currentPage: newPageNumber };

        const search$ = this.caseSearchService.customCaseSearch({ ...currentLocation.filter, currentPage: newPageNumber })
          .pipe(map(_searchResult => {
            return {
              ...currentLocation,
              filter: newFilter,
              pageItems: Object.assign(_searchResult.pageItems),
              totalCount: _searchResult.totalCount,
              caseNumberIndex: direction == 'forward' ? 0 : (_searchResult.pageItems.length - 1)
            } as ReportsTrackingRecord | FlaggedCasesTrackingRecord;
          }))
          .pipe(tap(_newOpening => { this.openCase(_newOpening) }))

        return search$;
      }

      case 'myTasks':
      case 'newCaseInformation': {
        const newPageNumber = currentLocation.filter.currentPage + (direction == 'forward' ? 1 : -1);
        const newFilter = { ...currentLocation.filter, currentPage: newPageNumber };

        const parameter = { ...currentLocation.filter, currentPage: newPageNumber };

        const search$ = (currentLocation.type == 'myTasks' ? this.customerService.getTasks(parameter) : this.customerService.getNewCaseInformation(parameter))
          .pipe(map(_searchResult => {
            return {
              ...currentLocation,
              filter: newFilter,
              pageItems: Object.assign(_searchResult.pageItems),
              totalCount: _searchResult.totalNumberOfItems,
              caseNumberIndex: direction == 'forward' ? 0 : (_searchResult.pageItems.length - 1)
            } as CaseTasksTrackingRecord;
          }))
          .pipe(tap(_newOpening => { this.openCase(_newOpening) }))

        return search$;
      }
      case 'toDebtCollection': {
        const newPageNumber = currentLocation.filter.currentPage + (direction == 'forward' ? 1 : -1);
        const newFilter = { ...currentLocation.filter, currentPage: newPageNumber };

        const search$ = this.casesService.getToDebtCollectionList(newFilter)
          .pipe(map(_searchResult => {
            return {
              ...currentLocation,
              filter: newFilter,
              pageItems: Object.assign(_searchResult.pageItems),
              totalCount: _searchResult.totalCount,
              caseNumberIndex: direction == 'forward' ? 0 : (_searchResult.pageItems.length - 1)
            } as ToDebtCollectionCasesTrackingRecord
          }))
          .pipe(tap(_newOpening => { this.openCase(_newOpening) }))
      }

      default: return null;
    }
  }

  private mapToInterateCasesInstructions(opening: SupportedTracking): CaseIteratorInput {
    if (opening == null) return null;

    switch (opening.type) {

      case 'search':
        return {
          selectedIndex: opening.caseNumberIndex
          , caseNumbers: opening.data.pageItems.map(_ => _.caseNumber)
          , hasNextPage: opening.data.totalNumberOfItems > opening.data.pageSize * opening.data.pageNumber
          , hasPreviousPage: opening.data.pageNumber > 1
        } as CaseIteratorInput

      case 'reports':
      case 'flagged':
      case 'myTasks':
      case 'newCaseInformation':
      case 'toDebtCollection':
        return {
          selectedIndex: opening.caseNumberIndex
          , caseNumbers: opening.pageItems.map(_ => _.caseNumber)
          , hasNextPage: opening.totalCount > opening.filter.pageSize * opening.filter.currentPage
          , hasPreviousPage: opening.filter.currentPage > 1

        } as CaseIteratorInput

      default: return null;
    }
  }
}

export interface BaseCaseSearchTracking<TSearchFilter> {
  caseNumberIndex: number
  filter: IPaginatedSearch<TSearchFilter>
  totalCount: number
  pageItems: Array<{ caseNumber: number }>
  type: 'reports' | 'flagged' | 'myTasks' | 'newCaseInformation' | 'toDebtCollection'
}

export interface SearchTrackingRecord {
  caseNumberIndex: number;
  type: 'search',
  filter: ICaseSearchOptions
  data: ICaseSearchResult
}

export interface ReportsTrackingRecord extends BaseCaseSearchTracking<ICustomCaseSearchOptions> {
  type: 'reports'
}

export interface FlaggedCasesTrackingRecord extends BaseCaseSearchTracking<ICustomCaseSearchOptions> {
  type: 'flagged'
}

export interface CaseTasksTrackingRecord extends BaseCaseSearchTracking<ICaseNotesFilter> {
  type: 'myTasks' | 'newCaseInformation',
  caseNoteCategories: KeyValue<string, string>[]
}

export interface ToDebtCollectionCasesTrackingRecord extends BaseCaseSearchTracking<ICustomerCasesToDebtCollectionRequest> {
  type: 'toDebtCollection'
}

export type SupportedTracking = SearchTrackingRecord | ReportsTrackingRecord | FlaggedCasesTrackingRecord | CaseTasksTrackingRecord | ToDebtCollectionCasesTrackingRecord;
