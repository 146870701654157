<div class="page-container" [hidden]="!user">
  <fieldset class="title-container">
    <div>
      <h3 class="title">{{pageTitleLabel | translate}}</h3>
      <hr class="separation-line" />
    </div>
  </fieldset>
  <form #form="ngForm" class="form-container">
    <fieldset>
      <div class="title-container"> {{'Admin.UserDetails' | translate}}
        <hr class="separation-line" />
      </div>
      <app-base-user-info #baseInfo (changeEvent)="validate()" class="options-container"></app-base-user-info>
      <app-twofactor-user-setup class="options-container"></app-twofactor-user-setup>
    </fieldset>
    <fieldset>
      <div class="title-container"> {{'Admin.CustomerAccess' | translate}}
        <hr class="separation-line" />
      </div>
      <div id="customersTableContainer">
        <div [hidden]="areCustomersValid" class="input-error">
          <span>{{ customersInvalidMessage | translate}}</span>
        </div>
        <ng-container [ngTemplateOutlet]="recursiveTable"
          [ngTemplateOutletContext]="{$implicit: customers}"></ng-container>
      </div>
    </fieldset>
    <fieldset class="options-container">
      <div class="options-container">
        <div class="title-container"> {{'Admin.OtherRights' | translate}}
          <hr class="separation-line" />
        </div>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webNotesLocked" name="webNotesLocked" 
            id="webNotesLocked">
          <span class="checkmark"></span>
          <label for="webNotesLocked">{{'Admin.BlockedFromAddingMessages' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webNewCaseLocked" name="webNewCaseLocked"
           id="webNewCaseLocked">
          <span class="checkmark"></span>
          <label for="webNewCaseLocked">{{'Admin.BlockedFromAddingNewCases' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webCloseCaseLocked"
            name="webCloseCaseLocked" id="webCloseCaseLocked">
          <span class="checkmark"></span>
          <label for="webCloseCaseLocked">{{'Admin.BlockedFromClosingCases' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webCanLockCase" name="webCanLockCase"
          id="webCanLockCase">
          <span class="checkmark"></span>
          <label for="webCanLockCase">{{'Admin.AccessToCaseLocking' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webElectricCompany"
            name="webElectricCompany" id="webElectricCompany">
          <span class="checkmark"></span>
          <label for="webElectricCompany">{{'Admin.HasAccessToElectronicCompanies' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webRegisterPaymentsLocked"
            name="webRegisterPaymentsLocked" id="webRegisterPaymentsLocked">
          <span class="checkmark"></span>
          <label for="webRegisterPaymentsLocked">{{'Admin.BlockedFromAddingDirectPayments' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webCanChangeMessageType"
            name="webCanChangeMessageType" id="webCanChangeMessageType">
          <span class="checkmark"></span>
          <label for="webCanChangeMessageType">{{'Admin.AccessToChangingMessageType' | translate }}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webCanViewScore" name="webCanViewScore"
           id="webCanViewScore">
          <span class="checkmark"></span>
          <label for="webCanViewScore">{{'Admin.ScoreEnabled' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webCanNotAddMessagesOnSameReference"
            name="webCanNotAddMessagesOnSameReference" id="webCanNotAddMessagesOnSameReference">
          <span class="checkmark"></span>
          <label for="webCanNotAddMessagesOnSameReference">
            {{'Admin.BlockedFromMessagesOnSameReference' | translate}}
          </label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webCanCreatePaymentLink"
            name="webCanCreatePaymentLink" id="webCanCreatePaymentLink">
          <span class="checkmark"></span>
          <label for="webCanCreatePaymentLink">{{'Admin.CanCreatePaymentLinks' | translate}}</label>
        </label>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webHasAccessToIper"
            name="webHasAccessToIper" id="webHasAccessToIper" [disabled]="!(allowIperAccess$ | async)">
          <span class="checkmark"></span>
          <label for="webHasAccessToIper">{{'Admin.AccessToIper' | translate}}</label>
        </label>
        <fieldset class="credentials-iper" *ngIf="user.webHasAccessToIper">
          <input type="text" class="text-input" name="iperUsername" [ngModel]="getUserSetting('iper','Username')"
          (ngModelChange)="updateUserSetting($event, 'iper', 'Username')"
          placeholder="{{'Admin.Username' | translate}}">
          <input type="password" class="text-input" name="iperPassword" [ngModel]="getUserSetting('iper','Password')"
          (ngModelChange)="updateUserSetting($event, 'iper', 'Password')"
          placeholder="{{'Admin.Password' | translate}}">
        </fieldset>

      </div>
      <div class="options-container">
        <div class="title-container"> {{'Admin.Documents' | translate}}
          <hr class="separation-line" />
        </div>
        <div class="options-container">
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webDocShowRemittance"
              name="webDocShowRemittance" id="webDocShowRemittance">
            <span class="checkmark"></span>
            <label for="webDocShowRemittance">{{'Admin.WebDocShowRemittance' | translate}}</label>
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webDocShowInvoice" name="webDocShowInvoice"
              id="webDocShowInvoice">
            <span class="checkmark"></span>
            <label for="webDocShowInvoice">{{'Admin.WebDocShowInvoice' | translate}}</label>
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webDocShowNewCase" name="webDocShowNewCase"
              id="webDocShowNewCase">
            <span class="checkmark"></span>
            <label for="webDocShowNewCase">{{'Admin.WebDocShowNewCase' | translate}}</label>
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="user.webDocShowClosedCase"
              name="webDocShowClosedCase" id="webDocShowClosedCase">
            <span class="checkmark"></span>
            <label for="webDocShowClosedCase">{{'Admin.WebDocShowClosedCase' | translate}}</label>
          </label>
        </div>
      </div>
    </fieldset>
    <fieldset class="options-container">
      <div class="options-container">
        <div class="title-container"> {{'Admin.AccessToReports' | translate}}
          <hr class="separation-line" />
        </div>
        <label class="custom-checkbox-container" *ngFor="let report of reportsAvailable">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="report.isSelected"
            [ngModelOptions]="{standalone: true}" [id]="report.label">
          <span class="checkmark"></span>
          <label [for]="report.label">{{ ('Admin.Reports.' + report.label) | translate}}</label>
        </label>
      </div>
      <div class="options-container">
        <div class="title-container"> {{'Admin.ContractClaimTypes' | translate}}
          <hr class="separation-line" />
        </div>
        <label class="custom-checkbox-container" *ngFor="let claimType of claimTypes">
          <input type="checkbox" class="column-checkbox" [(ngModel)]="claimType.isSelected"
            [ngModelOptions]="{standalone: true}" [id]="claimType.claimTypeCode ">
          <span class="checkmark"></span>
          <label [for]="claimType.claimTypeCode ">
            {{claimType.claimTypeCode + ' - ' + claimType.claimTypeDescription}}
          </label>
        </label>
      </div>
    </fieldset>
  </form>
  <div class="button-input-combo">
    <button class="button-red" id="block-user-button" *ngIf="canBlockUser$?.value"
      (click)="blockUser()">{{blockUserLabel | translate}}</button>
    <button class="button-basic" id="add-user-button" (click)="addOrEdit()"
      [disabled]="!canAddOrEdit$?.value">{{(user.locked ? openUserLabel : saveButtonLabel ) | translate}} </button>
  </div>
</div>
<ng-template #recursiveTable let-values>
  <ng-container>
    <p-table [value]="[[]]">
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th></th>
          <th>{{'General.CustomerNumber' | translate}}</th>
          <th>{{'General.CustomerName' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <ng-container *ngTemplateOutlet="tableBody; context:{ $implicit: values}"></ng-container>
      </ng-template>
    </p-table>
  </ng-container>
</ng-template>
<ng-template #tableBody let-values>
  <ng-container *ngFor="let value of values">
    <ng-container *ngIf="value.children?.length > 0">
      <tr>
        <td><i [class]="'fas' +  (value?.showChildren ? ' fa-caret-up' : ' fa-caret-down')"
            (click)="value.showChildren = !value.showChildren"></i></td>
        <td>
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="value.isSelected"
              (ngModelChange)="manageUserCustomers($event,value)" [ngModelOptions]="{standalone: true}">
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{value.number}}</td>
        <td>{{value.name}}</td>
      </tr>
      <tr [style.display]="value?.showChildren ? 'table-row' : 'none'">
        <td></td>
        <td colspan="3">
          <ng-container *ngTemplateOutlet="recursiveTable; context:{ $implicit: value.children}"></ng-container>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="value.children == null || value.children.length == 0">
      <tr>
        <td></td>
        <td>
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="value.isSelected"
              (ngModelChange)="manageUserCustomers($event,value)" [ngModelOptions]="{standalone: true}">
            <span class="checkmark"></span>
          </label>
        </td>
        <td>{{value.number}}</td>
        <td>{{value.name}}</td>
      </tr>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #errorsDisplay let-c="control">
  <div *ngIf="(c.dirty || c.touched) && !c.valid" class="input-error">
    <span>{{ ('Admin.Error.' + c.errors.errorCode) | translate}}</span>
  </div>
</ng-template>
