import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable, Subscription, fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-input-with-spinner',
  templateUrl: './input-with-spinner.component.html',
  styleUrls: ['./input-with-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "value",
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputWithSpinnerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges{

  constructor(private selfRef : ElementRef,private changeDetectorRef : ChangeDetectorRef)
  {}
  
  
  @Input()
  showSpinner : boolean = false;

  @Input()
  spinnerPosition : string = "flex-start" 

  @Input()
  spinnerColor : string = "var(--clr-svea-1)" 
  
  @Input()
  spinnerDiameter : number = 30;

  @Input()
  autoUpdateSpinnerSize : boolean = true;

  @Input()
  clearOnLoading : boolean = true;

  @Input()
  value : string;

  @Input()
  name: string = `input-${Math.floor(10000 + Math.random() * 90000)}`;

  @Input()
  readonly : boolean = false;

  @Output()
  valueChange : EventEmitter<string> = new EventEmitter<string>();

  resizeWindowObservable$ : Observable<any>;
  resizeWindowSubscription$ :  Subscription;

  ngOnInit(): void {
    if(this.autoUpdateSpinnerSize)
    {
      this.resizeWindowObservable$ = fromEvent(window, 'resize').pipe(debounce(() => interval(250)));
      this.resizeWindowSubscription$ = this.resizeWindowObservable$.subscribe( evt => {
        this.sizeSpinners()
      })
    }

  }


  ngOnDestroy(): void {
    this.resizeWindowSubscription$?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if(this.autoUpdateSpinnerSize)
      this.sizeSpinners();

  }

  ngOnChanges(changes : SimpleChanges)
  {
    if(changes.showSpinner)
    {

      if(changes.showSpinner.currentValue == true && this.clearOnLoading)
      {
        this.value = "";
        this.valueChange.emit(this.value);
      }
    }
  }

  sizeSpinners()
  {
    let inputHeight = (this.selfRef.nativeElement as HTMLElement).getElementsByTagName('input')[0].offsetHeight;
    this.spinnerDiameter = inputHeight  - 16; // 16 seems to look good on all tested sizes, no other reason for this value 

    this.changeDetectorRef.markForCheck();
    
  }

  checkForChanges()
  {
    this.changeDetectorRef.detectChanges();
  }

}
