<svg class="doughnut-chart" viewBox="0 0 50 50">
  <path class="circle-bg" fill="none" stroke="#ddd" stroke-width="3.5"
    [attr.d]="getCirclePath()">
  </path>
  <path class="circle"
    [attr.stroke-dasharray]="getStrokeDasharray(percentage)" fill="none" [attr.stroke]="color" stroke-width="3.5" stroke-linecap="round"
    [attr.d]="getCirclePath()">
  </path>

  <!-- Percentage text -->
  <text [attr.x]="x" [attr.y]="y" text-anchor="middle" alignment-baseline="middle" font-size="5" font-weight="bold" font-style="italic" [attr.fill]="color">{{percentage}}%</text>

  <!-- Pill shape -->
  <rect x="5" y="38" rx="5" ry="5" width="40" height="8" [attr.fill]="color" />

  <!-- Text inside the pill -->
  <text x="25" y="43.5" text-anchor="middle" font-family="Asap" font-size="4" fill="white">{{title}}</text>
</svg>
