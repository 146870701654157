<p-table [value]="list" [scrollable]="true" selectionMode="single" [scrollable]="true"
    scrollHeight="flex" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th>{{'Case.Payments.Date' | translate}}</th>
      <th>{{'Case.Payments.Reference' | translate}}</th>
      <th>{{'Case.Payments.Amount' | translate}} {{firmCurrencyCode}}</th>
      <th *ngIf="hasInternationalCurrency">{{'Case.Payments.Amount' | translate}} {{caseCurrencyCode}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-payment>
    <tr>
      <td>{{ payment.date | date }}</td>
      <td>{{ isEnglish? payment.actionDescriptionEn : payment.actionDescription }}</td>
      <td>{{ payment.amount | number : numberFormat.style: numberFormat.culture }}</td>
      <td *ngIf="hasInternationalCurrency">{{ payment.currencyAmount | number : numberFormat.style: numberFormat.culture }}</td>
    </tr>
  </ng-template>
</p-table>