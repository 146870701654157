import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardRoutingModule } from './dashboard/dashboard-routing.module';
import { CasesRoutingModule } from './cases/cases-routing.module';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { CommonModule } from '@angular/common';
import { ContractsRoutingModule } from './contracts/contracts-routing.module';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'login/:requestId', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'reset-password/:requestId', component: ResetPasswordComponent },
  { path: 'unauthorized', component: LoginComponent },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  {
    path: 'search', loadComponent: () =>
      import('./search/search.component').then(m => m.SearchComponent), canActivate: [AuthGuard]
  },
  {
    path: 'search/Iper', loadComponent: () =>
      import('./search/iper-search/iper-search.component').then(m => m.IperSearchComponent), canActivate: [AuthGuard],
      pathMatch: 'full'
  },
  {
    path: 'search/:provider', loadComponent: () =>
      import('./search/third-party-search.component').then(m => m.ThirdPartySearchComponent), canActivate: [AuthGuard]
  },
  { path: 'information', loadChildren: () => import('./information/information.module').then(m => m.InformationModule) },

  { path: 'documents', loadComponent: ()=> import('./documents/documents.component').then(m => m.DocumentsComponent), canActivate: [AuthGuard] },

  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'cases', loadChildren: () => import('./cases/cases.module').then(m => m.CasesModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [CommonModule, DashboardRoutingModule, CasesRoutingModule, ContractsRoutingModule, RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
