import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { LoginService } from '../login/login.service';
import { LogicalOperator } from '../shared/models';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private appService: AppService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // handle access to login page
    if (this.navigatingToLogInPage(route)) {
      if (this.loginService.isLoggedIn()) {

        this.router.navigate(['']);

        return of(false);
      }

      return of(true);
    }

    // handle access to anything else
    return this.determineIsAuthorized(route, state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.determineIsAuthorized(childRoute, state.url);
  }

  private determineIsAuthorized(route: ActivatedRouteSnapshot, url: string): Observable<boolean> {
    if (!this.loginService.isLoggedIn()) {
      this.router.navigate(['/login'], { queryParams: { 'redirect': url } });
      return of(false);
    }

    return this.isProtected(route) ? this.userIsAuthorized(route) : of(true);
  }

  private navigatingToLogInPage(route: ActivatedRouteSnapshot) {
    return route.url?.length > 0 && route.url[0].path === 'login';
  }

  private isProtected(route: ActivatedRouteSnapshot): boolean {
    return route.data.userRight != null || route.data.userRole != null;
  }

  private userIsAuthorized(route: ActivatedRouteSnapshot) {
    return this.appService.getPermissions().pipe(map((_permissions) => {
      if (!_permissions) return false;

      if (route.data?.comparison != null && (route.data?.comparison as LogicalOperator) == 'And') {
        let userRights = (_permissions.rights ?? []);
        let requestedRight = route.data?.userRight;
        let hasRequestedRight = requestedRight == null || userRights.findIndex(x => x == requestedRight) >= 0
        return hasRequestedRight && (_permissions.role == route.data.userRole)
      }
      
      return (_permissions.rights ?? []).indexOf(route.data?.userRight) >= 0 || (_permissions.role == route.data.userRole);
    }));
  }
}
