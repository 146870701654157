import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import {
  ICreditNoteOrPayment, ICreditNoteOrPaymentRegistered
  , ICaseModel, ICaseViewModel, IHistoryData, IInvoice, ICasePaymentSummary, ServiceResult, ICaseDocument, IRiskClass, IDebtorModel, ICaseFlagStatus, ICaseRecallCost, INewMessage, IRelatedCase, IPaginatedSearch, IPaginatedResponse, ICaseSearchOptions, ICaseSecuritySummary, ICaseViewModelExtended, ICreditorCasePauseRequest
} from '../shared/models';
import { CaseSearchService } from './case-search.service';
import { ICustomerCasesToDebtCollectionRequest, ICustomerCaseToDebtCollectionModel } from '../reports/reports-models';

@Injectable({
  providedIn: 'root'
})
export class CasesService {

  constructor (
    private kreditorWs: KreditorService,
    private caseSearchService: CaseSearchService) { }

  getByNumber(caseNumber: number, catchException: boolean = false): Observable<ICaseViewModelExtended> {
    return this.kreditorWs.getServiceResult<ICaseViewModel>(`cases/${caseNumber}`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  getSummaryByNumber(caseNumber: number, catchException: boolean = false): Observable<ICaseModel> {
    return this.kreditorWs.getServiceResult<ICaseModel>(`cases/${caseNumber}/summary`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  getLatestAccessed(catchException: boolean = false): Observable<ICaseModel[]> {
    return this.caseSearchService.search({ term: '123', type: 'generic', pageNumber: 1, pageSize: 5 } as ICaseSearchOptions)
      .pipe(map(_ => _.pageItems))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getCaseFlagStatus(caseNumber: number): Observable<ICaseFlagStatus> {
    return this.kreditorWs.getServiceResult<Date>(`cases/${caseNumber}/flag`)
      .pipe(map(_ => { return { caseNumber: caseNumber, flagDate: _.result } as ICaseFlagStatus }));
  }

  toggleCaseFlagStatus(caseNumber: number): Observable<ICaseFlagStatus> {
    return this.kreditorWs.post<ServiceResult<Date>, any>(`cases/${caseNumber}/flag`, null)
      .pipe(map(_ => { return { caseNumber: caseNumber, flagDate: _.result } as ICaseFlagStatus }));
  }
  
  getPayments(caseNumber: number, catchException: boolean = false): Observable<ICasePaymentSummary[]> {
    return this.kreditorWs.getServiceResult<ICasePaymentSummary[]>(`cases/${caseNumber}/payments`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getSecurities(caseNumber: number, catchException: boolean = false): Observable<ICaseSecuritySummary[]> {
    return this.kreditorWs.getServiceResult<ICaseSecuritySummary[]>(`cases/${caseNumber}/securities`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getInvoices(caseNumber: number, catchException: boolean = false): Observable<IInvoice[]> {
    return this.kreditorWs.getServiceResult<IInvoice[]>(`cases/${caseNumber}/invoices`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getInvoiceCopy(caseNumber: number, invoiceNumber: string): Observable<Blob> {
    return this.kreditorWs.getFile(`cases/${caseNumber}/invoices/${invoiceNumber}/file`);
  }

  uploadInvoiceCopy(caseNumber: number, invoiceNumber: string, file: File) {
    var data = new FormData();
    data.append('file', file);

    return this.kreditorWs.postFormData(`cases/${caseNumber}/invoices/${invoiceNumber}/file`, data);
  }

  getActions(caseNumber: number, executed: boolean = null, catchException: boolean = false): Observable<IHistoryData[]> {
    const query = (executed == null) ? '' : `?executed=${executed}`;
    return this.kreditorWs.getServiceResult<IHistoryData[]>(`cases/${caseNumber}/actions${query}`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  registerCreditNote(caseNumber: number, data: ICreditNoteOrPayment): Observable<ICreditNoteOrPaymentRegistered> {
    return this.kreditorWs.post<ServiceResult<ICreditNoteOrPaymentRegistered>, ICreditNoteOrPayment>(
      `cases/${caseNumber}/creditNotes`, data)
      .pipe(map(_ => _.result));
  }

  registerPayment(caseNumber: number, data: ICreditNoteOrPayment): Observable<ICreditNoteOrPaymentRegistered> {
    return this.kreditorWs.post<ServiceResult<ICreditNoteOrPaymentRegistered>, ICreditNoteOrPayment>(
      `cases/${caseNumber}/payments`, data)
      .pipe(map(_ => _.result));
  }

  getCaseDocuments(caseNumber: number): Observable<ICaseDocument[]> {
    return this.kreditorWs.getServiceResult<ICaseDocument[]>(`cases/${caseNumber}/documents`)
      .pipe(map(_ => _.result));
  }

  downloadDocument(documentIdentifier: string): Observable<Blob> {
    return this.kreditorWs.getFile(`cases/documents/${documentIdentifier}/content`)
  }

  downloadLetter(caseNumber: number, identifier: string): Observable<Blob> {
    return this.kreditorWs.getFile(`cases/${caseNumber}/letters/${identifier}/content`)
  }

  getRiskClasses(): Observable<IRiskClass[]> {
    return this.kreditorWs.getServiceResult<IRiskClass[]>(`cases/riskClasses/`)
      .pipe(map(_ => _.result));
  }

  updateRiskClass(caseNumber: number, riskClass: number): Observable<IDebtorModel> {
    return this.kreditorWs.post<ServiceResult<IDebtorModel>, number>(
      `cases/${caseNumber}/riskClass`, riskClass)
      .pipe(map(_ => _.result));
  }

  getWithdrawCost(caseNumber: number): Observable<ICaseRecallCost> {
    return this.kreditorWs.getServiceResult<ICaseRecallCost>(`cases/${caseNumber}/recallcosts`)
      .pipe(map(_ => _.result));
  }

  sendWithdrawCaseRequest(caseNr: number, acceptedTerms: string): Observable<INewMessage> {
    return this.kreditorWs.post<ServiceResult<INewMessage>, INewMessage>(`cases/${caseNr}/recall`,
      { caseNumber: caseNr, text: acceptedTerms } as INewMessage)
      .pipe(map(_ => _.result));
  }

  getPaymentlinkRelatedCases(caseNumber: number): Observable<IRelatedCase[]> {
    return this.kreditorWs.getServiceResult<IRelatedCase[]>(`cases/${caseNumber}/paymentlinks/relatedcases`)
      .pipe(map(_ => _.result))
  }
  
  getToDebtCollectionList(paginatedParameters: IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>): Observable<IPaginatedResponse<ICustomerCaseToDebtCollectionModel>> {
    return this.kreditorWs.postServiceResult<IPaginatedResponse<ICustomerCaseToDebtCollectionModel>, IPaginatedSearch<ICustomerCasesToDebtCollectionRequest>>(
      'cases/toDebtCollection', paginatedParameters)
      .pipe(map(_ => _.result));
  }

  addToLastAccessed(caseNumber: number) {
    return this.kreditorWs.postServiceResult<null, null>(`cases/${caseNumber}/lastAccessed`, null);
  } 

  pause(pauseRequest : ICreditorCasePauseRequest): Observable<number> {
    return this.kreditorWs.postServiceResult<number, ICreditorCasePauseRequest>(`cases/${pauseRequest.caseNumber}/pause`, pauseRequest)
      .pipe(map(_ => _.result));
  }

  downloadInvoicesExcel(caseNumber: number, languageCode: string): Observable<Blob> {
    return this.kreditorWs.getFile(`cases/${caseNumber}/invoices/download?languageCode=${languageCode}`);
  }
}
