<input type="file" [(accept)]="acceptedFormats" [id]="fileInputId" [multiple]="multiFile" #importFile>

<ng-container *ngIf="icon; else button">
  <i class="fas fa-cloud-upload-alt" (click)="selectFile()" [matTooltip]="(multiFile ? 'General.FileUpload.UploadFiles' : 'Shared.FileUpload.UploadFile' ) | translate">
  </i>
</ng-container>

<ng-template #button>
  <button type="button" class="button-basic-reverse" for="uploadFile" (click)="selectFile()" name="fileButton">
    <i class="fas fa-upload"></i>
    {{ (multiFile ? 'General.FileUpload.UploadFiles' : 'Shared.FileUpload.UploadFile' ) | translate}}
  </button>
  <ng-container *ngIf="displayFilesList">
    <label #fileLabel id="fileLabel" for="importFile" name="fileLabel"></label>
    <button *ngIf="files?.length" type="button" aria-label="Close" class="close-button" (click)="clear()">
      <span aria-hidden="true">&times;</span>
    </button>
  </ng-container>
</ng-template>
