<div class="title-container">
    <h2 class="title"> {{ 'Reports.ToDebtCollectionList.Title' | translate }} </h2>
    <hr class="separation-line" />
</div>

<section class="filters-container">
    <fieldset class="customers">
        <label class="label"> {{'Reports.ToDebtCollectionList.Filter' | translate}} </label>
        <input type="text" class="text-input" (input)="onSearchTermChanged($event)">
    </fieldset>
    <label class="totals-label">
        {{'Reports.ToDebtCollectionList.TotalNumberOfCases' | translate}}: {{this.totalNumberOfItems}}
    </label>
</section>

<ng-container *ngTemplateOutlet="caseListTemplate;context:{paginatedCaseList: list$ | async}"></ng-container>

<ng-template #caseListTemplate let-data="paginatedCaseList">
    <section class="results-container">
        <p-table 
            styleClass="p-datatable-sm" 
            [paginator]="true" 
            [first]="first"
            [autoLayout]="false"
            [columns]="columns" 
            [lazy]="true"
            (onLazyLoad)="onPageChange($event)" 
            (onSort)="onTableSort($event)"
            [rows]="numberOfRowsPerPage$.value" 
            [rowsPerPageOptions]="[15, 20, 25]"
            [totalRecords]="totalNumberOfItems" 
            selectionMode="single" 
            [loading]="isLoading" 
            [value]="data"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="col.name" *ngFor="let col of columns"> 
                        {{ col.translationTag | translate }} 
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-case let-columns="columns">
                <tr (click)="onNavigateToCase(case, data)">
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchCase="'date'"> 
                                {{ case[col.name] | date: dateFormat }} 
                            </ng-container>
                            <ng-container *ngSwitchCase="'decimal'"> 
                                {{ case[col.name] | number : numberFormat.style: numberFormat.culture }} 
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{case[col.name]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </section>
</ng-template>