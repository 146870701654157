<section class="header">
  <div class="title-container">
    <h2 *ngIf="largeHeading;else smallHeading" class="title">
      {{ 'Cases.' + listCategory.capitalize() | translate }}
    </h2>
    <ng-template #smallHeading>
      <h5 class="title">
        {{ 'Cases.' + listCategory.capitalize() | translate }}
      </h5>
    </ng-template>
    <hr class="separation-line" />
    <span class="total-items">{{'Cases.TotalNumberItems' | translate}}: {{totalNumberOfItems}}</span>
  </div>
  <div class="tasks-filters-container">
    <fieldset class="filter">
      <label class="label">{{ 'CaseRow.NoteCategory' | translate }}</label>
        <custom-multi-select [options]="noteCategories" 
        [clearEnabled]="true" [selectedKeys]="categoryCodesBS?.value" 
        (selectedKeysChange)="pageNumberBS.next(1);categoryCodesBS.next($event)"></custom-multi-select>
    </fieldset>
    <fieldset class="filter">
      <label class="label">{{ 'CaseRow.CustomerReference' | translate }}</label>
      <input type="text" class="text-input" [value]="anyCustomerReferenceBS?.value" 
        (input)="pageNumberBS.next(1);anyCustomerReferenceBS.next($event.target.value)" />
    </fieldset>
  </div>
</section>

<p-table class="cases-table" [paginator]="true" [lazy]="true" [loading]="isLoading"
  [value]="notesSearchResult$ | async" (onLazyLoad)="onPageChange($event)" [(rows)]="numberOfItemsPerPage"
  [first]="first" [rowsPerPageOptions]="[5,10,15,20]" [totalRecords]="totalNumberOfItems" selectionMode="single"
  [scrollable]="true"  #tbl>
  <ng-template pTemplate="header">
    <tr>
      <th colspan="2" scope="col">{{ 'CaseRow.CaseNumber' | translate }}</th>
      <th scope="col">{{ 'CaseRow.CustomerNumber' | translate }}</th>
      <th scope="col">{{ 'CaseRow.Date' | translate }}</th>
      <th colspan="2" scope="col">{{ 'CaseRow.NoteCategory' | translate }}</th>
      <th colspan="2" scope="col">{{ 'CaseRow.DebtorName' | translate }}</th>
      <th colspan="2" scope="col" class="reference-cell">{{ 'CaseRow.CustomerReference' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-caseNote>
    <tr (click)="openCase(caseNote, tbl.value)">
      <td colspan="2">
        <i [ngClass]="{'fas fa-circle fa-xs': caseNote.caseNumber}"></i>
        <span class="case-number">{{caseNote?.caseNumber}}</span>
      </td>
      <td>{{caseNote?.customerNumber}}</td>
      <td>{{caseNote?.date | date: dateFormat}}</td>
      <td colspan="2">{{isEnglish ? caseNote?.categoryEN : caseNote?.category}}</td>
      <td colspan="2">{{caseNote.debtorName}}</td>
      <td colspan="2" class="reference-cell">{{caseNote?.customerReference}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <ng-container *ngIf="!initialLoadingDone">
      <tr *ngFor="let emptyRow of emptyRows">
        <td>
          <i></i>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
