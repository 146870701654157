import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ContractsComponent } from './contracts/contracts.component';
import { NotesComponent } from './notes/notes.component';
import { CanDeactivateGuard } from '../shared/can-deactivate.guard';


const routes: Routes = [
  { path: '', component: ContractsComponent, canActivate: [AuthGuard] },
  { path: 'contracts/:contractStatusId/notes', component: NotesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractsRoutingModule { }
