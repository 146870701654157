import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UserListComponent } from "./user-list/user-list.component";
import { SharedModule } from "../shared/shared.module";
import { AddEditUserComponent } from "./add-edit-user/add-edit-user.component";
import { AddSubCustomerComponent } from "./add-sub-customer/add-sub-customer.component";
import { ControlErrorsComponent } from "../shared/control-errors/control-errors.component";
import { AdminRoutingModule } from "./admin-routing.module";


@NgModule({
  declarations: [
    UserListComponent,
    AddEditUserComponent,
    AddSubCustomerComponent
  ],
  imports: [
    TranslateModule,
    SharedModule,
    ControlErrorsComponent,
    AdminRoutingModule
  ]
})
export class AdminModule { }
