import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { IRouteData, LogicalOperator, UserRole, WebUserRight } from "../shared/models";
import { AuthGuard } from "../auth/auth.guard";
import { UserListComponent } from "./user-list/user-list.component";
import { AddSubCustomerComponent } from "./add-sub-customer/add-sub-customer.component";
import { AddEditUserComponent } from "./add-edit-user/add-edit-user.component";
import { UserGuard } from "./user-guard";

const protectedBy = (right: WebUserRight = null, role: UserRole = null, comparison: LogicalOperator = 'Or') => { return { userRight: right, userRole: role, comparison: comparison } as IRouteData; }

const routes: Routes = [
  { path: 'admin/userList', component: UserListComponent, canActivate: [AuthGuard], data: protectedBy(null, 'SuperUser') },
  { path: 'admin/addSubCustomer', component: AddSubCustomerComponent, canActivate: [AuthGuard], data: protectedBy(WebUserRight.AddSubCustomer, 'SuperUser', 'And') },
  { path: 'admin/user', component: AddEditUserComponent, canActivate: [AuthGuard], data: protectedBy(WebUserRight.AddUser, 'SuperUser', 'And') },
  { path: 'admin/user/:userCode', component: AddEditUserComponent, canActivate: [AuthGuard, UserGuard], data: protectedBy(null, 'SuperUser') },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
