import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor() { }

  @Input()
  labelTranslationTag: string;
  @Input()
  value: number;
  @Input()
  routerLink: string;
  @Input()
  queryParams: any;

  ngOnInit(): void {
  }
}
