import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Observable } from 'rxjs/internal/Observable';
import { FaqsService } from './faq.service';
import { LocalizationService } from '../shared/localization/localization.service';
import { IFaqsModel } from '../shared/models';
import { ValidatorService } from '../shared/validator.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  constructor(
    private service: FaqsService,
    public datepipe: DatePipe,
    private localizationService: LocalizationService,
    public validator: ValidatorService,
  ) {}

  data$: Observable<IFaqsModel[]>;
  isEnglish: boolean;

  ngOnInit(): void {
    this.data$ = this.service.getAllAsync();
    this.localizationService.currentLanguage$.subscribe(
      (u)=> this.isEnglish = u.toLocaleLowerCase()  === 'en');
  }
}
