import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[subMenuTrigger]'
})
export class SubMenuTriggerDirective implements AfterViewInit {

  @Input()
  subMenuId: string;
  @Input()
  subMenuDisplayType: string;

  subMenuElement: HTMLElement;

  constructor(private selfReference : ElementRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.subMenuElement = document.getElementById(this.subMenuId);
      this.hideSubMenu();
    }, 0);

  }

  @HostListener('document:click', ['$event']) onDocumentClick(event : MouseEvent) {
    if(event.relatedTarget == null || event.relatedTarget != this.selfReference.nativeElement)
      this.hideSubMenu();
  }

  @HostListener('click', ['$event']) onClick($event) {
    if (window.getComputedStyle(this.subMenuElement, null).display == "none") this.showSubMenu();
    else this.hideSubMenu();

    $event.stopPropagation();
    document.dispatchEvent(new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
        relatedTarget : this.selfReference.nativeElement
      }));
  }

  private hideSubMenu(): void {
    if (this.subMenuElement) this.subMenuElement.setAttribute("style", "display: none");
  }

  private showSubMenu(): void {
    this.subMenuElement.setAttribute("style", `display: ${this.subMenuDisplayType}`);
  }
}
