import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilKeyChanged, map, share, tap } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { IAppUser, IAuthResult, IForgotPasswordRequest, IResetPasswordRequest, IUserCredentials, ServiceResult } from '../shared/models';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor (private kreditorService: KreditorService) { }

  private extendSession$ = this.kreditorService.getRefreshedToken(localStorage.getItem('token'))
    .pipe(
      tap(_ => this.setSession(_))
      , map(() => this.getCurrentUser())
      , catchError(e => { this.clearSession(); return of(this.getCurrentUser()) })
      , delay(1000 * 60 * 5)
      , share());

  private currentUserSubject = new BehaviorSubject<IAppUser>(this.getCurrentUser());

  currentUser$: Observable<IAppUser> = this.currentUserSubject.asObservable()
    .pipe(distinctUntilKeyChanged('code'));

  login(userCredentials: IUserCredentials): Observable<any> {
    return this.kreditorService.getTokenAtLogin(userCredentials)
      .pipe(tap(authResponse => this.setSession(authResponse)), map(() => this.getCurrentUser()));
  }

  extendSession(): void {
    this.extendSession$.subscribe();
  }

  logout() {
    this.clearSession();

    return this.getCurrentUser();
  }

  isLoggedIn(): boolean {
    return this.getCurrentUser().isAuthenticated;
  }

  forgotPassword(userCodeEmail: string, selectedLanguageCode: string, applicationSource: string) {
    var forgotPasswordRequest = { userCodeOrEmail: userCodeEmail, userLanguageCode: selectedLanguageCode, applicationSource: applicationSource } as IForgotPasswordRequest;

    const apiresult = this.kreditorService.postAnonymousServiceResult<ServiceResult<IForgotPasswordRequest>, IForgotPasswordRequest>('users/forgotPassword', forgotPasswordRequest);
    return apiresult;
  }

  changePassword(requestToken: string, newPassword: string) {
    var resetPasswordRequest = { token: requestToken, newPassword: newPassword } as IResetPasswordRequest;

    const apiresult = this.kreditorService.postAnonymousServiceResult<ServiceResult<IResetPasswordRequest>, IResetPasswordRequest>('users/resetPassword', resetPasswordRequest);
    return apiresult;
  }

  getCurrentUser() {
    let user = this.isExpired() ? this.unauthenticatedUser() : this.tryGetuserFromToken();
    this.currentUserSubject?.next(user);
    return user;
  }

  unauthenticatedUser(): IAppUser {
    return { isAuthenticated: false, code: null} as IAppUser;
  }

  private setSession(auth: IAuthResult) {
    localStorage.setItem('token', auth.value);
    localStorage.setItem('token_expiration', auth.expiration);
  }

  private clearSession() {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
  }

  private isExpired(): boolean {
    const expiration = localStorage.getItem("token_expiration");

    if (!expiration) return true;

    const expiresAt = new Date(expiration)?.getTime();

    let now = new Date().getTime() - 1000 * 60;

    return expiresAt && expiresAt <= now;
  }

  private tryGetuserFromToken() {
    try {
      const decoded = jwt_decode<any>(localStorage.getItem('token'));
      return {
        name: decoded.sub,
        code: decoded.userCode,
        email: decoded.email,
        isAuthenticated: true,
      } as IAppUser;
    }
    catch { return this.unauthenticatedUser(); }
  }
}




