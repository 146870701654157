import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IContractClosingType, IContractDebtorInfo, IContractNote, IContractNoteEdit } from '../models';
import { Observable, Subscription, finalize } from 'rxjs';
import { LocalizationService } from 'src/app/shared/localization/localization.service';
import { ContractsService } from '../contracts.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { NgForm } from '@angular/forms';
import { ValidatorService } from 'src/app/shared/validator.service';
import { ICanDeactivateComponent } from 'src/app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy, ICanDeactivateComponent {

  @ViewChild('form') form: NgForm;

  contractStatusId: number;
  contractReference: string;
  contractClosingTypeId: number;

  data$: Observable<IContractNote[]>;
  closingTypes$: Observable<IContractClosingType[]>;
  format$ = this.localizationService.settings$;

  debtorInfo$: Observable<IContractDebtorInfo>;
  selectedNote: IContractNoteEdit;
  subs: Subscription[] = [];
  question: string;
  originalClosingType: IContractClosingType;
  selectedClosingType: IContractClosingType;
  closingTypeChanged: boolean;

  constructor(
    private localizationService: LocalizationService,
    private service: ContractsService,
    private messageToUi: MessageToUiService,
    private validator: ValidatorService,
    private translateServcie: TranslateService,
    private dialogRef: MatDialogRef<any>,
    private spinner: GlobalSpinnerService) { }

  canDeactivate(): boolean {
    return !this.form.dirty;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.data$ = this.service.getContractNotes(this.contractStatusId);
    this.closingTypes$ = this.service.getClosingTypes();
    this.debtorInfo$ = this.service.getContractDebtor(this.contractStatusId);
    this.question = this.translateServcie.instant('Contracts.Notes.Question');

    this.subs.push(this.service.getClosingTypes()
      .pipe(finalize(() => {
        this.spinner.hide();
      }))
      .subscribe({
        next: (_) => {
          this.selectedClosingType = _.find(c => c.closingTypeId == this.contractClosingTypeId);
          this.originalClosingType = this.selectedClosingType;
        },
        error: () => {
          this.messageToUi.genericError();
        }
      }));
    this.newNote();
  }

  ngOnDestroy(): void {
    this.subs?.forEach(x => x.unsubscribe());
    this.sendNewIdOnClose();
  }

  cancel() {
    if (this.selectedNote.text) {
      let result = confirm(this.question);
      if (result == true) {
        this.dialogRef.close();
      }
    }
    else {
      this.sendNewIdOnClose();
    }
  }

  sendNewIdOnClose() {
    if (this.closingTypeChanged && this.selectedClosingType != this.originalClosingType) {
      this.dialogRef.close(this.selectedClosingType.closingTypeId);
    }
    else this.dialogRef.close();
  }

  editNote(note: IContractNote) {
    if (this.form.dirty) {
      let result = confirm(this.question);
      if (result == true) {
        this.selectedNote = { id: note.id, internal: note.internal, text: note.text } as IContractNoteEdit;
        this.form.control.markAsPristine();
      }
    }
    else {
      this.selectedNote = { id: note.id, internal: note.internal, text: note.text } as IContractNoteEdit;
      this.form.control.markAsPristine();
    }
  }

  newNote() {
    this.selectedNote = {} as IContractNoteEdit;
    this.form?.control.markAsPristine();
  }

  deleteNote(note: IContractNote) {
    this.subs.push(
      this.service.deleteNote(this.contractStatusId, note.id)
        .subscribe({
          next: (_) => {
            this.data$ = this.service.getContractNotes(this.contractStatusId);
            this.newNote();
          },
          error: (_) => { this.messageToUi.genericError(); }
        }));
  }

  save() {
    this.spinner.show()
    this.changeClosingType();
    const serviceCall = this.selectedNote.id ?
      this.service.updateNote(this.contractStatusId, this.selectedNote) :
      this.service.addNote(this.contractStatusId, this.selectedNote);
    this.subs.push(
      serviceCall.pipe(finalize(() => { this.spinner.hide(); }))
        .subscribe({
          next: (_) => {
            this.data$ = this.service.getContractNotes(this.contractStatusId);
            this.newNote();
          },
          error: (error) => { this.validator.handleError(this.form, error); }
        }));

  }

  changeClosingType() {
    if (this.originalClosingType != this.selectedClosingType) {
      this.service.changeClosingType(this.contractStatusId, this.selectedClosingType.closingTypeId).subscribe(
        {
          next: () => { },
          error: (error) => { this.validator.handleError(this.form, error); }
        }
      );
      this.closingTypeChanged = true;
    }
  }

  onInternalChange(selectedNote: IContractNoteEdit) {
    if (selectedNote.internal == true) {
      selectedNote.sendMessageToCaseHandler = false;
    }
  }

  onSendToCaseHandlerChange(selectedNote: IContractNoteEdit) {
    if (selectedNote.sendMessageToCaseHandler == true) {
      selectedNote.internal = false;
    }
  }

  onChangedClosingType(event, closingType: IContractClosingType) {
    let closingTypeChangedInfo =
      this.translateServcie.instant('ContractClosingType.Note.Text')
      + this.translateServcie.instant(closingType.closingTypeTranslationTag);

    if (this.selectedNote.text) {
      this.selectedNote.text = this.selectedNote.text += '\n' + closingTypeChangedInfo;
    }
    else {
      this.selectedNote.text = closingTypeChangedInfo;
    }
  }
}
