<ng-container *ngIf="value !== null; else loading">
  <a [routerLink]="routerLink" [queryParams]="queryParams">
    <label class="label">
      {{ labelTranslationTag | translate }}
    </label>

    <div class="value-container">
      <div class="value" [class.red-dot]="value > 0">{{value}}</div>
    </div>
  </a>
</ng-container>

<ng-template #loading>
  <label class="label">
    {{ labelTranslationTag | translate }}
  </label>

  <div class="value-container loading">
  </div>
</ng-template>

