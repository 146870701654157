import { Injectable } from '@angular/core';
import { KreditorService } from '../shared/kreditor.service';
import { IEntitySearchRequest, IEntitySearchResult } from '../shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityHistory, IEntity3PartySearchResult, IperFilterTypeEnum } from '../search/models';

@Injectable({
  providedIn: 'root'
})
export class EntitySearchService {

  constructor (private kreditorService: KreditorService) { }

  getEntitySearchResult(request: IEntitySearchRequest): Observable<IEntitySearchResult> {
    return this.kreditorService.postServiceResult<IEntitySearchResult, IEntitySearchRequest>('entities/search', request)
      .pipe(map(_ => _.result));
  }

  thirdPartySearch(provider: string, term: string): Observable<IEntity3PartySearchResult> {
    return this.kreditorService.postServiceResult<IEntity3PartySearchResult, any>(`entities/3partysearch`, { provider: provider, criteria: term })
      .pipe(map(_ => _.result));
  }

  iperSearch(filterType: IperFilterTypeEnum, term: string): Observable<IEntity3PartySearchResult> {
    return this.kreditorService.postServiceResult<IEntity3PartySearchResult, any>(`entities/ipersearch`, { filterType: filterType, searchTerm: term })
      .pipe(map(_ => _.result));
  }
}
