import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/operators';

import { KreditorService } from '../shared/kreditor.service';
import { IFaqsModel, ServiceResult } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  constructor(
    private kreditorWs: KreditorService) { }

  getByIdAsync(id: number, catchException: boolean = false): Observable<IFaqsModel> {
    return this.kreditorWs.getServiceResult<IFaqsModel>(`faqs/${id}`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  getAllAsync(catchException: boolean = false): Observable<IFaqsModel[]> {
    return this.kreditorWs.getServiceResult<IFaqsModel[]>(`faqs`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  addAsync(model: IFaqsModel, catchException: boolean = false): Observable<IFaqsModel> {
    return this.kreditorWs.postServiceResult<IFaqsModel, IFaqsModel>(`faqs`, model)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  updateAsync(id: number, model: IFaqsModel, catchException: boolean = false): Observable<IFaqsModel> {
    return this.kreditorWs.postServiceResult<IFaqsModel, IFaqsModel>(`faqs/${id}`, model)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  deleteAsync(id: number, catchException: boolean = false) {
    return this.kreditorWs.delete<number>(`faqs/${id}`)
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));

  }
}
