import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { FaqComponent } from './faq.component';
import { InformationComponent } from './information.component';

const routes: Routes = [
  {
    path: '', component: InformationComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: 'faq', canActivate: [AuthGuard], component: FaqComponent } as Route
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
