import { Component, Input, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ICustomCaseSearchOptions, ICustomCaseSearchResult } from 'src/app/reports/reports-models';
import { AppService } from 'src/app/services/app.service';
import { CaseSearchService } from 'src/app/services/case-search.service';
import { LocalizationService } from 'src/app/shared/localization/localization.service';
import { IPaginatedSearch } from 'src/app/shared/models';
import { CaseOpenLocationService } from '../case-open-location.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';

@Component({
  selector: 'app-flagged-cases',
  templateUrl: './flagged-cases.component.html',
  styleUrls: ['../cases.component.scss']
})
export class FlaggedCasesComponent implements OnInit {
  static readonly FIRST_PAGE_NUMBER: number = 1;
  static readonly NUMBER_ITEMS_PER_PAGE: number = 5;

  @Input() resume?: IPaginatedSearch<ICustomCaseSearchOptions>

  totalNumberOfItems: number;
  filter = { isFlagged: true, } as ICustomCaseSearchOptions;
  dateFormat: string;
  isLoading: boolean;
  emptyRows: ICustomCaseSearchResult[];
  initialLoadingDone: boolean;

  private readonly unsubscribe$ = new Subject();
  private readonly pageNumberBS = new BehaviorSubject(FlaggedCasesComponent.FIRST_PAGE_NUMBER);
  private readonly numberOfItemsPerPageBS = new BehaviorSubject(FlaggedCasesComponent.NUMBER_ITEMS_PER_PAGE);
  readonly anyCustomerReferenceBS = new BehaviorSubject('');
  readonly numberOfItemsPerPage$ = this.numberOfItemsPerPageBS.asObservable();

  private searchCriteria$: Observable<IPaginatedSearch<ICustomCaseSearchOptions>> = combineLatest([
    this.pageNumberBS.pipe(debounceTime(200))
    , this.numberOfItemsPerPage$
    , this.appService.customerNumber$
    , this.anyCustomerReferenceBS.pipe(debounceTime(1000))
  ]).pipe(map(([pageNumber, numberOfItemsPerPage, customerNumber, anyCustomerReference]) => {
    return {
      pageSize: numberOfItemsPerPage
      , currentPage: pageNumber
      , filter: {
        customerNumber: customerNumber
        , anyCustomerReference: anyCustomerReference?.length > 0 ? anyCustomerReference : null
        , isFlagged: true
        , caseSearchColumns: ['FlaggedDate', 'DebtorName', 'CustomerReference1', 'CustomerReference2', 'Customer']
      }
    } as IPaginatedSearch<ICustomCaseSearchOptions>
  }), distinctUntilChanged((p, n) => JSON.stringify(p) == JSON.stringify(n))
  );

  flaggedCases$ = this.searchCriteria$.pipe(
    tap(() => this.isLoading = true),
    switchMap(criteria => {
      return this.casesSearchService.customCaseSearch(criteria)
        .pipe(finalize(() => { this.isLoading = false; this.initialLoadingDone = true }))
        .pipe(map(_ => {
          this.totalNumberOfItems = _.totalCount;

          this.mapCustomerReferences(_.pageItems);

          return this.fillToTotal(_.pageItems, criteria.pageSize);
        }), catchError(() => {
          this.messageToUiService.genericError();
          return of(this.fillToTotal([], criteria.pageSize));
        }));
    })
    , shareReplay(1));

    get first(): number {
      return ((this.pageNumberBS.getValue() ?? 1)  - 1) * this.numberOfItemsPerPageBS.getValue();
    }

  constructor(
    private casesSearchService: CaseSearchService,
    private appService: AppService,
    private localizationService: LocalizationService,
    private openFromTracker: CaseOpenLocationService,
    private messageToUiService: MessageToUiService) { }

  ngOnInit(): void {
    this.localizationService.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => this.dateFormat = _.datePipeFormat);

    let numberOfEmptyRows = FlaggedCasesComponent.NUMBER_ITEMS_PER_PAGE;

    if (this.resume && this.resume.filter) {
      this.resumeSearch();
      numberOfEmptyRows = this.resume.pageSize
    }
    this.emptyRows = this.fillToTotal([], numberOfEmptyRows);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onPageChange(event: LazyLoadEvent) {
    this.numberOfItemsPerPageBS.next(event.rows);
    this.pageNumberBS.next((event.first == 0) ? 1 : (event.first / event.rows + 1));
  }

  openCase(cas: ICustomCaseSearchResult, list: ICustomCaseSearchResult[]) {
    if (cas.caseNumber) {
      this.searchCriteria$.pipe(takeUntil(this.unsubscribe$)).subscribe(_ => {
        this.openFromTracker.openCase({ type: 'flagged', caseNumberIndex: list.indexOf(cas), filter: _, pageItems: list.filter(c => c.caseNumber), totalCount: this.totalNumberOfItems })
      });
    }
  }

  private fillToTotal(data: ICustomCaseSearchResult[], minRows: number): ICustomCaseSearchResult[] {
    for (let i = data.length; i < minRows; i++) {
      data.push({} as ICustomCaseSearchResult);
    }
    return data;
  }

  private resumeSearch() {
    this.filter.anyCustomerReference = this.resume.filter.anyCustomerReference;
    this.numberOfItemsPerPageBS.next(this.resume.pageSize);
    this.pageNumberBS.next(this.resume.currentPage);
    this.anyCustomerReferenceBS.next(this.resume.filter.anyCustomerReference);
  }

  private mapCustomerReferences(cases: ICustomCaseSearchResult[])
  {
    cases.forEach(cas => {
      if(cas.customerReference1 != undefined
        && cas.customerReference1.trim() != ""
        && cas.customerReference2 != undefined
        && cas.customerReference2.trim() != "") {
        cas.customerReference = cas.customerReference1 + "/" + cas.customerReference2;
      }
      else if (cas.customerReference1 != undefined && (cas.customerReference2 == undefined || cas.customerReference2.trim() == "" )) {
        cas.customerReference = cas.customerReference1;
      }
      else
      {
        cas.customerReference = cas.customerReference2;
      }
    });
  }
}
