<div class="component-container" *ngIf="approvals?.length">
  <span> {{getQuestion(approvals[0])}}</span>
  <div class="actions">
    <button class="button-red" type="button" (click)="respond(false, approvals[0])">
      {{'Case.Approvals.Decline' | translate}}
    </button>
    <button class="button-basic" type="button" (click)="respond(true, approvals[0])">
      {{'Case.Approvals.Approve' |  translate}}
    </button>
  </div>
</div>
