import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { CasesService } from 'src/app/services/cases.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';
import { LocalizationService, LocalizationSettings } from 'src/app/shared/localization/localization.service';
import { ICaseViewModel, ICreditNoteOrPayment } from 'src/app/shared/models';
import { ValidatorService } from 'src/app/shared/validator.service';

@Component({
  selector: 'app-case-add-payment',
  templateUrl: './case-add-payment.component.html',
  styleUrls: ['./case-add-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseAddPaymentComponent implements OnChanges {

  constructor (
    private fb: FormBuilder,
    private caseService: CasesService,
    private localization: LocalizationService,
    public validator: ValidatorService,
    private notification: MessageToUiService,
    private cdr: ChangeDetectorRef,
    private spinner: GlobalSpinnerService
  ) { }

  currencies: string[] = [];
  isLoading: boolean;
  form: FormGroup;

  @Input() case: ICaseViewModel;
  @Input() paymentType: 'Payment' | 'KreditNote';

  @Output() added = new EventEmitter<'Payment' | 'KreditNote'>

  locale: LocalizationSettings = {} as LocalizationSettings;

  ngOnInit() {
    this.localization.settings$.subscribe(_ => { this.locale = _; });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const caseChange = changes['case'];
    if (caseChange && caseChange.currentValue?.number != caseChange.previousValue?.number) {
      this.setUp();
    }
  }

  save(f: FormGroupDirective) {
    if (f.valid) {
      this.spinner.show();
      const model = { ...this.form.value, type: this.paymentType } as ICreditNoteOrPayment & { type: 'Payment' | 'KreditNote' };

      const save$ = this.paymentType == 'KreditNote'
      ? this.caseService.registerCreditNote(this.case.number, model)
      : this.caseService.registerPayment(this.case.number, model);

      save$
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next:() => {
          this.notification.successKey();

          f.resetForm();

          this.cdr.detectChanges();
          this.added.emit(model.type);
        },
        error:(e) => {
          this.validator.handleError(this.form, e);
          this.cdr.detectChanges()
        }});
    }
  }

  private setUp() {
    this.currencies = [this.localization.getFirmCurrencyCode()]

    if (this.case?.currencyCode && this.currencies[0] != this.case.currencyCode) this.currencies.push(this.case.currencyCode);

    this.form = this.fb.group({
      date: new FormControl(null, Validators.required),
      amount: new FormControl(null),
      currencyCode: new FormControl(this.currencies[0], Validators.required),
      reference: new FormControl(null, [Validators.required])
    });
  }
}
