<div class="dashboard-container">
  <section *ngIf="dashboardAlert$ | async as dashboardAlert" class="alert alert-warning" role="alert">
    <i class="fas fa-exclamation-triangle"></i>
    <span [innerHTML]="dashboardAlert.newsText"></span>
  </section>

  <div class="left-container">
    <section class="notification-container">
      <div class="notification-container-item">
        <app-notification labelTranslationTag="Dashboard.MyTasks"
          [value]="(dashboardNotifications$ | async)?.tasksCount" [routerLink]="['/cases/lists']">
        </app-notification>
      </div>
      <div class="notification-container-item">
        <app-notification labelTranslationTag="Dashboard.MyUnreadDocuments"
          [value]="(dashboardNotifications$ | async)?.untreatedDocumentsCount" [routerLink]="['/documents']"
          [queryParams]="untreatedDocumentsQueryParams">
        </app-notification>
      </div>
      <div class="notification-container-item">
        <app-notification labelTranslationTag="Dashboard.MyFlaggedCases"
          [value]="(dashboardNotifications$ | async)?.flaggedCasesCount" [routerLink]="['/cases/lists']">
        </app-notification>
      </div>
      <div class="notification-container-item">
        <app-notification labelTranslationTag="Dashboard.NewCaseInformation"
          [value]="(dashboardNotifications$ | async)?.casesWithNewInfoCount" [routerLink]="['/cases/lists']">
        </app-notification>
      </div>
      <div class="notification-container-item" *ngIf="userHasAccessToDebtCollectionList">
        <app-notification labelTranslationTag="Dashboard.ToDebtCollection"
          [value]="(dashboardNotifications$ | async)?.casesToDebtCollectionCount"
          [routerLink]="['/cases/todebtcollection']">
        </app-notification>
      </div>
    </section>

    <section class="graphs-container">
      <div class="graphs-container-item">
        <app-graph labelTranslationTag="Dashboard.ActiveCases" [name]="'CaseVolume'">
        </app-graph>
      </div>
      <div class="graphs-container-item">
        <app-graph labelTranslationTag="Dashboard.ActiveCases" [name]="'CapitalVolume'">
        </app-graph>
      </div>
      <div class="graphs-container-item">
        <app-success-rate-percentages></app-success-rate-percentages>
      </div>
    </section>

    <section class="statistics-container">
      <div class="statistics-container-item">
        <app-count-statistic labelTranslationTag="Dashboard.UnsolvedCapital"
          [value]="(statistics$ | async)?.unsolvedCapital" [currency]="currencySymbol"
          [currencyPipeFormat]="currencyPipeFormat">
        </app-count-statistic>
      </div>
      <div class="statistics-container-item">
        <app-count-statistic labelTranslationTag="Dashboard.SolvedCapital"
          [value]="(statistics$ | async)?.solvedCapitalLast12Months" subLabelTranslationTag="Dashboard.Last12Months"
          [currency]="currencySymbol" [currencyPipeFormat]="currencyPipeFormat">
        </app-count-statistic>
      </div>
      <div class="statistics-container-item">
        <app-count-statistic labelTranslationTag="Dashboard.ActiveCases"
          [value]="(statistics$ | async)?.numberOfUnsolvedCases" [integerPipeFormat]="integerPipeFormat">
        </app-count-statistic>
      </div>
      <div class="statistics-container-item">
        <app-count-statistic labelTranslationTag="Dashboard.SolvedCases"
          [value]="(statistics$ | async)?.numberOfSolvedCasesLast12Months"
          subLabelTranslationTag="Dashboard.Last12Months" [integerPipeFormat]="integerPipeFormat">
        </app-count-statistic>
      </div>
      <div class="statistics-container-item">
        <app-count-statistic labelTranslationTag="Dashboard.InstallmentAgreements"
          [value]="(statistics$ | async)?.numberOfAgreementInstallments" [integerPipeFormat]="integerPipeFormat">
        </app-count-statistic>
      </div>
      <div class="statistics-container-item">
        <app-count-statistic labelTranslationTag="Dashboard.ExpectedCashflowFromAgreements"
          [value]="(statistics$ | async)?.expectedCashFlowFromAgreementsNextMonth"
          subLabelTranslationTag="Dashboard.NextMonth" [currency]="currencySymbol"
          [currencyPipeFormat]="currencyPipeFormat">
        </app-count-statistic>
      </div>
      <div class="statistics-container-item" *ngIf="userHasAccessToDebtCollectionList">
        <app-count-statistic labelTranslationTag="Dashboard.ToDebtCollectionCasesRestCapital"
          [value]="(statistics$ | async)?.toDebtCollectionCasesRestCapital"
          [subLabel]="toDebtCollectionCasesRestCapitalSubLabel" [currency]="currencySymbol"
          [currencyPipeFormat]="currencyPipeFormat">
        </app-count-statistic>
      </div>
    </section>
  </div>

  <div class="right-container">
    <section class="news-container">
      <img src="./assets/newsImage.jpg" alt="News image">
      <h2 class="title">{{'Dashboard.News' | translate}}</h2>
      <hr class="separation-line" />
      <div class="news-items">
        <p *ngFor="let news of news$ | async">
          <span [innerHTML]="news.newsText"></span>
        </p>
      </div>
    </section>

    <section class="contact-container">
      <h2 class="title">{{'Dashboard.MyContactPerson' | translate}}</h2>
      <hr class="separation-line" />
      <div class="img-container"><img [src]="'data:image/png;base64,'+ (contactPerson$ | async)?.image"
          onerror="this.src='./assets/accountManagers/Firstname Lastname.png'" alt="User icon"></div>
      <p class="name">{{ (contactPerson$ | async)?.name }}</p>
      <p class="job-title">{{ (contactPerson$ | async)?.title }}</p>
      <div class="contact-info">
        <p *ngIf="(contactPerson$ | async)?.phone">
          <span class="contact-method">{{'Dashboard.Phone' | translate}}:</span>
          {{(contactPerson$ | async)?.phone }}
        </p>
        <p *ngIf="(contactPerson$ | async)?.email">
          <span class="contact-method">{{'Dashboard.Email' | translate}}:</span>
          {{ (contactPerson$ | async)?.email }}
        </p>
      </div>
    </section>
  </div>
</div>