<div *ngIf="customers?.length > 1;else singleCustomer" class="select" (click)="showDropdown = !showDropdown; $event.stopPropagation()">
  <div *ngIf="customerNumber;else unselected" class="selected-customer">
    <div class="customer-number">{{ customerNumber}}</div>
    <div class="customer-name">{{ customerName }}</div>
  </div>
  <ng-template #unselected>
    <div class="selected-customer">
      {{'General.Customer' | translate}}
      <span>&#x25BC;</span>
    </div>
  </ng-template>

  <div class="select-content" *ngIf="showDropdown">
    <div *ngIf="canFilter" class="select-content-item">
      <input type="text" class="text-input" (click)="$event.stopPropagation()"
      (input)="onSearchInputChanged($event.target.value)" #searchInput>
    </div>
    <div class="select-content-item" (click)="onSelectedChange()">{{'General.All' | translate}}</div>
    <div class="select-content-item" *ngFor="let customer of filteredCustomers" (click)="onSelectedChange(customer)">
      {{customer.number}} - {{customer.name}}
    </div>
  </div>
</div>

<ng-template #singleCustomer>
  <div *ngIf="customerNumber" class="single-customer">
    <div class="customer-number">{{ customerNumber}}</div>
    <div class="customer-name">{{ customerName }}</div>
  </div>
</ng-template>
