import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';

export interface IGroup<Tkey, T> {
  key: Tkey;
  values: T[];
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private router: Router) { }

  toFormData<T>(object: T, file: File | File[] = null, attachmentName: string) {
    const formData = new FormData();
    for (const key of Object.keys(object)) {
      const value = object[key];
      formData.append(key, value);
    }

    if (file) {
      if (Array.isArray(file))
        file.forEach((f) => formData.append(`${attachmentName}`, f));
      else
        formData.append(attachmentName, file);
    }

    return formData;
  }

  toFileFormData(file: File | File[], attachmentName: string) {
    const formData = new FormData();
    if (Array.isArray(file)) file.forEach((f) => formData.append(`${attachmentName}`, f));
    else formData.append(attachmentName, file);
    return formData;
  }

  previewFile(file: Blob) {
    const fileURL = window.URL.createObjectURL(file);
    let tab = window.open();
    tab.location.href = fileURL;
  }

  downloadFile(file: Blob, fileName: string) {
    FileSaver.saveAs(file, fileName);
  }

  public markFormGroupUntouchedPristine(formGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();

      if (control.controls) {
        this.markFormGroupUntouchedPristine(control);
      }
    });
  }

  groupBy<Tkey, T>(list: T[], keyGetter: (input: T) => Tkey): IGroup<Tkey, T>[] {
    const result: IGroup<Tkey, T>[] = [];
    this.getMapGroupBy(list, keyGetter).forEach((v: T[], k: Tkey) => { result.push({ key: k, values: v }); });
    return result;
  }

  getMapGroupBy<Tkey, T>(list: T[], keyGetter: (input: T) => Tkey): Map<Tkey, T[]> {
    const resultMap = new Map<Tkey, T[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = resultMap.get(key);
      if (!collection) {
        resultMap.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return resultMap;
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  toDefaultKeyValue(list: any[]): KeyValue<any, string>[] {
    return list.map(x => { return { key: x, value: x } });
  }

  getUrlFromObject<T>(url: string, object: T = null, prefix: string = ''): string {
    if (object == null) return url;

    Object.keys(object).forEach(key => {
      if (object[key] != null && object[key].toString().length > 0) {
        if (Object.prototype.toString.call(object[key]) === '[object Array]') {
          object[key].forEach(x => url += (url.indexOf('?') > -1 ? '&' : '?') + `${prefix}${key}=${x}`);
        }
        else if (object[key] instanceof Date) {
          const value = this.getDateISOstring(new Date(object[key]));
          url += (url.indexOf('?') > -1 ? '&' : '?') + `${prefix}${key}=${value}`;
        }
        else if (typeof object[key] === 'object') {
          url = this.getUrlFromObject(url, object[key], `${key}.`);
        }
        else {
          const value = String(object[key]);
          url += (url.indexOf('?') > -1 ? '&' : '?') + `${prefix}${key}=${value}`;
        }
      }
    });
    return url;
  }

  getDateISOstring(d: Date): string {
    return (new Date(d.getTime() - (d.getTimezoneOffset() * 60000))).toISOString();
  }

  filterArray<T>(models: T[], filterBy: string, excludeColumns: string[]): T[] {
    if (!filterBy) {
      return models;
    }

    return models.filter(o => Object.keys(o).some(k =>
      !excludeColumns.some(c => c.toLocaleLowerCase() === k.toLocaleLowerCase())
      && o[k].toString().toLocaleLowerCase().includes(filterBy.toLocaleLowerCase())));
  }

  readCssStyleProperty(name: string): string {
    let bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue(name);
  }

  enumToKeyValuePair(enumeration, translationTagPrefix: string = null): KeyValue<string, any>[] {
    let keyValues = [];
    for (const enumMember in enumeration) {
      if (typeof enumeration[enumMember] === 'string') {
        keyValues.push({
          key: enumMember,
          value: translationTagPrefix ? `${translationTagPrefix}.${enumeration[enumMember]}` : enumeration[enumMember]
        });
      }
    }
    return keyValues;
  }

  navigateTo(resourceName: string, resourceValue: any, newTab?: boolean) {
    let resourcePath: string = "/";

    switch (resourceName) {
      case "case":
        {
          resourcePath = `/cases/${resourceValue}`
          break;
        }
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([resourcePath])
    );

    window.open(url, newTab ? '_blank' : null);
  }

  tryEncodeAsUTF8(blob: Blob): Blob {
    const shouldChangeEncoding = blob.type == 'text/html';
    if (shouldChangeEncoding) blob = new Blob(['\ufeff', blob]);
    return blob;
  }
}


String.prototype.isTruthy = function (this: string): boolean {
  const value = this.toLocaleLowerCase();
  if (value == null) return false;

  return value.startsWith("t") ||
    value.startsWith("1") ||
    value.startsWith("y") ||
    value.startsWith("j");
}

String.prototype.capitalize = function (this: string): string {
  return this.charAt(0).toLocaleUpperCase() + this.slice(1);
}

String.prototype.decapitalize = function (this: string): string {
  return this.charAt(0).toLowerCase() + this.slice(1);
}

String.prototype.equalsIgnoringCase = function (this: string, otherString): boolean {
  return this.localeCompare(otherString, undefined, { sensitivity: 'base' }) === 0;
}

Array.prototype.firstOrDefault = function <T>(this: Array<T>, defaultValue: T = null): T | null {
  return this.length ? this[0] : defaultValue;
}

Array.prototype.lastOrDefault = function <T>(this: Array<T>, defaultValue: T = null): T | null {
  return this.length ? this[this.length - 1] : defaultValue;
}

Array.prototype.move = function<T>(this: Array<T>, from: number, to: number): Array<T> {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

Array.prototype.mapOrder = function<T>(this: Array<T>, order: Array<T>): Array<T> {
  this.sort( function (a, b) {
    if (order.indexOf(a) > order.indexOf(b)) {
      return 1;
    } else {
      return -1;
    }
  });
  
  return this;
} 

