import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { takeUntil, finalize, map, catchError, debounceTime, distinctUntilChanged, tap, switchMap, shareReplay } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { CustomersService } from 'src/app/services/customers.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { LocalizationService } from 'src/app/shared/localization/localization.service';
import { ICaseNoteCategory, ICaseNoteSummary, ICaseNotesFilter, ICaseNotesSearchResults, IPaginatedSearch } from 'src/app/shared/models';
import { CaseOpenLocationService } from '../case-open-location.service';

@Component({
  selector: 'app-categorized-notes-list',
  templateUrl: './categorized-notes-list.component.html',
  styleUrls: ['../cases.component.scss']
})
export class CategorizedNotesListComponent implements OnInit, OnDestroy {
  static readonly FIRST_PAGE_NUMBER: number = 1;

  @Input()
  listCategory: NoteListCategory;
  @Input()
  numberOfItemsPerPage: number;
  @Input()
  largeHeading: boolean = false;

  @Input()
  resume: any;

  totalNumberOfItems: number;
  dateFormat: string;
  isLoading: boolean = true;
  emptyRows: ICaseNoteSummary[];

  noteCategories: KeyValue<string, string>[];
  serviceCall: Observable<ICaseNotesSearchResults>;
  initialLoadingDone: boolean;

  readonly anyCustomerReferenceBS = new BehaviorSubject('');
  categoryCodesBS = new BehaviorSubject([]);
  readonly pageNumberBS = new BehaviorSubject(CategorizedNotesListComponent.FIRST_PAGE_NUMBER);

  private readonly unsubscribe$ = new Subject();
  private readonly numberOfItemsPerPageBS = new Subject<number>();
  private currentSeachCriteria: IPaginatedSearch<ICaseNotesFilter>;

  private searchCriteria$: Observable<IPaginatedSearch<ICaseNotesFilter>> = combineLatest([
    this.pageNumberBS.pipe(debounceTime(200))
    , this.numberOfItemsPerPageBS
    , this.appService.customerNumber$
    , this.anyCustomerReferenceBS.pipe(debounceTime(1000))
    , this.categoryCodesBS
  ]).pipe(map(([pageNumber, numberOfItemsPerPage, customerNumber, anyCustomerReference, categoryCodes]) => {
    return { pageSize: numberOfItemsPerPage, currentPage: pageNumber, filter: { customerNumber: customerNumber,
      anyCustomerReference: anyCustomerReference, categoryCodes: categoryCodes } } as IPaginatedSearch<ICaseNotesFilter>
  }), distinctUntilChanged((p, n) => JSON.stringify(p) == JSON.stringify(n)));

  notesSearchResult$: Observable<ICaseNoteSummary[]> = this.searchCriteria$.pipe(
    tap(() => this.isLoading = true),
    switchMap(criteria => {
      const serviceCall = this.determineServiceCall(criteria);

      return serviceCall
        .pipe(finalize(() => { this.isLoading = false; this.initialLoadingDone = true; }))
        .pipe(map(_ => {
          this.totalNumberOfItems = _.totalNumberOfItems;
          const categorySpecified = criteria.filter.categoryCodes?.length > 0;
          this.mapNoteCategoriesToSelectList(_.categories, categorySpecified);
          this.mapCaseReferences(_.pageItems);
          this.currentSeachCriteria = criteria;
          return this.fillToTotal(_.pageItems, criteria.pageSize);
        })
          , catchError(() => {
            this.messageToUiService.genericError();
            return of(this.fillToTotal([], criteria.pageSize));
          }))
    })
    , shareReplay(1));

  get isEnglish() {
    return this.translateService.getDefaultLang().toLocaleLowerCase() == 'en';
  };

  get first(): number {
    return ((this.pageNumberBS.getValue() ?? 1) - 1) * this.numberOfItemsPerPage;
  }

  constructor(
    private customersService: CustomersService,
    private appService: AppService,
    private localizationService: LocalizationService,
    private translateService: TranslateService,
    private messageToUiService: MessageToUiService,
    private openFromTracker: CaseOpenLocationService) { }

  ngOnInit(): void {
    this.localizationService.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => this.dateFormat = _.datePipeFormat);

    if (this.resume) {
      if (this.resume.options) {
        this.numberOfItemsPerPage = this.resume.options.pageSize;
        this.anyCustomerReferenceBS.next(this.resume.options.filter?.anyCustomerReference);
        this.categoryCodesBS.next(this.resume.options.filter?.categoryCodes)
        this.pageNumberBS.next(this.resume.options.currentPage);
      }
      if (this.resume.caseNoteCategories) {
        this.noteCategories = this.resume.caseNoteCategories;
      }
    }

    this.emptyRows = this.fillToTotal([], this.numberOfItemsPerPage);
    this.numberOfItemsPerPageBS.next(this.numberOfItemsPerPage);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onPageChange(event: LazyLoadEvent) {
    this.numberOfItemsPerPageBS.next(event.rows);
    this.pageNumberBS.next((event.first == 0) ? 1 : (event.first / event.rows + 1));
  }

  openCase(note: ICaseNoteSummary, list: ICaseNoteSummary[]) {
    if (this.listCategory && note.caseNumber) {
      this.openFromTracker.openCase({
        type: this.listCategory
        , caseNumberIndex: list.indexOf(note)
        , filter: this.currentSeachCriteria
        , pageItems: list.filter(c => c.caseNumber)
        , totalCount: this.totalNumberOfItems
        , caseNoteCategories: this.noteCategories
      })
    }
  }

  private determineServiceCall(paginatedSearch: IPaginatedSearch<ICaseNotesFilter>): Observable<ICaseNotesSearchResults> {
    switch (this.listCategory) {
      case 'newCaseInformation':
        return this.customersService.getNewCaseInformation(paginatedSearch);
      case 'myTasks':
        return this.customersService.getTasks(paginatedSearch);
    }
  }

  private fillToTotal(data: ICaseNoteSummary[], minRows: number): ICaseNoteSummary[] {
    for (let i = data.length; i < minRows; i++) {
      data.push({} as ICaseNoteSummary);
    }
    return data;
  }

  private mapNoteCategoriesToSelectList(categories: ICaseNoteCategory[], categorySpecified: boolean) {
    if (categorySpecified) return;
    if (this.isEnglish) this.noteCategories = categories.map(x => {
      return { key: x.categoryCode, value: x.categoryNameEn } as KeyValue<string, string>;
    });
    else this.noteCategories = categories.map(x => {
      return { key: x.categoryCode, value: x.categoryName } as KeyValue<string, string>;
    })
  }

  private mapCaseReferences(notes: ICaseNoteSummary[])
  {
    notes.forEach(note => {
      if(note.customerReference1 != undefined
        && note.customerReference1.trim() != ""
        && note.customerReference2 != undefined
        && note.customerReference2.trim() != "") {
        note.customerReference = note.customerReference1 + "/" + note.customerReference2;
      }
      else if (note.customerReference1 != undefined && (note.customerReference2 == undefined || note.customerReference2.trim() == "" )) {
        note.customerReference = note.customerReference1;
      }
      else
      {
        note.customerReference = note.customerReference2;
      }
    });
  }
}

export type NoteListCategory = 'myTasks' | 'newCaseInformation';
