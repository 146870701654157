<div class="component-container">
  <form #form="ngForm">
    <div *ngIf="addCaseNote$ | async">
      <textarea class="text-input" #text="ngModel" ngModel id="text" name="text" rows="5" required maxlength="2000"
        placeholder="{{'Case.NewNote.Placeholder' | translate}}">
    </textarea>
      <app-control-errors *ngIf="form.submitted" [control]="text"></app-control-errors>
      <section class="top">
        <label *ngIf="canAddMessageOnSameReference$ | async" class="custom-checkbox-container">
          <input type="checkbox" class="column-checkbox" ngModel name="sendMessageOnAllReferences"
            id="sendMessageOnAllReferences">
          <span class="checkmark"></span>
          <label for="sendMessageOnAllReferences">{{'Notes.AddMessageOnSameReference' | translate}}</label>
        </label>
        <div class="attachments">
          <div *ngFor="let attachment of attachments">
            <span> {{attachment.name}} </span>
            <i class="fas fa-times" (click)="onRemoveAttachment(attachment.name)"></i>
          </div>
        </div>
      </section>
    </div>
    <section class="bottom">
      <ng-container *ngIf="addCaseNote$ | async">
        <span class="quick-reply-text">{{'Case.NewNote.QuickReply' | translate}}:</span>
        <div class="quick-replies-container">
          <button *ngFor="let reply of quickReplies$ | async" type="button" class="quick-reply-button"
            (click)="quickReply(form, reply)">{{reply | translate}}</button>
        </div>
      </ng-container>
      <div class="actions-container">
        <div *ngIf="addCaseNote$ | async">
          <div>
            <app-file-upload [multiFile]="true" name="files" (fileToUploadChange)="attachments = $event"
              [displayFilesList]="false"></app-file-upload>
          </div>
          <button type="submit" class="button-basic" (click)="submit(form)" [disabled]="form.status != 'VALID'">
            {{'Save' | translate}} </button>
        </div>
        <button type="button" class="button-blue" (click)="openPaymentLinkDialog()"
          *ngIf="!terminationDate && (displayPaymentLinkOption$ | async)">{{'Notes.OpenPaymentLink' |
          translate}}</button>
      </div>
    </section>
  </form>
  <div class="list-container">
    <app-note-list *ngIf="list?.length" [caseNumber]="caseNumber" [list]="list" (downloadAttachments)="onDownloadAttachments($event)"
    (downloadAttachment)="onDownloadAttachment($event)"></app-note-list>
  </div>
</div>