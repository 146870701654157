import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISuccessRateSummary } from 'src/app/reports/reports-models';
import { ReportsService } from 'src/app/reports/reports.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-success-rate-percentages',
  templateUrl: './success-rate-percentages.component.html',
  styleUrls: ['./success-rate-percentages.component.scss']
})
export class SuccessRatePercentagesComponent implements OnInit, OnDestroy {
  lastYearColor: any;
  color90days: any;
  color60days: any;

  lastYearTitle: string;
  title90days: string;
  title60days: string;

  selectedCustomer?: number;

  data: ISuccessRateSummary;
  private readonly unsubscribe$ = new Subject();

  constructor(
    private appService: AppService, private reportsService: ReportsService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.lastYearColor = this.readStyleProperty('--clr-svea-2');
    this.color90days = this.readStyleProperty('--clr-svea-1');
    this.color60days = this.readStyleProperty('--clr-svea-5');

    this.lastYearTitle = this.translate.instant('Graphs.SuccessRate.LastYear');
    this.title90days = this.translate.instant('Graphs.SuccessRate.Last90Days');
    this.title60days = this.translate.instant('Graphs.SuccessRate.Last60Days');

    this.appService.customer$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.data = null;
        this.selectedCustomer = result?.number;
        this.getData();
     });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getData() {
    this.reportsService.getSuccessRateSummary(this.selectedCustomer).pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        { next: result =>  this.data = result });
  }

  protected readStyleProperty(name: string): string {
    let bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue(name);
  }

}
