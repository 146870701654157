import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';

@Component({
  selector: 'app-count-statistic',
  templateUrl: './count-statistic.component.html',
  styleUrls: ['./count-statistic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountStatisticComponent implements OnInit {

  constructor() { }

  @Input()
  labelTranslationTag: string;
  @Input()
  subLabelTranslationTag: string;
  @Input()
  subLabel: string;

  @Input() currency: string;
  @Input() currencyPipeFormat: LocalizedNumberFormat;
  
  @Input() integerPipeFormat: LocalizedNumberFormat;

  @Input()
  value: number;

  ngOnInit(): void {
  }
}
