import { Component, Input } from '@angular/core';
import { LocalizationService, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { ICaseViewModel, RequirementsTotalCalculator } from 'src/app/shared/models';

@Component({
  selector: 'app-case-requirements',
  templateUrl: './case-requirements.component.html',
  styleUrls: ['./case-requirements.component.scss'],
})
export class CaseRequirementsComponent {

  constructor (public localizationService: LocalizationService) { }

  @Input() case: ICaseViewModel;
  @Input() hasInternationalCurrency: boolean;
  @Input() firmCurrencyCode: string;
  @Input() numberFormat: LocalizedNumberFormat;

  get totals(): RequirementsTotalCalculator & { remitted: number } {
    if (!this.case?.requirements) return undefined;

    const car = this.case.requirements;

    return Object.assign(new RequirementsTotalCalculator(car)
      , { remitted: car.remittedCapital + car.remittedInterest + car.remittedPublicFee + car.remittedPublicFee + this.SveasFees.remitted }
    );
  }


  get SveasFees(): { original: number, paid: number, rest: number, restValuta: number, remitted: number } {

    if (!this.case?.requirements) return undefined;

    const car = this.case.requirements;

    return {
      original: car.originalChargeInterest
        + car.originalFee
        + car.originalAdditionalFee
        + car.originalNewCaseFee
        + car.originalCustomerReminderFee,
      paid: car.paidChargeInterest
        + car.paidFee
        + car.paidAdditionalFee
        + car.paidEuFee
        + car.paidNewCaseFee
        + car.paidCustomerReminderFee,
      rest: car.restChargeInterest
        + car.restFee
        + car.restAdditionalFee
        + car.restNewCaseFee
        + car.restCustomerReminderFee,
      restValuta: car.restValutaChargeInterest
        + car.restValutaFee
        + car.restValutaAdditionalFee
        + car.restValutaNewCaseFee
        + car.restValutaCustomerReminderFee,
      remitted:
        car.remittedFee
        + car.remittedAdditionalFee
        + car.remittedNewCaseFee
        + car.remittedCustomerReminderFee,
    }
  }
}
