import { IAppGraphModel } from './graph.component';
import { ICustomerMonthlyCaseStatistics } from 'src/app/reports/reports-models';
import { GraphBase } from './GraphBase';
import { ReportsService } from 'src/app/reports/reports.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class CaseVolumeGraph extends GraphBase {

  constructor (private reportsService: ReportsService, private translate: TranslateService) {
    super();
  }

  get(customerNumber?: number): Observable<IAppGraphModel> {
    return this.reportsService.getMonthlyCasesData(customerNumber)
      .pipe(map(_ => this.withData(_)));
  }

  withData(stats: ICustomerMonthlyCaseStatistics[]): IAppGraphModel {

    let color1 = this.readStyleProperty('--clr-svea-1');
    let color2 = this.readStyleProperty('--clr-svea-2');

    let graph = { type: 'line' } as IAppGraphModel;
    graph.data = {
      labels: stats.map(x => this.getShortMonthName(x.month).toLowerCase()),
      datasets: [{
        label: this.translate.instant('Graphs.CaseVolume.NewCases'),
        backgroundColor: color1,
        borderColor: color1,
        borderWidth: 1,
        data: stats.map(x => x.numberOfRegisteredCases),
      },
      {
        label: this.translate.instant('Graphs.CaseVolume.ClosedCases'),
        backgroundColor: color2,
        borderColor: color2,
        borderWidth: 1,
        data: stats.map(x => x.numberOfTerminatedCases),
      }
      ]
    };

    graph.options = {
      plugins: {
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            boxHeight: 2,
          }
        }
      },
      scales: {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
            color: 'green',
          },
          ticks: {
          },
          offset: true
        },
        y: {
          min: 0,
          padding: 50,
          border: {
            display: true,
            width: 2,
            color: color2
          },
          grid: {
            display: true,
            drawOnChartArea: false,
            drawTicks: true,
            tickLength: 5,
            color: color2
          },
          ticks: {
            color: 'black',
          }
        }
      }
    };


    return graph;
  }

}
