<div class="page-container">
    <section class="header">
        <section class="title-container">
            <h3 class="title">
                {{ "Contracts.CaseOverview.Title" | translate }}
            </h3>
            <hr class="separation-line" />
        </section>

        <span class="contracts-selected">{{"Contracts.CaseOverview.Contract" | translate}} {{contractReference}}</span>
    </section>

    <section class="details">

        <p-table [value]="data$ | async" class="customTable" styleClass="p-datatable-sm" [scrollable]="false"
            [loading]="isLoading">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ "Contracts.CaseOverview.CaseNumber" | translate }}</th>
                    <th>{{ "Contracts.CaseOverview.Status" | translate }}</th>
                    <th class="text-right">{{ "Contracts.CaseOverview.Claim" | translate }}</th>
                    <th class="text-right">{{ "Contracts.CaseOverview.RestClaim" | translate }}</th>
                    <th class="text-right">{{ "Contracts.CaseOverview.Total" | translate }}</th>
                    <th class="text-right">{{ "Contracts.CaseOverview.RestTotal" | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-case>
                <tr *ngIf="format$ | async as format" (click)="selectCase(case)" [class]="getCaseClass(case)">
                    <td>{{case.caseNumber}} </td>
                    <td>{{case.statusCode}} {{case.statusText}}
                        <span *ngIf="case.isWarningStatus == true">
                            <i class="fa fa-exclamation-triangle"></i>
                        </span>
                    </td>
                    <td class="text-right">{{case.originalCapital | number : format.numberPipeFormat.style:
                        format.numberPipeFormat.culture}} </td>
                    <td class="text-right">{{case.restCapital | number : format.numberPipeFormat.style:
                        format.numberPipeFormat.culture}}
                    </td>
                    <td class="text-right">{{case.originalTotal | number : format.numberPipeFormat.style:
                        format.numberPipeFormat.culture}}
                    </td>
                    <td class="text-right">{{case.restTotal | number : format.numberPipeFormat.style:
                        format.numberPipeFormat.culture}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </section>

    <section class="footer">
      <button type="button" class="button-blue paymentLink" (click)="openPaymentLinkDialog()"
      *ngIf="(displayPaymentLinkOption$ | async)">{{'Notes.OpenPaymentLink' |
      translate}}</button>
        <button class="btn btn-red" (click)="close()">{{'General.Cancel' | translate}}</button>
    </section>
</div>
