<div class="label">
  <label>{{ labelTranslationTag | translate }}</label>
  <label class="label-sub" *ngIf="subLabelTranslationTag; else subLabelTemplate">({{ subLabelTranslationTag | translate }})</label>
  <ng-template #subLabelTemplate>
    <label class="label-sub" *ngIf="subLabel">({{ subLabel }})</label>
  </ng-template>
</div>

<div class="value">
  <ng-container *ngIf="currency; else number">
    {{ value | currency: currency : 'code' : currencyPipeFormat.style : currencyPipeFormat.culture }}
  </ng-container>
  <ng-template #number>
    {{ value | number : integerPipeFormat.style : integerPipeFormat.culture }}
  </ng-template>
</div>
