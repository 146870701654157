import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CasesService } from 'src/app/services/cases.service';
import { RxHook, RxState } from 'src/app/shared/RxHook';
import { ICaseFlagStatus, ICaseMessage, ICaseSecuritySummary, ICaseViewModel, IDebtorStats, IHistoryData, IInvoice, ICasePaymentSummary, IUploadDocumentRestrictions, WebUserRight, ICaseViewModelExtended } from 'src/app/shared/models';
import { Observable, combineLatest, of } from 'rxjs';
import { shareReplay, mergeMap, map, tap } from 'rxjs/operators';
import { DebtorsService } from 'src/app/services/debtors.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { MessagesService } from 'src/app/messages/messages.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WithdrawCaseComponent } from './withdraw-case.component';
import { CaseOpenLocationService } from '../case-open-location.service';
import { LocalizationService, LocalizationSettings, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { SettingsService } from 'src/app/services/settings.service';
import { AppService } from 'src/app/services/app.service';
import { FirmService } from 'src/app/shared/firm/firm.service';
import { PauseCaseComponent } from './pause-case/pause-case.component';
import { KeyValue } from '@angular/common';
import { UtilsService } from 'src/app/shared/utils.service';

export type CaseLoadType = undefined | 'notes' | 'invoices' | 'flag';
@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxHook]
})
export class CaseComponent implements OnChanges, OnInit {

  constructor(private service: CasesService, private rxHook: RxHook, private debtorsService: DebtorsService, private notifications: MessageToUiService
    , private messagesService: MessagesService, private matDialog: MatDialog, private appService: AppService, private firmService: FirmService
    , public openFromTracker: CaseOpenLocationService, private localizationService: LocalizationService, private settingsService: SettingsService
    , public dialog: MatDialog, public utilsService: UtilsService) {
  }

  ngOnInit(): void {
  }


  @Input() caseNumber: number;

  displayWithdrawOption$ = this.appService.userHasRight(WebUserRight.WithdrawCase);
  displayPaymentOption$ = this.appService.userHasRight(WebUserRight.RegisterPayment);
  displayScore$ = this.appService.userHasRight(WebUserRight.ViewScore);
  approveLegalRequest$ = this.appService.userHasRight(WebUserRight.ApproveLegalRequest);

  notes: RxState<ICaseMessage[]>;
  debtorStats: RxState<IDebtorStats>;
  caseEvents: RxState<IHistoryData[]>;
  invoices: RxState<IInvoice[]>;
  payments: RxState<ICasePaymentSummary[]>;
  securities: RxState<ICaseSecuritySummary[]>;
  caseFlag: RxState<ICaseFlagStatus>;
  approvals: RxState<ICaseMessage[]>;
  case: RxState<ICaseViewModel>;
  localizationSettings: RxState<LocalizationSettings>;
  language: RxState<string>;
  uploadDocumentRestrictions: RxState<IUploadDocumentRestrictions>;
  remittanceSelected: boolean;
  kreditNoteSelected: boolean;
  caseTransactionsTab: CaseTransactionsTab = 'Invoices';
  hasInternationalCurrency: boolean;
  firmCurrencyCode = this.firmService.currency;

  canPauseCase: RxState<boolean>;
  caseEvents$: Observable<IHistoryData[]>;

  @ViewChild("smsModal") smsModal: TemplateRef<any>;

  ngOnChanges(changes: SimpleChanges): void {
    const caseChange = changes['caseNumber'];

    if (caseChange.currentValue != caseChange.previousValue) {
      this.load();
      if (this.caseNumber != null) {
        this.service.addToLastAccessed(this.caseNumber).subscribe();

        this.canPauseCase = this.rxHook.use(combineLatest([this.caseEvents$
          , this.appService.userDaysToDebtCollection()
          , this.appService.userHasRight(WebUserRight.ToDebtCollectionList)]).pipe(map(([caseActions, userDaysToDebtCollection, hasRight]) => {
            if (hasRight == false) return false;

            let millisecondsToDebtCollection = userDaysToDebtCollection * 24 * 60 * 60 * 1000;

            return caseActions.some(caseAction => this.qualifiesCaseForDebtCollection(caseAction, millisecondsToDebtCollection))
          })));
      }
    }
  }


  private qualifiesCaseForDebtCollection(action: IHistoryData, millisecondsToDebtCollection: number) {
    if (action?.code?.toLocaleLowerCase() !== "inkove")
      return false

    if (action.isExecuted == true)
      return false;

    let actionDate = new Date(action.date);
    let now = new Date();

    if (actionDate.getTime() - now.getTime() > millisecondsToDebtCollection)
      return false;

    return true;

  }

  toggleCaseFlag(caseNumber: number) {
    this.service.toggleCaseFlagStatus(caseNumber)
      .subscribe(() => this.load('flag'));
  }

  private load(area: CaseLoadType = undefined) {
    let case$ = (this.caseNumber ? this.service.getByNumber(this.caseNumber) : of(null as ICaseViewModelExtended))
      .pipe(shareReplay(1),
        tap(_ => this.hasInternationalCurrency = _?.currencyCode != this.firmService.currency));

    this.caseEvents$ = case$.pipe(mergeMap(_ => this.service.getActions(_.number)), shareReplay(1));

    let debtorStats$ = case$.pipe(mergeMap(_ => this.debtorsService.getStatistics(_.debtor.id, true)));
    let caseInvoices$ = case$.pipe(mergeMap(_ => this.service.getInvoices(_.number)));
    let casePayments$ = case$.pipe(mergeMap(_ => this.service.getPayments(_.number)));
    let caseSecurities$ = case$.pipe(mergeMap(_ => this.service.getSecurities(_.number, true)));
    let caseNotes$ = case$.pipe(mergeMap(_ => this.messagesService.getCaseMessages(_.number)), shareReplay(1));
    let caseFlag$ = case$.pipe(mergeMap(_ => this.service.getCaseFlagStatus(_.number)));
    let approvals$ = caseNotes$.pipe(map(_ => _.filter(note => note.requiresPermission && !note.isTreated)));
    let localizationSettings$ = this.localizationService.settings$;
    let language$ = this.localizationService.settings$.pipe(map(_ => _.language.toLocaleLowerCase()));
    let uploadDocumentRestrictions$ = this.settingsService.getFileUploadRestrictions();

    switch (area) {
      case 'notes': this.notes = this.rxHook.use(caseNotes$); break;
      case 'invoices': this.invoices = this.rxHook.use(caseInvoices$); break;
      case 'flag': this.caseFlag = this.rxHook.use(caseFlag$); break;
      default:
        {
          this.case = this.rxHook.use(case$);
          this.notes = this.rxHook.use(caseNotes$);
          this.debtorStats = this.rxHook.use(debtorStats$);
          this.caseEvents = this.rxHook.use(this.caseEvents$);
          this.invoices = this.rxHook.use(caseInvoices$);
          this.payments = this.rxHook.use(casePayments$);
          this.securities = this.rxHook.use(caseSecurities$);
          this.caseFlag = this.rxHook.use(caseFlag$)
          this.approvals = this.rxHook.use(approvals$);
          this.localizationSettings = this.rxHook.use(localizationSettings$);
          this.language = this.rxHook.use(language$);
          this.uploadDocumentRestrictions = this.rxHook.use(uploadDocumentRestrictions$);

          break;
        }
    }
  }

  refresh(area: CaseLoadType = undefined) {
    this.load(area);
  }

  openCaseLetter(identifier: string) {
    this.service.downloadLetter(this.case.value?.number, identifier)
      .subscribe({
        next: (_) => {
          _ = this.utilsService.tryEncodeAsUTF8(_);
          var fileURL = window.URL.createObjectURL(_);
          let tab = window.open();
          tab.location.href = fileURL;
        },
        error: () => this.notifications.genericError()
      });
  }

  openSms(item: IHistoryData) {
    this.service.downloadLetter(this.case.value?.number, item.documentIdentifier)
      .subscribe({
        next: (_) => {
          _.text().then((value) => {
            this.viewSMS(item, value);
          })
            .catch((err) => {
              this.notifications.error(err)
            });
        },
        error: () => this.notifications.genericError()
      });
  }

  openDialog(dialogType: AvailablePopups) {
    let windowType, windowOptions: MatDialogConfig = {}, windowInputs: KeyValue<string, any>[] = [];

    switch (dialogType) {

      case 'PauseCase':
        windowType = PauseCaseComponent;
        windowOptions = {
          maxWidth: window.innerWidth < 1080 ? "90vw" : "30vw",
          minWidth: window.innerWidth < 1080 ? "90vw" : "unset"
        };
        windowInputs = [{ key: "case", value: this.case }]
        break;

      case 'WithdrawCase':
        windowType = WithdrawCaseComponent;
        windowInputs = [{ key: "caseNumber", value: this.caseNumber }]
        break;

      default:
        return;
    }

    let dialogRef = this.matDialog.open(windowType, windowOptions);

    windowInputs.forEach(input => {
      dialogRef.componentInstance[input.key] = input.value;
    });

    dialogRef.afterClosed().subscribe(changes => { if (changes === true) this.refresh() })
  }

  viewSMS(smsItem: IHistoryData, content: string) {
    this.dialog.open(this.smsModal, {
      data: {
        message: smsItem,
        content: content
      }
    });
  }
}

export type CaseTransactionsTab = 'Invoices' | 'Payments' | 'Securities';
export type AvailablePopups = 'PauseCase' | 'WithdrawCase';
