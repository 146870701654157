import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-case-page',
  templateUrl: './case-page.component.html',
  styleUrls: ['./case-page.component.scss']
})
export class CasePageComponent implements OnInit {

  caseNumber$: Observable<number>;

  constructor (private route: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.caseNumber$ = this.route.paramMap.pipe(map(_ => {
      return +_.get('caseNumber');
    }));
  }

}
