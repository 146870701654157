import { CaseRoleName, CaseStateFilter, WebUserRight } from "../shared/models";

export interface ICustomerMonthlyCaseStatistics {
  month: number;
  numberOfTerminatedCases: number;
  numberOfRegisteredCases: number;
  originalCapital: number;
  paidCapital: number;
}

export interface ICustomCaseSearchOptions {
  customerNumber?: number;
  activeOrClosed: CaseStateFilter;
  isPerson?: boolean;
  roleNames: CaseRoleName[];
  statusGroupId: number;
  isFlagged: boolean;
  registeredDateFrom: Date;
  registeredDateTo: Date;
  closedDateFrom: Date;
  closedDateTo: Date;
  transferredDateToLtsFrom: Date;
  transferredDateToLtsTo: Date;
  originalCustomers: string[];
  anyCustomerReference: string;
  reference1: string;
  reference2: string;
  reference3: string;
  hasUntreatedMessages: boolean;
  customerNumbers: number[];
  invoiceNumber: string;
  debtorId?: number;
  caseSearchColumns: CustomCaseSearchColumn[];
}

export interface ICustomCaseSearchResult {
  caseNumber: number;
  debtorName: string;
  socialNumber: string;
  customerReference: string;
  customerReference1: string;
  customerReference2: string;
  invoiceDate: string;
  invoiceNumber: string;
  originalCapital?: number;
  restTotal?: number;
  restCapital?: number;
  paidTotal?: number;
  currencyCode: string;
  untreatedMessage: string;
  customer: string;
  originalCustomer: string;
  statusCode: string;
  statusText: string;
  statusTextEn: string;
  installmentAmount?: number;
}

export const CUSTOM_CASE_SEARCH_COLUMNS = ['CaseNumber', 'DebtorName', 'OrgOrBirthDate', 'CustomerReference', 'InvoiceDate', 'InvoiceNumber', 'OriginalCapital'
  , 'RestTotal', 'RestCapital', 'PaidTotal', 'CurrencyCode', 'UntreatedMessage', 'Customer', 'OriginalCustomer', 'StatusCode', 'StatusText', 'InstallmentAmount'
  , 'AddressHistory', 'RegisteredDate', 'StatusTextEn', 'FlaggedDate', 'DebtorScoreColor', 'ClosingDate', 'ClosingText', 'ClosingTextEn', 'CustomerReference1', 'CustomerReference2'] as const;
export const CUSTOM_CASE_SEARCH_COLUMNS_TYPE = [
  { name: 'CaseNumber' }
  , { name: 'DebtorName' }
  , { name: 'OrgOrBirthDate', type: 'maybedate' }
  , { name: 'CustomerReference' }
  , { name: 'InvoiceDate' }
  , { name: 'InvoiceNumber' }
  , { name: 'OriginalCapital', type: 'decimal' }
  , { name: 'RestTotal', type: 'decimal' }
  , { name: 'RestCapital', type: 'decimal' }
  , { name: 'PaidTotal', type: 'decimal' }
  , { name: 'CurrencyCode' }
  , { name: 'UntreatedMessage' }
  , { name: 'Customer' }
  , { name: 'OriginalCustomer' }
  , { name: 'StatusCode' }
  , { name: 'StatusText' }
  , { name: 'InstallmentAmount' }
  , { name: 'AddressHistory' }
  , { name: 'RegisteredDate', type: 'maybedate' }
  , { name: 'DebtorScoreColor', type: 'color', permissions: Array<WebUserRight>(WebUserRight.ViewScore) }
  , { name: 'ClosingDate', type: 'maybedate' }
  , { name: 'ClosingText' }
  , { name: 'StatusTextEn' }

] as const;

export type CustomCaseSearchColumn = typeof CUSTOM_CASE_SEARCH_COLUMNS[number];

export interface ICustomCaseListColumn {
  name: CustomCaseSearchColumn;
  type?: string;
  permissions?: Array<WebUserRight>;
}

export interface ISavedCustomSearch {
  userPreferenceId: number;
  name: string;
  customSearch: ICustomCaseSearchOptions;
}

export interface IPortfolioAnalysis {
  debtorAgeData: IDebtorAgeStatistics;
  quarterlyStatstics: IQuarterlyStatistics[];
  businessSectorStatistics: IBusinessSectorStatistic[]
}

export interface IDebtorAgeStatistics {
  numberDebtorsAgeUnder30: number;
  numberDebtorsAge30: number;
  numberDebtorsAge40: number;
  numberDebtorsAge50: number;
  numberDebtorsAge60: number;
  numberDebtorsAgeOver67: number;
}

export interface IQuarterlyStatistics {
  quarter: string;
  numberOfCasesRegisteredReminder: number;
  numberOfCasesRegisteredCollection: number;
  totalAmountCapital: number;
  numberOfRegisteredCases: number;
  successRate: number;
  averageRegistrationAge: number;
}

export interface IBusinessSectorStatistic {
  code: string;
  name: string;
  numberOfCases: number;
}

export interface IDebtorCasePeriodBalance {
  customerRef1: string;
  casesBalance: ICaseBalance[];
  periodsBalanceTotals: ICaseBalance;
}

export interface ICaseBalance {
  caseNumber: number;
  debtorName: string;
  customerRef1: string;
  customerRef2: string;
  customerNumber: number;
  outstandingAmount: number;
  registeredDate: string;
  periodsBalance: ICasePeriodBalance[];
  previousPeriodsBalance: number;
  balanceCapital: number;
}

export interface ICasePeriodBalance {
  caseNumber: number;
  month: number;
  year: number;
  balanceAmount: number;
}

export interface ICustomerCaseBalanceRequest {
  customerNumbers: string;
  customerRef1: string;
  customerRef2: string;
  month: number;
  year: number;
  numberOfPeriodsToLookBack: number;
  calculateTotals: boolean;
}

export interface ICustomerCaseBalanceResponse {
  totalNumberOfItems: number;
  pageNumber: number;
  pageSize: number;
  balanceCapitalTotal: number;
  previousPeriodsBalanceTotal: number;
  outstandingAmountTotal: number;
  originalCapitalTotal: number;
  periodBalanceTotals: ICasePeriodBalance[];
  pageItems: ICaseBalance[];
}

export interface IActiveCaseReportResult {
  customerNumber: number,
  customerName: string;
  originalCustomerName: string;
  caseNumber: number;
  debtorName: string;
  debtorType: string;
  address: string;
  postNo: string;
  postName: string;
  reference: string;
  registeredDate: Date;
  statusText: string;
  statusTextEn: string;
  statusTextDisplay: string;
  originalCapital: number;
  restCapital: number;
  interest: number;
  restInterest: number;
}

export interface IClosedCaseReportResult {
  customerNumber: number,
  customerName: string;
  caseNumber: number;
  customerReference: string;
  debtorName: string;
  terminationCode: string;
  terminationDate: Date;
  statusText: string;
  statusTextEn: string;
  statusTextDisplay: string;
  originalCapital: number;
  restCapital: number;
  paidCapital: number;
  paidInterest: number;
  restInterest: number;
  restTotal: number;
}

export const ACTIVE_CASE_SEARCH_RESULT_COLUMNS = [
    { name: 'customerNumber', translationTag: 'Reports.SimpleCaseSearchResult.CustomerNumber' }
  , { name: 'customerName', translationTag: 'Reports.SimpleCaseSearchResult.CustomerName' }
  , { name: 'originalCustomerName', translationTag: 'Reports.SimpleCaseSearchResult.OriginalCustomerName' }
  , { name: 'caseNumber', translationTag: 'Reports.SimpleCaseSearchResult.CaseNumber' }
  , { name: 'debtorName', translationTag: 'Reports.SimpleCaseSearchResult.DebtorName' }
  , { name: 'debtorType', translationTag: 'Reports.SimpleCaseSearchResult.DebtorType' }
  , { name: 'address', translationTag: 'Reports.SimpleCaseSearchResult.Address' }
  , { name: 'postNo', translationTag: 'Reports.SimpleCaseSearchResult.PostNo' }
  , { name: 'postName', translationTag: 'Reports.SimpleCaseSearchResult.PostName' }
  , { name: 'customerReference', translationTag: 'Reports.SimpleCaseSearchResult.CustomerReference' }
  , { name: 'registeredDate', type: 'date', translationTag: 'Reports.SimpleCaseSearchResult.RegisteredDate' }
  , { name: 'statusTextDisplay', translationTag: 'Reports.SimpleCaseSearchResult.StatusText' }
  , { name: 'originalCapital', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.OriginalCapital' }
  , { name: 'restCapital', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.RestCapital' }
  , { name: 'interest', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.Interest' }
  , { name: 'restInterest', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.RestInterest' }
] as const;

export const CLOSED_CASE_SEARCH_RESULT_COLUMNS = [
    { name: 'customerNumber', translationTag: 'Reports.SimpleCaseSearchResult.CustomerNumber' }
  , { name: 'customerName', translationTag: 'Reports.SimpleCaseSearchResult.CustomerName' }
  , { name: 'caseNumber', translationTag: 'Reports.SimpleCaseSearchResult.CaseNumber' }
  , { name: 'customerReference', translationTag: 'Reports.SimpleCaseSearchResult.CustomerReference' }
  , { name: 'debtorName', translationTag: 'Reports.SimpleCaseSearchResult.DebtorName' }
  , { name: 'terminationCode', translationTag: 'Reports.SimpleCaseSearchResult.TerminationCode' }
  , { name: 'terminationDate', type: 'date', translationTag: 'Reports.SimpleCaseSearchResult.TerminationDate' }
  , { name: 'statusTextDisplay', translationTag: 'Reports.SimpleCaseSearchResult.StatusText' }
  , { name: 'originalCapital', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.OriginalCapital' }
  , { name: 'restCapital', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.RestCapital' }
  , { name: 'paidCapital', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.PaidCapital' }
  , { name: 'paidInterest', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.PaidInterest' }
  , { name: 'restInterest', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.RestInterest' }
  , { name: 'restTotal', type: 'decimal', translationTag: 'Reports.SimpleCaseSearchResult.RestTotal' }
] as const;

export interface IActiveCasesRequest {
  customerNumbers: string;
}
export interface IClosedCasesRequest {
  customerNumbers: string;
  startDate: Date;
  endDate: Date;
}

export interface ICasesStatusRequest {
  customerNumbers: number[];
}

export interface ICasesStatusDetailsRequest {
  customerNumbers: number[]
  statusCode: number
}

export interface ICustomerCasesStatusSummary {
  statusCode: number,
  statusText: string;
  numberOfCases: number;
  capital: number;
  interest: number;
  payments: number;
  restCapital: number;
  restInterest: number;
  restTotal: number;
  cases: ICaseStatusDetail[];
}

export interface ICaseStatusDetail {
  customerNumber: number
  customerName: string;
  customerReference: string
  caseNumber: number;
  debtorName: string;
  originalCapital: number;
  originalInterest: number;
  payments: number;
  restCapital: number;
  restInterest: number;
  restTotal: number;
}

export const STATUS_REPORT_RESULT_COLUMNS = [
  { name: 'statusCode', translationTag: 'Reports.StatusReportResult.Status' }
  , { name: 'statusText', translationTag: 'Reports.StatusReportResult.Text' }
  , { name: 'numberOfCases', translationTag: 'Reports.StatusReportResult.NumberOfCases' }
  , { name: 'capital', type: 'decimal', translationTag: 'Reports.StatusReportResult.Capital' }
  , { name: 'interest', type: 'decimal', translationTag: 'Reports.StatusReportResult.Interest' }
  , { name: 'payments', type: 'decimal', translationTag: 'Reports.StatusReportResult.Payments' }
  , { name: 'restCapital', type: 'decimal', translationTag: 'Reports.StatusReportResult.RestCapital' }
  , { name: 'restInterest', type: 'decimal', translationTag: 'Reports.StatusReportResult.RestInterest' }
  , { name: 'restTotal', type: 'decimal', translationTag: 'Reports.StatusReportResult.RestTotal' }
] as const;

export const STATUS_REPORT_DETAILS_COLUMNS = [
  { name: 'customerNumber', translationTag: 'General.CustomerNumber' }
  , { name: 'customerName', translationTag: 'General.CustomerName' }
  , { name: 'customerReference', translationTag: 'General.CustomerReference' }
  , { name: 'caseNumber', translationTag: 'General.CaseNumber' }
  , { name: 'debtorName', translationTag: 'General.DebtorName' }
  , { name: 'originalCapital', type: 'decimal', translationTag: 'General.Capital' }
  , { name: 'originalInterest', type: 'decimal', translationTag: 'General.Interest' }
  , { name: 'payments', type: 'decimal', translationTag: 'General.Payments' }
  , { name: 'restCapital', type: 'decimal', translationTag: 'General.RestCapital' }
  , { name: 'restInterest', type: 'decimal', translationTag: 'General.RestInterest' }
  , { name: 'restTotal', type: 'decimal', translationTag: 'General.RestTotal' }
] as const;

export interface ICasesScoreRequest {
  customerNumbers: number[];
}

export interface ICasesScoreDetailsRequest {
  customerNumbers: number[]
  score: DebtorScoreColor
}

export interface ICustomerCasesScoreSummary {
  score: DebtorScoreColor,
  numberOfCases: number;
  capital: number;
  interest: number;
  payments: number;
  restCapital: number;
  restInterest: number;
  restTotal: number;
  cases: ICustomerCasesScoreDetail[];
}

export interface ICustomerCasesScoreDetail {
  score: DebtorScoreColor,
  customerNumber: number
  customerName: string;
  customerReference: string
  caseNumber: number;
  debtorName: string;
  originalCapital: number;
  originalInterest: number;
  payments: number;
  restCapital: number;
  restInterest: number;
  restTotal: number;
}

export const SCORE_REPORT_RESULT_COLUMNS = [
  { name: 'score', translationTag: 'Reports.ScoreReportResult.ScoreCode' }
  , { name: 'numberOfCases', translationTag: 'Reports.ScoreReportResult.NumberOfCases' }
  , { name: 'capital', type: 'decimal', translationTag: 'Reports.ScoreReportResult.Capital' }
  , { name: 'interest', type: 'decimal', translationTag: 'Reports.ScoreReportResult.Interest' }
  , { name: 'payments', type: 'decimal', translationTag: 'Reports.ScoreReportResult.Payments' }
  , { name: 'restCapital', type: 'decimal', translationTag: 'Reports.ScoreReportResult.RestCapital' }
  , { name: 'restInterest', type: 'decimal', translationTag: 'Reports.ScoreReportResult.RestInterest' }
  , { name: 'restTotal', type: 'decimal', translationTag: 'Reports.ScoreReportResult.RestTotal' }
] as const;

export const SCORE_REPORT_DETAILS_COLUMNS = [
  { name: 'customerNumber', translationTag: 'General.CustomerNumber' }
  , { name: 'customerName', translationTag: 'General.CustomerName' }
  , { name: 'customerReference', translationTag: 'General.CustomerReference' }
  , { name: 'caseNumber', translationTag: 'General.CaseNumber' }
  , { name: 'debtorName', translationTag: 'General.DebtorName' }
  , { name: 'originalCapital', type: 'decimal', translationTag: 'General.Capital' }
  , { name: 'originalInterest', type: 'decimal', translationTag: 'General.Interest' }
  , { name: 'payments', type: 'decimal', translationTag: 'General.Payments' }
  , { name: 'restCapital', type: 'decimal', translationTag: 'General.RestCapital' }
  , { name: 'restInterest', type: 'decimal', translationTag: 'General.RestInterest' }
  , { name: 'restTotal', type: 'decimal', translationTag: 'General.RestTotal' }
] as const;

export enum RenderFormat {
  Csv = 0,
  Doc = 1,
  MHtml = 2,
  Pdf = 3,
  Tif = 4,
  Xls = 5,
  Xlsx = 6,
  Xml = 7,
  Docx = 8
}

export interface IReportParameters {
  templatePath: string;
  displayName: string;
  format: RenderFormat;
  parameters: { [key: string]: string; };
}

export enum PerformancePeriod {
  Last12,
  Last6,
  Last3,
  Custom
}

export enum DebtorScoreColor {
  Undefined = 0,
  Green = 1,
  Yellow = 2,
  Red = 3
}

export interface ISuccessRateSummary {
  successRateCapitalLastYear: number;
  successRateCapital90DaysAfterRegistration: number;
  successRateCapital60DaysAfterRegistration: number;
}

export interface ICustomerCaseToDebtCollectionModel {
  customerNumber: number;
  customerName: string;
  caseNumber: number;
  customerReference1: string;
  invoiceNo: string;
  debtorName: string;
  localizedStatus: string;
  status: string;
  statusEn: string;
  originalTotal: number;
  paymentsAmount: number;
  restTotal: number;
  numberOfDaysToDebtCollection: number;
}

export interface ICustomerCasesToDebtCollectionRequest {
  customerNumber: number | null;
  searchTerm: string;
}
