import { KeyValue } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KEYS, MessageToUiService } from 'src/app/services/message-to-ui.service';
import { FileUploadComponent } from 'src/app/shared/file-upload/file-upload.component';
import { INewInvoice } from 'src/app/shared/models';

@Component({
  selector: 'app-invoice-copies',
  templateUrl: './invoice-copies.component.html',
  styleUrls: ['./invoice-copies.component.scss']
})
export class InvoiceCopiesComponent implements OnInit, AfterViewInit {

  @Input()
  invoiceList: INewInvoice[];

  @Input()
  files: File[];

  @Input()
  fileFormatFilter: string;

  fileNames: KeyValue<string, string>[];

  @ViewChild(FileUploadComponent) fileInput: FileUploadComponent;

  constructor (private dialogRef: MatDialogRef<any>, private messageToUi: MessageToUiService) { }

  ngOnInit(): void {
    this.buildSelectFileOptions();
  }

  ngAfterViewInit(): void {
    //  if (!this.files?.length) { this.fileInput.selectFile(); }
  }

  private buildSelectFileOptions() {
    this.fileNames = this.files.map(x => { return { key: x.name, value: x.name }; });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    const distinctInvoiceCopies = [... new Set(this.invoiceList.map(x => x.invoiceCopyName))];

    if (distinctInvoiceCopies.length < this.invoiceList.length) {
      this.messageToUi.errorKey(KEYS.NewCase_Errors_NotDistinctInvoiceCopies);
      return;
    }

    this.dialogRef.close({ invoices: this.invoiceList, files: this.files });
  }

  onNewFiles(files: File[]) {

    if (!files?.length) return;

    this.files = this.files.concat(files.filter(newFile => {
      const newFileIdentifier = this.getFileIdentifier(newFile);

      return !this.files.find(oldFile => this.getFileIdentifier(oldFile) === newFileIdentifier)
    }));
    
    this.buildSelectFileOptions();

    if (this.invoiceList?.length == 1 && !this.invoiceList[0].invoiceCopyName) {
      this.invoiceList[0].invoiceCopyName = this.files[0].name;
    }
  }

  private getFileIdentifier(file: File): string {
    return `${file.name}_${file.size}_${file.lastModified}`
  }
}
