import { KeyValue } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { NgForm } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ReplaySubject, interval, merge, of } from "rxjs";
import { debounce, finalize, map, mergeMap } from "rxjs/operators";
import { InputType } from "src/app/admin/add-edit-user/add-edit-users-models";
import { AddEditUserUtils } from "src/app/admin/add-edit-user/add-edit-users-utils";
import { BaseUserInfoComponent } from "src/app/admin/add-edit-user/base-user-info/base-user-info.component";
import { UserDataValidator } from "src/app/admin/add-edit-user/user-data-validator";
import { AdminService } from "src/app/admin/admin.service";
import { AppService } from "src/app/services/app.service";
import { MessageToUiService } from "src/app/services/message-to-ui.service";
import { GlobalSpinnerService } from "src/app/shared/global-spinner/global-spinner.service";
import { MailFrequencyEnum, UserViewModel } from "src/app/shared/models";
import { ValidatorService } from "src/app/shared/validator.service";

@Component({
    selector: 'app-user-profile',
    templateUrl:"./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
    changeDetection : ChangeDetectionStrategy.OnPush
  })
  export class UserProfileComponent implements OnInit, OnDestroy, AfterViewInit{
    
    constructor(
        private adminService : AdminService,
        private spinnerService : GlobalSpinnerService,
        public validator: ValidatorService,
        private translate : TranslateService,
        private messageToUiService : MessageToUiService,
        private application : AppService,){}
    
   
    user : UserViewModel = new UserViewModel();
    saveButtonLabel : string = 'Save';
    pageTitleLabel : string  = 'User.MyProfile';
    requestInProgress : boolean = false;
    utils : AddEditUserUtils = new AddEditUserUtils();
  
    dataValidator : UserDataValidator;
    $validatingSubject : ReplaySubject<any> = new ReplaySubject<any>(1);
    $userSubject : ReplaySubject<string> = new ReplaySubject<string>(1);
    
    @ViewChildren('form') public forms !: QueryList<NgForm>;
    @ViewChild('baseInfo') public baseInfoComponent !: BaseUserInfoComponent;


    mailFrequency : KeyValue<string,string>[] = [
        {key:MailFrequencyEnum[MailFrequencyEnum.NoEmail],value:this.translate.instant('Admin.MailFrequency.NoEmail')}
       ,{key:MailFrequencyEnum[MailFrequencyEnum.Daily],value:this.translate.instant('Admin.MailFrequency.Daily')}
       ,{key:MailFrequencyEnum[MailFrequencyEnum.Weekly],value:this.translate.instant('Admin.MailFrequency.Weekly')}
   ];

   
    baseUserInputs : any = {
        webEmail : {name:"webEmail",type:"text", value: this.user.webEmail, label:"Admin.UsernameEmail",formOrder:0,labelClass:"required",readonly:true} as InputType,
        name : {name:"name",type:"text", value: this.user.name, label:"General.Name",formOrder:1, labelClass:"required",readonly:true}as InputType,
        password : {name:"password",type:"text",subtype:"password", label:"Admin.Password",formOrder:2}as InputType,
        passwordConfirm : {name:"passwordConfirm",type:"text",subtype:"password", label:"Admin.ConfirmPassword",formOrder:3}as InputType,
        phoneNumber : {name:"phoneNumber",type:"text", value: this.user.phone, label:"Admin.PhoneNumber",formOrder:4}as InputType,
        mailFrequency : {name:"mailFrequency",type:"select",options:this.mailFrequency, value : this.user.webMailFrequency, label:"Admin.MailFrequency",formOrder:5,labelClass:"required"}as InputType,
    };


    ngOnInit(): void {

        this.$validatingSubject.pipe(debounce(() => interval(300)))
        .subscribe(() => {
            this.dataValidator?.validate();
            this.baseInfoComponent.checkChanges();
        })

        let applicationUserCode$ = this.application.user$.pipe(map(_ => _.code));

        let $user = merge(applicationUserCode$,this.$userSubject).pipe(mergeMap(userCode => {
            if(userCode)
                return this.adminService.getUser(userCode);
            return of(new UserViewModel)
        }))

        $user.subscribe(user =>{
            this.user = user;
            this.user.webMailFrequency ??= MailFrequencyEnum[MailFrequencyEnum.NoEmail]

            this.createUserBaseData();

            this.baseInfoComponent.inputs = this.baseUserInputs;
            this.baseInfoComponent.checkChanges();
            this.dataValidator = new UserDataValidator(false,this.baseUserInputs,this.forms.first);
            this.$validatingSubject.next(null);
            this.spinnerService.hide();
        })
    }

    ngAfterViewInit(): void {
        this.forms.first.valueChanges.subscribe(()=>{
            this.$validatingSubject.next(null)
           }) 
    }
    
    ngOnDestroy(): void {
        this.$userSubject.complete();
        this.$validatingSubject.complete();
    }

    createUserBaseData()
    {
      this.baseUserInputs.name.value = this.user.name;
      this.baseUserInputs.webEmail.value = this.user.webEmail;
      this.baseUserInputs.phoneNumber.value = this.user.phone;
      this.baseUserInputs.mailFrequency.value = this.user.webMailFrequency;
      this.baseUserInputs.mailFrequency.options = this.mailFrequency;
      this.baseUserInputs.password.value = "";
      this.baseUserInputs.passwordConfirm.value = "";
    }

    save()
    {
        this.spinnerService.show();
    
        this.transferProprieties();
    
        this.requestInProgress = true;
        this.adminService.addEditUser(this.user)
          .pipe(finalize(() => {
            this.spinnerService.hide(); 
            this.requestInProgress = false;
            this.baseInfoComponent?.checkChanges();
          }))
          .subscribe({
            next : (user : UserViewModel) => { this.$userSubject.next(user.code) },
            error : (e) => {  
              this.formatErrors(e?.error?.result?.errors)
              this.validator.handleError(this.forms.first,e,true);
          },
            complete: () =>{
              this.messageToUiService.success(this.translate.instant('Generic.Success'));
            } 
          })
    
    }

    canSave()
    {
        return !this.requestInProgress && this.forms?.first?.valid
    }
    
    private transferProprieties()
    {
        this.user.webEmail = this.baseUserInputs.webEmail.value 
        this.user.name = this.baseUserInputs.name.value 
        this.user.webMailFrequency = this.baseUserInputs.mailFrequency.value 
        this.user.password = this.baseUserInputs.password.value 
        this.user.phone = this.baseUserInputs.phoneNumber.value 
    }


    private formatErrors(errors = [])
    {
        for (let i = 0; i < errors.length; i++) {
        let split = errors[i].propertyName?.split(".") 
        errors[i].propertyName = split?.pop();
        }
    }


    validate()
    {
        this.$validatingSubject.next(null);
    }

}