import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentCustomerService {

  private CUSTOMER_KEY = 'CUS70M3R';

  constructor() { }

  private selectedNumberSubject = new BehaviorSubject<number | null>(null);

  selectedNumber$ = this.selectedNumberSubject.asObservable().pipe(distinctUntilChanged());

  change(customerNumber: number) {
    sessionStorage.setItem(this.CUSTOMER_KEY, JSON.stringify(customerNumber));

    this.checkSelectedNumber();
  }

  checkSelectedNumber() {
    // this method will be called from the component that will keep the customers dropdown
    const value = JSON.parse(sessionStorage.getItem(this.CUSTOMER_KEY) ?? null);

    this.selectedNumberSubject.next((value && +value) ? +value : null);
  }
}
