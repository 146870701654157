import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CasesService } from '../../../services/cases.service';
import { PaymentLinkComponent } from './payment-link.component';
import { map, mergeMap } from 'rxjs/operators';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ICaseViewDebtorModel } from '../../../shared/models';

@Component({
  selector: 'app-payment-link-dialog',
  standalone: true,
  imports: [CommonModule, PaymentLinkComponent, MatProgressBarModule, TranslateModule, MatDialogModule],
  templateUrl: './payment-link-dialog.component.html',
  styleUrls: ['./payment-link-dialog.component.scss']
})
export class PaymentLinkDialogComponent implements OnInit {

  constructor (private dialogRef: MatDialogRef<any>, private service: CasesService) { }

  caseNumber: number;
  contractReference: string;
  data$: Observable<{ debtor: ICaseViewDebtorModel, cases: { isSelected?: boolean, caseNumber: number }[] }>

  ngOnInit(): void {
    this.data$ = this.service.getByNumber(this.caseNumber)
      .pipe(mergeMap(cas =>
        this.service.getPaymentlinkRelatedCases(this.caseNumber)
          .pipe(
            map(relatedCases => {
              return {
                debtor: cas.debtor,
                cases: relatedCases.map(c => Object.assign(c, { isSelected: c.caseNumber == this.caseNumber, isDisabled: c.caseNumber === this.caseNumber }))
              }
            }))));
  }

  cancel() { this.dialogRef.close() }

  onSuccess() { this.cancel(); }
}
