import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../localization/localization.service';

@Component({
  selector: 'app-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit {

  supportedLanguages: string[];
  selectedLanguageCode: string;

  constructor(
    private localization: LocalizationService,
  ) { }

  ngOnInit(): void {
    this.supportedLanguages = this.localization.getSupportedLanguages().map(x => x.code.capitalize());
    this.localization.currentLanguage$.subscribe(selected => {
      this.selectedLanguageCode = selected;
    })
  }

  onChangeLanguage(code: string) {
    this.selectedLanguageCode = code;
    this.localization.changeLanguage(code);
  }

  isCurrentlySelectedLanguage(language: string): boolean {
    return language.toLocaleLowerCase() == this.selectedLanguageCode.toLocaleLowerCase();
  }
}
