import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { MessagesService } from 'src/app/messages/messages.service';
import { AppService } from 'src/app/services/app.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { SettingsService } from 'src/app/services/settings.service';
import { FileUploadComponent } from 'src/app/shared/file-upload/file-upload.component';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';
import { ICaseMessage, ICaseNoteAttachment, WebUserRight } from 'src/app/shared/models';
import { UtilsService } from 'src/app/shared/utils.service';
import { ValidatorService } from 'src/app/shared/validator.service';
import { PaymentLinkDialogComponent } from './payment-link/payment-link-dialog.component';
import { CasesService } from 'src/app/services/cases.service';

@Component({
  selector: 'app-case-notes',
  templateUrl: './case-notes.component.html',
  styleUrls: ['./case-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseNotesComponent implements OnChanges {

  constructor(
      private service: MessagesService
    , private caseService: CasesService
    , private utils: UtilsService
    , private translate: TranslateService
    , private spinner: GlobalSpinnerService
    , private notification: MessageToUiService
    , private validator: ValidatorService
    , private cdr: ChangeDetectorRef
    , private matDialog: MatDialog
    , private application: AppService
    , private settingsService: SettingsService) { }

  @Input() caseNumber: number;
  @Input() terminationDate?: Date;
  @Input() list: ICaseMessage[];

  @Output() caseNotesChanged: EventEmitter<any> = new EventEmitter();

  canAddMessageOnSameReference$ = this.application.userHasRight(WebUserRight.AddMessageOnSameReference);
  displayPaymentLinkOption$ = this.application.userHasRight(WebUserRight.PaymentLink)
  addCaseNote$ = this.application.userHasRight(WebUserRight.AddCaseNote);

  attachments: File[] = [];

  quickReplies$ = this.settingsService.getSettingValueByName('KreditorWeb_QuickReplies_TranslationTags')
    .pipe(map(_ => _.split(';')));

  @ViewChild(FileUploadComponent) filesComponent: FileUploadComponent;

  private fileTypesPreviewUnsupported: string[]  = ['msg'];

  ngOnChanges(changes: SimpleChanges): void {
    var caseNumberChange = changes['caseNumber'];

    if (caseNumberChange && caseNumberChange.currentValue != caseNumberChange.previousValue) {
      this.filesComponent?.clear();
    }
   }

  quickReply(form: NgForm, reply: string) {
    form.controls['text'].setValue(this.translate.instant(reply))

    this.submit(form);
  }

  submit(ngForm: NgForm) {
    let sendMessageOnAllReferences = ngForm.controls.sendMessageOnAllReferences && ngForm.controls?.sendMessageOnAllReferences.value;

    var message = this.utils.toFormData(ngForm.value, this.attachments, 'attachments');

    message.set('sendOnAllCases', sendMessageOnAllReferences);

    this.spinner.show();

    this.service.sendMessage(this.caseNumber, message)
      .subscribe({
        next: () => this.caseNotesChanged.emit()
        , error: (e) => {
          this.spinner.hide();

          this.validator.handleError(ngForm, e);

          this.cdr.detectChanges();
        }
        , complete: () => {
          this.filesComponent?.clear();
          ngForm.resetForm();

          this.cdr.detectChanges()

          this.spinner.hide();
          this.notification.successKey()
        }
      });
  }

  openPaymentLinkDialog() {
    let dialogRef = this.matDialog.open(PaymentLinkDialogComponent);

    dialogRef.componentInstance.caseNumber = this.caseNumber;

    dialogRef.afterClosed().subscribe(() => { })
  }

  onRemoveAttachment(name: string) {
    this.attachments = this.attachments.filter(x => x.name != name);
  }

  onDownloadAttachments(attachments: ICaseNoteAttachment[]) {
    attachments.forEach(x => {
      this.onDownloadAttachment(x);
    });
  }

  onDownloadAttachment(attachment: ICaseNoteAttachment) {
    this.caseService.downloadDocument(attachment.documentIdentifier)
    .subscribe({
      next: (_) => {
        const fileExtension = attachment.fileName.split('.').lastOrDefault();
        if (this.fileTypesPreviewUnsupported.indexOf(fileExtension) > -1) {
          this.utils.downloadFile(_, attachment.fileName);
        }
        else {
          this.utils.previewFile(_);
        }
      },
      error: () => this.notification.genericError()
    });
  }
}
