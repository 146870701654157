<div class="page-container" notes-content>
  <section class="header">
    <section class="title-container">
      <h3 class="title"> {{ "Contracts.Notes.Title" | translate }} </h3>
      <hr class="separation-line" />
    </section>
    <section class="header-details" *ngIf="debtorInfo$ | async as debtorInfo">
      <div class="flex-column">
        <label class="label">{{ "Contracts.ContractReference" | translate }}</label>
        <label>{{debtorInfo.contractReference}}</label>
      </div>
      <div class="flex-column">
        <label class="label">{{ "Contracts.DebtorName" | translate }}</label>
        <label>{{debtorInfo.debtorName}}</label>
      </div>
      <div class="flex-column">
        <label class="label">{{ "Contracts.Notes.ContactPhone" | translate }}</label>
        <label>{{debtorInfo.debtorPhone}}</label>
      </div>
      <div class="flex-column">
        <label class="label">{{ "Contracts.Notes.ContactEmail" | translate }}</label>
        <label>{{debtorInfo.debtorEmail}}</label>
      </div>
    </section>
  </section>
  <section class="details">
    <p-table [value]="data$ | async" class="custom-table" styleClass="p-datatable-sm" [scrollable]="true"
      [paginator]="true" [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5, 10, 25, 50]"
      [sortOrder]="-1" sortField="date" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="date" width="10%">{{ "Contracts.Notes.Date" | translate }} <p-sortIcon
              field="date"></p-sortIcon>
          </th>
          <th pSortableColumn="userCode" width="10%">{{ "Contracts.Notes.User" | translate }} <p-sortIcon
              field="userCode"></p-sortIcon>
          </th>
          <th pSortableColumn="text" width="70%">{{ "Contracts.Notes.Text" | translate }} <p-sortIcon
              field="text"></p-sortIcon>
          </th>
          <th pSortableColumn="internal" class="text-center" width="5%">{{ "Contracts.Notes.Internal" | translate }}
            <p-sortIcon field="internal"></p-sortIcon>
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-note>
        <tr *ngIf="format$ | async as format">
          <td>{{note.date | date: format.datePipeFormat}} </td>
          <td>{{note.userCode}} </td>
          <td class="note-text">{{note.text}} </td>
          <td class="text-center">
            <span *ngIf="note.internal == true">
              <i class="fas fa-check"></i>
            </span>
          </td>
          <td>
            <span (click)="editNote(note)" class="cursor-pointer">
              <i class="fas fa-pencil-alt fa-lg"></i>
            </span>
          </td>
          <td>
            <span (click)="deleteNote(note)" class="cursor-pointer">
              <i class="fas fa-trash-alt fa-lg"></i>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </section>
  <section class="details-flex">
    <section class="details-edit">
      <form #form="ngForm" class="flex-column">
        <fieldset>
          <textarea class="text-input" #text="ngModel" [(ngModel)]="selectedNote.text" id="text" name="text" rows="5"
            placeholder="{{'Contracts.Notes.Text' | translate}}"></textarea>
          <app-control-errors *ngIf="!text.valid" [control]="text"></app-control-errors>
        </fieldset>
        <fieldset class="checkboxes">
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="selectedNote.internal"
              (change)="onInternalChange(selectedNote)" name="internal" id="internal">
            <span class="checkmark"></span>
            <label for="internal">{{'Contracts.Notes.Internal' | translate}}</label>
          </label>
          <label class="custom-checkbox-container">
            <input type="checkbox" class="column-checkbox" [(ngModel)]="selectedNote.sendMessageToCaseHandler"
              (change)="onSendToCaseHandlerChange(selectedNote)" name="sendMessageToCaseHandler"
              id="sendMessageToCaseHandler">
            <span class="checkmark"></span>
            <label for="sendMessageToCaseHandler">{{'Contracts.Notes.SendMessageToCaseHandler' | translate}}</label>
          </label>
          <div class="flex-column">
            <label for="closingTypesDropDown" class="font-bold">{{'Contracts.Notes.ContractClosingType' | translate}}</label>

            <p-dropdown class="closing-types"
            name="closingTypeId"
            [options]="closingTypes$ | async"
            [(ngModel)]="selectedClosingType"
            optionLabel="closingTypeTranslationTag"
            placeholder="{{selectedClosingType?.closingTypeTranslationTag}}"
            (onChange)="onChangedClosingType($event, selectedClosingType)"
            >

            <ng-template let-item pTemplate="selectedItem">
              <span> {{item?.closingTypeTranslationTag | translate}} </span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span> {{item?.closingTypeTranslationTag | translate}} </span>
            </ng-template>
          </p-dropdown>
          </div>
        </fieldset>
      </form>
    </section>
    <section class="details-actions">
      <app-contract-actions [contractStatusId]="contractStatusId"></app-contract-actions>
    </section>
  </section>
  <section class="footer">
    <button class="button-basic" (click)="save()">{{'General.Save' | translate}}</button>
    <button class="button-red" (click)="cancel()">{{'General.Cancel' | translate}}</button>
  </section>
</div>
