import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, NgForm, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { IGenericServerError, IValidationError, IValidationResult } from './models';
import { TranslateService } from '@ngx-translate/core';
import { KEYS } from '../services/message-to-ui.service';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  private debugEnabled = true;
  constructor (
    private translate: TranslateService,
    private messageService: MessageService
  ) { }

  handleError(form: NgForm | FormGroup, e: HttpErrorResponse, showNotification: boolean = true): void {
    const controls = form ? form.controls : null;
    this.handleFormControlsErrors(controls, this.parseErrorType(e), showNotification);
  }

  handleFormControlsErrors(
    formControls: { [key: string]: AbstractControl; },
    e: Error,
    showNotification: boolean = true
  ) {
    if (e.original == null) { return; }

    if (e.validationResult && e.validationResult.errors) { this.onValidationResult(e.validationResult, formControls, showNotification); return; }

    if (this.hasErrorFromHtmlAppliedValidator(formControls)) { return; }

    if (e.serverError) { this.onServerError(e.serverError, showNotification); return; }

    if (showNotification === false) { return; }
    
    this.displayGenericError();
  }

  clearErrors(form: NgForm): void {
    const fields = Object.getOwnPropertyNames(form.controls);
    for (const field of fields) {
      const formControl = form.controls[field];
      if (formControl) {
        formControl.setErrors(null);
      }
    }
  }

  formatControlErrorMessage(controlErrors: any, errorCodePrefix: string = null) {
    if (controlErrors == null) { return null; }

    if (controlErrors.required) { return this.translate.instant(KEYS.ValidationError_Required); }
    if (controlErrors.email) { return this.translate.instant(KEYS.ValidationError_Email); }

    if (controlErrors.errorCode) { return this.translateErrorCode(controlErrors.errorCode, errorCodePrefix); }

    return JSON.stringify(controlErrors);
  }

  getPropertyValidationErrors(e: any, propertyName: string): IValidationError[] {
    return (this.parseErrorType(e).validationResult?.errors?.filter(item => item.propertyName?.toLowerCase() === propertyName?.toLowerCase()) ?? []);
  }

  private translateErrorCode(errorCode: string, errorCodePrefix: string = null) {
    if (errorCode == null || errorCode === undefined) { return null; }

    let codeTranslation = this.translate.instant(`${errorCodePrefix}${errorCode}`);

    if (codeTranslation == null || codeTranslation === '') { codeTranslation = this.translate.instant('Errors.' + errorCode); }

    if (codeTranslation == null || codeTranslation === '') { codeTranslation = this.translate.instant(errorCode); }

    return (codeTranslation != null && codeTranslation !== '') ? codeTranslation : errorCode;
  }

  private hasErrorFromHtmlAppliedValidator(formControls: { [key: string]: AbstractControl; }): boolean {
    const formControlNames: string[] = Object?.getOwnPropertyNames(formControls) ?? [];

    if (formControls == null || formControlNames.length === 0) { return; }

    for (const controlName of formControlNames) {
      const errors = formControls[controlName].errors;

      // in the future we may add other validators
      // but we have to treat their message display in method: formatControlErrorMessage
      if (errors && errors.required) { return true; }
    }

    return false;
  }

  private onServerError(e: IGenericServerError, showNotification: boolean): void {
    const errorMessage = `${this.translate.instant('Errors.ServerError.Message')}. #Error: ${e.errorReference}`;
    const title: string = this.translate.instant('Errors.ServerError.Title');

    if (showNotification) { this.messageService.add({ severity: 'error', summary: title, detail: errorMessage }); }
  }

  private onValidationResult(
    validationResult: IValidationResult,
    formControls: { [key: string]: AbstractControl; },
    showNotification: boolean
  ): void {
    const mapResult = { succeed: [], failed: [] } as ValidationMap;

    const formControlNames: string[] = Object?.getOwnPropertyNames(formControls) ?? [];

    if (formControls == null || formControlNames.length === 0) {
      validationResult.errors?.forEach(e => mapResult.failed.push(e));
    }

    for (const e of validationResult.errors ?? []) {
      if (!e.propertyName) { mapResult.failed.push(e); continue; }

      const matchingControlNames: string[] = formControlNames.filter(x => x.toLowerCase() === e.propertyName.toLowerCase());
      if (matchingControlNames.length === 0 && e.propertyName.match('\\.')) {
        const control = this.getControlByNameOrPath(e.propertyName, formControls);

        if (control == null) { mapResult.failed.push(e); continue; }

        control.setErrors({ errorCode: e.errorCode, validationError: e.errorMessage });
        mapResult.succeed.push(e);
        continue;
      }

      console.log(`matchingControlNames.length=${matchingControlNames.length}`);
      if (matchingControlNames.length === 0) { mapResult.failed.push(e); continue; }

      const controlName = matchingControlNames[0];
      console.log(`controlName=${controlName}`);

      formControls[controlName].setErrors({ errorCode: e.errorCode, validationError: e.errorMessage });
      mapResult.succeed.push(e);
    }

    if (showNotification) { this.displayValidationResult(validationResult, mapResult); }
  }

  private getControlByNameOrPath(nameOrPath: string, controls: { [key: string]: AbstractControl; }) {
    console.log(`ControlNameOrPath: ${nameOrPath}`);

    if (nameOrPath == null || controls == null) { return null; }

    const nestedMatch = nameOrPath.match('^(?<name>[^.]+)\\.(?<nested>.+)$');

    if (nestedMatch == null) {
      console.log('Not a nested control path!'); console.log(controls);

      return controls[this.withFirstCharToLowerCase(nameOrPath)];
    }
    const controlName = nestedMatch?.groups['name'];
    const nestedControlName = nestedMatch?.groups['nested'];
    console.log(`Matched name: '${controlName}' and nested: '${nestedControlName}'`);

    if (controlName == null || nestedControlName == null) { return null; }

    const control = controls[this.withFirstCharToLowerCase(controlName)];
    console.log(control == null ? `Parent control ${controlName} NOT FOUND!` : `Parent control '${controlName}' found`);

    if (control == null) { return null; }
    console.log(control);

    const nestedControl = control.get(this.withFirstCharToLowerCase(nestedControlName));
    console.log(nestedControl == null ? `Nested control '${nestedControlName}' NOT FOUND on '${controlName}'!` : `Nested control '${nestedControlName}' found on '${controlName}'`);

    if (nestedControl == null) { return null; }
    console.log(nestedControl);

    return this.getControlByNameOrPath(nestedControlName, (control as UntypedFormGroup).controls);
  }

  private withFirstCharToLowerCase(sourceString: string): string {
    if (sourceString == null || sourceString.length < 1) { return sourceString; }
    return sourceString[0].toLowerCase() + sourceString.substring(1);
  }

  private displayValidationResult(vr: IValidationResult, handlingOfMapping: ValidationMap): void {
    if (handlingOfMapping.succeed.length > 0) { return; }

    let message: string = vr.message;

    if ((message != null && message !== '') || handlingOfMapping.failed.length > 0) {
      handlingOfMapping.failed.forEach(er =>
        {
          var translation = this.translate.instant(er.errorCode);
          message += `\r\n${er.propertyName} - ${(translation  != '' ? translation : er.errorCode)}`
        });

      console.log(message);
      this.messageService.add(
        {
          severity: 'error',
          summary: this.translate.instant('Errors.ValidationResult.Title'),
          detail: message
        });
    }
  }

    private parseErrorType(e: HttpErrorResponse): Error {
    const result = { original: e } as Error;

    if (e.error == null) { return result; }

    result.validationResult = e.error.result as IValidationResult;
    if (result.validationResult) { return result; }

    result.serverError = e.error as IGenericServerError;
    if (result.validationResult) { return result; }

    return result;
  }

  private displayGenericError() {
    this.messageService.add(
      {
        severity: 'error',
        summary: this.translate.instant('Errors.Generic.Title'),
        detail: this.translate.instant('Errors.Generic.Message')
      });
  }

  private log(data: any): void {
    if (this.debugEnabled) { console.log(data); }
  }
}

export interface Error {
  validationResult: IValidationResult;
  serverError: IGenericServerError;
  original: HttpErrorResponse;
  is401Unauthorized: boolean;
}

export interface ValidationMap {
  succeed: IValidationError[];
  failed: IValidationError[];
}
