<div class="login-container">

  <!-- Alerts -->
  <ng-container *ngFor="let news of loginNews">
    <div class="alert alert-warning" role="alert">
      <div>
        <i class="fas fa-exclamation-triangle"></i>
      </div>
      <span [innerHTML]="news.newsText"></span>
    </div>
  </ng-container>

  <div class="login-form">
    <ng-container>
      <div class="form-header">
        <div class="flags-container">
          <a class="small-flag" *ngFor="let item of supportedLanguages">
            <img src="{{'./assets/' + item.imageName}}" matTooltip="{{'Login.'+item.name | translate}}"
              (click)="onChangeLanguage(item.code)" />
          </a>
        </div>
        <div class="logo-container">
          <img src="./assets/sveaLogo.png">
        </div>
      </div>
  
      <form #loginForm="ngForm">
        <ng-container *ngIf="!isPending2FA">
          <fieldset>
            <input type="text" class="text-input" placeholder="{{'Login.Username' | translate}}" name="username"
              [(ngModel)]="userCredentials.userName" #username="ngModel">
            <ng-container *ngTemplateOutlet="errorsDisplay;context:{controll: username}"></ng-container>
          </fieldset>
  
          <fieldset class="password-container">
            <custom-password-input [(ngModel)]="userCredentials.password" [(password)]="userCredentials.password"
              name="password" #password="ngModel" placeholder="{{'Login.Password' | translate}}"
              [required]="true"></custom-password-input>
            <ng-container *ngTemplateOutlet="errorsDisplay;context:{controll: password}"></ng-container>
          </fieldset>
        </ng-container>
  
        <ng-container *ngIf="isPending2FA">
          <label>
            {{ twoFactorMessage }}
          </label>
  
          <fieldset class="twofactor-container">
            <input class="text-input" [(ngModel)]="userCredentials.twoFactorCode"
              name="twoFactorCode" #twoFactorCode="ngModel" placeholder="{{'Login.TwoFactorAuthenticationCode' | translate}}"
              [required]="true">
            <ng-container *ngTemplateOutlet="errorsDisplay;context:{controll: twoFactorCode}"></ng-container>
          </fieldset>
        </ng-container>
  
        <fieldset>
          <button class="button-basic" type="submit" (click)="onLogin()">
            {{'Login.LoginButton' | translate}}
          </button>
  
          <ng-container *ngIf="!isPending2FA; else cancel2FA">
            <a href="javascript:void(0)" (click)="openForgotPasswordModal()">
              {{'Login.ForgotPassword' | translate}}</a>
          </ng-container>
  
          <ng-template #cancel2FA>
            <button class="button-basic" type="submit" (click)="onCancel()">
              {{'General.Cancel' | translate}}
            </button>
          </ng-template>
  
        </fieldset>
      </form>
    </ng-container>
  </div>

</div>

<ng-template #forgotPasswordModal>
  <div mat-dialog-title>
    <div class="logo-container">
      <img src="./assets/sveaLogo.png">
    </div>
  </div>
  <mat-dialog-content>
    <form #forgotPassworForm="ngForm">
      <input name="userCodeOrEmail" type="text" [(ngModel)]="usernameEmail" class="text-input"
        #userCodeOrEmail="ngModel" placeholder="{{'Login.Username' | translate}}" required ngbAutofocus>
      <ng-container *ngTemplateOutlet="errorsDisplay;context:{controll: userCodeOrEmail}"></ng-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" class="button-basic" (click)="sendForgotPasswordRequest(forgotPassworForm)" [disabled]="userCodeOrEmail.errors">
      {{'Login.ResetPassword' | translate}}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #errorsDisplay let-c="controll">
  <div [hidden]="c.valid || (!c.dirty && !c.touched)" class="input-error">
    <span>{{validator.formatControlErrorMessage(c.errors, 'Login.Errors.')}}</span>
  </div>
</ng-template>
