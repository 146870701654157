import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { AbstractControl, NgForm } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, debounceTime } from "rxjs";
import { CasesService } from "src/app/services/cases.service";
import { MessageToUiService } from "src/app/services/message-to-ui.service";
import { RxState } from "src/app/shared/RxHook";
import { GlobalSpinnerService } from "src/app/shared/global-spinner/global-spinner.service";
import { CasePauseReasonEnum, ICaseViewModel } from "src/app/shared/models";
import { ValidatorService } from "src/app/shared/validator.service";


@Component({
    selector : "app-pause-case"
    ,templateUrl : "./pause-case.component.html"
    ,styleUrls : ["./pause-case.component.scss"]
    ,changeDetection : ChangeDetectionStrategy.OnPush
})
export class PauseCaseComponent implements AfterViewInit, OnDestroy
{
    constructor(private dialogRef: MatDialogRef<any>
        , private changeDetectorRef : ChangeDetectorRef
        , private casesService : CasesService
        , private validator : ValidatorService
        , private messageToUiService : MessageToUiService
        , private translate : TranslateService
        , private spinnerService: GlobalSpinnerService  )
    {}
    
    @Input()
    case: RxState<ICaseViewModel>;
    
    @ViewChild('form')
    userForm : NgForm;
    
    noteTextValue : string;
    pauseReason : CasePauseReasonEnum;
    userConfirmationModel : boolean = false;
    subscriptions : Subscription[] = [];
    CasePauseReasonEnum = CasePauseReasonEnum;
    requestInProgress : boolean = false;

    get userConfirmationNeeded()
    {
        return this.pauseReason == CasePauseReasonEnum.DeferralOfPayment; 
    }

    get isValid()
    {
        let pauseReasonControl = this.userForm?.form.controls.pauseReason as AbstractControl;
        let noteTextCasePauseControl = this.userForm?.form.controls.noteTextCasePause as AbstractControl;
        let userConfirmationCheckboxControl = this.userForm?.form.controls.userConfirmationCheckbox as AbstractControl;

        return this.userForm?.valid && 
                    ! this.userForm?.pristine && 
                    ! pauseReasonControl?.pristine &&
                    ! noteTextCasePauseControl.pristine &&
                    ! (this.userConfirmationNeeded && userConfirmationCheckboxControl?.pristine) && 
                    ! this.requestInProgress;
    }

    get canUseDeferralOfPayment()
    {
        return this.case?.value?.wasPostponedByCreditor == true;
    }

    resetUserApproval()
    {
        this.userConfirmationModel = false;
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(
            this.userForm.valueChanges
                .pipe(debounceTime(250))
                .subscribe(changes => this.validate(changes))
        );
    }

    ngOnDestroy(): void {
       this.subscriptions.forEach(s => s.unsubscribe());
    }


    validate(changes : any | null)
    {
        let pauseReasonControl = this.userForm?.form.controls.pauseReason as AbstractControl;
        let noteTextCasePauseControl = this.userForm?.form.controls.noteTextCasePause as AbstractControl;
        let userConfirmationCheckboxControl = this.userForm?.form.controls.userConfirmation as AbstractControl;

        pauseReasonControl?.setErrors(null)
        noteTextCasePauseControl?.setErrors(null)
        userConfirmationCheckboxControl?.setErrors(null)

        if((changes?.pauseReason == null || changes?.pauseReason.length == 0) && !pauseReasonControl?.pristine)
            pauseReasonControl?.setErrors({ errorCode : "IsRequired" });
        
        if(this.userConfirmationNeeded && changes?.userConfirmation == false)
            userConfirmationCheckboxControl?.setErrors({ errorCode : "IsRequired" });
        
        if((changes?.noteTextCasePause == null || changes?.noteTextCasePause.trim().length == 0) && !noteTextCasePauseControl.pristine)
            noteTextCasePauseControl?.setErrors({ errorCode : "IsRequired" });

        this.changeDetectorRef.markForCheck();
    }


    save()
    {
        this.requestInProgress = true;
        this.spinnerService.show();
        this.changeDetectorRef.markForCheck();

        this.casesService.pause({
            caseNumber : this.case?.value?.number,
            noteText : this.noteTextValue,
            pauseReason : this.pauseReason,
            userConfirmation : this.userConfirmationModel
        }).subscribe({        
            next: (_) => {
                this.messageToUiService.success(this.translate.instant("Generic.Success"));
                this.dialogRef.close(true);
            }
            , error: (e) => {
                this.validator.handleError(this.userForm, e, true);
                this.changeDetectorRef.markForCheck();
            }
            , complete: () => {
                this.requestInProgress = false;
                this.spinnerService.hide();
            } 
        });

    }
}
