import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { IUploadDocumentRestrictions } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private kreditorWs: KreditorService) { }

  getSettingValueByName(name: string) : Observable<string> {
    return this.kreditorWs.getServiceResult<string>(`settings/${name}`).pipe(map(_ => _.result));
  }

  getFileUploadRestrictions(): Observable<IUploadDocumentRestrictions> {
    return this.kreditorWs.getServiceResult<IUploadDocumentRestrictions>('settings/documentUploadRestrictions')
    .pipe(map(_ => _.result));
  }
}
