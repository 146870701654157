import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KEYS, MessageToUiService } from 'src/app/services/message-to-ui.service';
import { SettingsService } from 'src/app/services/settings.service';
import { IUploadDocumentRestrictions } from '../models';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, AfterViewInit {

  @ViewChild('fileLabel') fileLabel: ElementRef;
  files: File[];
  @Output() fileToUploadChange: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Input() multiFile = false;
  @Input() acceptedFormats: string = '*.*';
  @Input() icon = false;
  @Input() displayFilesList: boolean = true;
  @Input() fileInputId: string = 'fileInput';

  uploadRestrictions: IUploadDocumentRestrictions;
  private _fileUpload: HTMLInputElement;

  constructor(
    private settingsService: SettingsService,
    private messageToUiService: MessageToUiService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.settingsService.getFileUploadRestrictions().subscribe(_ => this.uploadRestrictions = _);
    if (this.icon) this.displayFilesList = false;
  }

  ngAfterViewInit() {
    this._fileUpload = document.getElementById(this.fileInputId) as HTMLInputElement;
    this._fileUpload.onchange = () => {
      this.files = Array.from(this._fileUpload.files);

      this.validateFiles();
      if (!this.files?.length) { this.clear(); return; }

      this.fileToUploadChange.emit(this.files);

      if (!this.icon && this.fileLabel) {
        this.fileLabel.nativeElement.innerText = this.files?.length > 1 ? `${this.files.length} files` : this.files?.[0]?.name;
      }
      this._fileUpload.value = null;
    };
  }

  selectFile() {
    const fileUpload = document.getElementById(this.fileInputId) as HTMLInputElement;
    fileUpload?.click();
  }

  clear() {
    this.files = null;
    if (this.displayFilesList) this.fileLabel.nativeElement.innerText = "";
    this.fileToUploadChange.emit(this.files);
  }

  validateFiles() {
    const displayFileName = this.files.length > 1;

    for (let i = 0; i < this.files.length; i++) {
      const fileSizeMb = this.files[i].size / (1024 * 1024);
      let errorMessage = '';

      if (fileSizeMb > this.uploadRestrictions.maxSizeMb) {
        errorMessage = this.translateService.instant('Error.AttachmentSizeExceedsMax', { maxSizeMb: this.uploadRestrictions.maxSizeMb });
      }

      const fileExtension = this.getFileExtension(this.files[i].name);

      if (this.uploadRestrictions.allowedFileExtensions.indexOf(fileExtension) < 0) {
        errorMessage = this.translateService.instant('Error.AttachmentExtensionNotAllowed', { allowedFileTypes: this.uploadRestrictions.allowedFileExtensions.join(', ') });
      }

      if (errorMessage.length) {
        this.messageToUiService.error(displayFileName ? this.files[i].name : KEYS.Error_GenericMessage, errorMessage, true);
        this.files.splice(i, 1);
      }
    };
  }

  private getFileExtension(fileName: string): string {
    const fileNameSplit = fileName.split('.');
    return fileNameSplit[fileNameSplit.length - 1];
  }
}
