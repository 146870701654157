<section class="header">
  <section class="title-container">
    <h4 class="title">{{'NewCase.Title' | translate}}</h4>
    <hr class="separation-line" />
  </section>

  <section class="steps-container">
    <div class="numbers-container">
      <div class="number-circle" (click)="onNavigateToTab(1)">
        1
      </div>
      <div class="number-line" [class.disabled]="!isDebtorInformationComplete"></div>
      <div class="number-circle" [class.disabled]="!isDebtorInformationComplete" (click)="onNavigateToTab(2)">
        2
      </div>
      <div class="number-line" [class.disabled]="!isDebtorInformationComplete || !isInvoicesInformationComplete">
      </div>
      <div class="number-circle" [class.disabled]="!isDebtorInformationComplete || !isInvoicesInformationComplete"
        (click)="onNavigateToTab(3)">
        3
      </div>
    </div>
    <div class="step-names-container">
      <span class="step-name">
        {{'NewCase.Step1.Name' | translate}}
      </span>
      <span class="step-name" [class.disabled]="!isDebtorInformationComplete">
        {{'NewCase.Step2.Name' | translate}}
      </span>
      <span class="step-name" [class.disabled]="!isInvoicesInformationComplete">
        {{'NewCase.Step3.Name' | translate}}
      </span>
    </div>
  </section>
</section>

<form #form="ngForm">
  <section class="tab" *ngIf="activeTab == 1">
    <section class="left-panel">
      <h4 class="title">{{'NewCase.Step' | translate}} 1</h4>
      <p>{{'NewCase.Step1.Paragraph1' | translate}}</p>
      <p>{{'NewCase.Step1.Paragraph2' | translate}}</p>
      <p><b>{{'NewCase.Step1.Paragraph3' | translate}}</b></p>
      <p>{{'NewCase.Step1.Paragraph4' | translate}}</p>
      <p>{{'NewCase.Step1.Paragraph5' | translate}}</p>
      <p>{{'NewCase.Step1.Paragraph6' | translate}}</p>
    </section>

    <section class="right-panel">
      <h4 class="title required">{{'NewCase.ChooseCustomer' | translate}}</h4>
      <section class="debtor-details">
        <fieldset class="full-row">
          <custom-select name="customerNumber" [options]="customers$ | async" [(selectedKey)]="newCase.customerNumber"
            placeholderTranslationTag="General.SelectCustomer" [searchEnabled]="true"
            (selectedKeyChange)="onCustomerChange();determineIsInformationComplete()"
            [(ngModel)]="newCase.customerNumber" #customerNumber="ngModel">
          </custom-select>
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: customerNumber }"></ng-container>
        </fieldset>
        <h4 class="title full-row">{{'NewCase.DebtorInformation' | translate}}</h4>
        <fieldset>
          <label class="label" for="socialNumber">
            {{"NewCase.SocialNumber" | translate}}
          </label>
          <input type="text" class="text-input" id="socialNumber" name="socialNumber" #socialNumber="ngModel"
            [(ngModel)]="newCase.socialNumber" (focusout)="debtorInfoSearch(newCase.socialNumber, null)"
            (blur)="determineIsInformationComplete()" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: socialNumber }"></ng-container>
        </fieldset>
        <fieldset>
          <label for="name" class="label required">{{"NewCase.Name" | translate}}</label>
          <input type="text" class="text-input" id="name" name="name" #name="ngModel" [(ngModel)]="newCase.name"
            required (blur)="determineIsInformationComplete()" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: name }"></ng-container>
        </fieldset>
        <fieldset>
          <label for="customerReference1" class="label required">{{"NewCase.CustomerReference" | translate}} 1</label>
          <input type="text" class="text-input" id="customerReference1" name="customerReference1"
            #customerReference1="ngModel" [(ngModel)]="newCase.customerReference1"
            (focusout)="debtorInfoSearch(null, newCase.customerReference1)" required
            (blur)="determineIsInformationComplete()" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: customerReference1 }"></ng-container>
        </fieldset>
        <fieldset>
          <label for="customerReference2" class="label">{{"NewCase.CustomerReference" | translate}} 2</label>
          <input type="text" class="text-input" id="customerReference2" name="customerReference2"
            #customerReference2="ngModel" [(ngModel)]="newCase.customerReference2" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: customerReference2 }"></ng-container>
        </fieldset>
        <fieldset class="full-row">
          <label class="label required" for="countryCode">{{"NewCase.Country" | translate}}</label>
          <custom-select name="countryCode" [options]="countries$ | async" [(selectedKey)]="newCase.countryCode"
            [searchEnabled]="true" [(ngModel)]="newCase.countryCode"
            (ngModelChange)="determineIsInformationComplete()"></custom-select>
        </fieldset>
        <fieldset>
          <label for="address1" class="label required">{{"NewCase.Address1" | translate}}</label>
          <input type="text" class="text-input" id="address1" name="address1" #address1="ngModel"
            [(ngModel)]="newCase.address1" required (blur)="determineIsInformationComplete()" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: address1 }"></ng-container>
        </fieldset>
        <fieldset>
          <label class="label" for="address2">{{"NewCase.Address2" | translate}}</label>
          <input type="text" class="text-input" id="address2" name="address2" #address2="ngModel"
            [(ngModel)]="newCase.address2" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: address2 }"></ng-container>
        </fieldset>
        <fieldset>
          <label class="label" for="address3">{{"NewCase.Address3" | translate}}</label>
          <input type="text" class="text-input" id="address3" name="address3" #address3="ngModel"
            [(ngModel)]="newCase.address3" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: address3 }"></ng-container>
        </fieldset>
        <fieldset>
          <label for="postCode" class="label" [class.required]="determineIsPostCodeRequired()">{{"NewCase.PostCode" |
            translate}}</label>
          <input type="text" class="text-input" id="postCode" name="postCode" #postCode="ngModel"
            [(ngModel)]="newCase.postCode" [attr.maxLength]="determinePostCodeLength()"
            [required]="determineIsPostCodeRequired()" (blur)="determineIsInformationComplete()" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: postCode }"></ng-container>
        </fieldset>
        <fieldset>
          <label class="label" for="phoneNumber">
            {{"NewCase.PhoneNumber" | translate}}
            <i class="fa fa-info-circle" aria-hidden="true" [matTooltip]="'NewCase.PhoneNumberForeign' | translate"></i>
          </label>
          <input type="text" class="text-input" id="phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
            [(ngModel)]="newCase.phoneNumber" />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: phoneNumber }"></ng-container>
        </fieldset>
        <fieldset>
          <label class="label" for="emailAddress">{{"NewCase.EmailAddress" | translate}}</label>
          <input type="text" class="text-input" id="emailAddress" name="emailAddress" #emailAddress="ngModel"
            [(ngModel)]="newCase.emailAddress" email />
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: emailAddress }"></ng-container>
        </fieldset>
        <fieldset class="checkbox-container" *ngIf="canAddEuFee$ | async">
          <input type="checkbox" class="checkbox" name="isFirm" id="isFirm" [(ngModel)]="newCase.isFirm">
          <label class="label" for="isFirm">
            {{'NewCase.IsB2BClaim' | translate}}
          </label>
        </fieldset>
        <fieldset *ngIf="newCase.isFirm">
          <label class="label" for="euFee">{{'NewCase.EuFee' | translate }}</label>
          <p-inputNumber [(ngModel)]="newCase.euFee" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
            [useGrouping]="false" name="euFee" #euFee="ngModel">
          </p-inputNumber>
          <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: euFee }"></ng-container>
        </fieldset>
      </section>
      <div class="full-row">
        <button type="button" class="button-basic" [disabled]="!isDebtorInformationComplete"
          (click)="onNavigateToTab(2)">
          {{'NewCase.Next' | translate}}
        </button>
      </div>
    </section>
  </section>

  <section class="tab" *ngIf="activeTab == 2">
    <section class="left-panel">
      <h4 class="title">{{'NewCase.Step' | translate}} 2</h4>
      <p>{{'NewCase.Step2.Paragraph1' | translate}}</p>
      <p>
        <b>{{'NewCase.Step2.Paragraph2' | translate}}</b>
      </p>
      <p>{{'NewCase.Step2.Paragraph3' | translate}}</p>
      <p>
        <b>{{'NewCase.Step2.Paragraph4' | translate}}</b>
      </p>
      <p>{{'NewCase.Step2.Paragraph5' | translate}}</p>
      <p>
        <b>{{'NewCase.Step2.Paragraph6' | translate}}</b>
      </p>
      <p>{{'NewCase.Step2.Paragraph7' | translate}}</p>
    <app-reminder-collection-policy-info></app-reminder-collection-policy-info>
    </section>
    <section class="right-panel">
      <h4 class="title">{{'NewCase.Step2.Title' | translate}}</h4>
      <section class="invoice-details">
        <ul class="errors" *ngIf="invoicesErrors?.length">
          <li *ngFor="let errorCode of invoicesErrors">{{ ('NewCase.Invoices.' + errorCode) | translate}}</li>
        </ul>

        <div class="invoices-table">
          <div *ngIf="!isSmallScreen" class="invoices-header">
            <label class="label required">{{ "NewCase.Invoice.Date" | translate }}</label>
            <label class="label required">{{ "NewCase.Invoice.DueDate" | translate }}</label>
            <label class="label required">{{ "NewCase.Invoice.Number" | translate }}</label>
            <label class="label required">{{ "NewCase.Invoice.Amount" | translate }}</label>
            <label class="label required">{{ "NewCase.Invoice.Currency" | translate }}</label>
          </div>

          <ng-container *ngFor="let invoice of newCase.invoices; index as i" [ngModelGroup]="'invoices[' + i + ']'">
            <div class="invoices-group">
              <div *ngIf="isSmallScreen" class="invoices-header">
                <label class="label required">{{ "NewCase.Invoice.Date" | translate }}</label>
                <label class="label required">{{ "NewCase.Invoice.DueDate" | translate }}</label>
                <label class="label required">{{ "NewCase.Invoice.Number" | translate }}</label>
                <label class="label required">{{ "NewCase.Invoice.Amount" | translate }}</label>
                <label class="label required">{{ "NewCase.Invoice.Currency" | translate }}</label>
              </div>

              <div class="invoices-rows">
                <fieldset autocomplete="off">
                  <p-calendar id="date" name="date" [ngModel]="invoice.date | jsonDateParse"
                    (ngModelChange)="invoice.date=$event" #date="ngModel" [dateFormat]="locale.calendar.dateFormat"
                    [placeholder]="locale.calendar.dateFormatPlaceholder" (onBlur)="onInvoiceFieldLeave()" required>
                  </p-calendar>
                  <ng-container *ngTemplateOutlet="errorsDisplay; context: { controll: date }"></ng-container>
                </fieldset>

                <fieldset autocomplete="off">
                  <p-calendar id="dueDate" name="dueDate" [ngModel]="invoice.dueDate | jsonDateParse"
                    (ngModelChange)="invoice.dueDate=$event" #dueDate="ngModel"
                    [dateFormat]="locale.calendar.dateFormat" [placeholder]="locale.calendar.dateFormatPlaceholder"
                    (onBlur)="onInvoiceFieldLeave()" required>
                  </p-calendar>
                  <ng-container *ngTemplateOutlet="  errorsDisplay;  context: { controll: dueDate }"></ng-container>
                </fieldset>
                <fieldset>
                  <input type="text" class="text-input" id="number" name="number" #number="ngModel"
                    [(ngModel)]="invoice.number" (onBlur)="onInvoiceFieldLeave()" required />
                  <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: number }"></ng-container>
                </fieldset>
                <fieldset>
                  <p-inputNumber [(ngModel)]="invoice.amount" #amount="ngModel" inputId="amount" name="amount"
                    mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [useGrouping]="false"
                    (onBlur)="onInvoiceFieldLeave('amount')" required>
                  </p-inputNumber>
                  <ng-container *ngTemplateOutlet="errorsDisplay;  context: { controll: amount }"></ng-container>
                </fieldset>
                <fieldset>
                  <custom-select name="currencyCode" [options]="currencyCodes$ | async"
                    [(selectedKey)]="invoice.currencyCode" [searchEnabled]="true" [(ngModel)]="invoice.currencyCode">
                  </custom-select>
                </fieldset>
                <fieldset>
                  <button class="floating-round-button floating-add mr-2" *ngIf="i + 1 === newCase.invoices?.length"
                    (click)="onAddInvoice()">
                    <i class="fas fa-plus"></i>
                  </button>
                  <button class="floating-round-button floating-delete" *ngIf="i > 0 || newCase.invoices?.length > 1"
                    (click)="onRemoveInvoice(i)">
                    <i class="fas fa-trash"></i>
                  </button>
                </fieldset>
              </div>
            </div>
          </ng-container>
        </div>

        <label class="total-label">
          {{ 'NewCase.Invoices.Total' | translate }} :
          {{ invoicesTotal | number : locale.numberPipeFormat.style : locale.numberPipeFormat.culture}}
        </label>
      </section>

      <section class="invoice-copies">
        <div class="invoice-copies-header">
          <i class="fa fa-info-circle" [matTooltip]="'NewCase.InvoiceCopiesInfo' | translate"></i>
          <label class="label required">
            {{'NewCase.UploadInvoiceCopies' | translate}}
          </label>          
          <i class="fas fa-cloud-upload-alt cursor-pointer" (click)="openInvoiceCopyMatchingDialog()"></i>
        </div>
        <div *ngFor="let invoice of newCase.invoices" class="invoice-copies-list">
          <ng-container *ngIf="invoice.invoiceCopyName?.length > 0">
            <span>
              {{invoice.number}}
            </span>
            <span>
              {{invoice.invoiceCopyName}}
            </span>
            <i class="fas fa-times" (click)="onRemoveInvoiceCopy(invoice)"></i>
          </ng-container>
        </div>
      </section>

      <section class="other-details">
        <fieldset>
          <label class="label required" for="invoiceDescription">
            {{ 'NewCase.InvoiceDescription' | translate }}
          </label>
          <input type="text" class="text-input" id="invoicesDescription" name="invoicesDescription"
            #invoicesDescription="ngModel" [(ngModel)]="newCase.invoicesDescription"
            (blur)="determineIsInformationComplete()" />
          <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: invoicesDescription }"></ng-container>
        </fieldset>
        <div class="inline-inputs">
          <fieldset>
            <label class="label required" for="isRoadFreight">
              {{ 'NewCase.IsRoadFreight' | translate }}</label>
            <custom-select name="isRoadFreight" [options]="yesNoSelection"
              [(selectedKey)]="newCase.isRoadFreight"></custom-select>
          </fieldset>
          <fieldset>
            <label class="label required" for="debtorHasObjections">
              {{ 'NewCase.DebtorHasObjections' | translate }}
            </label>
            <custom-select name="debtorHasObjections" [options]="yesNoSelection"
              [(selectedKey)]="newCase.debtorHasObjections" [(ngModel)]="newCase.debtorHasObjections"></custom-select>
          </fieldset>
          <fieldset class="reminder">
            <label class="label required" for="shouldSendReminder">
              {{ 'NewCase.ShouldSendReminder' | translate }}
            </label>
            <custom-select name="shouldSendReminder" [options]="yesNoSelection"
              [(selectedKey)]="newCase.shouldSendReminder" [(ngModel)]="newCase.shouldSendReminder" (selectedKeyChange)="determineIsInformationComplete()"></custom-select>
          </fieldset>
        </div>

        <fieldset class="reminder-upload">
          <app-file-upload *ngIf="!newCase.shouldSendReminder" [acceptedFormats]="fileFormatFilter"
            (fileToUploadChange)="onReminderFilesChanged($event)" [multiFile]="true" fileInputId="reminderCopiesUpload">
          </app-file-upload>
        </fieldset>

        <fieldset class="additional-details-container">
          <ng-container *ngIf="newCase.debtorHasObjections">
            <label class="label required" for="additionalDetails">
              {{"NewCase.AdditionalDetails" | translate}}</label>
            <textarea id="additionalDetails" class="text-input" name="additionalDetails" #additionalDetails="ngModel"
              [(ngModel)]="newCase.additionalDetails"></textarea>
            <ng-container *ngTemplateOutlet="errorsDisplay;  context: { controll: additionalDetails }"></ng-container>
          </ng-container>
        </fieldset>

        <label class="custom-checkbox-container required" *ngIf="!newCase.shouldSendReminder && remindersDifferentThanInvoices">
          <input type="checkbox" class="column-checkbox" id="verification4" [(ngModel)]="verification4"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="determineIsInformationComplete()">
          <span class="checkmark"></span>
          <label for="verification4">{{ 'NewCase.Step2.VerificationCheckbox4' | translate}}</label>
        </label>

        <label class="custom-checkbox-container required">
          <input type="checkbox" class="column-checkbox" id="verification1" [(ngModel)]="verification1"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="determineIsInformationComplete()">
          <span class="checkmark"></span>
          <label for="verification1">{{ 'NewCase.Step2.VerificationCheckbox1' | translate}}</label>
        </label>

        <label class="custom-checkbox-container required">
          <input type="checkbox" class="column-checkbox" id="verification2" [(ngModel)]="verification2"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="determineIsInformationComplete()">
          <span class="checkmark"></span>
          <label for="verification2">{{ 'NewCase.Step2.VerificationCheckbox2' | translate}}</label>
        </label>
        <label class="custom-checkbox-container required" *ngIf="!newCase.shouldSendReminder">
          <input type="checkbox" class="column-checkbox" id="verification3" [(ngModel)]="verification3"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="determineIsInformationComplete()">
          <span class="checkmark"></span>
          <label for="verification3">{{ 'NewCase.Step2.VerificationCheckbox3' | translate}}</label>
        </label>
      </section>

      <div class="buttons-container">
        <button class="button-basic-reverse" type="button" (click)="onNavigateToTab(1)">
          {{ 'NewCase.Previous' | translate }}
        </button>
        <button class="button-basic" type="button" (click)="goToSummaryTab()"
          [disabled]="!isInvoicesInformationComplete">
          {{ 'NewCase.Next' | translate }}
        </button>
      </div>
    </section>
  </section>
  <section *ngIf="activeTab == 3" class="tab">
    <section class="left-panel">
      <h4 class="title">{{'NewCase.Step' | translate}} 3</h4>
      <p>{{'NewCase.Step3.Paragraph1' | translate}}</p>
    </section>
    <section class="right-panel">
      <h4 class="title">{{'NewCase.Step3.Name' | translate}}</h4>
      <label class="title">
        {{'NewCase.Customer' | translate}}
      </label>
      <label class="property-value">
        {{ newCase.customerNumber}}&nbsp;-&nbsp;{{selectedCustomerName }}
      </label>
      <section class="debtor-info-summary">
        <label class="title">
          {{ 'NewCase.DebtorInformation' | translate }}
        </label>
        <div>
          <label>
            {{ 'NewCase.Name' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.name }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.SocialNumber' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.socialNumber }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.Country' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.countryCode }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.Address1' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.address1}}
          </span>
        </div>
        <div *ngIf="newCase.address2?.length > 0">
          <label>
            {{ 'NewCase.Address2' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.address2 }}
          </span>
        </div>
        <div *ngIf="newCase.address3?.length > 0">
          <label>
            {{ 'NewCase.Address3' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.address3 }}
          </span>
        </div>
        <div *ngIf="newCase.phoneNumber?.length > 0">
          <label>
            {{ 'NewCase.PhoneNumber' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.phoneNumber }}
          </span>
        </div>
        <div *ngIf="newCase.emailAddress?.length > 0">
          <label>
            {{ 'NewCase.EmailAddress' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.emailAddress }}
          </span>
        </div>
      </section>

      <section class="claim-info-summary">
        <label class="title">
          {{ 'NewCase.Step2.Title' | translate }}
        </label>
        <div>
          <label>
            {{ 'NewCase.ClaimDescription' | translate}}:
          </label>
          <span class="property-value">
            {{ newCase.invoicesDescription }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.IsRoadFreight' | translate}}
          </label>
          <span class="property-value">
            {{ (newCase.isRoadFreight ? 'NewCase.Yes' : 'NewCase.No') | translate }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.DebtorHasObjections' | translate }}
          </label>
          <span class="property-value">
            {{ (newCase.debtorHasObjections ? 'NewCase.Yes' : 'NewCase.No') | translate }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.ShouldSendReminder' | translate }}
          </label>
          <span class="property-value">
            {{ (newCase.shouldSendReminder ? 'NewCase.Yes' : 'NewCase.No') | translate }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.ClaimFeesCorrect' | translate }}:
          </label>
          <span class="property-value">
            {{ 'NewCase.No' | translate}}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.DebtorMinor' | translate }}:
          </label>
          <span class="property-value">
            {{ 'NewCase.No' | translate }}
          </span>
        </div>
      </section>
      <section class="invoices-summary">
        <div>
          <label>
            {{ 'NewCase.Invoice.Number' | translate }}
          </label>
          <span *ngFor="let invoice of newCase.invoices" class="property-value">
            {{ invoice.number }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.Invoice.Amount' | translate }}
          </label>
          <span *ngFor="let invoice of newCase.invoices" class="property-value">
            {{ invoice.amount }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.Invoice.Date' | translate }}
          </label>
          <span *ngFor="let invoice of newCase.invoices" class="property-value">
            {{ invoice.date | date: locale.datePipeFormat }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.Invoice.DueDate' | translate }}
          </label>
          <span *ngFor="let invoice of newCase.invoices" class="property-value">
            {{ invoice.dueDate | date: locale.datePipeFormat }}
          </span>
        </div>
        <div>
          <label>
            {{ 'NewCase.Invoice.Currency' | translate }}
          </label>
          <span *ngFor="let invoice of newCase.invoices" class="property-value">
            {{ invoice.currencyCode }}
          </span>
        </div>
        <div>
          <label>&nbsp;</label>
          <span *ngFor="let invoice of newCase.invoices" class="property-value">
            <i class="fas fa-file-alt" [matTooltip]="invoice.invoiceCopyName"
              (click)="onPreviewCopy(invoice.invoiceCopyName)"></i>
          </span>
        </div>
      </section>
      <section>
        <label>{{ 'NewCase.Step3.Confirmation' | translate}}</label>
      </section>

      <div class="buttons-container">
        <button class="button-basic-reverse" type="button" (click)="onNavigateToTab(2)">
          {{ 'NewCase.Previous' | translate }}
        </button>
        <button class="button-basic" type="submit" (click)="onSubmit(form)">
          {{ 'NewCase.CreateCase' | translate }}
        </button>
      </div>
    </section>
  </section>

  <section class="recent-cases">
    <app-recently-created-cases></app-recently-created-cases>
  </section>
</form>

<ng-template #errorsDisplay let-c="controll">
  <div [hidden]="c.valid || (!c.touched && !c.dirty)" class="input-error">
    <span>{{ validator.formatControlErrorMessage(c.errors) }}</span>
  </div>
</ng-template>
