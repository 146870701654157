<div class="page-container">
    <section class="header">
        <section class="title-container">
            <h3 class="title">
                {{ "Contracts.ChangeStatus" | translate }}
            </h3>
            <hr class="separation-line" />
        </section>

        <span class="contracts-selected">{{selectedIds.length}} {{"Contracts.ContractsSelected" | translate}}</span>
    </section>

    <section class="details">

        <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>

        <p-table [value]="selectedClaimTypes" class="customTable" styleClass="p-datatable-sm" [scrollable]="false">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ "Contracts.ClaimType" | translate }}</th>
                    <th>{{ "Contracts.Status" | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-claimType>
                <tr>
                    <td>{{claimType.claimTypeDescription}} </td>
                    <td>
                        <p-dropdown [options]="claimType.statuses" [(ngModel)]="claimType.selectedStatus"
                            appendTo="body" name="status" optionLabel="name" showClear="true" id="statusDropdown"
                            (onChange)="onChangeStatus($event, claimType)" scrollHeight="250px"
                            placeholder="{{'General.Select' | translate}}">
                            <ng-template let-item pTemplate="selectedItem">
                                <span>
                                    {{isEnglish ? item.translationEn : item.translation}}
                                </span>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <span [class]="getClassForWorkListItem(item)">
                                    {{isEnglish ? item.translationEn : item.translation}}
                                </span>
                            </ng-template>
                        </p-dropdown>
                    </td>
                </tr>
                <tr
                    *ngIf="claimType.selectedStatus && (claimType.selectedStatus.profileType || claimType.selectedStatus.statusCode || claimType.selectedStatus.initiateClosingFee == true)">
                    <td colspan="100%">
                        <div class="extra-info">
                            <div *ngIf="claimType.selectedStatus.profileType">{{'Contracts.ChangeStatus_StartsProfile' |
                                translate}} {{claimType.selectedStatus.profileType}},
                                {{claimType.selectedStatus.profileText}}</div>
                            <div *ngIf="claimType.selectedStatus.statusCode">
                                {{'Contracts.ChangeStatus_ChangesStatusOnCases' | translate}}
                                {{claimType.selectedStatus.statusCode}}, {{claimType.selectedStatus.statusText}}</div>
                            <div *ngIf="claimType.selectedStatus.initiateClosingFee == true">
                                {{claimType.closingFeeText}}
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </section>

    <section class="footer">
        <button class="button-basic" (click)="update()">{{'General.Update' | translate}}</button>
        <button class="button-red" (click)="cancel()">{{'General.Cancel' | translate}}</button>
    </section>
</div>
