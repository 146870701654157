<section class="header-container">
  <nav *ngIf="filteredMenu$ | async as filteredMenu" class="menu">
    <a *ngFor="let menuItem of filteredMenu" [routerLink]="menuItem.link" class="menu-item"
      [class.selected]="menuItem.isSelected">
      {{menuItem.translationTag | translate}}
    </a>
  </nav>
</section>

<section class="sub-page-container">
  <router-outlet></router-outlet>
</section>