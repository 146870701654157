import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AdminService } from "../admin.service";
import { CurrentCustomerService } from "src/app/services/current-customer.service";
import { IKreditorUser, WebUserRight } from "src/app/shared/models";
import { finalize, map } from "rxjs/operators";
import { LocalizationService } from "src/app/shared/localization/localization.service";
import { Router } from "@angular/router";
import { AppService } from "src/app/services/app.service";
import { Observable } from "rxjs";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent implements OnInit{
  constructor(private translate : TranslateService,
    private adminService : AdminService,
    private currentCustomerService : CurrentCustomerService,
    private changeDetector : ChangeDetectorRef,
    private localizationService : LocalizationService,
    private router : Router,
    private application : AppService){}

  tableMetadata : any[] = [
    {label:'General.Email',propriety:"email",type:"string"},
    {label:'General.Name',propriety:"name",type:"string"},
    {label:'Admin.UserList.SuperUser',propriety:"isSuperUser",type:"boolAsString", getValue : (val : boolean) => {return (val ? 'General.Yes':'General.No')}},
    {label:'Admin.UserList.LastLoginDate',propriety:"lastLoginDateTime",type:"date"},
    {label:'Admin.UserList.AccountLocked',propriety:"accountLocked",type:"boolAsString", getValue : (val : boolean) => {return (val ? 'Admin.UserList.Blocked':'Admin.UserList.Active')}},
    {label:'Admin.UserList.EditProfile',propriety:"editProfile",type:"button",iconClass:"fas fa-cog", action: (user) => this.redirectToUserPage(user)},
  ]


  totalUsers : number = 0
  isLoading : boolean = false;
  tableRows : number = 10;
  pageNumber : number = 0;
  selectedCustomerNumber : number | null;
  first : number = 0;
  users : IKreditorUser[] = []
  dateFormat$ = this.localizationService.settings$.pipe(map(_ => _.datePipeFormat));



  ngOnInit(): void {
    this.currentCustomerService.selectedNumber$.subscribe(res =>{
      if(this.selectedCustomerNumber != res)
      {
        this.selectedCustomerNumber = res;
        this.onPageChange({rows:this.tableRows,first:0})
      }
    });
  }


  public getTranslatedTotalUsersLabel()
  {
    return this.translate.instant('Admin.UserList.TotalUsersFound',{
      totalNumberOfUsers : this.totalUsers
    })
  }

  public getPageReportTemplate() : string
  {
    return this.translate.instant('General.PageReportTemplate',{
      from : this.first + 1,
      to : this.tableRows * (this.pageNumber - 1) + this.users.length,
      totalCount: this.totalUsers
    })
  }


  onPageChange(event)
  {
    this.isLoading = true;
    this.adminService.getAllUsersHavingCommonCustomers((event.first/event.rows + 1), event.rows, this.selectedCustomerNumber)
    .pipe(finalize(() =>{
      this.isLoading = false;
      this.changeDetector.markForCheck();
    }))
    .subscribe(res => {
        this.pageNumber = res.pageNumber;
        this.users = res.pageItems;
        this.totalUsers = res.totalNumberOfItems
      });

  }

  canAddUser() : Observable<boolean>
  {
    return this.application.userHasRight(WebUserRight.AddUser);
  }

  redirectToCreateUser()
  {
    this.router.navigate(['admin/user']);
  }

  redirectToUserPage(user : IKreditorUser)
  {
    this.router.navigate([`admin/user/${user.username}`]);
  }

}
