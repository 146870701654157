<button class="btn open" (click)="viewAll()"><i class="fas fa-eye"></i></button>

<ol>
  <li></li>
  <ng-container *ngFor="let item of itemsOfIterest; index as i">
    <li (click)="view(item)" [class.clickable]="item.documentIdentifier" [class.disabled]="item.isFutureLetterOrSms"
      [matTooltip]='item.tooltip' [matTooltipClass]="'multiLineTooltip'"
      [matTooltipPosition]="i % 2 == 0 ? 'above' :  'below'"
      [ngClass]="{'icon-sms': item.type == 'SMS' || item.code == 'T-BR-S', 'icon-letter': item.type == 'Letter' || item.code == 'T-BR-P'}">
    </li>
  </ng-container>
  <li></li>
</ol>

<ng-template #itemView let-data>
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="{{'Case.Timeline.Events' | translate}}">
        <ng-container [ngTemplateOutlet]="eventsTable"
          [ngTemplateOutletContext]="{$implicit: data.executed}"></ng-container>
      </mat-tab>
      <mat-tab label="{{'Case.Timeline.FutureEvents' | translate}}">
        <ng-container [ngTemplateOutlet]="eventsTable"
          [ngTemplateOutletContext]="{$implicit: data.notExecuted}"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #eventsTable let-data>
  <table *ngIf="data?.length else noData" id="eventsTable">
    <tbody>
      <tr *ngFor="let item of data">
        <td>{{ item?.date | date:'dd.MM.yyyy'}}</td>
        <td> {{ item?.text}} </td>
        <td> {{ item?.sentBy}} </td>
        <td>
          <ng-container [ngSwitch]="item.type">
            <i *ngSwitchCase="'Letter'" class="fas fa-envelope fa-lg"
            [class.cursor-pointer]="item.documentIdentifier" (click)="view(item)"></i>
            <i *ngSwitchCase="'SMS'" class="fas fa-sms fa-lg"
            [class.cursor-pointer]="item.documentIdentifier" (click)="view(item)"></i>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <ng-template #noData>
    <span class="no-data">
      {{'Case.NoData' | translate}}
    </span>
  </ng-template>

</ng-template>
