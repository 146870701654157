import { KreditorService } from '../kreditor.service';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceResult } from '../models';
import { FirmService } from '../firm/firm.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';

export interface LocalizationSettings {
  language: string;
  calendar: any;
  culture: string;
  currency: string;
  datePipeFormat: string;
  dateTimePipeFormat: string;
  numberPipeFormat: LocalizedNumberFormat;
  integerPipeFormat: LocalizedNumberFormat;
  userTimezoneOffset: number;
  currencyPipeFormat: LocalizedNumberFormat;
}

export interface LocalizedNumberFormat {
  style: string;
  culture: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  readonly supportedLanguages: any[];

  constructor (
    private readonly kreditorService: KreditorService,
    private firmService: FirmService,
    private injector: Injector,
    private primeNGconfig: PrimeNGConfig
  ) {
    this.supportedLanguages = environment.supportedLanguages;
  }
  private currentLanguage = new BehaviorSubject('en');

  currentLanguage$: Observable<string> = this.currentLanguage.asObservable();

  get settings$(): Observable<LocalizationSettings> {
    return this.currentLanguage$.pipe(distinctUntilChanged(), map(lang => {
      const language = lang.toLocaleLowerCase();
      return {
        language: language,
        calendar: this.getCalendarLocalization(language),
        culture: this.getCulture(language),
        currency: this.getCurrency(language),
        datePipeFormat: this.getDatePipeFormat(language),
        dateTimePipeFormat: this.getDateTimePipeFormat(language),
        numberPipeFormat: this.getNumberPipeFormat(language),
        integerPipeFormat: this.getIntegerPipeFormat(language),
        currencyPipeFormat: this.getCurrencyPipeFormat(language),
        userTimezoneOffset: (new Date().getTimezoneOffset()) * 60000
      } as LocalizationSettings;
    }));
  }

  setupLanguage() : Observable<any>{
      const supported = this.getSupportedLanguages();
      const translate = this.injector.get(TranslateService);
      translate.addLangs(supported);

      var sessionLanguage = localStorage.getItem("language");

      if(!sessionLanguage){
        sessionLanguage = this.getFirmLanguage();
        localStorage.setItem("language", sessionLanguage);
      }

      translate.setDefaultLang(sessionLanguage);
      this.primeNGconfig.setTranslation(this.getCalendarLocalization(sessionLanguage));
      this.currentLanguage.next(sessionLanguage);

      return translate.use(sessionLanguage);
  }

  changeLanguage(code: string): Observable<any> {
    const translateService = this.injector.get(TranslateService);

    this.primeNGconfig.setTranslation(this.getCalendarLocalization(code));

    localStorage.setItem("language", code);

    this.currentLanguage.next(code);

    return translateService.use(code);
  }

  getLocalization(language: string): Observable<ServiceResult<JSON>> {
    const apiresult = this.kreditorService.getAnonymousServiceResult<JSON>(`localization/${language}`);
    return apiresult;
  }

  getFirmLanguage(): string {
    return this.firmService.language.toLocaleLowerCase();
  }

  getFirmCurrencyCode(): string {
    return this.firmService.currency;
  }

  getSupportedLanguages() {
    return this.supportedLanguages
      .filter(item => item.code === 'en' || item.code === this.getFirmLanguage());
  }

  getCalendarLocalization(language: string): any {
    const no = {
      firstDayOfWeek: 1,
      dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      dayNamesShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
      dayNamesMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
      monthNames: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
      monthNamesShort: ['Jan', 'Febr', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      today: 'I dag',
      clear: 'Klar',
      dateFormat: 'dd.mm.yy',
      dateFormatPlaceholder: 'dd.mm.åååå',
      weekHeader: 'Uke'
    };

    const dk = {
      firstDayOfWeek: 1,
      dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August',
        'September', 'Oktober', 'November', 'December'],
      monthNamesShort: ['Jan', 'Febr', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      today: 'I dag',
      clear: 'Klar',
      dateFormat: 'dd.mm.yy',
      dateFormatPlaceholder: 'dd.mm.åååå',
      weekHeader: 'Uge'
    };

    const en = {
      firstDayOfWeek: 0,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'dd.mm.yy',
      dateFormatPlaceholder: 'dd.mm.yyyy',
      weekHeader: 'Wk'
    };

    switch (language) {
      case 'no': return no;
      case 'dk': return dk;
      default: return en;
    }
  }

  private getCulture(language: string): string {
    switch (language) {
      case 'no': return 'nb-NO';
      case 'dk': return 'da-DK';
      default: return 'en-US';
    }
  }

  private getDatePipeFormat(language: string): string {
    switch (language) {
      case 'no': return 'dd.MM.yyyy';
      case 'dk': return 'dd.MM.yyyy';
      default: return 'dd.MM.yyyy';
    }
  }

  private getDateTimePipeFormat(language: string): string {
    switch (language) {
      case 'no': return 'dd.MM.yyyy HH:mm:ss';
      case 'dk': return 'dd.MM.yyyy HH:mm:ss';
      default: return 'dd.MM.yyyy HH:mm:ss';
    }
  }

  private getNumberPipeFormat(language: string) {
    return {
      style: '1.2-2',
      culture: this.getCulture(language)
    } as LocalizedNumberFormat;
  }

  private getIntegerPipeFormat(language: string) {
    return {
      style: '1.0-0',
      culture: this.getCulture(language)
    } as LocalizedNumberFormat;
  }

  private getCurrencyPipeFormat(language: string) {
    return {
      style: '1.0-0',
      culture: this.getCulture(language)
    } as LocalizedNumberFormat;
  }

  private getCurrency(language: string) {
    switch (language) {
      case 'no': return 'kr';
      case 'dk': return 'kr';
      default: return 'kr';
    }
  }
}
