import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription, map, takeUntil } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { IToolbarItemLink, WebUserRight } from 'src/app/shared/models';

@Component({
  selector: 'app-cases-root',
  templateUrl: './cases-root.component.html',
  styleUrls: ['./cases-root.component.scss']
})
export class CasesRootComponent implements OnInit, OnDestroy {

  userPermissions$ = this.application.getPermissions();
  filteredMenu$: Observable<IToolbarItemLink[]>;
  private MENU = [
    { translationTag: 'MainMenu.Cases', link: '/cases/lists', name: 'lists' } as IToolbarItemLink,
    { translationTag: 'CasesMenu.LastAccessedCases', link: '/cases/lastAccessed', name: 'lastAccessed' } as IToolbarItemLink,
    { translationTag: 'CasesMenu.ActiveCases', link: '/reports/activeCases', name: 'activeCases' } as IToolbarItemLink,
    { translationTag: 'CasesMenu.ContractAdministration', link: 'cases/contracts', name: 'contracts' } as IToolbarItemLink,
    { translationTag: 'CasesMenu.NewCase', link: '/cases/new', name: 'new', guard: { userRight: WebUserRight.CreateCase } } as IToolbarItemLink,
    { translationTag: 'CasesMenu.ToDebtCollection', link: '/cases/todebtcollection', name: 'todebtcollection', guard: { userRight: WebUserRight.ToDebtCollectionList } } as IToolbarItemLink,
  ];
  private readonly unsubscribe$ = new Subject();

  constructor(
    private application: AppService,
    private router: Router
  ) { }
  
  ngOnInit(): void {
    this.filterMenuItems();

    this.router.events
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(() => {
      const selectedPath = (this.router.url.substring(this.router.url.lastIndexOf('/') + 1)).split('?')[0];
      this.filteredMenu$ = this.filteredMenu$.pipe(map((_) => _.map(x => {
        x.isSelected = x.name == selectedPath;
        return x;
      })));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private filterMenuItems() {
    this.filteredMenu$ = this.userPermissions$.pipe(map(userPermissions => {
      const userRights = userPermissions?.rights ?? [];
      
      return this.MENU.filter(item =>
        (item.guard?.userRight == null ||
          userRights.indexOf(item.guard?.userRight) >= 0) &&
        (item?.guard?.userRole == null ||
          item?.guard?.userRole == userPermissions?.role));
    }));
  }
}
