<div class="engines">
  <div *ngFor="let engine of searchEngines">
    <div class="engines-item" [class.selected]="engine.isSelected" [class.disabled]="engine.isDisabled"
      (click)="selectEngine(engine)">
      {{ engine.name }}
    </div>
  </div>
</div>
<form class="form" #searchform="ngForm">
  <input type="text" class="search-input" name="searchTerm" placeholder="{{placeholder$ | async | translate}} "
    [(ngModel)]="searchTerm" (keyup.enter)="search()">
</form>
