import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { IRouteData, UserRole, WebUserRight } from '../shared/models';
import { CasePageComponent } from './case/case-page.component';
import { CasesComponent } from './cases.component';
import { NewCaseComponent } from './new-case/new-case.component';
import { CasesRootComponent } from './cases-root/cases-root.component';
import { ToDebtCollectionComponent } from './to-debt-collection/to-debt-collection.component';
import { LastAccessedCasesComponent } from './last-accessed-cases/last-accessed-cases.component';

const protectedBy = (right: WebUserRight = null, role: UserRole = null) => { return { userRight: right, userRole: role } as IRouteData; }

const routes: Routes = [
  {
    path: '', component: CasesRootComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: 'cases/contracts', loadChildren: () => import('../contracts/contracts.module').then(m => m.ContractsModule) },
      { path: 'cases/new', component: NewCaseComponent, canActivate: [AuthGuard], data: protectedBy(WebUserRight.CreateCase) },
      { path: 'cases/lists', component: CasesComponent, canActivate: [AuthGuard] },
      { path: 'cases/lastAccessed', component: LastAccessedCasesComponent, canActivate: [AuthGuard] },
      { path: 'cases/todebtcollection', component: ToDebtCollectionComponent, canActivate: [AuthGuard], data: protectedBy(WebUserRight.ToDebtCollectionList)}

    ]
  },
  { path: 'cases/:caseNumber', component: CasePageComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CasesRoutingModule { }
