<section class="title-container">
  <h2 class="title">
    {{ 'Faq.Title' | translate }}
  </h2>
  <hr class="separation-line" />
</section>
<section>
  <mat-accordion *ngIf="data$ | async as data" [multi]="true" #accordion="matAccordion">
    <mat-expansion-panel class="panel-expansion" [disabled]="panel.disabled" *ngFor="let item of data$ | async" #panel
      ngClass="{expanded: !isExpanded }">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ isEnglish ? item.questionEN : item.question}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      {{ isEnglish ? item.answerEN : item.answer}}
    </mat-expansion-panel>
  </mat-accordion>
</section>

