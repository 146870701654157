import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AppConfigurationModel } from '../app-configuration-model';
import { LocalizationService } from './localization/localization.service';
import { IAuthResult, IUserCredentials, ServiceResult } from './models';

@Injectable({
  providedIn: 'root'
})
export class KreditorService {
  private appConfigData: AppConfigurationModel;

  constructor (
    private http: HttpClient,
    appConfig: AppConfigService,
    private injector: Injector,
  ) {
    this.appConfigData = appConfig.getConfig();
  }

  get<T>(resourceUri: string): Observable<T> {

    return this.http.get<T>(this.appConfigData.kreditorServiceUrl + resourceUri, { headers: this.getHeaders() });
  }

  getServiceResult<T>(resourceUri: string): Observable<ServiceResult<T>> {

    return this.http.get<ServiceResult<T>>(this.appConfigData.kreditorServiceUrl + resourceUri, { headers: this.getHeaders() });
  }

  getAnonymousServiceResult<T>(resourceUri: string): Observable<ServiceResult<T>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Language': this.currentLanguage()
    });
    return this.http.get<ServiceResult<T>>(this.appConfigData.kreditorServiceUrl + resourceUri, { headers: headers });
  }

  post<TResult, TData>(resourceUri: string, data: TData): Observable<TResult> {

    return this.http.post<TResult>(this.appConfigData.kreditorServiceUrl + resourceUri, data, { headers: this.getHeaders() });
  }

  postServiceResult<T, S>(resourceUri: string, data: S): Observable<ServiceResult<T>> {

    return this.http.post<ServiceResult<T>>(this.appConfigData.kreditorServiceUrl + resourceUri, data, { headers: this.getHeaders() });
  }

  postAnonymousServiceResult<TResult, TData>(resourceUri: string, data: TData): Observable<ServiceResult<TResult>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Language': this.currentLanguage()
    });
    return this.http.post<ServiceResult<TResult>>(this.appConfigData.kreditorServiceUrl + resourceUri, data, { headers: headers })
  }

  delete<T>(resourceUri: string): Observable<T> {

    return this.http.delete<T>(this.appConfigData.kreditorServiceUrl + resourceUri, { headers: this.getHeaders() });
  }

  deleteServiceResult<T>(resourceUri: string): Observable<ServiceResult<T>> {

    return this.http.delete<ServiceResult<T>>(this.appConfigData.kreditorServiceUrl + resourceUri, { headers: this.getHeaders() });
  }

  postFormData<T>(resourceUri: string, data: FormData): Observable<T> {

    const headers = new HttpHeaders({ 'Language': this.currentLanguage() });

    return this.http.post<T>(this.appConfigData.kreditorServiceUrl + resourceUri, data, { headers });
  }

  getFile(resourceUri: string): Observable<Blob> {

    const headers = new HttpHeaders({ 'Language': this.currentLanguage() });

    const response = this.http.get(this.appConfigData.kreditorServiceUrl + resourceUri, { responseType: 'blob', 'headers': headers });

    return response;
  }

  postForFile<TData>(resourceUri: string, data: TData): Observable<Blob> {

    const headers = new HttpHeaders({ 'Language': this.currentLanguage() });

    const response = this.http.post(this.appConfigData.kreditorServiceUrl + resourceUri, data, { responseType: 'blob', 'headers': headers });

    return response;
  }

  getSsoLoginToken(guid: string): Observable<IAuthResult> {

    return this.http.get<IAuthResult>(this.appConfigData.kreditorServiceUrl + `auth/ssoToken/${guid}`)
      .pipe(map((result: IAuthResult) => result));
  }

  getTokenAtLogin(userCredentials: IUserCredentials): Observable<IAuthResult> {
    return this.http.post<IAuthResult>(this.appConfigData.kreditorServiceUrl + 'auth/token', userCredentials)
      .pipe(
        map((result: IAuthResult) => result)
      );
  }

  getRefreshedToken(token: string): Observable<IAuthResult> {
    const httpOptions = {
      headers: this.getHeaders()
    };

    return this.http.get<IAuthResult>(this.appConfigData.kreditorServiceUrl + 'auth/token', httpOptions)
      .pipe(map((result: IAuthResult) => result));
  }


  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Language': this.currentLanguage()
      });

    return headers;
  }

  private currentLanguage(): string {
    return this.injector.get(TranslateService)?.currentLang ?? this.appConfigData.languageCode;
  }
}
