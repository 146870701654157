<app-invoice-list [list]="list" [uploadEnabled]="caseNumber" [downloadEnabled]="caseNumber" 
  [hasInternationalCurrency]="hasInternationalCurrency" [firmCurrencyCode]="firmCurrencyCode" 
  [caseCurrencyCode]="caseCurrencyCode" (invoiceCopyRequested)="openInvoiceCopy($event)"
  (invoicesDownloadRequested)="downloadInvoices()" [numberFormat]="numberFormat">
</app-invoice-list>

<button *ngIf="uploadEnabled" class="button-basic" (click)="filesInput.click()">{{'Case.Invoices.AddInvoice' |
  translate}}</button>

<input #filesInput type="file" multiple style="display: none;"
  [accept]="uploadDocumentRestrictions?.allowedFileExtensions" (change)="handleSelectedFiles(filesPopup, $event)">

<ng-template #filesPopup let-data>
  <div mat-dialog-title></div>
  <ng-container *ngIf="data.files$ | async as files">
    <div mat-dialog-content>
      <table class="table">
        <thead>
          <th>{{'Case.Invoice.Number' | translate}}</th>
          <th>{{'Case.Invoice.FileName' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of data.invoices; index as i">
            <td>{{invoice.number}}</td>
            <td>
              <custom-select [options]="fileNames" [(selectedKey)]="invoice.fileIndex">
              </custom-select>
              <i style="margin-left: 1rem;"
                [ngClass]="{ 'fas': true, 'fa-spinner fa-spin': invoice.status == 'busy', 'fa-check success': invoice.status == 'success', 'fa-times error': invoice.status == 'error'}"></i>
              <span class="validation-error">{{files[invoice.fileIndex]?.errorMessage}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <mat-dialog-actions>
      <div class="buttons-container">
        <button class="button-basic-reverse" mat-raised-button mat-dialog-close>
          {{'General.Cancel' | translate}}
        </button>
        <button class="button-basic" (click)="upload(data.invoices, files)" mat-raised-button>
          {{'General.Save' | translate}}
        </button>
      </div>
    </mat-dialog-actions>
  </ng-container>
</ng-template>
