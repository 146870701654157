import { KeyValue } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NewCaseService } from "src/app/cases/new-case/new-case.service";
import { GlobalSpinnerService } from "src/app/shared/global-spinner/global-spinner.service";
import { IAddress, IBankAccount, ICustomerAddress, ICustomerBankAccount, ICustomerSummary, IPostCodeSummary, IEntitySearchRequest, IEntitySearchResult, ISubCustomerRequest, EntityType } from "src/app/shared/models";
import { debounceTime, finalize } from "rxjs/operators";
import { ReplaySubject, Subscription, forkJoin } from "rxjs";
import { CustomersService } from "src/app/services/customers.service";
import { SettingsService } from "src/app/services/settings.service";
import { FirmService } from "src/app/shared/firm/firm.service";
import { AbstractControl, NgForm } from "@angular/forms";
import { ValidatorService } from "src/app/shared/validator.service";
import { MessageToUiService } from "src/app/services/message-to-ui.service";
import { InputWithSpinnerComponent } from "src/app/shared/input-with-spinner/input-with-spinner.component";
import { EntitySearchService } from "src/app/services/entity-search.service";
import { PostService } from "src/app/services/post.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-add-sub-customer',
  templateUrl: "./add-sub-customer.component.html",
  styleUrls: ["./add-sub-customer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSubCustomerComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private globalSpinner: GlobalSpinnerService
    , private changeDetectorRef: ChangeDetectorRef
    , private newCaseService: NewCaseService
    , private customersService: CustomersService
    , private settingsService: SettingsService
    , private firmsService: FirmService
    , private validator: ValidatorService
    , private messageToUiService: MessageToUiService
    , private entitySearchService: EntitySearchService
    , private postService: PostService
    , private translate: TranslateService) {
  }

  @ViewChildren('inputWithSpinner') inputSpinners: QueryList<InputWithSpinnerComponent>;
  @ViewChildren('form') public forms !: QueryList<NgForm>;

  loadingOrganizationInfo: boolean = false;
  loadingPostPlace: boolean = false;

  spinnerDiameter: number = 30;

  customersOptions: KeyValue<number, string>[] = [];
  customers: ICustomerSummary[] = []
  selectedCustomer: number;

  countries: KeyValue<string, string>[] = [];
  selectedCountry: string = null;

  orgNumb;

  subCustomerRequest: ISubCustomerRequest = {} as ISubCustomerRequest;
  organizationInfo: IEntitySearchResult = {} as IEntitySearchResult;
  post: IPostCodeSummary = {} as IPostCodeSummary;
  accountNumber: string = "";

  vatOptions: KeyValue<string, string>[] = [
    { key: 'Yes', value: 'General.Yes' },
    { key: 'No', value: 'General.No' },
  ]
  selectedVatOption: string = this.vatOptions[0].key;
  validSsnLength: number;

  searchPostSubject$: ReplaySubject<any> = new ReplaySubject<any>(1);
  validationSubject$: ReplaySubject<any> = new ReplaySubject<any>(1);
  dataSubscription$: Subscription;
  formsSubscription$: Subscription;
  translationChangeSubscription$: Subscription;

  defaultCurrency: string;
  requiredFields = ['nameStandard', 'organizationNumber', 'Addresses[0].Address.Line1', 'bankAccounts[0].BankAccount.AccountNumber', 'contactPersonName', 'email', 'addresses[0].Address.PostCode', 'postPlace'];
  termsOfAgreementAccepted: boolean = false;
  requestInProgress: boolean = false;

  ngOnInit(): void {
    this.searchPostSubject$.pipe(debounceTime(1200)).subscribe(() => { this.searchPostPlace(); });
    this.validationSubject$.pipe(debounceTime(500)).subscribe(() => { this.validateForm(); });
    this.translationChangeSubscription$ = this.translate.onLangChange.subscribe(_ => {
      this.checkChangesChildren();
    })

    this.dataLoad();
  }


  ngOnDestroy(): void {
    this.searchPostSubject$?.complete();
    this.searchPostSubject$?.unsubscribe();

    this.validationSubject$?.complete();
    this.validationSubject$?.unsubscribe();

    this.formsSubscription$.unsubscribe();
    this.translationChangeSubscription$.unsubscribe();

    this.dataSubscription$?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.formsSubscription$ = this.forms.first.valueChanges.subscribe(() => {
      this.validationSubject$.next(null)
    })

  }


  initDefaultValues() {
    this.selectedVatOption = this.vatOptions[0].key;
    this.loadingOrganizationInfo = false;

    this.customersOptions = [];
    this.customers = []
    this.selectedCustomer = null;

    this.countries = [];
    this.selectedCountry = null;

    this.orgNumb = null;

    this.subCustomerRequest = {} as ISubCustomerRequest;
    this.organizationInfo = {} as IEntitySearchResult;
    this.post = {} as IPostCodeSummary;
    this.accountNumber = "";

    this.termsOfAgreementAccepted = false;
  }


  dataLoad() {
    this.globalSpinner.show();

    this.dataSubscription$?.unsubscribe();

    this.dataSubscription$ = forkJoin([this.customersService.getCustomers(),
    this.newCaseService.getCountries(),
    this.settingsService.getSettingValueByName('CompanySsnLength'),
    this.firmsService.getFirmCountryCode(),
    this.firmsService.getFirmDefaultCurrencyCode()])
      .pipe(finalize(() => {
        this.globalSpinner.hide();
        this.changeDetectorRef.detectChanges();
      }))
      .subscribe({
        next: ([customers, countries, ssnLength, firmCountry, firmCurrency]) => {
          this.initDefaultValues();

          this.customers = customers;
          this.setCustomerDropdown();
         

          this.countries = countries.map(country => {
            return { key: country.code, value: country.name } as KeyValue<string, string>;
          })

          this.selectedCountry = firmCountry;
          this.validSsnLength = parseInt(ssnLength);
          this.defaultCurrency = firmCurrency;

          this.globalSpinner.hide();
          this.changeDetectorRef.detectChanges();
        },
        error: (error) => { this.validator.handleError(this.forms?.first, error); }
      });
  }

  postNumberChange() {
    this.searchPostSubject$.next(null);
  }

  searchPostPlace() {
    if (!this.organizationInfo.postCode) {
      this.post = {} as IPostCodeSummary
      return;
    }

    this.loadingPostPlace = true;
    this.changeDetectorRef.detectChanges();
    this.postService.getPostCodeSummary(this.organizationInfo.postCode, this.selectedCountry)
      .pipe(finalize(() => {
        this.loadingPostPlace = false;
        this.changeDetectorRef.detectChanges()
      }))
      .subscribe({
        next: (postSummary) => {
          this.post = postSummary;
        },
        error: (error) => {
          if (error.status == 404) {
            this.messageToUiService.error(this.translate.instant('Errors.Generic.Message')
              , this.translate.instant('Errors.PostCodeDoesNotExistInGivenCountry'))
          }
          else {
            this.validator.handleError(this.forms?.first, error);
          }
        }
      })
  }

  searchOrganization() {
    if (this.orgNumb?.length == this.validSsnLength) {
      this.loadingOrganizationInfo = true;
      this.changeDetectorRef.markForCheck()

      this.entitySearchService.getEntitySearchResult({
        socialNumber: this.orgNumb, //990293171
      } as IEntitySearchRequest)
        .pipe(finalize(() => {
          this.loadingOrganizationInfo = false;
          this.changeDetectorRef.markForCheck()
        }))
        .subscribe({
          next: (orgInfo) => {
            if (orgInfo.name == null || orgInfo.name.length == 0 || orgInfo.type == EntityType.Invalid) {
              this.messageToUiService.warning(this.translate.instant('AddSubcustomer.NoOrganizationFoundWarningTitle')
                , this.translate.instant('AddSubcustomer.NoOrganizationFoundWarningText'), false);
            }
            else {
              this.organizationInfo = orgInfo;
              this.postNumberChange();
              this.selectedCountry = orgInfo.countryCode ? orgInfo.countryCode : this.selectedCountry;
            }
          }
          , error: (error) => {
            this.validator.handleError(this.forms?.first, error);
          }
        });
    }
    else {
      this.searchPostPlace();
    }
  }

  createBankAccountsForCustomer() {
    this.subCustomerRequest.bankAccounts = [
      { bankAccount: { accountNumber: this.accountNumber, currency: this.defaultCurrency } as IBankAccount, type: 'Capital' } as ICustomerBankAccount,
      { bankAccount: { accountNumber: this.accountNumber, currency: this.defaultCurrency } as IBankAccount, type: 'Interest' } as ICustomerBankAccount,
    ]
  }

  createAddressesForCustomer() {
    this.subCustomerRequest.addresses = [
      {
        address: {
          countryCode: this.selectedCountry
          , line1: this.organizationInfo.address
          , line2: this.organizationInfo.addressLine2
          , postCode: this.organizationInfo.postCode
        } as IAddress
        , addressType: 'Billing'
      } as ICustomerAddress,
      {
        address: {
          countryCode: this.selectedCountry
          , line1: this.organizationInfo.address
          , line2: this.organizationInfo.addressLine2
          , postCode: this.organizationInfo.postCode
        } as IAddress
        , addressType: 'Standard'
      } as ICustomerAddress
    ]
  }

  formatSubCustomerRequest() {
    this.subCustomerRequest.email = this.subCustomerRequest.email?.trim();
    this.organizationInfo.postCode = this.organizationInfo.postCode?.trim();
    this.organizationInfo.socialNumber = this.organizationInfo.socialNumber?.trim();

    this.createBankAccountsForCustomer();
    this.createAddressesForCustomer();

    this.subCustomerRequest.isVatExcepted = this.selectedVatOption == 'No';
    this.subCustomerRequest.agreementDocument = null;
    this.subCustomerRequest.nameBilling = this.organizationInfo?.name;
    this.subCustomerRequest.nameStandard = this.organizationInfo?.name;
    this.subCustomerRequest.organizationNumber = this.organizationInfo.socialNumber ? this.organizationInfo.socialNumber : this.orgNumb;
  }

  checkChangesChildren() {
    this.inputSpinners.forEach(spinner => {
      spinner.checkForChanges();
    })
  }

  validateForm() {
    if (this.loadingOrganizationInfo || this.loadingPostPlace) return;

    for (let i = 0; i < this.requiredFields.length; i++) {
      let control = this.forms?.first?.form?.controls[this.requiredFields[i]] as AbstractControl;

      if (!control)
        return

      if (!control.value) {
        control.setErrors({ errorCode: "IsRequired" })
      }
    }
    this.checkChangesChildren();
    this.changeDetectorRef.detectChanges();

  }

  fieldChanges() {
    this.validationSubject$.next(null);
  }

  addSubCustomer() {
    this.requestInProgress = true;
    this.formatSubCustomerRequest()

    this.globalSpinner.show();
    this.customersService.addSubCustomer(this.selectedCustomer, this.subCustomerRequest)
      .pipe(finalize(() => {
        this.globalSpinner.hide();
        this.requestInProgress = false;
        this.checkChangesChildren();
        this.changeDetectorRef.detectChanges();
        this.forms?.first?.form.markAsPristine();
      }))
      .subscribe({
        next: (result) => {
          if (result.isSuccess) {
            this.messageToUiService.success('Success', this.translate.instant("AddSubcustomer.SubCustomerCreateSuccess", {
              subCustomerNumber: result.subCustomerNumber
            }), true);

            this.dataLoad();
          }
          else
            this.messageToUiService.error(this.translate.instant('Errors.Generic.Message'), result.message);//to use translation
        },
        error: (error) => {
          this.validator.handleError(this.forms?.first, error);
        }
      });
  }

  canSave(): boolean {
    return this.forms?.first?.valid && this.termsOfAgreementAccepted && this.selectedCustomer != null && !this.requestInProgress;
  }

  private setCustomerDropdown() {
    if (this.customers.length > 1) {
      this.customersOptions = this.customers.filter(customer => customer.isParent)
      .map(parentCustomer => {
        return { key: parentCustomer.number, value: parentCustomer.number + " - " + parentCustomer.name } as KeyValue<number, string>
      });
    }

    this.selectedCustomer = this.customersOptions[0]?.key;
  }

}
