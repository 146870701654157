import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AdminService } from "./admin.service";
import { map } from "rxjs/operators";



@Injectable({
    providedIn: 'root',
})
export class UserGuard 
{
    constructor (
        private adminService : AdminService,
        private router : Router) { }
    
      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.adminService.isAllowedToViewOrEditUser(route.params['userCode']).pipe(map(result =>{
            if(result)
                return true;
            
            this.router.navigate(['dashboard'])
            return false;

        }));
      }
}
