import { NgModule } from "@angular/core";
import { UserProfileComponent } from "./profile/user-profile.component";
import { SharedModule } from "../shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { UserRoutingModule } from "./user-routing.module";
import { BaseUserInfoComponent } from "../admin/add-edit-user/base-user-info/base-user-info.component";

@NgModule({
    declarations: [
      UserProfileComponent
    ],
    imports: [
      TranslateModule,
      SharedModule
    ],
    exports: [
        UserRoutingModule
    ]
  })
  export class UserModule { }
  