import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { IClaimStatusStatistics, IContractCase, IContractClaimStatusFee, IContractClaimTypeSummary, IContractClosingType, IContractDebtorInfo, IContractExecutedAction, IContractNote, IContractNoteEdit, IContractSummary, IExternalContractStatus, IFilterContracts, IUpdateContractClosingTypeRequest, IUpdatedContractsMultipleStatuses } from './models';
import { UtilsService } from '../shared/utils.service';
import { AdminService } from '../admin/admin.service';
import { IAppUser } from '../shared/models';
import { LoginService } from '../login/login.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  constructor(private kreditorWs: KreditorService,
    private utils: UtilsService,
    private adminService: AdminService,
    private loginService: LoginService,
    private translate: TranslateService) { }

  get user$(): Observable<IAppUser> {
    return this.loginService.currentUser$;
  }

  getClaimTypesWithStatuses(catchException: boolean = false): Observable<IContractClaimTypeSummary[]> {
    return this.kreditorWs.getServiceResult<IContractClaimTypeSummary[]>(`contracts/claimtypes/statuses`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getContracts(filters: IFilterContracts, catchException: boolean = false): Observable<IContractSummary[]> {
    var url = this.utils.getUrlFromObject(`contracts`, filters);
    return this.kreditorWs.getServiceResult<IContractSummary[]>(url)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  exportExcel(filters: IFilterContracts, language: string): Observable<Blob> {
    var url = this.utils.getUrlFromObject(`contracts/exportExcel/${language}`, filters);
    return this.kreditorWs.getFile(url);
  }

  getSelectedClaimTypesForUser(): Observable<IContractClaimTypeSummary[]> {
    return this.user$.pipe(switchMap(user => {
      if (user?.isAuthenticated != true) return [];
      return forkJoin([this.adminService.getUser(user.code), this.getClaimTypesWithStatuses()])
        .pipe(map(([user, claimTypesWithStatuses]) => {
          if (user.userClaimTypes && user.userClaimTypes.length > 0) {
            var selectedClaimTypes = claimTypesWithStatuses.filter(x => user.userClaimTypes.findIndex(y => y.claimTypeCode == x.claimTypeCode) != -1);
            var defaultClaimTypeOnUser = user.userClaimTypes.find(p => p.isDefault == true);
            if (defaultClaimTypeOnUser) {
              for (var index in selectedClaimTypes) {
                if (selectedClaimTypes[index].claimTypeCode == defaultClaimTypeOnUser.claimTypeCode) {
                  selectedClaimTypes[index].isDefault = true;
                  break;
                }
              }
            }
            return selectedClaimTypes;
          }
          else return claimTypesWithStatuses;
        }))
    }))
  }

  changeStatus(updatedStatuses: IUpdatedContractsMultipleStatuses[]): Observable<any> { 
    return this.kreditorWs.postServiceResult<any, IUpdatedContractsMultipleStatuses[]>(`contracts/updateStatus`, updatedStatuses).pipe(map(_ => _.result));
  }

  changeClosingType(selectedContractId: number, newContractClosingTypeId: number): Observable<IUpdateContractClosingTypeRequest> {
    let input = { contractIdToUpdate: selectedContractId, contractClosingTypeId: newContractClosingTypeId };
    return this.kreditorWs.postServiceResult<IUpdateContractClosingTypeRequest, IUpdateContractClosingTypeRequest>(`contracts/updateClosingType`, input).pipe(map(_ => _.result));
  }

  getClosingFee(statusId: number, contractIds: number[]): Observable<string> {

    var url = `contracts/claimtypes/statuses/${statusId}/fees`;
    contractIds.forEach(x => url += (url.indexOf('?') > -1 ? '&' : '?') + `contractIds=${x}`);

    return this.kreditorWs.getServiceResult<IContractClaimStatusFee[]>(url)
      .pipe(map(_ => {
        var text = "";
        _.result.forEach(x => {
          if (x && x.fee && x.fee > 0)
            text = `${text} ${this.translate.instant('Contracts.ChangeStatus_ClosingFee', { fee: x.fee, parent: x.parentCustomerNumber })} `;
        });
        return text;
      }));
  }

  getStatistics(claimTypeCode: string, customerNumber?: number): Observable<IClaimStatusStatistics[]> {

    var url = `contracts/claimtypes/${claimTypeCode}/statusStatistics`;
    if (customerNumber) url += `?customerNumber=${customerNumber}`;

    return this.kreditorWs.getServiceResult<IClaimStatusStatistics[]>(url)
      .pipe(map(_ => _.result));
  }

  getCases(contractStatusId: number, catchException: boolean = false): Observable<IContractCase[]> {
    return this.kreditorWs.getServiceResult<IContractCase[]>(`contracts/${contractStatusId}/cases`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getExternalStatus(contractStatusId: number, catchException: boolean = false): Observable<IExternalContractStatus> {
    return this.kreditorWs.getServiceResult<IExternalContractStatus>(`contracts/${contractStatusId}/externalStatus`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  getContractNotes(contractStatusId: number, catchException: boolean = false): Observable<IContractNote[]> {
    return this.kreditorWs.getServiceResult<IContractNote[]>(`contracts/${contractStatusId}/notes`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }

  getContractDebtor(contractStatusId: number, catchException: boolean = false): Observable<IContractDebtorInfo> {
    return this.kreditorWs.getServiceResult<IContractDebtorInfo>(`contracts/${contractStatusId}/debtor`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  addNote(contractStatusId: number, note: IContractNoteEdit): Observable<IContractNote> {
    return this.kreditorWs.postServiceResult<IContractNote, IContractNoteEdit>(`contracts/${contractStatusId}/notes`, note)
    .pipe(map(_ => _.result));
  }

  updateNote(contractStatusId: number, note: IContractNoteEdit): Observable<IContractNote> {
    return this.kreditorWs.postServiceResult<IContractNote, IContractNoteEdit>(`contracts/${contractStatusId}/notes/${note.id}`, note)
    .pipe(map(_ => _.result));
  }

  deleteNote(contractStatusId: number, noteId: number): Observable<number> {
    return this.kreditorWs.deleteServiceResult<number>(`contracts/${contractStatusId}/notes/${noteId}`)
    .pipe(map(_ => _.result));
  }

  getClosingTypes(): Observable<IContractClosingType[]> {
    return this.kreditorWs.getServiceResult<IContractClosingType[]>('contracts/closingTypes')
    .pipe(map(_ => _.result));
  }

  getContractActions(contractStatusId: number, catchException: boolean = false): Observable<IContractExecutedAction[]> {
    return this.kreditorWs.getServiceResult<IContractExecutedAction[]>(`contracts/${contractStatusId}/actions`)
      .pipe(map(_ => _.result))
      .pipe(catchError(e => { if (catchException) { return of([]); } throw e; }));
  }


}
