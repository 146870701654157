import { BehaviorSubject, Observable } from "rxjs";
import { skip, map, tap, switchMap } from "rxjs/operators";

export class SimpleCache<T> {

  private readonly _dataBehaviorSubject: BehaviorSubject<{ timestamp: Date, data: T }>;
  private readonly _data$: Observable<T>;
  private readonly _valabilityInSeconds: number;
  private _loadFunction?(): Observable<T>;

  constructor (input: SimpleCacheInput<T>) {
    this._valabilityInSeconds = input.lifetime;
    this._dataBehaviorSubject = new BehaviorSubject<{ timestamp: Date, data: T }>(undefined as any)
    this._data$ = this._dataBehaviorSubject.asObservable().pipe(
      map(_ => _.data));
    this._loadFunction = input.loadFunction
  }

  public get data$(): Observable<T> {
    let cache = this._dataBehaviorSubject.getValue();

    if (!cache || !cache.data || this.isExpired(cache.timestamp)) {
      return this.refresh().pipe(switchMap(() => this._data$));
    }

    return this._data$;
  }

  private isExpired(cacheTime: Date): boolean {

    let validUntil = new Date(cacheTime).setSeconds(this._valabilityInSeconds);

    return validUntil < new Date().getTime();
  }

  private refresh(): Observable<T> {
    return this._loadFunction()
      .pipe(tap(_ => {
        this._dataBehaviorSubject.next({ timestamp: new Date(), data: _ })
  }));
  }
}

export class SimpleCacheInput<T> {
  lifetime: number;
  loadFunction?(): Observable<T>;
}
