<div>
    <p-table 
        styleClass="p-datatable-sm"
        [loading]="isLoading" 
        [value]="caseList$ | async"
        [rowsPerPageOptions]="[5,10,15,20]" 
        [totalRecords]="totalNumberOfItems"
        [paginator]="true"
        [first]="first"
        [(rows)]="numberOfItemsPerPage"
        paginatorDropdownAppendTo="body"
    >
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="col.name" *ngFor="let col of TABLE_COLUMNS"> 
                {{ col.translationTag | translate }} 
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-case >
        <tr (click)="utils.navigateTo('case', case.caseNumber, true)" class="clickable">
            <td *ngFor="let col of TABLE_COLUMNS">
                <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'datetime'"> 
                        {{ case[col.name] | date: dateTimeFormat }} 
                    </ng-container>
                    <ng-container *ngSwitchCase="'decimal'"> 
                        {{ case[col.name] | number : numberFormat.style: numberFormat.culture }} 
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{case[col.name]}}
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
          <tr *ngFor="let emptyRow of fillToPageSize()">
            <td *ngFor="let col of TABLE_COLUMNS">
              <i></i>
            </td>
          </tr>
      </ng-template>
</p-table>
</div>



      