import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonDateParse',
  standalone: true
})
export class JsonDateParsePipe implements PipeTransform {

  transform(value: any): Date {

    if (value == null) return null;

    if (typeof value == "string") {
      return new Date(value.toString());
    }

    return value;
  }
}
