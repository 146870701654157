import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { IDebtorStats, IRelatedCase, ServiceResult } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class DebtorsService {

  constructor (
    private kreditorWs: KreditorService
  ) { }

  getStatistics(debtorId: number, activeCasesOnly : boolean): Observable<IDebtorStats> {
    return this.kreditorWs.getServiceResult<IDebtorStats>(`debtors/${debtorId}/stats?activeCasesOnly=${activeCasesOnly}`)
      .pipe(map(_ => _.result))
  }

  getScore(debtorId: number): Observable<IDebtorCreditScore> {
    return this.kreditorWs.getServiceResult<IDebtorCreditScore>(`debtors/${debtorId}/score`)
      .pipe(map(_ => _.result))
  }

  sendPaymentLink(request: ISendPaymentLinkRequest): Observable<{ url: string }> {
    return this.kreditorWs.post<ServiceResult<string>, ISendPaymentLinkRequest>(`debtors/${request.debtorId}/paymentlinks`, request)
      .pipe(map(_ => { return { url: _.result } }))
  }
}

export type IDebtorScoreColor = 'Undefined' | 'Red' | 'Yellow' | 'Green';


export interface IDebtorCreditScore {
  color: IDebtorScoreColor;
}

export interface ISendPaymentLinkRequest {
  debtorId: number;
  caseNumbers: Array<number>;
  sendType: 'Sms' | 'Email';
  recipient: string;
  text: string;
  contractReference: string;
}

