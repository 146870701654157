import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { IContactPerson, IDashboardNotifications, IWebNewsModel } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private kreditorService: KreditorService) { }

  getDashboardNotifications(customerNumber: number = null): Observable<IDashboardNotifications> {
    const url = 'dashboard/notifications' + (customerNumber ? `?customerNumber=${customerNumber}` : '');
    return this.kreditorService.getServiceResult<IDashboardNotifications>(url).pipe(map(_ => _.result));
  }

  getContactPerson(customerNumber: number = null): Observable<IContactPerson> {
    const url = 'dashboard/contactPerson' + (customerNumber ? `?customerNumber=${customerNumber}` : '');
    return this.kreditorService.getServiceResult<IContactPerson>(url).pipe(map(_ => _.result));
  }

  getDashboardNews() : Observable<IWebNewsModel[]> {
    return this.kreditorService.getServiceResult<IWebNewsModel[]>(`dashboard/news`).pipe(map(x => x.result));
  }
}
