import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrentCaseService {

  constructor() { }
  private selectedCaseNumber = new BehaviorSubject<number | null>(null);

  selectedCaseNumber$ = this.selectedCaseNumber.asObservable().pipe(distinctUntilChanged());

  selectCaseNumber(caseNumber: number) {
    this.selectedCaseNumber.next(caseNumber);
  }
}
