import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { IInvoice } from 'src/app/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {MatRippleModule} from '@angular/material/core';
import { LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-invoice-list',
  standalone: true,
  imports: [CommonModule, TranslateModule, TableModule, ClipboardModule, MatRippleModule, MatTooltipModule],
  providers: [],
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceListComponent implements OnChanges {
  @Input() list: IInvoice[];

  @Input() uploadEnabled: boolean;
  @Input() downloadEnabled: boolean;
  @Input() hasInternationalCurrency: boolean;
  @Input() caseCurrencyCode: string;
  @Input() firmCurrencyCode: string;
  @Input() numberFormat: LocalizedNumberFormat;
  @Output() invoiceCopyRequested = new EventEmitter<IInvoice>();
  @Output() invoicesDownloadRequested = new EventEmitter();

  isTableExpanded: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['list']) {
      const maxNumberInvoicesToDisplay = this.list?.length > 8 ? 8 : this.list?.length;
      document.documentElement.style.setProperty('--invoice_lines_number', maxNumberInvoicesToDisplay?.toString());
    }
  }

  requestCopy(invoice: IInvoice) {
    if (this.downloadEnabled) {
      this.invoiceCopyRequested.emit(invoice);
    }
  }

  onToggleExpandTable() {
    this.isTableExpanded = !this.isTableExpanded;
  }

  onDownloadInvoices() {
    this.invoicesDownloadRequested.emit();
  }
}
