export interface IActive2FAModel {
    isActive: boolean;
    type: TwoFactorAuthType;
}

export interface ITwoFactorAuthAppSetupModel {
    appImageUrl: string;
    appManualEntryKey: string;
}

export enum TwoFactorAuthType {
    NotSpecified = 0,
    App = 1,
    Email = 2
}