import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { DebtorsService } from '../../../services/debtors.service';
import { ICaseViewDebtorModel, IRelatedCase } from '../../../shared/models';
import { GlobalSpinnerService } from '../../../shared/global-spinner/global-spinner.service';
import { finalize } from 'rxjs/operators';
import { MessageToUiService } from '../../../services/message-to-ui.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { ValidatorService } from '../../../shared/validator.service';
import { ControlErrorsComponent } from '../../../shared/control-errors/control-errors.component';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-payment-link',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, ControlErrorsComponent, TableModule],
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnChanges, OnInit {

  constructor (private localization: LocalizationService, private service: DebtorsService, private spinner: GlobalSpinnerService, private notification: MessageToUiService
    , private validator: ValidatorService, private translate: TranslateService) { }

  locale$ = this.localization.settings$;

  @Input() displaySend: boolean = true;
  @Input() debtor: ICaseViewDebtorModel;
  @Input() cases: Array<IRelatedCase & { isSelected?: boolean }>;
  @Input() contractReference: string;

  @Output() sent = new EventEmitter();

  vm: { sendType: 'Sms' | 'Email', text: string, email?: string, phone?: string, cases: Array<IRelatedCase & { isSelected?: boolean, isDisabled?: boolean }> }

  public get canSend(): boolean {
    return this.cases?.find(c => c.isSelected) != null;
  }

  @ViewChild('paymentLinkForm') paymentLinkForm: NgForm;

  ngOnInit(): void {
    if (this.vm == null) this.load()
  }

  ngOnChanges(changes: SimpleChanges): void {

    let debtorChange = changes['debtorId'];
    let casesChange = changes['cases'];

    if (debtorChange && debtorChange.currentValue != debtorChange.previousValue) {
      this.load();
    }
    else if (casesChange && casesChange.currentValue != casesChange.previousValue) {
      this.load();
    }
  }

  private load() {
    this.vm = {
      cases: this.cases.map(_ => { return Object.assign({ ..._ }) })
      , email: this.debtor.email
      , phone: this.debtor.phone
      , sendType: 'Email'
      , text: this.translate.instant('PaymentLink.Text')
    }
  }

  submit() {
    this.paymentLinkForm.onSubmit(undefined);
  }

  send(form: NgForm) {
    if (!this.paymentLinkForm.valid) return;

    this.spinner.show();

    this.service.sendPaymentLink({
      sendType: this.vm.sendType
      , caseNumbers: this.vm.cases.filter(_ => _.isSelected).map(_ => _.caseNumber)
      , text: this.vm.text
      , debtorId: this.debtor.id
      , recipient: (this.vm.sendType == 'Sms' ? this.vm.phone : this.vm.email)
      , contractReference: this.contractReference
    })
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(() => { this.sent.emit(); this.notification.successKey() }, e => this.validator.handleError(form, e));
  }
}


