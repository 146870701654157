<div class="case-container">
  <div class="approval-or-closed">
    <app-case-approval *ngIf="(approveLegalRequest$ | async ) && approvals?.complete && approvals?.value?.length > 0"
      [caseNumber]="case?.value?.number" [approvals]="approvals?.value" (change)="refresh()">
    </app-case-approval>
    <span *ngIf="case?.value?.terminationDate" class="closed-case">
      {{ 'Case.Closed' | translate }}&nbsp;
      {{ case.value.terminationDate | date: localizationSettings.value.datePipeFormat }}&nbsp;-&nbsp;
      {{ language.value == 'en' ? case.value.terminationDescriptionEn : case.value.terminationDescription}}
    </span>

    <ng-container *ngIf="case?.value?.status?.code == 5 || case?.value?.status?.code == 8">
      <ng-container *ngIf="case?.value?.paymentPlan as plan">
        <div class="repayment-plan-container">
          <div class="repayment-plan-title">
            {{ 'RepaymentPlan.Title' | translate }}
          </div>
          <div>
            <div>{{ 'RepaymentPlan.NextDueDate' | translate }}: {{ plan.nextDueDate | date }}</div>
            <div>{{ 'General.Amount' | translate }}: {{ plan.nextInstallmentAmount | number }}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>


  <div class="title">
    <label>{{'Case.CaseNumber' | translate}}:</label>
    <span>{{case?.value?.number ?? '‎'}}</span>
  </div>

  <div class="commands">
    <div>
      <button class="button-basic" type="button" *ngIf="canPauseCase?.value"
        (click)="openDialog('PauseCase')">
        {{'CasePause.Pause' | translate}}
      </button>

      <button class="button-basic" type="button" [disabled]="!caseFlag?.value"
        (click)="toggleCaseFlag(case.value.number)">
        {{(caseFlag?.value?.flagDate ? 'Case.UnflagCase' : 'Case.FlagCase') | translate}}
      </button>

      <button class="button-red" type="button" (click)="openDialog('WithdrawCase')" *ngIf="displayWithdrawOption$ | async"
        [disabled]="!case?.value || case.value.terminationDate">
        {{'Case.WithdrawCase' | translate}}
      </button>

      <button class="button-transparent" type="button" (click)="openFromTracker.return(case?.value?.number)"
        *ngIf="openFromTracker.caseOpenLocation$ | async">{{'Case.ReturnToOpennedFrom' | translate}}</button>
    </div>
  </div>

  <div class="debtor">
    <app-case-debtor *ngIf="debtorStats?.complete" [case]="case?.value"
      [debtorStats]="debtorStats?.value"></app-case-debtor>
  </div>

  <div class="group one">
    <section>
      <div class="title-container">
        <label class="title">{{'Case.CollectionProcess.Title' | translate}}</label>
        <hr class="separation-line" />
      </div>
      <app-case-collection-process [case]="case?.value"></app-case-collection-process>
    </section>

    <section class="timeline-section">
      <div class="title-container">
        <label class="title">{{'Case.Timeline' | translate}}</label>
        <hr class="separation-line" />
      </div>
      <app-case-timeline [list]="caseEvents?.value" (documentRequested)="openCaseLetter($event)" (smsRequested)="openSms($event)"></app-case-timeline>
    </section>

    <section>
      <div class="title-container">
        <label class="title" [class.error]="notes?.error">{{'Case.Notes' | translate}}</label>
        <hr class="separation-line" />
      </div>

      <app-case-notes [caseNumber]="case?.value?.number" [list]="notes?.value" [terminationDate]="case?.value?.terminationDate" (caseNotesChanged)="refresh('notes')">
      </app-case-notes>
    </section>
  </div>

  <div class="group two">
    <section class="remittance">
      <span class="multi-action">
        <div class="title-container">
          <label class="title" [class.selected]="!remittanceSelected"
            (click)="remittanceSelected = remittanceSelected ? false : remittanceSelected">
            {{'Case.Requirements' | translate}}
          </label>
          <hr class="separation-line" />
        </div>
        <label class="title" [class.selected]="remittanceSelected"
          (click)="remittanceSelected = remittanceSelected ? true : !remittanceSelected">
          / {{'Case.Remittance' | translate}}
        </label>
      </span>
      <app-case-requirements *ngIf="!remittanceSelected" [case]="case?.value" [firmCurrencyCode]="firmCurrencyCode"
        [hasInternationalCurrency]="hasInternationalCurrency" [numberFormat]="localizationSettings?.value?.numberPipeFormat">
      </app-case-requirements>
      <app-case-remittance *ngIf="remittanceSelected" [caseNumber]="case?.value?.number"></app-case-remittance>
    </section>

    <section class="case-transactions">
      <span class="multi-action">
        <div class="title-container">
          <label class="title" [class.selected]="caseTransactionsTab == 'Invoices'"
            (click)="caseTransactionsTab='Invoices'">
            {{'Case.Invoices' | translate}}
          </label>
          <hr class="separation-line" />
        </div>
        <label class="title" [class.selected]="caseTransactionsTab == 'Payments'"
          (click)="caseTransactionsTab = 'Payments'">
          / {{'Case.Payments.Title' | translate}}
        </label>
        <label class="title" [class.selected]="caseTransactionsTab == 'Securities'"
          (click)="caseTransactionsTab = 'Securities'">
          / {{'Case.Securities.Title' | translate}}
        </label>
      </span>
      <ng-container [ngSwitch]="caseTransactionsTab">
        <app-case-invoices *ngSwitchCase="'Invoices'" [caseNumber]="case?.value?.number" [list]="invoices?.value"
          [uploadDocumentRestrictions]="uploadDocumentRestrictions?.value" [hasInternationalCurrency]="hasInternationalCurrency"
          [firmCurrencyCode]="firmCurrencyCode" [caseCurrencyCode]="case?.value?.currencyCode" (change)="refresh('invoices')"
          [numberFormat]="localizationSettings?.value?.numberPipeFormat">
        </app-case-invoices>
        <app-case-payments *ngSwitchCase="'Payments'" [list]="payments?.value" [language]="language?.value"
          [hasInternationalCurrency]="hasInternationalCurrency" [firmCurrencyCode]="firmCurrencyCode"
          [caseCurrencyCode]="case?.value?.currencyCode" [numberFormat]="localizationSettings?.value?.numberPipeFormat">
        </app-case-payments>
        <app-case-securities *ngSwitchCase="'Securities'" [list]="securities?.value"></app-case-securities>
      </ng-container>
    </section>

    <div class="multi-section">
      <section *ngIf="displayPaymentOption$ | async">
        <span class="multi-action">
          <div class="title-container">
            <label class="title" [class.selected]="!kreditNoteSelected"
              (click)="kreditNoteSelected = kreditNoteSelected ? false : kreditNoteSelected">{{'Case.Payment' |
              translate}}
            </label>
            <hr class="separation-line" />
          </div>
          <label class="title" [class.selected]="kreditNoteSelected"
            (click)="kreditNoteSelected = kreditNoteSelected ? true : !kreditNoteSelected">/ {{'Case.CreditNote' |
            translate}}</label>
        </span>
        <app-case-add-payment [case]="case?.value" [paymentType]="kreditNoteSelected  ? 'KreditNote' : 'Payment'"
          (added)="refresh()"></app-case-add-payment>
      </section>

      <section *ngIf="displayScore$ | async">
        <div class="title-container">
          <label class="title">{{'Case.DebtorCreditScore' | translate}}</label>
          <hr class="separation-line" />
        </div>
        <app-credit-score [debtorId]="case?.value?.debtor.id"></app-credit-score>
      </section>
    </div>
  </div>

</div>

<ng-template #smsModal let-sms>
  <div mat-dialog-title class="sms-title">
    SMS - {{"General.Sent" | translate}} {{sms.message.date | date:'dd.MM.yyyy'}}
  </div>
  <div mat-dialog-content>
    <div class="sms-phone">{{sms.message.receiver}}</div>
    <div class="sms-text">{{sms.content}}</div>
  </div>
</ng-template>
