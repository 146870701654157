
<p-table [value]="data$ | async" styleClass="p-datatable-sm" [scrollable]="true"
  [paginator]="true" [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5, 10, 25, 50]"
  [sortOrder]="-1" sortField="executedDate" [scrollable]="true">
  <ng-template pTemplate="header">
    <tr>
      <!-- TODO: move widths to scss -->
      <th pSortableColumn="executedDate" width="15%">{{ "Contracts.Notes.ExecutedDate" | translate }}
      </th>
      <th pSortableColumn="dueDate" width="15%">{{ "Contracts.Notes.DueDate" | translate }}
      </th>
      <th pSortableColumn="sentByChannel" width="15%">{{ "Contracts.Notes.SentByChannel" | translate }}
      </th>
      <th pSortableColumn="actionDescription" width="55%">{{ "Contracts.Notes.ActionDescription" | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-action>
    <tr *ngIf="format$ | async as format">
      <td>{{action.executedDate | date: format.datePipeFormat}} </td>
      <td>{{action.dueDate | date: format.datePipeFormat}} </td>
      <td>{{action.sentByChannel}} </td>
      <td [matTooltip]="(action.receiver ? action.receiver : action.mobileNumber)" [ngClass]="getClass(action)" (click)="openCaseAction(action)" >
        {{action.actionDescription}}
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #smsModal let-sms>
  <div mat-dialog-title class="sms-title">
    SMS - {{"General.Sent" | translate}} {{sms.message.executedDate | date:'dd.MM.yyyy'}}
  </div>
  <div mat-dialog-content>
    <div class="sms-phone">{{sms.message.receiver}}</div>
    <div class="sms-text">{{sms.content}}</div>
  </div>
</ng-template>
