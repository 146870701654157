<div mat-dialog-title>
  {{'NewCase.InvoicesCopies.Title' | translate}}
</div>
<div mat-dialog-content class="modal-content">

  <app-file-upload #fileInput [acceptedFormats]="fileFormatFilter" (fileToUploadChange)="onNewFiles($event)"
    style="display: none;" [multiFile]="invoiceList?.length" [icon]="true" fileInputId="invoiceCopiesUpload">
  </app-file-upload>

  <div class="invoice-row" *ngFor="let invoice of invoiceList">
    <custom-select [options]="fileNames" [(selectedKey)]="invoice.invoiceCopyName">
    </custom-select>
    <label>{{invoice.number}}</label>
  </div>
</div>
<mat-dialog-actions class="modal-footer">
  <button type="button" class="button-basic-reverse" (click)="fileInput.selectFile()">
    {{'General.FileUpload.UploadFiles' | translate}}
  </button>
  <button type="button" class="button-basic-reverse" (click)="onCancel()">
    {{'General.Cancel' | translate}}
  </button>
  <button type="button" class="button-basic" (click)="onSave()">
    {{'General.Save' | translate}}
  </button>
</mat-dialog-actions>