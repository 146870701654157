import { Component } from '@angular/core';
import { IToolbarItemLink } from '../shared/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  MENU = [
    { translationTag: 'MainMenu.FAQ', link: '/information/faq', name: 'Faq' } as IToolbarItemLink,
  ]

  constructor(private router: Router) {

  }

  isSelectedMenuItem(menuItemName: string) {
    const selectedPath = (this.router.url.substring(this.router.url.lastIndexOf('/') + 1)).split('?')[0];
    return menuItemName == selectedPath.capitalize();
   }

}
