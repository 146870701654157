import { ChangeDetectorRef, Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";

export type RxState<T> = (RxSuspenseState | RxReadyState<T>) &
  RxCompleteState &
  RxErrorState &
{ state?: 'busy' | 'error' | 'success' };

export interface RxCompleteState {
  complete: boolean;
}

export interface RxErrorState {
  error: unknown;
}

export interface RxSuspenseState {
  ready: false;
  value: undefined;
}

export interface RxReadyState<T> {
  ready: true;
  value: T;
}

@Injectable()
export class RxHook implements OnDestroy {
  private readonly subscriptions: Array<Subscription> = [];

  // Get access to the ChangeDetectorRef of the component
  constructor (private cdr: ChangeDetectorRef) { }

  use<T>(observable: Observable<T>): RxState<T> {
    const state: RxState<T> = {
      state: 'busy',
      ready: false,
      value: undefined,
      complete: false,
      error: undefined
    };

    const patchState = (patch: Partial<RxState<T>>) => {
      Object.assign(state, patch);
      this.cdr.detectChanges();
    };

    this.subscriptions.push(
      observable.subscribe({
        next: (value) => patchState({ state: 'busy', ready: true, value }),
        error: (error) => patchState({ state: 'error', error }),
        complete: () => patchState({ state: 'success', complete: true }),
      })
    );

    return state;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe())
  }
}

