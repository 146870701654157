import { IAppGraphModel } from './graph.component';
import { Observable } from 'rxjs';
import { ICustomerMonthlyCaseStatistics } from 'src/app/reports/reports-models';
import { GraphBase } from './GraphBase';
import { ReportsService } from 'src/app/reports/reports.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export class CapitalVolumeGraph extends GraphBase {

  constructor (private reportsService: ReportsService, private translate: TranslateService) {
    super();
  }

  get(customerNumber?: number): Observable<IAppGraphModel> {
    return this.reportsService.getMonthlyCasesData(customerNumber).pipe(map(_ => this.withData(_)));
  }

  withData(stats: ICustomerMonthlyCaseStatistics[]): IAppGraphModel {

    let color1 = this.readStyleProperty('--clr-svea-1');
    let color2 = this.readStyleProperty('--clr-svea-2');

    let graph = { type: 'bar' } as IAppGraphModel;

    graph.data = {
      labels: stats.map(x => this.getShortMonthName(x.month).toLowerCase()),
      legend: {
        position: "top",
        align: 'start'
      },
      datasets: [
        {
          label: this.translate.instant('Graphs.CapitalVolume.ReceivedCapital'),
          backgroundColor: color2,
          data: stats.map(x => x.originalCapital),
        },
        {
          label: this.translate.instant('Graphs.CapitalVolume.PaidCapital'),
          backgroundColor: color1,
          data: stats.map(x => x.paidCapital),
        }
      ]
    };

    graph.options = {
      plugins: {
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      }
    };

    return graph;
  }

}
