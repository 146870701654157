import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { Type } from '@angular/compiler';
import { Observable, of } from 'rxjs';
import { IUploadDocumentRestrictions } from '../shared/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor (private settingsService: SettingsService, private translateService: TranslateService) { }

  readonly uploadRestrictions$ = this.settingsService.getFileUploadRestrictions().pipe(shareReplay(1));


  validateFile(file: File): Observable<IFileWithValidation> {

    return this.uploadRestrictions$.pipe(map(upload => this.validate(file, upload)))
  }

  validateFiles(files: File[]): Observable<IFileWithValidation[]> {

    return this.uploadRestrictions$.pipe(map(upload => files.map(_ => this.validate(_, upload))))
  }

  getFileExtension(fileName: string): string {
    const fileNameSplit = fileName.split('.');

    return fileNameSplit[fileNameSplit.length - 1];
  }

  private validate(file: File, upload: IUploadDocumentRestrictions): IFileWithValidation {

    let result = file as IFileWithValidation;

    const fileSizeMb = file.size / (1024 * 1024);

    if (fileSizeMb > upload.maxSizeMb) {

      result.errorCode = 'Error.AttachmentSizeExceedsMax';
    }

    const fileExtension = this.getFileExtension(file.name);

    if (upload.allowedFileExtensions.indexOf(fileExtension) < 0) {

      result.errorCode = 'Error.AttachmentExtensionNotAllowed';
    }

    if (result.errorCode) {
      result.errorMessage = this.translateService.instant(result.errorCode
        , { allowedFileTypes: upload.allowedFileExtensions.join(', '), maxSizeMb: upload.maxSizeMb });
    }

    result.isValid = !result.errorCode;

    return result;
  }

}

export type IFileWithValidation = File & { isValid: boolean, errorCode?: string, errorMessage?: string }
