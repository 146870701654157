import { Injectable } from '@angular/core';
import { KreditorService } from '../shared/kreditor.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IActive2FAModel, ITwoFactorAuthAppSetupModel } from '../shared/twofactor/twofactor.models';

@Injectable({
  providedIn: 'root'
})
export class TwofactorService {

  constructor(private kreditorWs: KreditorService) { }

  getTwoFactorStatus(): Observable<IActive2FAModel> {
    return this.kreditorWs.getServiceResult<IActive2FAModel>("TwoFactorAuth").pipe(map(_ => _.result));
  }

  getTwoFactorSetupApp(): Observable<ITwoFactorAuthAppSetupModel> {
    return this.kreditorWs.getServiceResult<ITwoFactorAuthAppSetupModel>("TwoFactorAuth/setup/app").pipe(map(_ => _.result));
  }

  getTwoFactorSetupEmail(): Observable<boolean> {
    return this.kreditorWs.getServiceResult<boolean>("TwoFactorAuth/setup/email").pipe(map(_ => _.result));
  }

  finishTwoFactorAuth(code: any, type: any) : Observable<boolean> {
    return this.kreditorWs.postServiceResult<boolean, any>("TwoFactorAuth/setup/complete", { code: code, type: type }).pipe(map(x => x.result)); 
  }
}
