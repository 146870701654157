import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app-config.service';
import { KreditorService } from '../kreditor.service';
import { IClaimSettings } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FirmService {
  
  constructor (private kreditorWs: KreditorService,
    private appConfig: AppConfigService) { }

  get language(): string { return this.appConfig.getConfig().languageCode; }
  get currency(): string { return this.appConfig.getConfig().currencyCode; }

  getClaimSettings(catchException: boolean = false): Observable<IClaimSettings> {
    return this.kreditorWs.getServiceResult<IClaimSettings>('firm/claimSettings')
      .pipe(map(data => data.result))
      .pipe(catchError(e => { if (catchException) { return of(null); } throw e; }));
  }

  getSveaAddress(): Observable<string> {
    return this.kreditorWs.getServiceResult<string>('firm/address')
    .pipe(map(data => data.result))
    .pipe(catchError(e => of(null)));
  }

  getFirmCountryCode(): Observable<string> {
    return this.kreditorWs.getServiceResult<string>('firm/country')
    .pipe(map(data => data.result))
  }

  getFirmDefaultCurrencyCode(): Observable<string> {
    return this.kreditorWs.getServiceResult<string>('firm/currency')
    .pipe(map(data => data.result))
  }

}
