<div class="form-container">
  <form #frm="ngForm" [formGroup]="form" *ngIf="form">
    <div class="inline">
      <fieldset>
        <p-calendar formControlName="date" [dateFormat]="locale.calendar.dateFormat"
         [placeholder]="locale.calendar.dateFormatPlaceholder"></p-calendar>
        <app-control-errors *ngIf="frm.submitted" [control]="form.controls.date"></app-control-errors>
      </fieldset>
      <fieldset>
        <select formControlName="currencyCode">
          <option *ngFor="let item of currencies">{{item}}</option>
        </select>
        <app-control-errors *ngIf="frm.submitted" [control]="form.controls.currencyCode"></app-control-errors>
      </fieldset>
    </div>
    <fieldset>
      <p-inputNumber type="text" formControlName="amount" [locale]="locale.culture" mode="decimal"
        [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0"
        placeholder="{{'Case.AddCreditNotePayment.Amount' | translate}}"></p-inputNumber>
      <app-control-errors *ngIf="frm.submitted" [control]="form.controls.amount"></app-control-errors>
    </fieldset>
    <fieldset>
      <input type="text" class="text-input" formControlName="reference" [required]="paymentType == 'KreditNote'"
        placeholder="{{'Case.AddCreditNotePayment.Reference' | translate}}">
      <app-control-errors *ngIf="frm.submitted" [control]="form.controls.reference"></app-control-errors>
    </fieldset>
    <button type="submit" (click)="save(frm)" class="button-basic save">
      {{'General.Save' | translate}}
    </button>
  </form>
</div>
