import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { KEYS, MessageToUiService, } from 'src/app/services/message-to-ui.service';
import { NewCaseService } from 'src/app/cases/new-case/new-case.service';
import { LocalizationService, LocalizationSettings, } from 'src/app/shared/localization/localization.service';
import { ICustomerSummary, IEntitySearchRequest, IEntitySearchResult, INewCase, INewInvoice } from 'src/app/shared/models';
import { ValidatorService } from 'src/app/shared/validator.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Observable, Subject } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { KeyValue } from '@angular/common';
import { UtilsService } from 'src/app/shared/utils.service';
import { EntitySearchService } from 'src/app/services/entity-search.service';
import { FirmService } from 'src/app/shared/firm/firm.service';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';
import { deepCopy } from 'src/app/shared/utilities';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceCopiesComponent } from './invoice-copies/invoice-copies.component';
import { FileUploadComponent } from 'src/app/shared/file-upload/file-upload.component';

@Component({
  selector: 'app-new-case',
  templateUrl: './new-case.component.html',
  styleUrls: ['./new-case.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)',
  }
})
export class NewCaseComponent implements OnInit, OnDestroy {
  constructor (
    private translate: TranslateService,
    private service: NewCaseService,
    private localization: LocalizationService,
    public validator: ValidatorService,
    private messageToUi: MessageToUiService,
    private settingService: SettingsService,
    private application: AppService,
    private utilsService: UtilsService,
    private entitiesSearchService: EntitySearchService,
    private firmService: FirmService,
    private spinner: GlobalSpinnerService,
    public matDialog: MatDialog
  ) { }

  get remindersDifferentThanInvoices(): boolean {
    return this._reminderAttachments != null && this._reminderAttachments?.length < this.newCase.invoices.length;
  }
  newCase: INewCase;
  locale: LocalizationSettings = {} as LocalizationSettings;
  countries$ = this.service.getCountries().pipe(map(_ => {
    return _.map(x => { return { key: x.code, value: x.name } });
  }));
  currencyCodes$ = this.service.getCurrencies().pipe(map(_ => this.utilsService.toDefaultKeyValue(_)));
  yesNoSelection: KeyValue<boolean, string>[];
  fileFormatFilter = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .msg';
  isBusy = false;
  invoicesTotal: number;
  invoicesErrors: string[] = [];
  canAddEuFee$ = this.settingService.getSettingValueByName('CanSpecifyCreateCaseEuFee').pipe(map(_ => _.isTruthy()));
  postCodeLength: number;
  customers$: Observable<KeyValue<number, string>[]>;
  customers: ICustomerSummary[];
  activeTab: number = 1;
  firmCountryCode: string;
  verification1: boolean;
  verification2: boolean;
  verification3: boolean;
  verification4: boolean;
  selectedCustomerName: string;
  isDebtorInformationComplete: boolean;
  isInvoicesInformationComplete: boolean;
  isSmallScreen: boolean;
  @ViewChild(FileUploadComponent) invoiceCopyFileUpload: FileUploadComponent;
  private defaultNewCase: INewCase;
  private readonly unsubscribe$ = new Subject();
  private _reminderAttachments: File[];
  private _invoiceCopies: File[] = [];

  ngOnInit(): void {
    this.localization.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.locale = _;
      });

    this.service.getDefaults()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((defaultCase) => {
        this.defaultNewCase = defaultCase;
      });

    this.yesNoSelection = this.getYesNoSelectionOptions();

    this.settingService.getSettingValueByName('PostCodeLength')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => this.postCodeLength = +_);

    this.firmService.getFirmCountryCode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => this.firmCountryCode = _);

    this.resetValues();

    this.setCustomers();
    this.getIsSmallScreen();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  determineIsInformationComplete() {
    if (this.activeTab == 1) {
      this.isDebtorInformationComplete =
        this.newCase.customerNumber > 0 &&
        this.newCase.name?.length > 0 &&
        this.newCase.address1?.length > 0 &&
        this.newCase.customerReference1?.length > 0 &&
        this.newCase.countryCode?.length > 0 &&
        (!this.determineIsPostCodeRequired() || this.newCase.postCode?.length > 0);
    }

    if (this.activeTab == 2) {
      this.isInvoicesInformationComplete =
        this.newCase.invoicesDescription?.length > 0 &&
        this.verification1 &&
        this.verification2 &&
        (this.newCase.shouldSendReminder || this.verification3) &&
        (this.newCase.shouldSendReminder || this.verification4 || !this.remindersDifferentThanInvoices) &&
        this.areAllInvoicesComplete();
    }
  }

  onReminderFilesChanged(files: File[]) {
    this._reminderAttachments = files;
    this.determineIsInformationComplete();
  }

  onAddInvoice() {
    this.service
      .getInvoiceDefaults()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => this.newCase.invoices.push(_));
    this.calculateTotal();
  }

  onRemoveInvoice(index: number) {
    if (index != null) {
      this.newCase.invoices = this.newCase.invoices.filter(
        (item, i) => i !== index
      );
    }
    this.calculateTotal();
  }

  onRemoveInvoiceCopy(invoice: INewInvoice) {
    this._invoiceCopies = this._invoiceCopies.filter(x => x.name != invoice.invoiceCopyName);
    invoice.invoiceCopyName = null;
    this.determineIsInformationComplete();
  }

  onSubmit(f: NgForm) {
    this.invoicesErrors = [];

    this.isBusy = true;

    this.adjustInvoiceDates();
    this.spinner.show();

    this.service.createCase(this.newCase, this._reminderAttachments, this._invoiceCopies)
      .pipe(finalize(() => { this.isBusy = false; this.spinner.hide() }))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (_) => {
          this.messageToUi.success(`${this.translate.instant(KEYS.NewCase_Inserted_CaseNumber)} ${_.caseNumber.toString()}`, null, true);
          if (!_.attachmentsUploadSuccess) {
            this.messageToUi.error(KEYS.NewCase_Errors_FailedToUploadAttachment, _.failedUploadDocuments.join(', '));
          }
          this.resetValues();
        },
        error: (e) => {
          this.invoicesErrors = this.validator.getPropertyValidationErrors(e, 'invoices').map(x => x.errorCode);
          this.validator.handleError(f, e, this.invoicesErrors.length == 0);
        }
      });
  }

  determinePostCodeLength(): number {
    if (this.newCase.countryCode == this.firmCountryCode) return this.postCodeLength;
    return null;
  }

  determineIsPostCodeRequired(): boolean {
    return this.newCase.countryCode == this.firmCountryCode;
  }

  onNavigateToTab(tabNumber: number) {
    switch (tabNumber) {
      case 1:
        this.activeTab = 1;
        break;
      case 2:
        if (this.isDebtorInformationComplete) this.activeTab = 2;
        break;
      case 3:
        if (this.isDebtorInformationComplete && this.isInvoicesInformationComplete) this.activeTab = 3;
      default: break;
    }
  }

  adjustInvoiceDates() {
    this.newCase.invoices.map(i => i.date = i.date != undefined ? new Date(i.date.getTime() - (i.date.getTimezoneOffset() * 60000)) : null);
    this.newCase.invoices.map(i => i.dueDate = i.dueDate != undefined ? new Date(i.dueDate.getTime() - (i.dueDate.getTimezoneOffset() * 60000)) : null);
  }

  onInvoiceFieldLeave(field: string = null) {
    this.invoicesErrors = [];
    if (field == 'amount') { this.calculateTotal(); }
    this.determineIsInformationComplete();
  }

  calculateTotal() {
    let totalAmount = 0;
    this.newCase.invoices.forEach((i) => (totalAmount += i.amount));
    this.invoicesTotal = totalAmount;
  }

  getYesNoSelectionOptions(): KeyValue<boolean, string>[] {
    return [{ key: true, value: 'NewCase.SendReminder.Yes' },
    { key: false, value: 'NewCase.SendReminder.No' }];
  }

  debtorInfoSearch(socialNumber: string, customerReference1: string) {
    if (socialNumber?.length == 0 && customerReference1?.length == 0) return;

    const entitySearchRequest = {
      socialNumber: socialNumber,
      customerReference: customerReference1,
      customerNumber: this.newCase.customerNumber,
      usePostadresseIfAvailable: false
    } as IEntitySearchRequest;

    this.entitiesSearchService.getEntitySearchResult(entitySearchRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        if (_.name) {
          if (socialNumber != null) {
            _.socialNumber = socialNumber;
          }
          this.mapToCreateCaseModel(_);
          this.determineIsInformationComplete();
        }
      });
  }

  goToSummaryTab() {
    if (this.areRemindersValid()) this.onNavigateToTab(3)
  }

  onCustomerChange() {
    if (this.newCase.customerNumber == null) {
      this.selectedCustomerName = null;
      return;
    }
    this.selectedCustomerName = this.customers.filter(x => x.number == this.newCase.customerNumber)[0].name;
  }

  onPreviewCopy(copyName: string) {
    const file = this._invoiceCopies.find(x => x.name == copyName);
    window.open(URL.createObjectURL(file));
  }

  onWindowResize(event) {
    this.getIsSmallScreen();
  }

  private setCustomers() {
    const validCustomers$ = this.application.customers$.pipe(map(_ => _?.filter(x => !x.isParent && !x.isBarred && !x.isBlockedNewCase)));
    this.customers$ = validCustomers$.pipe(map(_ => {
      this.customers = _;
      return _.map(x => { return { key: x.number, value: `${x.number} - ${x.name}` } });
    }));
  }

  private areAllInvoicesComplete(): boolean {
    return this.newCase.invoices
      .filter(x => !x.amount || x.currencyCode?.length == 0 || x.date == null || x.dueDate == null || x.number?.length == 0 || x.invoiceCopyName == null)
      .length == 0;
  }

  private areRemindersValid(): boolean {
    if (this.newCase.shouldSendReminder) return true;
    if (this._reminderAttachments == null) {
      this.messageToUi.errorKey(KEYS.NewCase_Errors_AttachmentRequiredIfNoReminder);
      return false;
    }
    return true;
  }

  private resetValues() {
    this.newCase = deepCopy(this.defaultNewCase);
    this.isBusy = false;
    this._reminderAttachments = null;
    this.invoicesTotal = 0;
    this.activeTab = 1;
    this.verification1 = false;
    this.verification2 = false;
    this.verification3 = false;
    this.verification4 = false;
    this._invoiceCopies = [];
  }

  private mapToCreateCaseModel(searchResult: IEntitySearchResult) {
    this.newCase.name = searchResult.name;
    this.newCase.address1 = searchResult.address;
    this.newCase.address2 = searchResult.addressLine2;
    this.newCase.countryCode = searchResult.countryCode;
    this.newCase.postCode = searchResult.postCode;
    this.newCase.emailAddress = searchResult.emailAddress;
    this.newCase.phoneNumber = searchResult.phoneNumber;
    if (searchResult.socialNumber != null) {
      this.newCase.socialNumber = searchResult.socialNumber;
    }
    else if (searchResult.birthDate != null) {
      this.newCase.socialNumber = new Date(searchResult.birthDate).toLocaleDateString('en-GB').replace(/\//g, '.');
    }
  }

  openInvoiceCopyMatchingDialog() {
    let dialogRef = this.matDialog.open(InvoiceCopiesComponent);

    dialogRef.componentInstance.invoiceList = deepCopy(this.newCase.invoices);
    dialogRef.componentInstance.files = this._invoiceCopies;
    dialogRef.componentInstance.fileFormatFilter = this.fileFormatFilter;

    dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe$), finalize(() => this.determineIsInformationComplete()))
      .subscribe(changes => {
        if (changes) {
          this.newCase.invoices = changes.invoices;
          this._invoiceCopies = changes.files.filter(x => this.newCase.invoices.findIndex(y => y.invoiceCopyName == x.name) > -1);
        }
      });
  }

  private getIsSmallScreen() {
    this.isSmallScreen = window.innerWidth < 600;
  }
}
