import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, QueryList, ViewChildren} from "@angular/core";
import { ValidatorService } from "src/app/shared/validator.service";
import { ControlContainer, NgForm } from "@angular/forms";
import { KeyValue } from "@angular/common";
import { InputType } from "../add-edit-users-models";

@Component({
    selector: 'app-base-user-info',
    templateUrl:"./base-user-info.component.html",
    styleUrls: ["./base-user-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
  })
  export class BaseUserInfoComponent{

    constructor(public validator: ValidatorService, private changeDetectorRef : ChangeDetectorRef)
    {}

    inputs : any = {}
    
    @Output()
    changeEvent : EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren("inputContainer") inputContainers : QueryList<ElementRef>;

    emitChangeEvent()
    {
      this.changeEvent.emit();
    }

    public checkChanges()
    {
      this.changeDetectorRef.markForCheck();
    }

    public orderByValueAsc(a: KeyValue<string,InputType>, b: KeyValue<string,InputType>) : number
    {
      return a.value.formOrder - b.value.formOrder
    }

  }