import { ICustomerSummary } from "src/app/shared/models";
import { CustomersTreeElement } from "./add-edit-users-models";

export class AddEditUserUtils
{

  tryAddAsChild(currentlyChecking : CustomersTreeElement, customerToAdd : CustomersTreeElement, userCustomers : number[])
  {
    if(currentlyChecking.number == customerToAdd.parentCustomerNumber)
    {
      if( currentlyChecking.children == null)  currentlyChecking.children = []
      customerToAdd.depth = currentlyChecking.depth + 1;
      customerToAdd.showChildren = true;
      customerToAdd.children = []
      let isSelectedForUser = userCustomers.findIndex(x => x == customerToAdd.number) != -1
      if(!isSelectedForUser && currentlyChecking.isSelected)
      {
        userCustomers.push(customerToAdd.number)
      }
      customerToAdd.isSelected =  isSelectedForUser || currentlyChecking.isSelected;
      currentlyChecking.children.push(customerToAdd);

      return true;
    }

    let wasAdded = false;
    for (let i = 0; i < currentlyChecking.children.length; i++) {
      wasAdded = wasAdded || this.tryAddAsChild(currentlyChecking.children[i],customerToAdd, userCustomers);
      if(wasAdded)
      {
        return wasAdded
      }
    }

    return false;

  }

  addAsRootElement(tree : CustomersTreeElement[],customer : ICustomerSummary, userCustomers : number[])
  { 
    let element = customer as CustomersTreeElement;
    element.children = [];
    element.depth = 0;
    element.isSelected = userCustomers.findIndex(x => x == element.number) != -1;
    tree.push(element)
  }

  buildCustomersTree(customers : ICustomerSummary[], userCustomers : number[])
  {
    let index = 0;
    let customersTree : CustomersTreeElement[] = []
    while(customers.length > 0)
    {
      if(customers[index].parentCustomerNumber == null)
      {
        this.addAsRootElement(customersTree,customers[index], userCustomers)
        customers.splice(index,1)
        index = 0
      }
      else
      {
        let wasAdded = false
        for(let i = 0 ; i< customersTree.length; i++)
        {
          wasAdded = this.tryAddAsChild(customersTree[i],customers[index] as CustomersTreeElement, userCustomers)
          if(wasAdded)
          {
            customers.splice(index,1);
            index = 0;
            break;
          }
        }
        if(!wasAdded)
        {
          if(customers.findIndex(x => x.number == customers[index].parentCustomerNumber) == -1)
          {
            this.addAsRootElement(customersTree,customers[index],userCustomers)
            customers.splice(index,1)
            index = 0
          }
          else
            index = ( index + 1 ) % customers.length
        }

      }
    }

    return this.orderByChildrenNumbers(customersTree);
  }

  orderByChildrenNumbers(customers : CustomersTreeElement[]) :  CustomersTreeElement[]
  {
    
    for (let i = 0; i < customers?.length; i++) {
      customers[i].children = this.orderByChildrenNumbers(customers[i].children)
    }

    if(customers == null || customers.length == 0 ) return [] as CustomersTreeElement[]
      return customers.sort((a,b) => a.children?.length - b.children?.length)
    
  }
}