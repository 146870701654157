<ng-container *ngIf="recentlyCreatedCases$ | async as recentlyCreatedCases">
  <table *ngIf="recentlyCreatedCases.length > 0" class="cases-table">
    <thead>
      <tr>
        <th class="case-col" scope="col">{{ 'CaseRow.CaseNumber' | translate }}</th>
        <th class="customer-col" scope="col">{{ 'CaseRow.CustomerNumber' | translate }}</th>
        <th class="reference-col" scope="col">
          {{ 'CaseRow.CustomerReference1' | translate }}
        </th>
        <th class="reference-col" scope="col">
          {{ 'CaseRow.CustomerReference2' | translate }}
        </th>
        <th class="debtor-col" scope="col">{{ 'CaseRow.DebtorName' | translate }}</th>
        <th class="amount-col" scope="col">{{ 'CaseRow.Amount' | translate }}</th>
        <th class="status-col" scope="col">{{ 'CaseRow.Status' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let case of recentlyCreatedCases">
        <td class="case-col">{{case.caseNumber}}</td>
        <td class="customer-col">{{case.customerNumber}}</td>
        <td class="reference-col">{{case.customerReference1}}</td>
        <td class="reference-col">{{case.customerReference2}}</td>
        <td class="debtor-col">{{case.debtorName}} </td>
        <td class="amount-col">{{case.amount | number : numberFormat.style : numberFormat.culture}}</td>
        <td class="status-col">{{'RecentlyCreatedCases.StatusProcessed' | translate}}</td>
      </tr>
      <ng-container *ngIf="recentlyCreatedCases.length < 3">
        <tr *ngFor="let emptyRow of [].constructor(3 - recentlyCreatedCases.length)">
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
