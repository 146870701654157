<div class="page-container">
    <div class="top-info">
      <div class="title-container">
        <h3 class="title">{{'Admin.UserList' | translate}}</h3>
        <hr class="separation-line" />
      </div>
      <h5>{{getTranslatedTotalUsersLabel()}}</h5>
    </div>
    <button class="button-basic" *ngIf="canAddUser() | async" id="add-user-button" (click)="redirectToCreateUser()">{{'Admin.UserList.AddUser' | translate}}</button>
    <ng-container *ngIf="dateFormat$ | async as dateFormat">
        <p-table [value]="users ?? [[]]"
                    [autoLayout]="true"
                    [paginator]="true"
                    [lazy]="true"
                    [(rows)]="tableRows"
                    [rowsPerPageOptions]="[5,10,15,20]"
                    [totalRecords]="totalUsers"
                    [loading]="isLoading"
                    [(first)]="first"
                    [showCurrentPageReport]="true"
                    [currentPageReportTemplate]="getPageReportTemplate()"
                    (onLazyLoad)="onPageChange($event)" id="usersListTable">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let column of tableMetadata">
                        {{column.label | translate}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr>
                    <ng-container *ngFor="let column of tableMetadata">
                        <ng-container [ngSwitch]="column.type">
                            <td *ngSwitchCase="'boolAsString'">
                                {{column.getValue(user[column.propriety]) | translate}}
                            </td>
                            <td *ngSwitchCase="'date'">
                                {{user[column.propriety] | date: dateFormat}}
                            </td>
                            <td *ngSwitchCase="'button'">
                                <i class="{{column.iconClass + ' clickable'}}" (click)="column.action(user)"></i>
                            </td>
                            <td *ngSwitchDefault>
                                {{user[column.propriety]}}
                            </td>
                        </ng-container>

                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</div>
