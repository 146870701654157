import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { CasesService } from 'src/app/services/cases.service';
import { FileUploadService, IFileWithValidation } from 'src/app/services/file-upload.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';
import { LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { IInvoice, IUploadDocumentRestrictions } from 'src/app/shared/models';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-case-invoices',
  templateUrl: './case-invoices.component.html',
  styleUrls: ['./case-invoices.component.scss']
})
export class CaseInvoicesComponent {

  constructor(private service: CasesService, private spinner: GlobalSpinnerService, private notification: MessageToUiService
    , public matDialog: MatDialog, private fileUplodService: FileUploadService, private translate: TranslateService) { }

  @Input() caseNumber: number;
  @Input() list: IInvoice[];
  @Input() uploadDocumentRestrictions: IUploadDocumentRestrictions;
  @Input() hasInternationalCurrency: boolean;
  @Input() caseCurrencyCode: string;
  @Input() firmCurrencyCode: string;
  @Input()  numberFormat: LocalizedNumberFormat;
  @Output() invoiceCopyChange = new EventEmitter();
  @Output() change = new EventEmitter();

  public get uploadEnabled(): boolean {
    return this.list?.filter(x => x.isInvoiceCopyAvailable).length != this.list?.length;
  }

  uploadDialogRef: MatDialogRef<any, any>;
  fileNames: KeyValue<number, string>[];

  openInvoiceCopy(invoice: IInvoice): void {
    if (!this.caseNumber || !invoice?.isInvoiceCopyAvailable) return;

    this.spinner.show();

    this.service.getInvoiceCopy(this.caseNumber, invoice.number)
      .subscribe({
        next: (blob) => {
          this.spinner.hide();

          var fileURL = window.URL.createObjectURL(blob);
          let tab = window.open();
          tab.location.href = fileURL;
        }
        , error: () => {
          this.spinner.hide();
          this.notification.errorKey('Errors.FileNotFound');
        }
      });
  }

  downloadInvoices() {
    this.spinner.show();
    this.service.downloadInvoicesExcel(this.caseNumber, this.translate.getDefaultLang())
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: (blob) => {
          saveAs(blob, `${this.translate.instant('Case.Invoices')}_${this.caseNumber}.xlsx`);
        },
        error: () => {
          this.notification.genericError();
        }
      });
  }

  handleSelectedFiles(component, event) {
    let files: File[] = Array.from((event.target as any).files);

    event.target.value = '';

    let invoices = this.list
      .filter(i => !i.isInvoiceCopyAvailable)
      .map(invoice => Object.assign({ fileIndex: null, status: 'none' }, invoice))

    // auto select file
    if (invoices?.length == 1 && files.length == 1) {
      invoices[0].fileIndex = 0;
    }

    const selectFilesList$ = this.fileUplodService.validateFiles(files).pipe(
      tap(_ => {
        let i = 0;
        this.fileNames = _.map(x => {
          return { key: i++, value: x.name } as KeyValue<number, string>
        });
      }));

    this.uploadDialogRef = this.matDialog.open(component, { data: { invoices: invoices, files$: selectFilesList$ } })
    this.uploadDialogRef.afterClosed().subscribe(_ => this.change.next(null))
  }

  upload(invoices: Array<InvoiceWithStatus>, files: IFileWithValidation[]): void {

    this.spinner.show();

    let uploadRequestArray = this.buildUploadRequestArray(invoices, files);

    if (!uploadRequestArray.length) {

      this.spinner.hide();
      this.uploadDialogRef.close();
    }

    forkJoin(uploadRequestArray).subscribe(
      results => {

        this.spinner.hide();

        const allUploadsSucceed = results.filter(succcess => succcess).length === results.length;

        if (allUploadsSucceed) this.uploadDialogRef.close();
      });
  }

  private buildUploadRequestArray(invoices: Array<InvoiceWithStatus>, files: Array<IFileWithValidation>) {
    return invoices
      .filter(i => (i.fileIndex >= 0) && (i.status === 'none') && (files[i.fileIndex]?.isValid === true))
      .map(i => {
        i.status = 'busy';

        return this.service.uploadInvoiceCopy(this.caseNumber, i.number, files[i.fileIndex])
          .pipe(
            map(_ => { i.status = 'success'; return true; }),
            catchError(_ => { i.status = 'error'; return of(false); }));
      });
  }
}

export type InvoiceWithStatus = IInvoice & { fileIndex?: number, status: 'none' | 'busy' | 'success' | 'error' }
