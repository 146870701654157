<section class="header-container">
  <nav class="menu">
    <a *ngFor="let menuItem of MENU" [routerLink]="menuItem.link" class="menu-item" [class.selected]="isSelectedMenuItem(menuItem.name)">
      {{menuItem.translationTag | translate}}
    </a>
  </nav>
</section>

<div class="page-container">
  <router-outlet></router-outlet>
</div>
