import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CasesService } from 'src/app/services/cases.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { LocalizationService } from 'src/app/shared/localization/localization.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ContractsService } from '../../contracts.service';
import { IContractExecutedAction } from '../../models';

@Component({
  selector: 'app-contract-actions',
  templateUrl: './contract-actions.component.html',
  styleUrls: ['./contract-actions.component.scss']
})
export class ContractActionsComponent implements OnInit {

  @Input()
  contractStatusId: number;

  caseNumber: number;

  data$: Observable<IContractExecutedAction[]>;
  format$ = this.localizationService.settings$;

  @ViewChild("smsModal") smsModal: TemplateRef<any>;

  constructor(
    private localizationService: LocalizationService,
    private service: ContractsService,
    private caseService: CasesService,
    private notifications: MessageToUiService,
    public utilsService: UtilsService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.data$ = this.service.getContractActions(this.contractStatusId);
  }

  openCaseAction(action: IContractExecutedAction) {
    if ((action.jobType?.toString() == "SMS")
      || (action.sentByChannel?.toString() == "SMS")) {
      this.openSms(action);
    }
    else {
      this.openLetter(action);
    }
  }

  openLetter(action: IContractExecutedAction) {
    this.caseService.downloadLetter(this.caseNumber, action.documentIdentifier)
      .subscribe({
        next: (_) => {
          _ = this.utilsService.tryEncodeAsUTF8(_);
          var fileURL = window.URL.createObjectURL(_);
          let tab = window.open();
          tab.location.href = fileURL;
        },
        error: () => this.notifications.genericError()
      });
  }

  openSms(action: IContractExecutedAction) {
    this.caseService.downloadLetter(this.caseNumber, action.documentIdentifier)
      .subscribe({
        next: (_) => {
          _.text().then((value) => {
            this.viewSMS(action, value);
          })
            .catch((err) => {
              this.notifications.error(err)
            });
        },
        error: () => this.notifications.genericError()
      });
  }

  viewSMS(smsItem: IContractExecutedAction, content: string) {
    this.dialog.open(this.smsModal, {
      data: {
        message: smsItem,
        content: content
      }
    });
  }

  getClass(action: IContractExecutedAction): string {
    if ((action.jobType?.toString() == "SMS")
      || (action.sentByChannel?.toString() == "SMS")
      || (action.jobType?.toString() == "Print")
      || (action.sentByChannel?.toString() == "Letter")) {
      return "link-label clickable";
    }
  }
}
