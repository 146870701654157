import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output, signal, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    }]
})
export class CustomSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  /*
  * The key uniquely identifies an option - it can have any type
  * The value can be either a translation tag or directly the option value
  */
  @Input()
  options: KeyValue<any, string>[];

  @Input()
  selectedKey: any;
  @Output()
  selectedKeyChange = new EventEmitter();

  @Input()
  placeholderTranslationTag?: string;

  @Input()
  searchEnabled: boolean;

  @Input()
  clearEnabled: boolean;

  @Input()
  disabled : boolean = false;

  selectedValue: string;
  filteredOptions: KeyValue<any, string>[];
  searchTerm = '';

  disableSelect = signal<boolean>(false);
  displayDropDown = signal<boolean>(false);

  @ViewChild('dropdownTrigger') trigger: ElementRef;

  @ViewChild('searchBox') set searchBox(searchBox: ElementRef) {
    if (searchBox != null) searchBox.nativeElement.focus();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event : MouseEvent) {
    if(event.relatedTarget == null || event.relatedTarget != this.trigger.nativeElement)
      this.displayDropDown.set(false);
  }

  @HostListener('click', ['$event']) onTriggerClick($event : MouseEvent) {
    if(this.disabled) return;
    
    this.displayDropDown.set(!this.displayDropDown());

    $event.stopPropagation();
    document.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        relatedTarget : this.trigger.nativeElement
      }));
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.options) {
      if (this.selectedKey != null) 
        this.selectedValue = this.options.filter(x => x.key == this.selectedKey)[0].value;
      
      this.filteredOptions = this.options;

      this.searchEnabled = this.searchEnabled && this.filteredOptions.length > 5;
    }
    if (changes['selectedKey']) {
      if (changes['selectedKey'].currentValue == null) this.clear();
      else this.selectedValue = this.options?.filter(x => x.key.toString() == this.selectedKey.toString())[0]?.value;
    }

    this.disableSelect.set(this.disabled || !this.filteredOptions?.length);
  }

  onSelectedChange(option: KeyValue<any, string>) {
    this.selectedKey = option.key;
    this.selectedValue = option.value;
    this.selectedKeyChange.emit(option.key);
  }

  onSearchInputChanged(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.filteredOptions = this.options.filter(x => x.value.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1);
  }

  clear(event?: any) {
    if (this.disabled) return;

    event?.stopPropagation();
    this.selectedValue = undefined;
    this.selectedKey = null;
    this.selectedKeyChange.emit(undefined);
  }

  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
