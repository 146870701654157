<div>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>{{'Case.Requirements.Claim' | translate}}</th>
        <th>{{'Case.Requirements.Paid' | translate}}</th>
        <th>{{'Case.Requirements.Rest' | translate}} {{firmCurrencyCode}}</th>
        <th *ngIf="hasInternationalCurrency">
          {{'Case.Requirements.Rest' | translate}} {{case?.currencyCode}}
        </th>
        <th>{{'Case.Requirements.Invoiced' | translate}}</th>
        <th>{{'Case.Requirements.Remitted' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>{{'Case.Requirements.Capital' | translate}}</th>
        <td>{{case?.requirements.originalCapital | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.paidCapital | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.restCapital | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td *ngIf="hasInternationalCurrency"> {{case?.requirements.restValutaCapital | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td></td>
        <td>{{case?.requirements.remittedCapital | number : numberFormat?.style: numberFormat?.culture}}</td>
      </tr>
      <tr>
        <th class="interest-rate">
          {{'Case.Requirements.Interest' | translate}}
          <div>{{ case?.requirements.interestRate | number }}%</div>
        </th>
        <td>{{case?.requirements.originalInterest | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.paidInterest | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.restInterest | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td *ngIf="hasInternationalCurrency">
          {{case?.requirements.restValutaInterest | number : numberFormat?.style: numberFormat?.culture}}
        </td>
        <td></td>
        <td>{{case?.requirements.remittedInterest | number : numberFormat?.style: numberFormat?.culture}}</td>
      </tr>

      <tr>
        <th>{{'Case.Requirements.SveasFees' | translate}}</th>
        <td>{{SveasFees?.original | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{SveasFees?.paid | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{SveasFees?.rest| number : numberFormat?.style: numberFormat?.culture}}</td>
        <td *ngIf="hasInternationalCurrency">
          {{SveasFees?.restValuta | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.customersAdditionalFee | number : numberFormat?.style: numberFormat?.culture}}

        </td>
        <td>{{SveasFees?.remitted| number : numberFormat?.style: numberFormat?.culture}}</td>
      </tr>

      <tr>
        <th>{{'Case.Requirements.PublicFee' | translate}}</th>
        <td>{{case?.requirements.originalPublicFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.paidPublicFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.restPublicFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td *ngIf="hasInternationalCurrency">
          {{case?.requirements.restValutaPublicFee | number : numberFormat?.style: numberFormat?.culture}}
        </td>
        <td>{{case?.requirements.customersPublicFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.remittedPublicFee | number : numberFormat?.style: numberFormat?.culture}}</td>
      </tr>

      <tr>
        <th>{{'Case.Requirements.EuFee' | translate}}</th>
        <td>{{case?.requirements.originalEuFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.paidEuFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.restEuFee | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td *ngIf="hasInternationalCurrency">
          {{case?.requirements.restValutaEuFee | number : numberFormat?.style: numberFormat?.culture}}
        </td>
        <td></td>
        <td>{{case?.requirements.remittedEuFee | number : numberFormat?.style: numberFormat?.culture}}</td>
      </tr>
      <tr>
        <th>{{'Case.Requirements.Total' | translate}}</th>
        <td>{{totals?.originalTotal | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{totals?.paidTotal | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td>{{case?.requirements.restTotal | number : numberFormat?.style: numberFormat?.culture}}</td>
        <td *ngIf="hasInternationalCurrency">
          {{case?.requirements.restValutaTotal | number : numberFormat?.style: numberFormat?.culture}}
        </td>
        <td>
          {{(case ? (case.requirements.customersAdditionalFee + case.requirements.customersPublicFee) : undefined)
          | number : numberFormat?.style: numberFormat?.culture}}
        </td>
        <td>{{totals?.remitted | number : numberFormat?.style: numberFormat?.culture}}</td>
      </tr>
    </tbody>
  </table>
</div>
