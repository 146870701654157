<section class="header">
  <section class="title-container">
    <h2 class="title">
      {{ 'Contracts.Title' | translate }}
    </h2>
    <hr class="separation-line" />
  </section>

  <section class="filter">
    <div>
      <table>
        <tr>
          <td><label>{{'Contracts.ClaimType' | translate}}</label></td>
          <td>
            <custom-select [options]="keyValueClaimTypes" [(ngModel)]="filter.contractType"
              [(selectedKey)]="filter.contractType" name="contractType" (selectedKeyChange)="getStatuses($event)"
              [clearEnabled]="true">
            </custom-select>
          </td>
        </tr>
        <tr>
          <td><label>{{'Contracts.Status' | translate}}</label></td>
          <td>
            <p-dropdown [options]="statuses" [(ngModel)]="selectedStatus" name="status" dropdownIcon="fas fa-caret-down"
              showClear="true" id="statusDropdown" placeholder="{{'General.Select' | translate}}" scrollHeight="250px">
              <ng-template let-item pTemplate="selectedItem">
                <span>
                  {{ isEnglish ? item?.translationEn : item?.translation }}
                </span>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <span [class]="getClassForWorkListItem(item)">
                  {{ isEnglish ? item?.translationEn : item?.translation }}
                </span>
              </ng-template>
            </p-dropdown>
          </td>
        </tr>
        <tr>
          <td> <label>{{'Contracts.DebtorType' | translate}}</label></td>
          <td>
            <custom-select name="debtorType" [options]="debtorTypes" [(selectedKey)]="filter.debtorType"
              [clearEnabled]="true" [(ngModel)]="filter.debtorType"></custom-select>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td><label>{{'Contracts.ScoreColor' | translate}}</label></td>
          <td>
            <custom-select name="scoreColor" [options]="scoreColors" [(selectedKey)]="filter.scoreColor"
              [clearEnabled]="true" [(ngModel)]="filter.scoreColor"></custom-select>
          </td>
        </tr>
        <tr>
          <td><label for="minimumCapitalAmount"> {{ "Contracts.MinimumCapitalAmount" | translate }} </label></td>
          <td>
            <p-inputNumber [(ngModel)]="filter.minimumCapitalAmount" #minimumCapitalAmount="ngModel"
              placeholder="{{'Contracts.MinimumCapitalAmount' | translate}}" inputId="minimumCapitalAmount"
              name="minimumCapitalAmount" mode="decimal">
            </p-inputNumber>
          </td>
        </tr>
        <tr>
          <td> <label for="minimumNumberOfInvoices"> {{ "Contracts.MinimumNumberOfInvoices" | translate }} </label></td>
          <td>
            <p-inputNumber [(ngModel)]="filter.minimumNumberOfInvoices" #minimumNumberOfInvoices="ngModel"
              placeholder="{{'Contracts.MinimumNumberOfInvoices' | translate}}" inputId="minimumNumberOfInvoices"
              name="minimumNumberOfInvoices" mode="decimal">
            </p-inputNumber>
          </td>
        </tr>
      </table>
    </div>
    <button class="button-basic" type="submit" (click)="onSubmit()">
      {{ "General.Filter" | translate }}
    </button>
  </section>
  <section class="statistics" *ngIf="filter.contractType">
    <app-contract-type-status-statistics [(contractType)]="filter.contractType" [isEnglish]="isEnglish"
      [refresh]="refreshStatistics">
    </app-contract-type-status-statistics>
  </section>
</section>

<section class="results">
  <div class="total" *ngIf="totalNumberOfItems> 0">
    {{'Contracts.TotalNumberContracts' | translate}}:&nbsp;{{totalNumberOfItems}}
  </div>
  <div class="flex">
    <div>
      <input type="text" class="text-input" id="generalFilter" name="generalFilter" [(ngModel)]="searchTerm"
        (ngModelChange)="onFilter()" placeholder="{{ 'General.Filter' | translate }}" />
    </div>
    <div>
      <button *ngIf="numberOfSelectedContracts()" class="button-basic" type="button" (click)="openChangeStatus()">
        {{ "Contracts.ChangeStatus" | translate }}
      </button>
    </div>
  </div>

  <p-table #contractsTable [value]="list$ | async" [paginator]="true" styleClass="p-datatable-sm" selectionMode="single"
    [(first)]="indexOfFirstTableElement" class="customTable" [totalRecords]="totalNumberOfItems"
    [rowsPerPageOptions]="[25, 50, 100]" [loading]="isLoading" [showCurrentPageReport]="true"
    [(rows)]="numberOfItemsPerPage" [currentPageReportTemplate]="getPageReportTemplate()">

    <ng-template pTemplate="header">
      <tr>
        <th class="small-column">
          <input *ngIf="totalNumberOfItems" type="checkbox" class="checkbox" (change)="selectAll($event)">
        </th>
        <th class="small-column"></th>
        <th pSortableColumn="contractReference">{{ "Contracts.ContractReference" | translate }}
          <p-sortIcon field="contractReference"></p-sortIcon>
        </th>
        <th pSortableColumn="claimTypeDescription">{{ "Contracts.ClaimType" | translate }}
          <p-sortIcon field="claimTypeDescription"></p-sortIcon>
        </th>
        <th pSortableColumn="statusName">{{ "Contracts.Status" | translate }}
          <p-sortIcon field="statusName"></p-sortIcon>
        </th>
        <th pSortableColumn="debtorName">{{ "Contracts.DebtorName" | translate }}
          <p-sortIcon field="debtorName"></p-sortIcon>
        </th>
        <th *ngIf="canViewScore$ | async" pSortableColumn="score">{{ "Contracts.Score" | translate }}
          <p-sortIcon field="score"></p-sortIcon>
        </th>
        <th pSortableColumn="debtorAddress">{{ "Contracts.DebtorAddress" | translate }}
          <p-sortIcon field="debtorAddress"></p-sortIcon>
        </th>
        <th pSortableColumn="debtorPostCode">{{ "Contracts.PostNumber" | translate }}
          <p-sortIcon field="debtorPostCode"></p-sortIcon>
        </th>
        <th pSortableColumn="statusChangedDate">{{ "Contracts.StatusDate" | translate }}
          <p-sortIcon field="statusChangedDate"></p-sortIcon>
        </th>
        <th class="text-right" pSortableColumn="numberOfCases">{{ "Contracts.Cases" | translate }}
          <p-sortIcon field="numberOfCases"></p-sortIcon>
        </th>
        <th class="text-right" pSortableColumn="numberOfInvoicesWithResidual">{{ "Contracts.Invoices" | translate }}
          <p-sortIcon field="numberOfInvoicesWithResidual"></p-sortIcon>
        </th>
        <th class="text-right" pSortableColumn="totalRest">{{ "Contracts.RestTotal" | translate }}
          <p-sortIcon field="totalRest"></p-sortIcon>
        </th>
        <th class="text-right" pSortableColumn="totalCapital">{{ "Contracts.RestCapital" | translate }}
          <p-sortIcon field="totalCapital"></p-sortIcon>
        </th>
        <th class="text-right" pSortableColumn="totalFees">{{ "Contracts.RestFees" | translate }}
          <p-sortIcon field="totalFees"></p-sortIcon>
        </th>
        <th class="text-right warned warned-separation" pSortableColumn="warnedTotalRest">{{ "Contracts.WarnedTotal" |
          translate }}
          <p-sortIcon field="warnedTotalRest"></p-sortIcon>
        </th>
        <th class="text-right warned" pSortableColumn="warnedTotalCapital">{{ "Contracts.WarnedCapital" | translate }}
          <p-sortIcon field="warnedTotalCapital"></p-sortIcon>
        </th>
        <th class="text-right warned" pSortableColumn="warnedTotalFees">{{ "Contracts.WarnedFees" | translate }}
          <p-sortIcon field="warnedTotalFees"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-contract>
      <tr *ngIf="format$ | async as format" [class.non-standard-closing]="contract.contractClosingTypeId != DEFAULT_CLOSING_TYPE">
        <td class="small-column">
          <input type="checkbox" class="checkbox" [(ngModel)]="contract.selected" (ngModelChange)="onToggleSelectContract($event)">
        </td>
        <td class="small-column">
          <span (click)="showNotes(contract.id, contract.contractReference, contract.contractClosingTypeId)" class="cursor-pointer">
            <i class="fas fa-comments fa-lg"></i>
          </span>
        </td>
        <td>
          <label class="link-label" (click)="showCases(contract.id, contract.contractReference)">
            {{contract.contractReference}}
          </label>
        </td>
        <td>{{contract.claimTypeDescription}} </td>
        <td>
          {{ contract.statusTranslationTag | translate }}
          <span *ngIf="contract.hasExternalStatus === true" (mouseover)="showExternalStatus($event, contract.id)">
            <i class="fas fa-cloud-upload-alt"
              [class]="getClassForExternalStatus(contract.lastExternalStatusUpdateSuccessful)"></i>
          </span>
          <span *ngIf="contract.numberOfCasesWithWarningStatuses > 0"
            [matTooltip]="'Contracts.CasesHaveWarnings' | translate">
            <i class="fa fa-exclamation-triangle"></i>
          </span>
        </td>
        <td>{{contract.debtorName}}</td>
        <td *ngIf="canViewScore$ | async">
          <div class="score" [class]="contract.scoreColor">
            {{contract.score}}
          </div>
        </td>
        <td>{{contract.debtorAddress}}</td>
        <td>{{contract.debtorPostCode}}</td>
        <td>{{contract.statusChangedDate | date:format.datePipeFormat}}</td>
        <td class="text-right">{{contract.numberOfCases}}</td>
        <td class="text-right">{{contract.numberOfInvoicesWithResidual}}</td>
        <td class="text-right">{{contract.totalRest | number : format.numberPipeFormat.style:
          format.numberPipeFormat.culture}}</td>
        <td class="text-right">{{contract.totalCapital | number : format.numberPipeFormat.style:
          format.numberPipeFormat.culture}}</td>
        <td class="text-right">{{contract.totalFees | number : format.numberPipeFormat.style:
          format.numberPipeFormat.culture}}</td>
        <td class="text-right warned warned-separation">{{contract.warnedTotalRest | number :
          format.numberPipeFormat.style:
          format.numberPipeFormat.culture}}</td>
        <td class="text-right warned">{{contract.warnedTotalCapital | number : format.numberPipeFormat.style:
          format.numberPipeFormat.culture}}</td>
        <td class="text-right warned">{{contract.warnedTotalFees | number : format.numberPipeFormat.style:
          format.numberPipeFormat.culture}}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <label class="label" (click)="onExportExcel()">
        {{ 'General.Download' | translate }}
      </label>
    </ng-template>
  </p-table>
</section>

<p-overlayPanel #externalStatus [showCloseIcon]="true">
  <ng-template pTemplate="content">
    <section *ngIf="externalStatusData$ | async as data">
      <section class="title-container">
        <h2 class="title">
          {{ data.externalPartyName }}
        </h2>
        <hr class="separation-line" />
      </section>
      <p-table [value]="data.changes">
        <ng-template pTemplate="body" let-change>
          <tr>
            <td>
              <div>
                <table *ngIf="format$ | async as format">
                  <tr>
                    <td>{{'Contracts.ExternalStatus.ChangeType' | translate}}</td>
                    <td> {{change.changeType}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contracts.ExternalStatus.Created' | translate}}</td>
                    <td> {{change.dateTimeCreated | date:format.datePipeFormat}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contracts.ExternalStatus.Executed' | translate}}</td>
                    <td> {{change.dateTimeExecuted | date:format.datePipeFormat}}</td>
                  </tr>
                  <tr *ngIf="change.success == true">
                    <td *ngIf="change.changeType == createClosingOrder">{{'Contracts.ExternalStatus.CreateClosingOrder'
                      | translate}}</td>
                    <td *ngIf="change.changeType == finishClosingOrder">{{'Contracts.ExternalStatus.FinishClosingOrder'
                      | translate}}</td>
                    <td> {{change.exernalOrderId}}</td>
                  </tr>
                  <tr *ngIf="change.success == false">
                    <td colspan="2">{{change.message}}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>
  </ng-template>
</p-overlayPanel>
