import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { MessagesService } from 'src/app/messages/messages.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { GlobalSpinnerService } from 'src/app/shared/global-spinner/global-spinner.service';
import { ICaseMessage } from 'src/app/shared/models';

@Component({
  selector: 'app-case-approval',
  templateUrl: './case-approval.component.html',
  styleUrls: ['./case-approval.component.scss']
})
export class CaseApprovalComponent {

  constructor (private messagesService: MessagesService, private notification: MessageToUiService, private spinner: GlobalSpinnerService, private translate: TranslateService) {

  }

  @Input() caseNumber: number;

  @Input() approvals: ICaseMessage[];

  @Output() change = new EventEmitter();

  respond(value: boolean, request: ICaseMessage) {

    let save$ = value === true
      ? this.messagesService.acceptMessageApproval(this.caseNumber, request.id)
      : this.messagesService.declineMessageApproval(this.caseNumber, request.id);

    save$ = save$.pipe(finalize(() => { this.spinner.hide(); this.change.emit() }));

    this.spinner.show();
    save$.subscribe(_ => { this.notification.successKey() }, e => { this.notification.genericError() });
  }

  getQuestion(approvalRequest: ICaseMessage) {
    let question = this.translate.instant(`Approval.Type_${approvalRequest.categoryCode}.Question`);

    if (question == null || question === '' || !(typeof question === 'string'))
      return approvalRequest.text;

    return question;
  }
}
