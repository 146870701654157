import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ReportsService } from 'src/app/reports/reports.service';
import { AppService as AppService } from 'src/app/services/app.service';
import { CapitalVolumeGraph } from './CapitalVolumeGraph';
import { CaseVolumeGraph } from './CaseVolumeGraph';
import { GraphBase } from './GraphBase';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})

export class GraphComponent implements OnInit {
  subscription: Subscription;

  constructor (private reportsService: ReportsService, private application: AppService, private translate: TranslateService) {
  }

  @Input() name: 'CaseVolume' | 'CapitalVolume';

  model: IAppGraphModel;

  ngOnInit(): void {
    this.application.customerNumber$
      .subscribe(nr => this.load(nr));
  }

  private load(customerNumber?: number) {

    this.subscription?.unsubscribe();
    this.model = undefined;

    let graph: GraphBase = null;

    switch (this.name) {
      case 'CaseVolume': graph = new CaseVolumeGraph(this.reportsService, this.translate); break;
      case 'CapitalVolume': graph = new CapitalVolumeGraph(this.reportsService, this.translate); break;

      default: graph = null; break;
    }

    this.subscription = graph?.get(customerNumber).subscribe(_ => this.model = _);
  }

  getTitle() : string {
    return this.translate.instant(`Graphs.${this.name}.Title`);
  }
}

export interface IAppGraphModel {

  data: any;
  type: 'line' | 'bar';
  options: any;
}

