import { Observable } from "rxjs";
import { IAppGraphModel } from "./graph.component";

export abstract class GraphBase {

  abstract get(customerNumber?: number): Observable<IAppGraphModel>;

  protected getShortMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'short', });
  }

  protected readStyleProperty(name: string): string {
    let bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue(name);
  }
}
