<ng-container *ngIf="vm$ | async as vm ">
  <section class="header-container" *ngIf="vm.hasPrevious || vm.hasNext">
    <button class="button-transparent" [disabled]="!vm.hasPrevious" (click)="previous(vm)">
      &lt;- {{'Case.PreviousCase' | translate}}
    </button>
    <button class="button-transparent" [disabled]="!vm.hasNext" (click)="next(vm)">
      {{'Case.NextCase' | translate}} -&gt;
    </button>
    <div *ngIf="vm.caseNumbers?.length === 0">
      {{'Case.NoItemsFound' | translate}}
    </div>
  </section>
  <section class="sub-page-container">
    <app-case [caseNumber]="vm.caseNumbers[vm.selectedIndex]"></app-case>
  </section>
</ng-container>