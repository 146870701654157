import { Component, Input } from '@angular/core';
import { ICaseSecuritySummary } from 'src/app/shared/models';

@Component({
  selector: 'app-case-securities',
  templateUrl: './case-securities.component.html',
})
export class CaseSecuritiesComponent {

  @Input() list: ICaseSecuritySummary[];
}
