import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { newId } from 'src/app/shared/utilities';
import { CasesService } from 'src/app/services/cases.service';
import { Observable, of } from 'rxjs';
import { ICaseRecallCost } from 'src/app/shared/models';
import { catchError, map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { CatchPipe } from 'src/app/shared/custom-pipes/catch.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';

@Component({
  selector: 'app-withdraw-case',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, MatProgressBarModule, FormsModule, CatchPipe],
  templateUrl: './withdraw-case.component.html',
  styleUrls: ['./withdraw-case.component.scss']
})
export class WithdrawCaseComponent implements OnInit {
  numberFormat: LocalizedNumberFormat;

  private readonly _termsId = `terms${newId()}`;


  constructor (private localization: LocalizationService, private service: CasesService, private dialogRef: MatDialogRef<any>, private notifiation: MessageToUiService) { }

  @Input() caseNumber: number;

  reason: string;

  agreementTerms$: Observable<ICaseRecallCost & { currencyCode?: string }>;

  get termsId(): string {
    return this._termsId;
  }

  get canAccept(): boolean {
    return this.reason && this.reason.trim().length > 0;
  }

  ngOnInit(): void {
    this.localization.settings$.subscribe(_ => this.numberFormat = _.numberPipeFormat);

    this.agreementTerms$ = this.service.getWithdrawCost(this.caseNumber)
      .pipe(
        map(_ => Object.assign(_, { currencyCode: this.localization.getFirmCurrencyCode() }))
        , catchError(e => { this.onLoadTermsError(); return of({} as ICaseRecallCost) }));
  }

  acceptAggreementTerms() {
    let terms = document.getElementById(this.termsId).innerHTML;

    let termsAndReason = terms + 'r\nReason: ' + this.reason;

    this.service.sendWithdrawCaseRequest(this.caseNumber, termsAndReason)
      .subscribe({
          next:() => this.dialogRef?.close(true)
        , error:() => this.notifiation.genericError()
      });
  }

  onLoadTermsError() {
    this.notifiation.genericError();
    this.cancel();
  }

  cancel() {
    this.reason = undefined;
    this.dialogRef?.close(false);
  }
}
