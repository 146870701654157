import { Injectable } from '@angular/core';
import { KreditorService } from '../shared/kreditor.service';
import { IEntitySearchRequest, IEntitySearchResult, IPostCodeSummary } from '../shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private kreditorService: KreditorService) { }

  public getPostCodeSummary(postCodeNumber : string, postCodeCountryCode : string) : Observable<IPostCodeSummary> {
    return this.kreditorService.getServiceResult<IPostCodeSummary>(`postCodes/byPostNumber/${postCodeCountryCode}/${postCodeNumber}`)
      .pipe(map(_ => _.result));
  }
}
