import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocalizationService } from './localization.service';
import { HttpClient } from '@angular/common/http';

export class LocalizationComponent implements TranslateLoader {

  constructor(private localizationService: LocalizationService, private http: HttpClient) { }

  getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      this.localizationService.getLocalization(lang).subscribe(
        data => {
          observer.next(data.result);
          observer.complete();
        },
        error => {
          console.log(error);
        }
      );
    });
  }
}



