<ng-container *ngFor="let item of inputs | keyvalue:orderByValueAsc ">
  <ng-container [ngSwitch]="inputs[item.key].type">
    <div class="label-input-combo" *ngSwitchDefault #inputContainer>
      <label [class]="inputs[item.key].labelClass">{{inputs[item.key].label | translate}}</label>
      <input type="{{inputs[item.key].subtype}}" class="text-input" [(ngModel)]="inputs[item.key].value"
        name="{{ inputs[item.key].name }}" #inputTemplate="ngModel" (ngModelChange)="emitChangeEvent()"
        [readonly]="inputs[item.key].readonly">
      <ng-container *ngTemplateOutlet="errorsDisplay; context: { control: inputTemplate }"></ng-container>
    </div>
    <div *ngSwitchCase="'bool'" #inputContainer>
      <label class="custom-checkbox-container">
        <input type="checkbox" class="column-checkbox" [(ngModel)]="inputs[item.key].value"
          name="{{ inputs[item.key].name }}" [readonly]="inputs[item.key].readonly">
        <span class="checkmark"></span>
        <label [class]="inputs[item.key].labelClass">{{inputs[item.key].label | translate}}</label>
      </label>
    </div>
    <div class="label-input-combo" *ngSwitchCase="'select'" #inputContainer>
      <label [class]="inputs[item.key].labelClass">{{inputs[item.key].label | translate}}</label>
      <custom-select [options]="inputs[item.key].options" [(selectedKey)]="inputs[item.key].value"></custom-select>
    </div>
  </ng-container>

</ng-container>

<ng-template #errorsDisplay let-c="control">
  <div *ngIf="(c.dirty || c.touched) && !c.valid" class="input-error">
    <span>{{ ('Admin.Error.' + c.errors?.errorCode) | translate}}</span>
  </div>
</ng-template>
