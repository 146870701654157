/** Return a deep clone of any non-cyclical object */
export function deepClone<T extends object | null | undefined>(obj: T): T {
  return obj ? JSON.parse(JSON.stringify(obj)) : null;
}


/** Returns a deep copy of an object preserving the data types of properties*/
export function deepCopy<T>(source: T): T {
  return Array.isArray(source)
  ? source.map(item => deepCopy(item))
  : source instanceof Date
  ? new Date(source.getTime())
  : source && typeof source === 'object'
        ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
           Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
           o[prop] = deepCopy((source as { [key: string]: any })[prop]);
           return o;
        }, Object.create(Object.getPrototypeOf(source)))
  : source as T;
}

let _newId = -1;
export function newId() {
  return _newId--;
}
