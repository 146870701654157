<div class="page-container">
  <div class="title-container">
    <h3 class="title">{{'MainMenu.AddSubCustomer' | translate }}</h3>
    <hr class="separation-line" />
  </div>

  <form #form="ngForm" class="form-container">
    <div>
      <div *ngIf="customers?.length > 1" class="label-input-combo">
        <label class="label">{{ 'General.Customer' | translate}}</label>
        <custom-select [options]="customersOptions" [(selectedKey)]="selectedCustomer" [searchEnabled]="true"
          name="customerNumber" #customerNumber></custom-select>
      </div>
      <div class="label-input-combo">
        <label class="label required">{{'AddSubcustomer.OrganizationNumber' | translate}}</label>
        <input type="text" class="text-input" [(ngModel)]="orgNumb"
          (ngModelChange)="searchOrganization();fieldChanges()" name="organizationNumber"
          #organizationNumberInput="ngModel">
        <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: organizationNumberInput }"></ng-container>
      </div>
      <div class="label-input-combo">
        <label class="label required">{{'General.Name' | translate}}</label>
        <app-input-with-spinner [(value)]="organizationInfo.name" (valueChange)="fieldChanges()"  [clearOnLoading]="false"
          [showSpinner]="loadingOrganizationInfo" name="nameStandard" #nameStandardInput></app-input-with-spinner>
        <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: nameStandardInput }"></ng-container>
      </div>
      <div class="label-input-combo" #address1>
        <label class="label required">{{'General.Address' | translate}}</label>
        <app-input-with-spinner [(value)]="organizationInfo.address" (valueChange)="fieldChanges()"
          [showSpinner]="loadingOrganizationInfo" name="Addresses[0].Address.Line1" [clearOnLoading]="false"
          #addressInput></app-input-with-spinner>
        <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: addressInput }"></ng-container>
      </div>
      <div class="label-input-combo" #address2>
        <label class="label">{{'General.Address' | translate}}</label>
        <app-input-with-spinner [(value)]="organizationInfo.addressLine2"  [clearOnLoading]="false"
          [showSpinner]="loadingOrganizationInfo"></app-input-with-spinner>
      </div>
      <div class="label-input-combo">
        <label class="label">{{'General.Country' | translate}}</label>
        <custom-select [options]="countries" [(selectedKey)]="selectedCountry" [searchEnabled]="true"
          (selectedKeyChange)="searchPostPlace()"></custom-select>
      </div>
    </div>
    <div>
      <div class="label-input-combo">
        <label class="label required">{{'AddSubcustomer.Account' | translate}}</label>
        <input type="text" class="text-input" [(ngModel)]="accountNumber" (ngModelChange)="fieldChanges()"
          name="bankAccounts[0].BankAccount.AccountNumber" #accountNumberInput="ngModel">
        <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: accountNumberInput }"></ng-container>
      </div>
      <div class="label-input-combo">
        <label class="label required">{{'AddSubcustomer.ContactPerson' | translate}}</label>
        <input type="text" class="text-input" [(ngModel)]="subCustomerRequest.contactPersonName"
          (ngModelChange)="fieldChanges()" name="contactPersonName" #contactPersonName="ngModel">
        <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: contactPersonName }"></ng-container>
      </div>
      <div class="label-input-combo">
        <label class="label required">{{'General.Email' | translate}}</label>
        <input type="text" class="text-input" [(ngModel)]="subCustomerRequest.email" (ngModelChange)="fieldChanges()"
          name="email" #emailInput="ngModel">
        <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: emailInput }"></ng-container>
      </div>
      <div class="horizontalContainer" id="postContainer">
        <div class="label-input-combo">
          <label class="label required">{{'AddSubcustomer.PostCode' | translate}}</label>
          <input type="text" class="text-input" [(ngModel)]="organizationInfo.postCode"
            (ngModelChange)="postNumberChange();fieldChanges();" name="addresses[0].Address.PostCode"
            #postCode="ngModel">
          <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: postCode }"></ng-container>
        </div>
        <div class="label-input-combo">
          <label class="label required">{{'AddSubcustomer.PostPlace' | translate}}</label>
          <app-input-with-spinner [(value)]="post.name" [showSpinner]="loadingPostPlace" [readonly]="true"
            #postPlaceInput name="postPlace"  [clearOnLoading]="false"></app-input-with-spinner>
          <ng-container *ngTemplateOutlet="errorsDisplay;context: { controll: postPlaceInput }"></ng-container>
        </div>
      </div>
      <div class="label-input-combo">
        <label class="label">{{'AddSubcustomer.VatResponsible' | translate}}</label>
        <custom-select [options]="vatOptions" [(selectedKey)]="selectedVatOption"></custom-select>
      </div>
    </div>
    <div>
      <label class="custom-checkbox-container required">
        <input type="checkbox" class="column-checkbox" [(ngModel)]="termsOfAgreementAccepted"
          name="termsOAgreementInput" id="termsOAgreementInput">
        <span class="checkmark"></span>
        <label for="termsOAgreementInput">{{'AddSubcustomer.TermsOfAgreement' | translate}}</label>
      </label>
    </div>
  </form>
  <div class="horizontalContainer">
    <p class="required">{{'AddSubcustomer.RequiredDisclaimer' | translate}}</p>
    <button class="button-basic" id="add-user-button" (click)="addSubCustomer()" [disabled]="!canSave()">
      {{'AddSubcustomer.SendForm' | translate}}
    </button>
  </div>
</div>

<ng-template #errorsDisplay let-c="controll">
  <app-control-errors *ngIf="!c.valid && c.touched && c.dirty" [control]="c"></app-control-errors>
</ng-template>
