import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KreditorService } from '../shared/kreditor.service';
import { ICaseSearchOptions, ICaseSearchResult, IPaginatedResponse, IPaginatedSearch } from '../shared/models';
import { ICustomCaseSearchOptions, ICustomCaseSearchResult, CUSTOM_CASE_SEARCH_COLUMNS } from '../reports/reports-models';
import { UtilsService } from '../shared/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CaseSearchService {

  DEFAULT_PAGE_SIZE = 20;
  constructor (private api: KreditorService, private utilsService: UtilsService) { }

  defaultOptions(): ICaseSearchOptions {
    return { term: null, state: 'Active' } as ICaseSearchOptions;
  }

  search(options: ICaseSearchOptions): Observable<ICaseSearchResult> {
    if (!options.type) { options.type = 'generic'; }
    const url = this.utilsService.getUrlFromObject('cases/search', options);

    return this.api.getServiceResult<ICaseSearchResult>(url)
      .pipe(map(_ => _.result));
  }

  exportExcel(options: ICaseSearchOptions, language: string): Observable<Blob> {
    options.pageNumber = 1;
    options.pageSize = 0;
    if (!options.type) { options.type = 'generic'; }
    
    const url = this.utilsService.getUrlFromObject('cases/exportExcel' + `/${language}`, options);

    return this.api.getFile(url);
  }

  getUrlParametersFromSearchOptions(options: ICaseSearchOptions): string {
    let urlParameters = `${options.term ? '?term=' + options.term + "&" : '?'}type=${options.type ?? 'generic'}&state=${options.state ?? 'NA'}&role=${options.role ?? 'NA'}`;

    if (options.customerReference1 != null) {
      urlParameters += `&customerReference1=${options.customerReference1}`
    }

    if (options.customerReference2 != null) {
      urlParameters += `&customerReference2=${options.customerReference2}`
    }

    urlParameters += `&pageNumber=${((options.pageNumber ?? 0) === 0 ? '1' : options.pageNumber)}&pageSize=${options.pageSize ?? this.DEFAULT_PAGE_SIZE}`;

    return urlParameters;
  }

  customCaseSearch(paginatedSearch: IPaginatedSearch<ICustomCaseSearchOptions>): Observable<IPaginatedResponse<ICustomCaseSearchResult>> {
    return this.api.postServiceResult<IPaginatedResponse<ICustomCaseSearchResult>, IPaginatedSearch<ICustomCaseSearchOptions>>('cases/customSearch', paginatedSearch)
      .pipe(map(_ => _.result))
  }

  customCaseSearchAllCases(filters: ICustomCaseSearchOptions): Observable<ICustomCaseSearchResult[]> {
    let paginatedSearch = { pageSize: 0, currentPage: 1, filter: filters } as IPaginatedSearch<ICustomCaseSearchOptions>

    if (!filters.caseSearchColumns) {
      filters.caseSearchColumns = Array.from(CUSTOM_CASE_SEARCH_COLUMNS);
    }

    return this.customCaseSearch(paginatedSearch)
      .pipe(map(_ => _.pageItems))
  }

  getUserSearchColumns(): Observable<string[]> {
    return this.api.getServiceResult<string[]>('users/searchColumns')
      .pipe(map(_ =>  _.result));
  }

  updateUserSearchColumns(columns: string[]) {
    return this.api.postServiceResult<any, string[]>('users/searchColumns', columns);
  }
}
