import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebtorsService, IDebtorCreditScore } from '../../../services/debtors.service';
import { RxHook, RxState } from '../../../shared/RxHook';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-credit-score',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './credit-score.component.html',
  styleUrls: ['./credit-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxHook]
})
export class CreditScoreComponent implements OnChanges {

  @Input() debtorId: number;


  constructor (private service: DebtorsService, private rxHook: RxHook) { }

  score: RxState<IDebtorCreditScore>;


  ngOnChanges(changes: SimpleChanges): void {

    let debtorIdChange = changes['debtorId'];

    if (debtorIdChange.currentValue != debtorIdChange.previousValue) {
      this.load();
    }
  }

  load() {
    this.score = this.rxHook.use(this.service.getScore(this.debtorId));
  }
}
