<div class="select-container" [class]="position">
    <span class="trigger" (click)="displayList.set(!displayList())">
        <i class="fas fa-chevron-down"></i>
    </span>
    <div *ngIf="displayList()" class="columns-container">
        <div>
            <label class="label">
                {{'TableSelectColumns.OrderColumns' | translate}}
                <i class="fa fa-info-circle" aria-hidden="true" 
                    [matTooltip]="'TableSelectColumns.OrderColumnsInfo' | translate"></i>
            </label>
            <ul (dragover)="onDragOver($event)"
                (drop)="onDrop($event, 'Selected')">
                <li *ngFor="let column of selectedColumns()"
                    [id]="column.name"
                     draggable="true"
                    (dragstart)="onDragStart($event, column.name, 'Selected')">
                    <i class="fas fa-bars"></i> {{column.translationTag | translate}} 
                </li>
            </ul>
        </div>
       
        <div class="available-columns-container">
            <label class="label">
                {{'TableSelectColumns.AddColumn' | translate}}
                <i class="fa fa-info-circle" aria-hidden="true" 
                    [matTooltip]="'TableSelectColumns.AddColumnInfo' | translate"></i>
            </label>
            <ul (dragover)="onDragOver($event)"
                (drop)="onDrop($event, 'Available')">
                <li *ngFor="let column of availableColumns()" draggable="true"
                    (dragstart)="onDragStart($event, column.name, 'Available')"
                    >
                    {{column.translationTag | translate}}
                </li>
            </ul>
        </div>
    </div>
</div>