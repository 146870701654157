import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { ValidatorService } from '../validator.service';

@Component({
  selector: 'app-control-errors',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss']
})
export class ControlErrorsComponent {

  constructor(public validator: ValidatorService) {}

  @Input() control : AbstractControl;

  @Input() hide: boolean;
}
