import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { concatAll, groupBy, mergeMap, take, takeUntil, toArray } from 'rxjs/operators';
import { ICustomCaseSearchOptions, ICustomCaseSearchResult } from 'src/app/reports/reports-models';
import { CaseSearchService } from 'src/app/services/case-search.service';
import { LocalizationService, LocalizedNumberFormat } from 'src/app/shared/localization/localization.service';
import { ICaseViewModel, IDebtorStats } from 'src/app/shared/models';
import { RxHook } from 'src/app/shared/RxHook';

@Component({
  selector: 'app-case-debtor',
  templateUrl: './case-debtor.component.html',
  styleUrls: ['./case-debtor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxHook]
})
export class CaseDebtorComponent implements OnInit, OnDestroy {
  @Input() case: ICaseViewModel;
  @Input() debtorStats: IDebtorStats;
  @ViewChild('relatedCasesPopup') relatedCasesPopup: TemplateRef<any>;

  SEARCH_CUSTOM_VALUE_NULL_STRING = '<NULL>'

  debtorRelatedCasesOnlyActive: boolean = true;
  referencesRelatedCasesOnlyActive: boolean = true;

  numberFormat: LocalizedNumberFormat;
  dateFormat: string;
  language: string;

  data;
  dialogRef: MatDialogRef<any>;

  private readonly unsubscribe$ = new Subject();

  get isEnglish() {
    return this.translateService.getDefaultLang().toLocaleLowerCase() == 'en';
  };

  constructor(
    public localizationService: LocalizationService
    , private rxHook: RxHook
    , private search: CaseSearchService
    , public dialog: MatDialog
    , public translateService: TranslateService
    , private router: Router) {
  }

  ngOnInit(): void {
    this.localizationService.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        this.numberFormat = _.numberPipeFormat;
        this.dateFormat = _.datePipeFormat;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getCasesWithSameDebtor() {
    const caseSearchOptions = {
      debtorId: this.case.debtor.id
      , activeOrClosed: this.debtorRelatedCasesOnlyActive ? 'Active' : 'NA'
      , caseSearchColumns: ['CaseNumber', 'CustomerReference', 'RestCapital', 'RestTotal', 'StatusText', 'StatusTextEn']
    } as ICustomCaseSearchOptions;

    let search$ = this.search.customCaseSearchAllCases(caseSearchOptions)
      .pipe(
        concatAll()
        , groupBy(_case => _case.caseNumber, _case => _case)
        , mergeMap(group => group.pipe(take(1)))
        , toArray()
      )

    return this.rxHook.use(search$);
  }


  getCasesWithSameReferences() {
    if (this.stringIsNullOrEmpty(this.case.reference1) && this.stringIsNullOrEmpty(this.case.reference2))
      return this.rxHook.use(of([]))

    const caseSearchOptions = {
      reference1: this.stringIsNullOrEmpty(this.case.reference1) ? this.SEARCH_CUSTOM_VALUE_NULL_STRING : this.case.reference1
      , reference2: this.stringIsNullOrEmpty(this.case.reference2) ? this.SEARCH_CUSTOM_VALUE_NULL_STRING : this.case.reference2
      , activeOrClosed: this.referencesRelatedCasesOnlyActive ? 'Active' : 'NA'
      , caseSearchColumns: ['CaseNumber', 'CustomerReference1', 'CustomerReference2', 'RestCapital', 'RestTotal', 'StatusText', 'StatusTextEn']
    } as ICustomCaseSearchOptions;

    let search$ = this.search.customCaseSearchAllCases(caseSearchOptions)
      .pipe(
        concatAll()
        , groupBy(_case => _case.caseNumber, _case => _case)
        , mergeMap(group => group.pipe(take(1)))
        , toArray()
      )

    return this.rxHook.use(search$);
  }

  onOpenRelatedCasesPopup() {
    this.data = { sameDebtorData: this.getCasesWithSameDebtor(), sameReferenceData: this.getCasesWithSameReferences() };

    this.dialogRef = this.dialog.open(this.relatedCasesPopup, { data: this.data, minHeight: '200px' });
    this.dialogRef.afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => { this.dialogRef = null })
  }

  onRelatedCasesToggleChange(type: RelatedCasesType) {
    if (type == 'Debtor')
      this.data.sameDebtorData = this.getCasesWithSameDebtor();
    else if (type == 'Reference')
      this.data.sameReferenceData = this.getCasesWithSameReferences();
  }

  openCase(caseNumber: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/cases/${caseNumber}`])
    );
    window.open(url, '_blank');
  }

  getTotals(value: ICustomCaseSearchResult[]) {
    return value?.reduce((prev, current) => {
      return {
        restCapitals: prev.restCapitals + current.restCapital,
        restCosts: prev.restCosts + (current.restTotal - current.restCapital),
        restTotals: prev.restTotals + current.restTotal
      }
    }, ({ restCapitals: 0, restCosts: 0, restTotals: 0 }))
  }


  private stringIsNullOrEmpty(value: string) {
    return value == null || value.trim() == '';
  }
}

export type RelatedCasesType = 'Reference' | 'Debtor';
