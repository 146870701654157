import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PaymentLinkDialogComponent } from 'src/app/cases/case/payment-link/payment-link-dialog.component';
import { AppService } from 'src/app/services/app.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LocalizationService } from 'src/app/shared/localization/localization.service';
import { WebUserRight } from 'src/app/shared/models';
import { ContractsService } from '../contracts.service';
import { IContractCase } from '../models';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit, OnDestroy {

  displayPaymentLinkOption$ = this.application.userHasRight(WebUserRight.PaymentLink)
  contractReference: string;
  contractStatusId: number;
  data$: Observable<IContractCase[]>;
  format$ = this.localizationService.settings$;
  isLoading: boolean = false;
  caseNumberForPaymentLink: number;


  warningStatuses$: Observable<number[]>;

  constructor(private dialogRef: MatDialogRef<any>,
    private service: ContractsService,
    private localizationService: LocalizationService,
    private settingsService: SettingsService,
    private router: Router,
    private application: AppService,
    private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.warningStatuses$ = this.settingsService.getSettingValueByName('CONTRACT_WARNING_STATUSES')
      .pipe(map(_ => {
        if (_)
          return _.split(',').map(nr => +nr);
        else return null;
      }));

    this.data$ = forkJoin([this.service.getCases(this.contractStatusId), this.warningStatuses$]).pipe(
      map(([cases, warningStatuses]) => {
        cases.forEach(_ => _.isWarningStatus = warningStatuses.includes(_.statusCode));
        this.caseNumberForPaymentLink = this.getCaseNumberForSendingPaymentLink(cases);
        return cases;
      }
      ),
      finalize(() => {
        this.isLoading = false;
      }));
  }

  selectCase(selectedCase: IContractCase) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/cases/${selectedCase.caseNumber}`])
    );
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
  }

  close() {
    this.dialogRef.close();
  }

  getCaseNumberForSendingPaymentLink(cases: IContractCase[]): number {
    let casesByRegDate = cases.sort((b, a) => new Date(b.registeredDate).getTime() - new Date(a.registeredDate).getTime());
    let caseWithRestCapital = casesByRegDate.find(c => c.restCapital > 10);

    if(caseWithRestCapital)
      return caseWithRestCapital.caseNumber;
    else
      return casesByRegDate[0].caseNumber;
  }

  getCaseClass(currentCase: IContractCase) {
    if (currentCase.isWarnedCase === true) return 'warned';
    return '';
  }

  openPaymentLinkDialog() {
    let dialogRef = this.matDialog.open(PaymentLinkDialogComponent);

    dialogRef.componentInstance.caseNumber = this.caseNumberForPaymentLink;
    dialogRef.componentInstance.contractReference = this.contractReference;

    dialogRef.afterClosed().subscribe(() => { this.dialogRef.close();})
  }
}
