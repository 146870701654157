import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../login.service';
import { ValidatorService } from 'src/app/shared/validator.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { IValidationResult } from 'src/app/shared/models';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordRequestToken: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    public validator: ValidatorService,
    private messageToUi: MessageToUiService) { }

  ngOnInit(): void {
    this.passwordRequestToken = this.route.snapshot.params.requestId;
  }

  onResetPassword() {
    if (this.newPassword === this.confirmPassword) {
      this.loginService.changePassword(this.passwordRequestToken, this.newPassword).subscribe(success => {
        this.messageToUi.successKey('Login.PasswordResetSuccess');
        this.router.navigate(['/login']);
      },
        error => {
          if (error.error?.result) {
            let validationError = error.error.result as IValidationResult;
            this.messageToUi.error(validationError.errors[0].errorMessage);
          }
          else {
            this.messageToUi.error(error);
          }
        });
    }
    else {
      this.messageToUi.errorKey('Login.Errors.PasswordMustMatch');
    }
  }
}
