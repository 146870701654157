import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KEYS, MessageToUiService } from '../services/message-to-ui.service';
import { LocalizationService } from '../shared/localization/localization.service';
import { IUserCredentials, IWebNewsModel } from '../shared/models';
import { ValidatorService } from '../shared/validator.service';
import { LoginService } from './login.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GlobalSpinnerService } from '../shared/global-spinner/global-spinner.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') form: NgForm;
  @ViewChild("forgotPasswordModal") forgotPasswordModal: TemplateRef<any>;

  userCredentials = {} as IUserCredentials;
  supportedLanguages: any[];
  selectedLanguageCode: string;
  usernameEmail: string;
  isPending2FA: boolean = false;
  twoFactorMessage: any;
  subs: Subscription[] = [];
  loginNews: IWebNewsModel[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private localization: LocalizationService,
    private translateService: TranslateService,
    public validator: ValidatorService,
    private messageToUi: MessageToUiService,
    public dialog: MatDialog,
    private spinner: GlobalSpinnerService,
    private dashService: DashboardService
  ) { }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  ngOnInit(): void {
    this.supportedLanguages = this.localization.getSupportedLanguages();
    this.selectedLanguageCode = this.translateService.getDefaultLang();

    this.subs.push(
      this.dashService.getDashboardNews().subscribe({
        next: (news) =>  this.loginNews = news.filter(x => x.newsSection == 'loginAlert')
      })
    )
  }

  onLogin() {
    this.form.form.markAllAsTouched();
    this.spinner.show();
    this.subs.push(
      this.loginService.login(this.userCredentials)
        .pipe(finalize(() => this.spinner.hide()))
        .subscribe({
          next: () => {
            const redirectUrl = this.route.snapshot.queryParams['redirect'];
            if (redirectUrl) this.router.navigate([redirectUrl]);
            else this.router.navigate(['/dashboard']);
          },
          error: (error) => {
            this.handleError(error);
          }
        })
    );
  }

  onChangeLanguage(code: string) {
    this.selectedLanguageCode = code;
    this.localization.changeLanguage(code);
  }

  openForgotPasswordModal() {
    this.dialog.open(this.forgotPasswordModal, { width: '30em', });
  }

  onCancel() {
    this.userCredentials = {} as IUserCredentials
    this.isPending2FA = false;
  }

  sendForgotPasswordRequest(form: NgForm) {
    var applicationSource = location.origin;
    this.subs.push(
      this.loginService.forgotPassword(this.usernameEmail, this.selectedLanguageCode, applicationSource)
        .subscribe({
          next: () => { this.messageToUi.successKey('Login.PasswordRequestSent'); this.dialog.closeAll() },
          error: (e) => this.validator.handleError(form, e)
        })
    );
  }

  private handleError(error: any) {
    let errorKey = '';
    if (error?.status === 401) {
      var msg = error?.error?.result?.message;

      if (msg == 'PasswordLocked')
        errorKey = KEYS.PasswordLocked;
      else if (msg === 'UserLocked')
        errorKey = KEYS.UserLocked;
      else if (msg.startsWith('TwoFactorRequired')) {

        this.twoFactorMessage = this.translateService.instant(KEYS.TwoFactorRequired) + " " + msg.split('_')[1];
        if (this.isPending2FA) {
          errorKey = KEYS.TwoFactorInvalidCode;
        } else {
          this.isPending2FA = true;
        }

      } else {
        errorKey = KEYS.WrongUsernameOrPassword;
      }

      if (errorKey.length > 0)
        this.messageToUi.errorKey(errorKey);

      return;
    }

    this.validator.handleError(this.form, error);
  }
}