import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CaseOpenLocationService } from '../case-open-location.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';

@Component({
  selector: 'app-cases-iterator',
  templateUrl: './cases-iterator.component.html',
  styleUrls: ['./cases-iterator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CasesIteratorComponent implements OnChanges {

  constructor (private router: Router, private openCaseTracker: CaseOpenLocationService, private notifications: MessageToUiService) {
  }

  @Input() caseNumber: number;

  vm$: Observable<CaseIteratorVM>;

  ngOnChanges(changes: SimpleChanges): void {

    const caseNumberChanges = changes['caseNumber'];

    if (caseNumberChanges.currentValue != caseNumberChanges.previousValue) {

      this.vm$ = this.openCaseTracker.caseIteration$
        .pipe(
          map(iterateInstructions => {

            let vm = { selectedIndex: 0, caseNumbers: [this.caseNumber] } as CaseIteratorVM;

            if (this.valid(iterateInstructions, this.caseNumber)) {

              vm.selectedIndex = iterateInstructions.selectedIndex;
              vm.caseNumbers = iterateInstructions.caseNumbers;
              vm.hasNextPage = iterateInstructions.hasNextPage
              vm.hasPreviousPage = iterateInstructions.hasPreviousPage;
            }

            vm.hasNext = vm.hasNextPage || (vm.selectedIndex < (vm.caseNumbers.length - 1))
            vm.hasPrevious = vm.hasPreviousPage || (vm.selectedIndex > 0)

            return vm;
          }));
    }
  }

  previous(vm: CaseIteratorVM) {

    (vm.selectedIndex == 0)
      ? this.openCaseTracker.movePage('back')?.subscribe({ next: () => { }, error: e => { this.notifications.genericError() } })
      : this.openCaseTracker.previous();

  }

  next(vm: CaseIteratorVM) {

    (vm.selectedIndex == (vm.caseNumbers.length - 1))
      ? this.openCaseTracker.movePage('forward')?.subscribe({ next: () => { }, error: e => { this.notifications.genericError() } })
      : this.openCaseTracker.next();
  }

  private valid(iterateInstructions: CaseIteratorInput, caseNumberToOpen: number): boolean {
    return iterateInstructions?.caseNumbers != null
      && iterateInstructions.caseNumbers[iterateInstructions.selectedIndex] == caseNumberToOpen;
  }
}
export interface CaseIteratorInput {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  caseNumbers?: Array<number>
  selectedIndex?: number;
}

export interface CaseIteratorVM extends CaseIteratorInput {
  hasPrevious: boolean;
  hasNext: boolean;
}

