import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { MessageToUiService } from 'src/app/services/message-to-ui.service';
import { LocalizationService } from 'src/app/shared/localization/localization.service';
import { WebUserRight } from 'src/app/shared/models';
import { UtilsService } from 'src/app/shared/utils.service';
import { CasesComponent } from '../cases/cases.component';
import { ChangeStatusComponent } from '../change-status/change-status.component';
import { ContractsService } from '../contracts.service';
import { ContractStatusChangeActionTypeEnum, DebtorTypeEnum, IContractClaimStatusSummary, IContractClaimTypeSummary, IContractSummary, IExternalContractStatus, IFilterContracts, IUpdatedContractsMultipleStatuses, ScoreColorEnum } from '../models';
import { NotesComponent } from '../notes/notes.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {

  @ViewChild('externalStatus') public externalStatus: OverlayPanel;
  subscriptions: Subscription[] = [];
  keyValueClaimTypes: KeyValue<string, string>[];
  claimTypes: IContractClaimTypeSummary[];
  statuses: IContractClaimStatusSummary[] = [];
  filter: IFilterContracts = { customerNumber: null };
  debtorTypes: KeyValue<string, string>[];
  scoreColors: KeyValue<string, string>[];
  private availableDebtorTypes = [DebtorTypeEnum.Person, DebtorTypeEnum.Company];
  private availableScoreColors = [ScoreColorEnum.Red, ScoreColorEnum.Yellow, ScoreColorEnum.Green];

  currentPage: number = 1;
  numberOfItemsPerPage: number = 25;
  totalNumberOfItems: number;
  indexOfFirstTableElement: number = 0;

  list$: Observable<IContractSummary[]>;
  originalList$: Observable<IContractSummary[]>;

  contractClosingTypeChanges$ = new BehaviorSubject<IChangedContract>(null);

  isLoading: boolean = false;
  searchTerm: string;

  format$ = this.localizationService.settings$;
  customerNumber$: Observable<number> = this.application.customerNumber$;
  externalStatusData$: Observable<IExternalContractStatus>;

  createClosingOrder = ContractStatusChangeActionTypeEnum.CreateClosingOrder;
  finishClosingOrder = ContractStatusChangeActionTypeEnum.FinishClosingOrder;
  isEnglish = this.translate.currentLang.toLocaleLowerCase() == 'en';
  canViewScore$: Observable<boolean> = this.application.userHasRight(WebUserRight.ViewScore).pipe(shareReplay());

  selectedStatus: IContractClaimStatusSummary;
  refreshStatistics: number = 0;
  numberOfSelectedContracts = signal(0);

  DEFAULT_CLOSING_TYPE: number = 1;

  constructor(private service: ContractsService,
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private messageToUi: MessageToUiService,
    private utils: UtilsService,
    private application: AppService,
    private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.debtorTypes = this.availableDebtorTypes.map(x => { return { key: x, value: this.translate.instant(`Debtors.${x}`) } as KeyValue<string, string> });
    this.scoreColors = this.availableScoreColors.map(x => { return { key: x, value: this.translate.instant(`Contracts.ScoreColor${x}`) } as KeyValue<string, string> });
    this.subscriptions.push(this.translate.onLangChange.subscribe(_ => this.isEnglish = _.lang.toLocaleLowerCase() == 'en'));
    this.getstatuses();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(x => x.unsubscribe());
  }

  getstatuses() {
    this.subscriptions.push(this.service.getSelectedClaimTypesForUser().subscribe(data => {
      this.claimTypes = data;
      this.keyValueClaimTypes = data.map(x => { return { key: x.claimTypeCode, value: `${x.claimTypeCode} ${x.claimTypeDescription}` } as KeyValue<string, string> });
      this.filter.contractType = this.claimTypes.find(p => p.isDefault == true)?.claimTypeCode;
      this.getStatuses(this.filter.contractType);
    }));
  }

  getStatuses(claimTypeCode: string) {
    let selectedClaimType = this.claimTypes?.find(p => p.claimTypeCode == claimTypeCode);
    if (selectedClaimType) {
      this.statuses = selectedClaimType.statuses;
    }
    else {
      this.statuses = [];
    }
  }

  getClassForWorkListItem(item: IContractClaimStatusSummary): string {
    if (item.workList === true) return 'work-list';
    return '';
  }

  public getPageReportTemplate(): string {
    if (this.totalNumberOfItems) {
      return this.translate.instant('General.PageReportTemplate', {
        from: this.indexOfFirstTableElement + 1,
        to: this.indexOfFirstTableElement + this.numberOfItemsPerPage > this.totalNumberOfItems ? this.totalNumberOfItems : this.indexOfFirstTableElement + this.numberOfItemsPerPage,
        totalCount: this.totalNumberOfItems
      });
    }
    return '';
  }

  onSubmit() {
    this.searchTerm = '';
    this.isLoading = true;

    this.refreshStatistics++;

    this.originalList$ = this.application.customerNumber$
      .pipe(mergeMap(newCustomerNr => {
        this.filter.customerNumber = newCustomerNr;
        this.filter.statusName = this.selectedStatus?.name;
        return this.service.getContracts(this.filter).pipe(
          catchError(e => {
            this.messageToUi.genericError();
            return of([] as IContractSummary[]);
          }),
          tap(_ => this.totalNumberOfItems = _.length),
          shareReplay(1),
          finalize(() => {
            this.isLoading = false;
          }));
      }),
        shareReplay(1));
    this.list$ = combineLatest([this.originalList$, this.contractClosingTypeChanges$])
      .pipe(map(([list, changedContract]) => {
        if (changedContract) {
          let indexToUpdate = list.findIndex(c => c.id == Number(changedContract.contractStatusId));
          list[indexToUpdate].contractClosingTypeId = Number(changedContract.closingTypeId);
        }
        return list;
      }));
  }

  onFilter() {
    if (this.list$) {
      this.list$ = this.list$.pipe(
        map(_ => {
          let result = this.utils.filterArray(_, this.searchTerm, []);
          this.totalNumberOfItems = result.length
          return result;
        })
      );
    }
  }

  onExportExcel() {
    this.isLoading = true;

    this.subscriptions.push(this.service.exportExcel(this.filter, this.translate.getDefaultLang())
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (blob) => {
          saveAs(blob, `${this.translate.instant('Contracts.Title')}_${new Date().getDate() + (new Date().getMonth() + 1) + new Date().getFullYear() + '_' + new Date().getTime().toString().slice(0, 6)}.xlsx`);
        },
        error: () => {
          this.messageToUi.genericError();
        }
      }));
  }

  onToggleSelectContract(isSelected: boolean) {
    const step = isSelected ? 1 : -1;
    this.numberOfSelectedContracts.set(this.numberOfSelectedContracts() + step);
  }

  selectAll(event) {
    if (this.list$) {
      this.list$ = this.originalList$.pipe(
        map(_ => {
          _.forEach(p => {
            p.selected = event.target.checked;
          });
          return _;
        })
      );
    }
    const numberOfSelectedContracts = event.target.checked ? this.totalNumberOfItems : 0;
    this.numberOfSelectedContracts.set(numberOfSelectedContracts);
  }

  openChangeStatus() {
    var selectedIds: number[];
    var contractsStatusInfo: IUpdatedContractsMultipleStatuses[];
    var uniqueSelectedClaimTypes: string[];
    this.subscriptions.push(this.originalList$.subscribe(data => {
      let result = data.filter(p => p.selected == true);
      if (result.length > 0) {
        selectedIds = result.map(x => x.id);
        let selectedClaimTypes = result.map(x => x.claimTypeCode);
        uniqueSelectedClaimTypes = selectedClaimTypes.filter((x, i) => selectedClaimTypes.indexOf(x) === i);
        contractsStatusInfo = result.map(x => {
          return {
            contractStatusId: x.id,
            claimTypeCode: x.claimTypeCode        
          } as IUpdatedContractsMultipleStatuses
        });
      }
      else this.messageToUi.errorKey('Contracts.NoContractSelected');
    }));

    if (!selectedIds || selectedIds.length == 0) return;

    let dialogRef = this.matDialog.open(ChangeStatusComponent);
    dialogRef.componentInstance.selectedIds = selectedIds;
    dialogRef.componentInstance.selectedClaimTypeCodes = uniqueSelectedClaimTypes;
    dialogRef.componentInstance.isEnglish = this.isEnglish;

    let claimTypes = this.claimTypes.filter(p => uniqueSelectedClaimTypes.includes(p.claimTypeCode));
    claimTypes.forEach(p => p.selectedStatus = p.statuses.find(x => x.name = 'Open'));
    dialogRef.componentInstance.selectedClaimTypes = claimTypes;
    dialogRef.componentInstance.contractsStatusInfo = contractsStatusInfo;

    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => { if (result) this.onSubmit(); }));
  }

  showCases(contractStatusId: number, contractReference: string) {
    let dialogRef = this.matDialog.open(CasesComponent);
    dialogRef.componentInstance.contractStatusId = contractStatusId;
    dialogRef.componentInstance.contractReference = contractReference;
    dialogRef.afterClosed().subscribe(() => { });
  }

  showExternalStatus(event: any, contractStatusId: number) {
    this.externalStatusData$ = this.service.getExternalStatus(contractStatusId);

    this.externalStatus.show(event);
  }

  hideExternalStatus() {
    this.externalStatus.hide();
  }


  getClassForExternalStatus(lastUpdateWasSuccessfull: boolean) {
    return lastUpdateWasSuccessfull == true ? '' : 'red';
  }

  showNotes(contractStatusId: number, contractReference: string, contractClosingTypeId: number) {
    let dialogRef = this.matDialog.open(NotesComponent, {id: "notesPopup"});
    dialogRef.componentInstance.contractStatusId = contractStatusId;
    dialogRef.componentInstance.contractReference = contractReference;
    dialogRef.componentInstance.contractClosingTypeId = contractClosingTypeId;
    dialogRef.afterClosed().subscribe((closingTypeId) => {
      if (closingTypeId) {
        this.contractClosingTypeChanges$.next({ contractStatusId, closingTypeId } as IChangedContract);
      }
    });
  }
}

export interface IChangedContract {
  contractStatusId: number;
  closingTypeId: number;
}
