<p-table [value]="data?.value ?? []" [scrollable]="true" selectionMode="single" scrollHeight="250px"
  [tableStyle]="{'min-height': '100px'}" styleClass="p-datatable-sm" (onRowSelect)="openLetter($event)">
  <ng-template pTemplate="header">
    <tr>
      <th>{{'Case.Remittance.Date' | translate}}</th>
      <th>{{'Case.Remittance.FileName' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-letter>
    <tr>
      <th>{{letter.dateCreated | date}}</th>
      <td (click)="openLetter(letter)"><span class="filename">{{letter.fileName}}</span></td>
    </tr>
  </ng-template>
</p-table>
