import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit {
  resume: { flagged?: any; tasks?: any; newCaseInfo?: any };

  ngOnInit(): void {
    this.resume = history.state.resume as unknown;
  }
}

