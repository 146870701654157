<div>
  <p>
    <b>{{'NewCase.Step2.SelfCollectionInfo' | translate}}</b>
  </p>
  <ul class="info-bullets">
    <li>{{'NewCase.Step2.BulletPoint1' | translate}}</li>
    <li>{{'NewCase.Step2.BulletPoint2' | translate: {reminderDaysLimit: reminderDaysLimit} }}</li>
    <div *ngIf="usingDanishRules()">
      <li>{{'NewCase.Step2.BulletPoint2.1' | translate}}</li>
      <li>{{'NewCase.Step2.BulletPoint2.2' | translate}}</li>
    </div>
    <li>{{'NewCase.Step2.BulletPoint3' | translate: {reminderDaysLimit: reminderDaysLimit} }}</li>
    <li>{{'NewCase.Step2.BulletPoint4' | translate}}</li>
    <li>{{'NewCase.Step2.BulletPoint5' | translate}}</li>
  </ul>
</div>
