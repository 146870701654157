import { Inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigurationModel } from './app-configuration-model';
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AppConfigService {

  constructor(@Inject(APP_CONFIG) private appConfig, private http: HttpClient) { }

  async loadAppConfig() {
    let url = "./environments/appConfig.json";

    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    const dat$ = this.http.get<AppConfigurationModel>(url, { headers: headers })
    this.appConfig = await firstValueFrom(dat$);
  }

  getConfig() {
    return this.appConfig;
  }
}

export const APP_CONFIG: InjectionToken<AppConfigurationModel>
  = new InjectionToken<AppConfigurationModel>('Application Configuration');